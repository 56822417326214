import { Button, Space } from "antd";



function HomePageBottomActionBar({onClick}){
    return(
        <div className="home-mobile-bottom-bar no-desktop">
            <Space>
                <h2 className="align-div vertical-align" >
                    Get Started:
                </h2>
                <div className="align-div vertical-align">
                    <Button type={'primary'} size='large' className='rounded-button' style={{color:'white',backgroundColor:'#ef3078',borderColor:'#ef3078'}} onClick={onClick}>Create Your Profile</Button>
                </div>
            </Space>
        </div>
    )
}

export default HomePageBottomActionBar;