import React, {Suspense, useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from './containers/landing/HomePage';
import './App.css';
import "antd/dist/antd.css";
import './style/index.css';
import { AuthLayout } from './components/Layout/AuthLayout';
import { DashboardLayout } from './components/Layout/DashboardLayout';
import LoginPage from './containers/user/LoginPage';
import RegisterPage from './containers/user/RegisterPage';
import CreatorProfilePage from './containers/creators/CreatorProfilePage';
import ForBusinessPage from './containers/landing/ForBusinessPage';
import { EventTrackingProvider } from './components/Context/EventTrackingContext';
import DiscoveryLayout from './components/Layout/DiscoveryLayout';
import LoadingPage from './containers/landing/LoadingPage';

const PostPage = React.lazy(() => import('./containers/posts/PostPage'));
const AddProductToPostPage = React.lazy(() => import('./containers/products/AddProductToPostPage'));
const ForCreatorPage = React.lazy(() => import('./containers/landing/ForCreatorsPage'));
const CreatorSearchPage = React.lazy(() => import('./containers/creators/CreatorSearchPage'));
const PrivacyPolicyPage = React.lazy(() => import('./containers/landing/PrivacyPolicyPage'));
const WorkPage = React.lazy(() => import('./containers/work/WorkPage'));
const CreateCollabPage = React.lazy(() => import('./containers/work/CreateCollabPage'));
const ShopPage = React.lazy(() => import('./containers/shop/ShopPage'));
const ProfilePage = React.lazy(() => import('./containers/shop/ProfilePage'));
const CreateBrandPage = React.lazy(() => import('./containers/work/CreateBrandPage'));
const OnboardingPage = React.lazy(() => import('./containers/user/OnboardingPage'));
const CollabPage = React.lazy(() => import('./containers/work/CollabPage'));
const ActivePage = React.lazy(() => import('./containers/work/ActivePage'));

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUserToken = () => {
      const userToken = localStorage.getItem('user-token');
      if (!userToken || userToken === 'undefined') {
          setIsLoggedIn(false);
      }
      setIsLoggedIn(true);
  }

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  return (
    <div className="App">
      <EventTrackingProvider>
        <Router>
          <Suspense fallback={<LoadingPage />} >
            <Routes>
              <Route element={<AuthLayout />}>
                <Route path='/' element={<HomePage /> } />
                <Route path='/influencer' element={<ForCreatorPage /> } />
                <Route path='/business' element={<ForBusinessPage /> } />
                <Route path='/creators' element={<CreatorSearchPage /> } />
                <Route path='/contest/:contest_id/post/:id' element={<PostPage /> } />
                <Route path='/onboarding' element={<OnboardingPage /> } />
                <Route path='/privacy' element={<PrivacyPolicyPage /> } />
                <Route element={<DashboardLayout /> }>
                  <Route path='/login' element={<LoginPage /> } />
                  <Route path='/register' element={<RegisterPage /> } />
                  <Route path='/:username' element={<CreatorProfilePage /> } />
                  <Route path='/:username/post/:id/addproducts' element={<AddProductToPostPage /> } />
                  <Route path='/:username/post/:id' element={<PostPage /> } />  
                  <Route element={<DiscoveryLayout /> }>
                    <Route path="/wander" element={<LoadingPage />} />
                    <Route path="/work" element={<WorkPage />} />
                    <Route path="/active" element={<ActivePage />} />
                    <Route path="/shop" element={<ShopPage />} />
                    <Route path="/profile" element={<ProfilePage />} />
                    <Route path="/collab/:id" element={<CollabPage />} />
                    <Route path="/collab/create" element={<CreateCollabPage />} />
                    <Route path="/brand/create" element={<CreateBrandPage />} />
                  </Route>
                </Route>
              </Route>
            </Routes>
          </Suspense>
        </Router>
      </EventTrackingProvider>
    </div>
  );
}

export default App;
