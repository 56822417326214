import { Link } from "react-router-dom";

function BottomButton({text='Download Now!', url='https://onelink.to/fppgwf'}){

    return(
        <div style={{paddingBottom:'10px',cursor:'pointer'}}>
            <div className="align-div" style={{marginRight:'5px'}}>
                <Link to='/register'>
                    <div style={{backgroundColor:'#0891b2',borderRadius:'25px',width:'fit-content',color:'white',fontSize:'16px',padding:'8px',paddingLeft:'12px',paddingRight:'12px',fontWeight:600,margin:'auto'}}>
                        Create an Account
                    </div>
                </Link>
            </div>
            <div className="align-div">
                <a href={url} target="_blank">
                    <div style={{backgroundColor:'rgba(0,0,0,0.8)',borderRadius:'25px',width:'fit-content',color:'white',fontSize:'16px',padding:'8px',paddingLeft:'12px',paddingRight:'12px',fontWeight:600,margin:'auto'}}>
                        {text}
                    </div>
                </a>
            </div>
        </div>
    )
}

export default BottomButton;


