import { Form, Input } from "antd";
import { useState } from "react";
import AuthServices from "../../../utils/AuthServices";
import i18next from "i18next";
import { CheckCircleFilled, CloseCircleOutlined, LoadingOutlined, ShopOutlined, UserOutlined } from "@ant-design/icons";

function OnboardingForm8({formValues,setFormValues}){
    const [userValidated,setUserValidated] = useState(false);
    const [userValidating,setUserValidating] = useState(false);

    return(
        <div style={{width:'100%'}}>
            <div style={{width:'100%',paddingLeft:'10px',marginBottom:'20px'}}>
                <h2 style={{fontWeight:600,marginBottom:0,color:'rgba(0,0,0,0.8)'}}>
                    <strong>
                        {
                            formValues?.type === 'brand'
                            ? 'Choose Your Brand Name'
                            : 'Choose Your Username'
                        }
                    </strong>
                </h2>
            </div>
            <div style={{width:'100%',paddingBottom:'10px',textAlign:'left'}}>
                <div style={{marginBottom:'20px'}}>
                    <div style={{width:'100%'}}>
                        {
                            formValues?.type === 'brand'
                            ? <Input autoFocus={true} style={{borderRadius:'10px',borderColor:'#ef3078'}} placeholder={"ie. WanderCart Inc"} value={formValues?.name} size='large' prefix={<ShopOutlined style={{color:'rgba(0,0,0,0.4)',paddingRight:'5px'}} />} onChange={(value) => setFormValues({...formValues,name:value.target.value})} />
                            :
                                <Form.Item
                                    name="username"
                                    style={{marginBottom:'5px'}}
                                    rules={[
                                        {
                                            required: true,
                                            message: i18next.t('dashboard.auth.username_error_missing'),
                                            whitespace: true,
                                        },
                                        {
                                            validator: async (_, value) => {
                                                setUserValidating(true);
                                                const check = await AuthServices.checkRegister({'username': value});
                                                setUserValidating(false);
                                                if(value.includes('_')){
                                                    setUserValidated(false);
                                                    return Promise.reject(i18next.t('dashboard.auth.username_error_underscore'));
                                                }
                                                else if(value.includes(' ')){
                                                    setUserValidated(false);
                                                    return Promise.reject(i18next.t('dashboard.auth.username_error_space'));
                                                }
                                                else if(check.usernameUnique){
                                                    setUserValidated(true);
                                                    return Promise.resolve();
                                                } 
                                                else {
                                                    setUserValidated(false);
                                                    return Promise.reject(i18next.t('dashboard.auth.username_error_exists'));
                                                }
                                            }
                                        }
                                    ]}
                                    >
                                    <Input size='large' suffix={formValues?.username !== '' ? userValidating ? <LoadingOutlined style={{fontSize:'14px'}} /> : userValidated ? <CheckCircleFilled style={{color:'#52c41a',fontSize:'14px'}} /> : <CloseCircleOutlined style={{color:'#f5222d',fontSize:'14px'}} /> : null} prefix={<UserOutlined style={{color:'rgba(0,0,0,0.4)'}} />} placeholder={i18next.t('dashboard.auth.username')} value={formValues?.username} onChange={(value) => setFormValues({...formValues,username:value.target.value})} style={{borderRadius:'10px'}} />
                                </Form.Item>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OnboardingForm8;