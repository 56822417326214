import { EnvironmentOutlined } from "@ant-design/icons";
import { Button } from "antd";
import SocialTag from "../../Tag/SocialTag";
import noImage from '../../../images/no_image.webp';
import noBgImage from '../../../images/colorkit.webp';
import CategoryTag from "../../Tag/CategoryTag";
import { BsTiktok,BsInstagram,BsTwitter,BsSnapchat,BsWhatsapp,BsLinkedin,BsFacebook,BsEnvelope } from "react-icons/bs";
import WhatsAppServices from "../../../utils/WhatsAppServices";
import RateCardModal from "../../Modal/Profile/RateCardModal";
import { useState } from "react";

function UserCardContent({user,key='',socials=false,metrics=false,onClick=false,clickOffer=false,isOffer=false,hideButtons=false}){
    const [isRateCardModalVisible,setIsRateCardModalVisible] = useState(false);

    const clickCard = () => {
        if(onClick){
            onClick();
        }
    }
    const clickRate = () => {
        setIsRateCardModalVisible(true);
    }
    const clickWhatsApp = (value) => {
        WhatsAppServices.sendMessage({phoneNumber:value});
    }

    return(
        <div key={key} >
            <div className="user-card-image-container" onClick={clickCard}>
                <div className='user-card-banner-image' style={{backgroundImage:`url(${user?.bgImage || noBgImage})`}}>
                    <div className='user-card-avatar-image' style={{backgroundImage:`url(${user?.avatar_img || noImage})`}}></div>
                </div>
            </div>
            <div className="user-card-detail-container">
                <div style={{width:'100%'}} onClick={clickCard}>
                    <h2>
                        {user?.username} 
                    </h2>
                    {
                        !!(user?.region && user?.region !== '') &&
                            <p style={{color:'rgba(0,0,0,0.6)',fontSize:'14px'}}>
                                <EnvironmentOutlined color='red' /> {user?.region}
                            </p>
                    }
                    {
                        !!(user?.category && user?.category.length > 0) &&
                            <div style={{maxWidth:'200px',margin:'auto'}}>
                                <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
                                    {
                                        user?.category.map((value,index) => 
                                            <div key={`user-card-category-tag-${index}`} style={{marginBottom:'7px'}}>
                                                <CategoryTag value={value?.name || value} />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                    }
                    {
                        !!(user?.description && user?.description !== '') &&
                            <div style={{padding:'5px',marginBottom:5}}>
                                <div className="user-description-text">
                                    <h4 className="description-text color_4" style={{lineHeight:1.1}}>
                                        {user?.description}
                                    </h4>
                                </div>
                            </div>
                    }
                    {
                        !!(user?.link && user?.link !== "") &&
                            <div style={{marginBottom:'15px'}}>
                                <div className="user-description-text">
                                    <a href={user?.link} target='_blank' className="color_4">{user?.link.length > 40 ? user?.link.slice(40) : user?.link}</a> 
                                </div>
                            </div>
                    }
                    {
                        !!(user?.socials) &&
                            <div className="user-description-text" style={{marginBottom:15}}>
                                {user?.socials?.whatsapp && user?.socials?.whatsapp !== '' && <div className="align-div profile-social-button hover-scale-sm"><a onClick={() => clickWhatsApp(user?.socials?.whatsapp)} className="profile-social-icon" target='_blank'><BsWhatsapp /></a></div>}
                                {user?.socials?.tiktok && user?.socials?.tiktok !== '' && <div className="align-div profile-social-button hover-scale-sm"><a href={user?.socials?.tiktok} className="profile-social-icon" target='_blank'><BsTiktok /></a></div>}
                                {user?.socials?.instagram && user?.socials?.instagram !== '' && <div className="align-div profile-social-button hover-scale-sm"><a href={user?.socials?.instagram} className="profile-social-icon" target='_blank'><BsInstagram /></a></div>}
                                {user?.socials?.facebook && user?.socials?.facebook !== '' && <div className="align-div profile-social-button hover-scale-sm"><a href={user?.socials?.facebook} className="profile-social-icon" target='_blank'><BsFacebook /></a></div>}
                                {user?.socials?.linkedin && user?.socials?.linkedin !== '' && <div className="align-div profile-social-button hover-scale-sm"><a href={user?.socials?.linkedin} className="profile-social-icon" target='_blank'><BsLinkedin /></a></div>}
                                {user?.socials?.email_social && user?.socials?.email_social !== '' && <div className="align-div profile-social-button hover-scale-sm"><a href={user?.socials?.email_social} className="profile-social-icon" target='_blank'><BsEnvelope /></a></div>}
                                {user?.socials?.twitter && user?.socials?.twitter !== '' && <div className="align-div profile-social-button hover-scale-sm"><a href={user?.socials?.twitter} className="profile-social-icon" target='_blank'><BsTwitter /></a></div>}
                                {user?.socials?.snapchat && user?.socials?.snapchat !== '' && <div className="align-div profile-social-button hover-scale-sm"><a href={user?.socials?.snapchat} className="profile-social-icon" target='_blank'><BsSnapchat /></a></div>}
                            </div>
                    }
                    {
                        !!(socials || metrics) &&
                            <div>
                                <div style={{marginTop:'20px'}}>
                                    <h3>Stats:</h3>
                                </div>
                                <div style={{widht:'100%',textAlign:'center',flexWrap:'wrap',marginTop:'5px',marginBottom:'15px'}}>
                                    {
                                        socials &&
                                            socials.map((value) => 
                                                <div className="align-div" style={{padding:'2px'}} >
                                                    <SocialTag name={value?.name} followers={value?.followers} />
                                                </div>
                                            )
                                    }
                                </div>
                                {
                                    metrics &&
                                        <div style={{marginBottom:'15px'}}>
                                            {metrics?.followers && <h4><strong>Total Followers: </strong> {metrics?.followers}</h4>}
                                            {metrics?.er && <h4><strong>Engagement Rate: {metrics?.er}</strong></h4>}
                                        </div>
                                }
                            </div>
                    }
                </div>
                {
                    !hideButtons
                    ?
                        <div style={{display:'flex',maxWidth:'300px',margin:'auto'}}>
                            {
                                !isOffer &&
                                    <div style={{flex:1}}>
                                        <Button type={'primary'} onClick={clickOffer} className='rounded-button hover-scale-sm' style={{color:'white',backgroundColor:'#ef3078',border:0,borderRadius:'50px',fontSize:'12px',width:'95%',margin:'auto',padding:0}} >
                                            Send Offer
                                        </Button>
                                    </div>
                            }
                            <div style={{flex:1}}>
                                <Button type={'outline'} onClick={clickRate} className='rounded-button hover-scale-sm' style={{color:'#ef3078',backgroundColor:'white',borderColor:'#ef3078',borderRadius:'50px',fontSize:'12px',width:'95%',margin:'auto',padding:0}} >
                                    View Rates
                                </Button>
                            </div>
                        </div>
                    : null
                }
            </div>
            <RateCardModal username={user?.username} values={user?.rate} isRateCardModalVisible={isRateCardModalVisible} setIsRateCardModalVisible={setIsRateCardModalVisible} />
        </div>
    )

}

export default UserCardContent;

