import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MenuFoldOutlined, RiseOutlined, UserOutlined, ShoppingOutlined } from '@ant-design/icons';
import '../../style/index.css';
import { Button, Menu } from 'antd';
import MobileMenuDrawer from '../Drawers/MobileMenuDrawer';
import logoWide from '../../images/logo/logo-wide.png';
import logoDarkWide from '../../images/logo/logo.png';
import logoIcon from '../../images/logo/icon-gradient.jpeg';
import { useAuth } from '../../utils/useAuth';
import LanguageSelect from '../Select/LanguageSelect';
import i18next from '../../utils/i18n';
import { useEventTracker } from '../Context/EventTrackingContext';

const pathKeys = {
    '':'1',
    'ootd':'2',
    'for-sellers':'3',
};

function NavBarLogin({useDarkLogo=false, username=false, hideMenu=false, width='100%', maxWidth='100%', theme=false}) {
    const {user} = useAuth();
    const navigate = useNavigate();
    const {sendEvent} = useEventTracker();
    const [isMenuOpen,setIsMenuOpen] = useState(false);
    const [selectedKey, setSelectedKey] = useState([pathKeys[window.location.pathname.split('/')[1]]]);

    const menuItems = [
        {
            key: '1',
            icon: <RiseOutlined style={{fontSize:'18px'}} />,
            label: i18next.t('sidebar.trending'),
            onClick: () => { clickNavBar('trending'); }
        },
        {
            key: '2',
            icon: <ShoppingOutlined style={{fontSize:'18px'}} />,
            label: i18next.t('sidebar.products'),
            onClick: () => { clickNavBar('products'); }
        },
        {
            key: '3',
            icon: <UserOutlined style={{fontSize:'18px'}} />,
            label: i18next.t('dashboard.general.creators'),
            onClick: () => { clickNavBar('posts'); }
        }
    ];

    const changeLanguage = (value) => {
        i18next.changeLanguage(value);
    }
    const clickNavBar = (value) => {
        sendEvent({category:'navigation', action: value, label:'navbar'});
        navigate(`/${value}`);
    }

    const setThemeColors = () => {
        if(theme){
            document.getElementsByClassName('color_1')[0].style.backgroundColor = theme['color_1'];

            Array.from(document.getElementsByClassName('color_3')).forEach(elem => {
                elem.style.color = theme['color_3'];
            });
            Array.from(document.getElementsByClassName('color_4')).forEach(elem => {
                elem.style.color = theme['color_4'];
            });
        }
    }

    useEffect(() => {
        setThemeColors();
    },[theme,username])

    return(
        <div className='navbar-container color_1' >
            <div className='navbar-general nav-zindex' style={{maxWidth:maxWidth,width:width}}>
                <div className='navbar-logo color_1'>
                    {
                        username
                        ?
                            <div onClick={() => clickNavBar('')} style={{cursor:'pointer'}}>
                                <div className='align-div' style={{verticalAlign:'middle',paddingLeft:'15px'}}>
                                    <img src={logoIcon} alt="wandercart-icon-logo" style={{height:'40px',borderRadius:'50%'}} />
                                </div>
                                <div className='align-div' style={{textAlign:'left',verticalAlign:'middle',paddingLeft:'5px'}}>
                                    <h2 className='color_4' style={{marginBottom:1,lineHeight:1.1}}>WanderCart</h2>
                                    <h5 className='color_4'>with {username}</h5>
                                </div>
                            </div>
                        :
                            useDarkLogo
                            ? <Link to='/'><img src={logoDarkWide} alt="wandercart-logo-dark" className="logo-style" /></Link>
                            : <Link to='/'><img src={logoWide} alt="wandercart-logo-og" className="logo-style" /></Link>
                    }
                    {
                        !hideMenu &&
                            <div style={{marginLeft:'5px'}} className='no-mobile'>
                                <Menu className='navbar-menu' onClick={(e) => setSelectedKey(e.key)} selectedKeys={selectedKey} mode="horizontal" items={menuItems} />
                            </div>
                    }
                </div>
                <div className='navbar-button-container nav-zindex'>
                    {
                        user 
                        ?
                            <div className='align-div'  onClick={() => clickNavBar('profile')} style={{ verticalAlign:'middle',marginRight:'5px',cursor:'pointer'}}>
                                <img src={user?.avatar_img || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReh8NuEJj15o7u5x7dW99KPdSO_rH0LKihMw&usqp=CAU'} className="avatar-sidebar" style={window.innerWidth > 1000 ? {width:'40px',height:'40px'} : {width:'33px',height:'33px'}}  />
                            </div>
                        :
                            <div className='no-mobile'>
                                <div className='align-div'>
                                    <Button type={'primary'} ghost={true} className='rounded-button' style={{color:'#0891b2',borderColor:'#0891b2'}} onClick={() => clickNavBar('register')} >{i18next.t('dashboard.general.create_account')}</Button>
                                </div>
                                <div className='align-div'>
                                    <Button type={'link'} style={{color:'#0891b2'}} onClick={() => clickNavBar('login')} >{i18next.t('dashboard.general.login')}</Button>
                                </div>
                            </div>
                    }
                    <div className='align-div no-mobile' style={{marginRight:'10px',verticalAlign:'middle'}}>
                        <div style={{height:'33px',verticalAlign:'middle'}}>
                            <LanguageSelect defaultValue={i18next.language} onSelect={changeLanguage} onlyImage={true} />
                        </div>
                    </div>
                    <div className='no-desktop' style={{paddingBottom:'10px',paddingTop:'5px'}}>
                        <div className='align-div color_4'>
                            <MenuFoldOutlined style={{fontSize:'24px',paddingTop:'3px',verticalAlign:'middle'}} onClick={() => setIsMenuOpen(true)} />
                        </div>
                    </div>
                </div>
                <MobileMenuDrawer setOpen={setIsMenuOpen} isOpen={isMenuOpen} />
            </div>
        </div>
    )

}
export default NavBarLogin;