import ReactGA from "react-ga4";
import axios from "axios";

const ANALYTICS_URL = `${process.env.REACT_APP_SERVER_URL}/analytics/${process.env.REACT_APP_API_KEY}`;

const returnFullDateList = async (start,end) => {
    let difference = end - start;
    let numberDays = Math.ceil(difference / (1000 * 3600 * 24));

    let dateRangeList = [];
    Array.from({length: numberDays - 1}, (_, i) => i + 1).forEach(value => {
        var date = new Date(start);
        date.setDate(date.getDate() + value);
        dateRangeList.push({'datetime':date, 'count':0});
    })
    return dateRangeList
}

const addDateFilter = ({queryUrl,startDate,endDate}) => {

    if(!startDate){
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        queryUrl = queryUrl + `&datetime__gte=${oneMonthAgo.toISOString().split('T')[0]}`;
    }
    else{
        queryUrl = queryUrl + `&datetime__gte=${startDate.toISOString().split('T')[0]}`;
    }

    if(!endDate){
        const today = new Date();
        queryUrl = queryUrl + `&datetime__lte=${today.toISOString().split('T')[0]}`;
    }
    else{
        queryUrl = queryUrl + `&datetime__lte=${endDate.toISOString().split('T')[0]}`;
    }
    return queryUrl
    
}

class AnalyticsServices {

    updateAnalyticsUser({user_id}){
        ReactGA.gtag("set", "user_properties", {
            account_verified: true,
        });        
        ReactGA.gtag("set", "user_properties", {
            user_id: user_id,
        });
    }

    async getFullDateList({metrics}){
        const endDate = new Date();
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);

        const dateSample = new Date(metrics[0].datetime);

        let allDates = [];

        if(startDate.getTime() < dateSample.getTime()){
            allDates.push({datetime:startDate,count:0});
        }
        const isoMetrics = metrics.map((value) => {const tempDate = new Date(value['datetime']); return {datetime:tempDate, count: value.count}});
        allDates = [...allDates, ...isoMetrics];

        if(dateSample.getTime() < endDate.getTime()){
            allDates.push({datetime:endDate,count:0});
        }

        let finalDate = [];

        await allDates.forEach(async (value,index) => {
            try{
                finalDate = [...finalDate,value];

                let newList = await returnFullDateList(allDates[index]['datetime'].getTime(),allDates[index+1]['datetime'].getTime());
                finalDate = [...finalDate,...newList];
            }
            catch{
                
            }
        })
        var final = finalDate.sort(({datetime:a}, {datetime:b}) => a-b).map(value => {const tempDate = new Date(value['datetime']); return {datetime:value['datetime'].toISOString().split('T')[0], count:value['count']}});
        return final;
    }

    getProfileViews({user_id, ordering='datetime',startDate=false,endDate=false}) {

        let queryUrl = ANALYTICS_URL + `/profile/view?user_id=${user_id}&ordering=${ordering}`;
        queryUrl = addDateFilter({queryUrl:queryUrl,startDate:startDate,endDate:endDate});

        const configuration = {
            method: "get",
            url: queryUrl
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    };

    getProfileFollows({user_id, ordering='datetime',startDate=false,endDate=false}) {

        let queryUrl = ANALYTICS_URL + `/profile/follow?user_id=${user_id}&ordering=${ordering}`;
        queryUrl = addDateFilter({queryUrl:queryUrl,startDate:startDate,endDate:endDate});

        const configuration = {
            method: "get",
            url: queryUrl
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    };

    getPostViews({user_id, ordering='datetime',startDate=false,endDate=false,groupby=false}) {

        let queryUrl = ANALYTICS_URL + `/post/view?user_id=${user_id}&ordering=${ordering}`;
        queryUrl = addDateFilter({queryUrl:queryUrl,startDate:startDate,endDate:endDate});

        if(groupby){
            queryUrl = queryUrl + `&groupby=${groupby}`;
        }

        const configuration = {
            method: "get",
            url: queryUrl
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }

    getPostEngages({user_id, ordering='datetime', type='Shop Click',startDate=false,endDate=false}) {

        let queryUrl = ANALYTICS_URL + `/post/engage?user_id=${user_id}&ordering=${ordering}`;
        queryUrl = addDateFilter({queryUrl:queryUrl,startDate:startDate,endDate:endDate});

        if(type){
            queryUrl = queryUrl + `&type=${type}`
        }
        
        const configuration = {
            method: "get",
            url: queryUrl
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    };
    getPostLikes({user_id, ordering='datetime',startDate=false,endDate=false}) {

        let queryUrl = ANALYTICS_URL + `/post/likes?user_id=${user_id}&ordering=${ordering}`;
        queryUrl = addDateFilter({queryUrl:queryUrl,startDate:startDate,endDate:endDate});

        const configuration = {
            method: "get",
            url: queryUrl
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    };

    getProductViews({user_id, ordering='datetime',startDate=false,endDate=false}) {

        let queryUrl = ANALYTICS_URL + `/product/view?user_id=${user_id}&ordering=${ordering}`;
        queryUrl = addDateFilter({queryUrl:queryUrl,startDate:startDate,endDate:endDate});
        
        const configuration = {
            method: "get",
            url: queryUrl
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    };
    getProductEngages({user_id, ordering='datetime', type='Shop Click',startDate=false,endDate=false}) {

        let queryUrl = ANALYTICS_URL + `/product/engage?user_id=${user_id}&ordering=${ordering}`;
        queryUrl = addDateFilter({queryUrl:queryUrl,startDate:startDate,endDate:endDate});

        if(type){
            queryUrl = queryUrl + `&type=${type}`
        }
        
        const configuration = {
            method: "get",
            url: queryUrl
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    };
    getProductLikes({user_id, ordering='datetime',startDate=false,endDate=false}) {

        let queryUrl = ANALYTICS_URL + `/product/likes?user_id=${user_id}&ordering=${ordering}`;
        queryUrl = addDateFilter({queryUrl:queryUrl,startDate:startDate,endDate:endDate});

        const configuration = {
            method: "get",
            url: queryUrl
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    };
}

export default new AnalyticsServices();
