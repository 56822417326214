import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../images/logo/icon-no-bg.webp';
import '../../style/index.css';
import { Avatar, Button, Input, Popover, Space } from 'antd';
import { EnvironmentOutlined, MenuOutlined, PlusOutlined, SearchOutlined, SettingOutlined, ShopOutlined, ShoppingCartOutlined, ShoppingOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { useAuth } from '../../utils/useAuth';
import noPhotoImage from '../../images/no_image.webp';
import NavBarMorePopover from '../Popover/NavBarMorePopover';
import { useLocation } from '../Layout/DiscoveryLayout';

function DiscoveryNavBar() {
    const {user} = useAuth();
    const {setIsCreateProductDrawerVisible,setIsCreateCollabDrawerVisible} = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [navSelect,setNavSelect] = useState(window.location.pathname.split('/')[1]);
    const [openProfile,setOpenProfile] = useState(false);
    const [searchValue,setSearchValue] = useState('');
    const [locationValue,setLocationValues] = useState('');

    const setActive = () => {
        ['work','shop'].forEach((value) => {
            if(value === navSelect){
                document.getElementById(`${navSelect}`).classList.add("active");
            }
            else{
                document.getElementById(`${value}`).classList.remove("active");
            }
        });
    }

    const profileMenu = (
        <div className='navbar-dropdown-container' style={{textAlign:'left'}}>
            <div className='navbar-dropdown-section'>
                <Link to='/profile?tab=Profile'>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <div style={{paddingRight:'5px'}}>
                            <Avatar src={user?.avatar_img || noPhotoImage} size={'large'} style={{cursor:'pointer'}}  />
                        </div>
                        <div>
                            <h3 style={{marginBottom:0,lineHeight:1}}>{user?.username}</h3>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='navbar-dropdown-section'>
                <Link to='/work?tab=Collabs' onClick={() => setNavSelect('collabs')}>
                    <div className='navbar-dropdown-link' >
                        <TeamOutlined/> Collaborations
                    </div>
                </Link>
                <Link to='/active?tab=Active'>
                    <div className='navbar-dropdown-link'>
                        <TeamOutlined/> Active Collabs
                    </div>
                </Link>
                <div className='navbar-dropdown-link' style={{color:'rgb(76,204,132)'}} onClick={() => setIsCreateCollabDrawerVisible(true)}>
                    <PlusOutlined/> Create New Collab
                </div>
            </div>
            <div className='navbar-dropdown-section'>
                <Link to='/profile?tab=Storefront' onClick={() => setNavSelect('sell')}>
                    <div className='navbar-dropdown-link' >
                        <ShopOutlined/> Storefront
                    </div>
                </Link>
                <Link to='/shop?tab=Products' onClick={() => setNavSelect('product')}>
                    <div className='navbar-dropdown-link'>
                        <ShoppingOutlined/> Products                            
                    </div>
                </Link>
                <div className='navbar-dropdown-link' style={{color:'rgb(76,204,132)'}} onClick={() => setIsCreateProductDrawerVisible(true)}>
                    <PlusOutlined/> Add Product
                </div>
            </div>
            <div className='navbar-dropdown-section'>
                <Link to='/profile?tab=Profile'>
                    <div className='navbar-dropdown-link'>    
                        <UserOutlined/> Profile
                    </div>
                </Link>
                <Link to='/profile?tab=Settings'>
                    <div className='navbar-dropdown-link'>
                        <SettingOutlined/> Settings
                    </div>
                </Link>
            </div>
        </div>
    )
    const setSearch = () => {
        let searchVal = searchParams.get('search');
        if(searchVal) setSearchValue(searchVal);
        
        let locVal = searchParams.get('location');
        if(locVal) setLocationValues(locVal);
    }

    const enterSearch = () => {
        let updatedSearchParams = new URLSearchParams(searchParams.toString());
        if(searchValue !== ''){
            updatedSearchParams.set('search',searchValue);   
        }
        if(locationValue !== ''){
            updatedSearchParams.set('location',locationValue);
        }
        window.location.href = window.location.pathname + '?' + updatedSearchParams.toString();
    }

    useEffect(() => {
        setSearch();
    },[])
    useEffect(() => {
        setActive();
    },[navSelect]);

    return(
        <div className='navbar-container' >
            <div className='navbar-general' style={{display:'flex',alignItems:'center'}}>
                <div style={{height:'65px',aspectRatio:1/1,display:'flex',alignItems:'center'}}>
                    <Link to={user ? '/profile?tab=Profile' : '/login'}>
                        <img src={logo} alt="datarebel-logo" className="logo-style" />
                    </Link>
                </div>
                <div style={{flex:2,paddingRight:'5px',paddingLeft:'5px'}}>
                    <Input size='large' prefix={<SearchOutlined />} value={searchValue} onPressEnter={enterSearch} onChange={(value) => setSearchValue(value.target.value)} placeholder='Search' style={{width:'100%',borderRadius:'20px'}} />
                </div>
                <div className='no-mobile' style={{flex:1}}>
                    <Input size='large' prefix={<EnvironmentOutlined />} value={locationValue} onPressEnter={enterSearch} onChange={(value) => setLocationValues(value.target.value)} placeholder='Location' style={{width:'100%',borderRadius:'20px'}} />
                </div>
                <Space size={'large'} className='no-mobile' style={{display:'flex',paddingLeft:'20px',paddingRight:'20px'}}>
                    <Link to='/work' id='work' className='navbar-button-text' onClick={() => setNavSelect('work')}>
                        Collabs
                    </Link>
                    <Link to='/shop' id='shop' className='navbar-button-text' onClick={() => setNavSelect('shop')}>
                        Products
                    </Link>
                </Space>
                <div>
                    {
                        user 
                        ?
                            <Space space={window.innerWidth > 1000 ? 10 : 0} style={{paddingRight:window.innerWidth > 1000 ? '30px': '10px'}}>
                                <Space size={0}>
                                    <Link to='/profile?tab=Profile'>
                                        <Avatar src={user?.avatar_img || noPhotoImage} size={'large'} style={{cursor:'pointer'}}  />
                                    </Link>
                                    <Popover trigger="click" placement='bottomRight' open={openProfile} onOpenChange={setOpenProfile} content={profileMenu} style={{paddingLeft:'10px'}}>
                                        <Button type='text' size='large' icon={<MenuOutlined />} ></Button>
                                    </Popover>
                                </Space>
                                {
                                    false &&
                                        <Popover trigger='hover' content={'Cart'}>
                                            <Button type='text' shape='circle' size='large' className='hover-scale-sm' icon={<ShoppingCartOutlined />}></Button>
                                        </Popover>
                                }
                                {
                                    false &&
                                        <NavBarMorePopover />
                                }
                            </Space>
                        :
                            <Space space={window.innerWidth > 1000 ? 'large' : '1'} style={{paddingRight:window.innerWidth > 1000 ? '20px' : '5px'}}>
                                <Button type={'primary'} shape="round" className='rounded-button' onClick={() => navigate('/login')} style={{color:'#ef3078',backgroundColor:'white',borderColor:'#ef3078'}}>Login</Button>
                                <NavBarMorePopover />
                            </Space>
                    }
                </div>
            </div>
        </div>
    )

}
export default DiscoveryNavBar;