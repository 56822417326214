import { Form } from "antd";
import SocialFormInput from "../Component/SocialFormInput";
import { PlusCircleOutlined } from "@ant-design/icons";
import i18next from "i18next";
const socials = ['Instagram','Tiktok','Facebook','Lemon8','Twitter','YouTube','Twitch','LinkedIn'];

function OnboadingForm4({formValues,setFormValues}){

    const addSocial = () => {
        const current = formValues.socials.map(value => value.type);
        const missing = socials.filter(item => !current.includes(item));
        setFormValues({...formValues,socials:[...formValues.socials,{type: missing.length > 0 ? missing[0] : 'Instagram',username:'',follower_range:''}]});
    }
    const changeSocial = (value, index) => {
        let socialNew = [...formValues.socials];
        socialNew[index] = value;
        setFormValues({...formValues,socials:socialNew});
    }
    const removeSocial = (index) => {
        formValues.socials.splice(index,1);
        setFormValues({...formValues,socials:formValues.socials});
    }

    return(
        <div style={{width:'100%',paddingLeft:window.innerWidth > 1000 ? '20px' :0,paddingBottom:'2vh'}}>
            <div style={{width:'100%',marginBottom:'10px'}}>
                <h2 style={{fontWeight:600,marginBottom:0,color:'rgba(0,0,0,0.8)',lineHeight:1.2}}>
                    <strong>{i18next.t('onboarding.panel4.question')}</strong>
                </h2>
                <h3 style={{paddingLeft:'2px',fontSize:'12px',color:'rgba(0,0,0,0.6)',marginBottom:0}}>
                    {i18next.t('onboarding.panel4.question_detail')}
                </h3>
            </div>
            <Form.Item style={{width:'100%',marginBottom:0}}>
                <div style={{maxHeight:'47vh',overflowY:'auto',width:'100%',paddingRight:'5px',marginBottom:'10px'}}>
                    {
                        formValues.socials.map((value,index) => 
                            <div key={`social-input-key-${index}`} style={{paddingBottom:'5px'}}>
                                <SocialFormInput inputValue={value} onChange={(item) => changeSocial(item,index)} onRemove={() => removeSocial(index)} />
                            </div>
                        )
                    }
                </div>
                <div style={{textAlign:'center'}}>
                    <div className="align-div" style={{verticalAlign:'middle',paddingRight:'3px'}}>
                        <PlusCircleOutlined style={{color:'rgba(24,144,255,1)',fontSize:'14px'}} />
                    </div>
                    <div className="align-div" style={{verticalAlign:'middle'}}>
                        <a onClick={addSocial}>{i18next.t('onboarding.panel4.add_another')}</a>
                    </div>
                </div>
            </Form.Item>
        </div>
    )
}

export default OnboadingForm4;