import { PictureOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import ProductImageRemovePopconfirm from "../Popconfirm/ProductImageRemovePopconfirm";
import i18next from '../../utils/i18n';

function ProductImagePreview({values, selectProduct=false, onHover=false, removeProduct=false, text=false, isSelected=false}){

    const hoverPost = () => {
        if(!onHover){
            if(selectProduct){
                document.getElementById(`display-hover-${values.id}`).style.opacity = 1;
            }
        }
        else {
            onHover(values.id);
        }
    }
    const hoverOut = () => {
        if(!onHover){
            if(selectProduct){
                document.getElementById(`display-hover-${values.id}`).style.opacity = 0;
            }
        }
        else {
            onHover(false);
        }
    }

    return(
        <div id='image-card' className="product-image-card" style={{aspectRatio:1/1}} onMouseOver={hoverPost} onMouseOut={hoverOut}>
            {
                values?.images.length > 0
                ? 
                    <div className='product-image-square' style={{backgroundImage:`url(${values.images[0].image})`, border: isSelected ? '1px solid rgba(0,0,0,0.6)': '1px solid rgba(0,0,0,0.1)'}}></div>
                :
                    <div className="post-image-full square" style={{border: isSelected ? '1px solid rgba(0,0,0,0.4)': '1px solid rgba(0,0,0,0.1)'}}>
                        <div className="center-full">
                            <div style={{textAlign:'center'}}>
                                <PictureOutlined style={{fontSize:'30px',color:'rgba(0,0,0,0.7)'}} />
                                <div style={{marginTop:'5px'}}>
                                    {i18next.t('dashboard.general.no_photo')}
                                </div>
                            </div>
                        </div>
                    </div>
            }
            {
                removeProduct &&
                    <ProductImageRemovePopconfirm onConfirm={removeProduct} text={text} />
            }
            {
                selectProduct &&
                    <div id={`display-hover-${values?.id}`} className="post-hover-description-container center-full" style={{opacity:0,transition:'ease-in 0.2s'}}>
                        <div>
                            <div className="post-hover-description no-mobile">
                                {values.name.length > 60 ? values.name.slice(0,60) : values.name}
                            </div>
                            <div style={{marginTop:'8px'}}>
                                <Button size='small' className="rounded-button" onClick={() => selectProduct(values)}>{i18next.t('dashboard.product_tag.select_product')}</Button>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )

}

export default ProductImagePreview;