import { EnvironmentOutlined } from "@ant-design/icons";
import { Button } from "antd";
import SocialTag from "../../Tag/SocialTag";
import noImage from '../../../images/no_image.webp';
import noBgImage from '../../../images/colorkit.webp';
import { BsTiktok,BsInstagram,BsTwitter,BsSnapchat,BsWhatsapp,BsLinkedin,BsFacebook,BsEnvelope } from "react-icons/bs";
import WhatsAppServices from "../../../utils/WhatsAppServices";
import CategoryTag from "../../Tag/CategoryTag";

function UserCard({key,image,username,values=false,bgImage=false,categories=false,socials=false,metrics=false,clickOffer=false,clickRate=false,onClick=false,isOffer=false}){

    const clickCard = () => {
        if(onClick){
            onClick();
        }
    }
    const clickWhatsApp = (value) => {
        WhatsAppServices.sendMessage({phoneNumber:value});
    }

    return(
        <div key={key} className="user-card-container hover-scale-xs" style={{paddingBottom:'45px'}}>
            <div className="user-card-image-container" onClick={clickCard}>
                <div className='user-card-banner-image' style={{backgroundImage:`url(${bgImage || noBgImage})`}}>
                    <div className='user-card-avatar-image' style={{backgroundImage:`url(${image || noImage})`}}></div>
                </div>
            </div>
            <div className="user-card-detail-container">
                <div style={{width:'100%'}} onClick={clickCard}>
                    <h2>
                        {username} 
                    </h2>
                    {
                        values?.country && values?.country !== '' &&
                            <p style={{color:'rgba(0,0,0,0.6)',fontSize:'14px'}}>
                                <EnvironmentOutlined color='red' /> {values?.region && `${values?.region}, `}{values?.country}
                            </p>
                    }
                    {
                        categories && categories.length > 0 &&
                            <div style={{marginBottom:'10px',display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
                                {
                                    categories.map((value,index) => 
                                        <div key={`user-card-category-tag-${index}`} style={{marginBottom:'7px'}}>
                                            <CategoryTag value={value?.name || value} />
                                        </div>
                                    )
                                }
                            </div>
                    }
                    {
                        !!(values?.description && values?.description !== '') &&
                            <div style={{padding:'5px',marginBottom:5}}>
                                <div className="user-description-text">
                                    <h4 className="description-text color_4" style={{lineHeight:1.1}}>
                                        {values?.description}
                                    </h4>
                                </div>
                            </div>
                    }
                    {
                        !!(values?.link && values?.link !== "") &&
                            <div style={{marginBottom:'15px'}}>
                                <div className="user-description-text">
                                    <a href={values?.link} target='_blank' className="color_4">{values?.link.length > 40 ? values?.link.slice(40) : values?.link}</a> 
                                </div>
                            </div>
                    }
                    {
                        !!(values?.socials) &&
                            <div className="user-description-text" style={{marginBottom:15}}>
                                {values?.socials?.whatsapp && values?.socials?.whatsapp !== '' && <div className="align-div profile-social-button hover-scale-sm"><a onClick={() => clickWhatsApp(values?.socials?.whatsapp)} className="profile-social-icon" target='_blank'><BsWhatsapp /></a></div>}
                                {values?.socials?.tiktok && values?.socials?.tiktok !== '' && <div className="align-div profile-social-button hover-scale-sm"><a href={values?.socials?.tiktok} className="profile-social-icon" target='_blank'><BsTiktok /></a></div>}
                                {values?.socials?.instagram && values?.socials?.instagram !== '' && <div className="align-div profile-social-button hover-scale-sm"><a href={values?.socials?.instagram} className="profile-social-icon" target='_blank'><BsInstagram /></a></div>}
                                {values?.socials?.facebook && values?.socials?.facebook !== '' && <div className="align-div profile-social-button hover-scale-sm"><a href={values?.socials?.facebook} className="profile-social-icon" target='_blank'><BsFacebook /></a></div>}
                                {values?.socials?.linkedin && values?.socials?.linkedin !== '' && <div className="align-div profile-social-button hover-scale-sm"><a href={values?.socials?.linkedin} className="profile-social-icon" target='_blank'><BsLinkedin /></a></div>}
                                {values?.socials?.email_social && values?.socials?.email_social !== '' && <div className="align-div profile-social-button hover-scale-sm"><a href={values?.socials?.email_social} className="profile-social-icon" target='_blank'><BsEnvelope /></a></div>}
                                {values?.socials?.twitter && values?.socials?.twitter !== '' && <div className="align-div profile-social-button hover-scale-sm"><a href={values?.socials?.twitter} className="profile-social-icon" target='_blank'><BsTwitter /></a></div>}
                                {values?.socials?.snapchat && values?.socials?.snapchat !== '' && <div className="align-div profile-social-button hover-scale-sm"><a href={values?.socials?.snapchat} className="profile-social-icon" target='_blank'><BsSnapchat /></a></div>}
                            </div>
                    }
                    {
                        (socials || metrics) &&
                            <div>
                                <div style={{marginTop:'20px'}}>
                                    <h3>Stats:</h3>
                                </div>
                                <div style={{widht:'100%',textAlign:'center',flexWrap:'wrap',marginTop:'5px',marginBottom:'15px'}}>
                                    {
                                        socials &&
                                            socials.map((value) => 
                                                <div className="align-div" style={{padding:'2px'}} >
                                                    <SocialTag name={value?.name} followers={value?.followers} />
                                                </div>
                                            )
                                    }
                                </div>
                                {
                                    metrics &&
                                        <div style={{marginBottom:'15px'}}>
                                            {metrics?.followers && <h4><strong>Total Followers: </strong> {metrics?.followers}</h4>}
                                            {metrics?.er && <h4><strong>Engagement Rate: {metrics?.er}</strong></h4>}
                                        </div>
                                }
                            </div>
                    }
                </div>
                <div style={{display:'flex',maxWidth:'250px',margin:'auto',position:'absolute',bottom:0,left:0,right:0,padding:'5px 10px 20px 10px'}}>
                    {
                        !isOffer &&
                            <div style={{flex:1}}>
                                <Button type={'primary'} onClick={() => clickOffer && clickOffer(values)} className='rounded-button hover-scale-sm' style={{color:'white',backgroundColor:'#ef3078',border:0,borderRadius:'50px',fontSize:'12px',width:'95%',margin:'auto',padding:0}} >
                                    Send Offer
                                </Button>
                            </div>
                    }
                    <div style={{flex:1}}>
                        <Button type={'outline'} onClick={() => clickRate && clickRate(values)} className='rounded-button hover-scale-sm' style={{color:'#ef3078',backgroundColor:'white',borderColor:'#ef3078',borderRadius:'50px',fontSize:'12px',width:'95%',margin:'auto',padding:0}} >
                            View Rates
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default UserCard;