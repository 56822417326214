import image1 from '../images/background/image1.webp';
import image2 from '../images/background/image2.webp';
import image3 from '../images/background/image3.webp';
import image4 from '../images/background/image4.webp';
import image5 from '../images/background/image5.webp';
import image6 from '../images/background/image6.webp';
import image7 from '../images/background/image7.webp';
import image8 from '../images/background/image8.webp';
import image9 from '../images/background/image9.webp';
import image10 from '../images/background/image10.webp';
import image11 from '../images/background/image11.webp';
import image12 from '../images/background/image12.webp';
import image13 from '../images/background/image13.webp';
import image14 from '../images/background/image14.webp';
import image15 from '../images/background/image15.webp';
import image16 from '../images/background/image16.webp';
import image17 from '../images/background/image17.webp';
import image18 from '../images/background/image18.webp';
import image19 from '../images/background/image19.webp';
import image20 from '../images/background/image20.webp';
import image21 from '../images/background/image21.webp';

export const BackgroundImages = [
    {
        'image': image1
    },
    {
        'image': image2
    },
    {
        'image': image3
    },
    {
        'image': image4
    },
    {
        'image': image5
    },
    {
        'image': image6
    },
    {
        'image': image7
    },
    {
        'image': image8
    },
    {
        'image': image9
    },
    {
        'image': image10
    },
    {
        'image': image11
    },
    {
        'image': image12
    },
    {
        'image': image13
    },
    {
        'image': image14
    },
    {
        'image': image15
    },
    {
        'image': image16
    },
    {
        'image': image17
    },
    {
        'image': image18
    },
    {
        'image': image19
    },
    {
        'image': image20
    },
    {
        'image': image21
    }
];
