import React, {useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import NavBarGeneral from "../../components/NavBar/NavBarGeneral";
import DownloadModal from '../../components/Modal/DownloadModal';
import Footer from '../../components/Footer/Footer';
import bannerImg from '../../images/homepage/for-businesses/bg1.png';
import mobileAppStorefrontImage from '../../images/homepage/app/mobile_product_preview.png';
import influencerTravel from '../../images/homepage/for-influencers/influencers/influencerTravel.jpg';
import influencerFashion from '../../images/homepage/for-influencers/influencers/influencerFashion.jpg';
import influencerBusiness from '../../images/homepage/for-influencers/influencers/influencerBusiness.jpg';
import influencerTravelBG from '../../images/homepage/for-influencers/influencers/influencerTravelBG.jpg';
import influencerFashionBG from '../../images/homepage/for-influencers/influencers/influencerFashionBG.jpg';
import influencerBusinessBG from '../../images/homepage/for-influencers/influencers/influencerBusinessBG.jpg';
import howItWorksImg1 from '../../images/homepage/howitworks/register.webp';
import howItWorksImg2 from '../../images/homepage/howitworks/create-collab.webp';
import howItWorksImg3 from '../../images/homepage/howitworks/influencer.webp';
import arrowSVG from '../../images/homepage/Arrow_elements/Arrows/arrow_44.svg';
import { Button, Space } from 'antd';
import InfluencerOnboardingModal from '../../components/Modal/InfluencerOnboardingModal';
import UserCard from '../../components/Card/User/UserCard';
import HomePageBottomActionBar from '../../components/Mobile/HomePageBottomActionBar';

const userList = [
    {
        metrics:{
            followers:'3.2M+',
            er:'5.3%'
        },
        socials:[
            {
                name:'Instagram',
                followers:'135K'
            },
            {
                name:'Tiktok',
                followers:'2.3M'
            },
            {
                name:'YouTube',
                followers:'302K'
            }
        ]
    },
    {
        metrics:{
            followers:'800K+',
            er:'1.2%'
        },
        socials:[
            {
                name:'Instagram',
                followers:'633K'
            }
        ]
    },
    {
        metrics:{
            followers:'200K+',
            er:'2%'
        },
        socials:[
            {
                name:'Instagram',
                followers:'33K'
            },
            {
                name:'Lemon8',
                followers:'63K'
            },
            {
                name:'Tiktok',
                followers:'103K'
            }
        ]
    },
]

function ForBusinessPage(){
    const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
    const [isInfluencerOnboardingModalVisible, setIsInfluencerOnboardingModalVisible] = useState(false);
    
    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
    
        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 0;
        
            if (elementTop < windowHeight - elementVisible ) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }
    window.addEventListener("scroll", reveal);

    const clickOnboarding = () => {
        window.open('/register');
    }

    const tempColors = ['#aa24aa','#ef3078','#f6a444'];
    return (
        <div className='full-page' style={{backgroundColor:'white'}}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>WanderCart - Influencers</title>
                <meta name="description" content={`WanderCart: The New Way for Influencers To Shop and Earn Online`} />
                <link rel="canonical" href="https://www.wanderc.art/" />
            </Helmet>
            <NavBarGeneral setIsInfluencerOnboardingModalVisible={setIsInfluencerOnboardingModalVisible} createAccount={true} />
            <div className='full-page-content-section' >
                <div className='landing-page-flex-container landing-title-fadein'>
                    <div className='landing-page-banner-left-container'>
                        <div className='home-business-banner-text-container'>
                            <div style={{marginBottom:'3vh'}}>
                                <h1 className='home-business-headline-text'>
                                    Manage Micro-Influencer Campaigns at Scale.
                                </h1>
                                <h2 className='home-business-subheadline-text'>
                                    WanderCart is on a mission empower individuals around the world and grow access to opportunities to earn more with just their ideas and a phone.
                                </h2>
                            </div>
                            <div>
                                <Space space={2}>
                                    <Button type={'primary'} size='large' className='rounded-button' style={{color:'white',backgroundColor:'#ef3078',borderColor:'#ef3078'}} onClick={() => window.open('/register')}>Create Your Profile</Button>
                                    <Button type={'primary'} ghost={true} size='large' className='rounded-button' style={{color:'#ef3078',borderColor:'#ef3078'}} onClick={() => {window.location.href='#learn-more'}}>Learn More</Button>
                                </Space>
                            </div>
                        </div>
                    </div>
                    <div className='landing-page-banner-right-container'>
                        <div className='home-business-content-container home-business-section-image-container' style={{marginLeft: window.innerWidth > 1000 ? '80px' : 0}}>
                            <div style={{position:'relative'}} onClick={clickOnboarding} >
                                <div style={{position:'absolute',top:0,right:0,left:0,bottom:0,transform:'translateX(60px) translateY(-20px)'}} onClick={clickOnboarding} >
                                    <UserCard username='TravelWithSteph' image={influencerTravel} bgImage={influencerTravelBG} location='Bali, ID' categories={['Lifestyle','Travel']} socials={userList[1]?.socials} metrics={userList[1]?.metrics} />
                                </div>
                                <div style={{maxWidth:'400px',transform:'translateX(30px) translateY(-10px)'}}>
                                    <UserCard username='NickRoyce' image={influencerBusiness} bgImage={influencerBusinessBG} location='New York City, US' categories={['Lifestyle','Education','Business','Technology & Gadget']} socials={userList[0]?.socials} metrics={userList[0]?.metrics}  />
                                </div>
                                <div style={{position:'absolute',top:0,right:0,left:0,bottom:0}} onClick={clickOnboarding} >
                                    <UserCard username='StylesByChu' image={influencerFashion} bgImage={influencerFashionBG} location='Singapore' categories={['Fashion','Lifestyle','Makeup']} socials={userList[2]?.socials} metrics={userList[2]?.metrics} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='full-width-page-section' id='learn-more'>
                    <div className='content-section'>
                        <div className='reveal' style={{textAlign:'center'}}>
                            <h1 style={{fontSize:'2.5em',fontWeight:700,lineHeight:1.4}}>
                                How it Works
                            </h1>
                        </div>
                    </div>
                    <div className='home-business-section-container reveal' style={{alignItems:'stretch'}}>
                        <div className='home-business-benefit-card-container'>
                            <div>
                                <h1 style={{fontWeight:500,lineHeight:1.2}}>1. Create Your Account</h1>
                            </div>
                            <div style={{padding:'0 15px 10px 15px'}}>
                                <div className='background-img' style={{aspectRatio:3/2,border:'1px solid rgba(0,0,0,0.1)',borderRadius:'15px',backgroundImage:`url(${howItWorksImg1})`}}>
                                </div>
                                <div style={{paddingTop:'10px',width:'90%',margin:'auto'}}>
                                    <h2>
                                        Tell us about your Brand, Mission, and Values! We help brands find the best influencers for their goals.
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className='home-business-benefit-card-container'>
                            <div>
                                <h1 style={{fontWeight:500,lineHeight:1.2}}>2. Launch a Collaboration</h1>
                            </div>
                            <div style={{padding:'0 15px 10px 15px'}}>
                                <div className='background-img' style={{aspectRatio:3/2,border:'1px solid rgba(0,0,0,0.1)',borderRadius:'15px',backgroundImage:`url(${howItWorksImg2})`}}>
                                </div>
                                <div style={{paddingTop:'10px',width:'90%',margin:'auto'}}>
                                    <h2>
                                        Post your Collaboration ideas and we will match you with the best fit influencers to represent your brand, budget, and goals.
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className='home-business-benefit-card-container'>
                            <div>
                                <h1 style={{fontWeight:500,lineHeight:1.2}}>3. Track Results!</h1>
                            </div>
                            <div style={{padding:'0 15px 10px 15px'}}>
                                <div className='background-img' style={{aspectRatio:3/2,border:'1px solid rgba(0,0,0,0.1)',borderRadius:'15px',backgroundImage:`url(${howItWorksImg3})`}}>
                                </div>
                                <div style={{paddingTop:'10px',width:'90%',margin:'auto'}}>
                                    <h2 style={{fontWeight:500,lineHeight:1.2}}>
                                        Track Results for Each Collaboration or Explore Results by Influencer to find Best Fit for your Brand and Product.
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='full-width-page-section'>
                    <div className='content-section' style={{marginBottom:'5vh'}}>
                        <div className='reveal' style={{textAlign:'center'}}>
                            <h1 style={{fontSize:'2.5em',fontWeight:600,lineHeight:1.4}}>
                                <strong>Our Features</strong>
                            </h1>
                        </div>
                    </div>
                    <div className='home-business-section-container reveal'>
                        <div className='home-business-section-half-container home-business-section-text-container'>
                            <div className='home-business-content-container'>
                                <div className='home-business-banner-text-container'>
                                    <div style={{marginBottom:'2vh'}}>
                                        <div style={{paddingLeft:'20px'}}>
                                            <h1 className='home-business-header-text'>
                                                Influencer Marketplace
                                            </h1>
                                            <h2 style={{color:'rgba(0,0,0,0.6)'}}>
                                                Discover a vast network of influencers ready to elevate your brand.
                                            </h2>
                                        </div>
                                        <h2 className='home-business-subheader-text'>
                                            <ul>
                                                <li>Efficient Networking</li>
                                                <li>Diverse Collaboration Opportunities</li>
                                                <li>Maximized Exposure and Revenue</li>
                                            </ul>
                                        </h2>
                                    </div>
                                    <div style={{paddingLeft:'20px'}}>
                                        <Button type={'link'} size='large' className='rounded-button' style={{color:'white',backgroundColor:'#ef3078',borderColor:'#ef3078'}} onClick={() => setIsInfluencerOnboardingModalVisible(true)}>Browse Influencers</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='home-business-section-half-container'>
                            <div className='home-business-content-container home-business-section-image-container'>
                                <div style={{position:'relative'}} onClick={clickOnboarding} >
                                    <div style={{position:'absolute',top:0,right:0,left:0,bottom:0,transform:'translateX(60px) translateY(-20px)'}} onClick={clickOnboarding} >
                                        <UserCard username='TravelWithSteph' image={influencerTravel} bgImage={influencerTravelBG} location='Bali, ID' categories={['Lifestyle','Travel']} socials={userList[1]?.socials} metrics={userList[1]?.metrics} />
                                    </div>
                                    <div style={{maxWidth:'400px',transform:'translateX(30px) translateY(-10px)'}}>
                                        <UserCard username='NickRoyce' image={influencerBusiness} bgImage={influencerBusinessBG} location='New York City, US' categories={['Lifestyle','Education','Business','Technology & Gadget']} socials={userList[0]?.socials} metrics={userList[0]?.metrics}  />
                                    </div>
                                    <div style={{position:'absolute',top:0,right:0,left:0,bottom:0}} onClick={clickOnboarding} >
                                        <UserCard username='StylesByChu' image={influencerFashion} bgImage={influencerFashionBG} location='Singapore' categories={['Fashion','Lifestyle','Makeup']} socials={userList[2]?.socials} metrics={userList[2]?.metrics} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='full-width-page-section'>
                    <div className='home-business-section-container reveal'>
                        <div className='home-business-section-half-container'>
                            <div className='home-business-content-container home-business-section-image-container'>
                                <div style={{position:'relative'}} onClick={clickOnboarding} >
                                    <UserCard username='TravelWithSteph' image={influencerTravel} bgImage={influencerTravelBG} location='Bali, ID' categories={['Lifestyle','Travel']} socials={userList[1]?.socials} metrics={userList[1]?.metrics} />
                                </div>
                            </div>
                        </div>
                        <div className='home-business-section-half-container home-business-section-text-container'>
                            <div className='home-business-content-container'>
                                <div className='home-business-banner-text-container'>
                                    <div style={{marginBottom:'2vh'}}>
                                        <div style={{paddingLeft:'20px'}}>
                                            <h1 className='home-business-header-text'>
                                                Influencer eCommerce
                                            </h1>
                                            <h2 style={{color:'rgba(0,0,0,0.6)'}}>
                                                Sell Your Product Directly to Influencers on Our Influencer-Only eCommerce Marketplace.
                                            </h2>
                                        </div>
                                        <h2 className='home-business-subheader-text'>
                                            <ul>
                                                <li>Authentic Endorsements</li>
                                                <li>Targeted Exposure</li>
                                                <li>Exclusive Collaborations</li>    
                                            </ul>
                                        </h2>
                                    </div>
                                    <div style={{paddingLeft:'20px'}}>
                                        <Button type={'link'} size='large' className='rounded-button' style={{color:'white',backgroundColor:'#ef3078',borderColor:'#ef3078'}} onClick={() => setIsInfluencerOnboardingModalVisible(true)}>Get Started</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='full-width-page-section' style={{marginBottom:'5vh'}}>
                    <div className='home-business-section-container reveal'>
                        <div className='home-business-section-half-container home-business-section-text-container'>
                            <div className='home-business-content-container'>
                                <div className='home-business-banner-text-container'>
                                    <div style={{marginBottom:'2vh'}}>
                                        <div style={{paddingLeft:'20px'}}>
                                            <h1 className='home-business-header-text'>
                                                Influencer Storefronts
                                            </h1>
                                            <h2 style={{color:'rgba(0,0,0,0.6)'}}>
                                                Each influencer becomes a brand ambassador with their own unique storefront.
                                            </h2>
                                        </div>
                                        <h2 className='home-business-subheader-text'>
                                            <ul>
                                                <li>Personalized Brand Representation</li>
                                                <li>Authentic Engagement</li>
                                                <li>Targeted Marketing Opportunities</li>
                                            </ul>
                                        </h2>
                                    </div>
                                    <div style={{paddingLeft:'20px'}}>
                                        <Button type={'link'} size='large' className='rounded-button' style={{color:'white',backgroundColor:'#ef3078',borderColor:'#ef3078'}} onClick={() => setIsInfluencerOnboardingModalVisible(true)}>Get Started</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='home-business-section-half-container'>
                            <div className='home-business-content-container home-business-section-image-container'>
                                <div>
                                    <img src={mobileAppStorefrontImage} style={{maxHeight:'80vh'}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='full-width-page-section' style={{marginBottom:'25vh'}}>
                    <div className='content-section'>
                        <div className='reveal' style={{textAlign:'center'}}>
                            <h1 style={{fontSize:'2.5em',fontWeight:600,lineHeight:1.4}}>
                                <strong>Why Join Us?</strong>
                            </h1>
                        </div>
                    </div>
                    <div className='home-business-section-container reveal' style={{alignItems:'stretch'}}>
                        <div className='home-business-benefit-card-container'>
                            <div className='home-business-benefit-card' style={{backgroundColor:'rgba(170,36,170,0.1)'}}>
                                <div className='home-business-benefit-tag' style={{backgroundColor:'#aa24aa'}}>
                                    Partner
                                </div>
                                <div>
                                    <h2 className='home-business-benefit-title'>Form Win-Win Relationships</h2>
                                    <div className='home-business-benefit-description'>
                                        Mutually Benefits for Influencers and Businesses
                                        <ul style={{paddingLeft:'15px'}}>
                                            <li>Influencers receive discounted or exclusive products.</li>
                                            <li>Businesses gain authentic endorsements and brand recognition.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='home-business-benefit-card-container'>
                            <div className='home-business-benefit-card' style={{backgroundColor:'rgba(239,48,120,0.1)'}}>
                                <div className='home-business-benefit-tag' style={{backgroundColor:'#ef3078'}}>
                                    Save
                                </div>
                                <div>
                                    <h2 className='home-business-benefit-title'>Save on Marketing Costs</h2>
                                    <div className='home-business-benefit-description'>
                                        <ul style={{paddingLeft:'15px'}}>
                                            <li>Offering products at a discount to influencers.</li>
                                            <li>New cost-effective way to leverage influencer marketing without significant upfront costs.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='home-business-benefit-card-container'>
                            <div className='home-business-benefit-card' style={{backgroundColor:'rgba(246,164,68,0.1)'}}>
                                <div className='home-business-benefit-tag' style={{backgroundColor:'#f6a444'}}>
                                    Endorse
                                </div>
                                <h2 className='home-business-benefit-title'>Gain Authentic Endorsements</h2>
                                <div className='home-business-benefit-description'>
                                    <ul style={{paddingLeft:'15px'}}>
                                        <li>Influencers are more likely to provide authentic endorsements if they genuinely appreciate and use the products they promote.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='full-width-page-section' style={{marginTop:'5vh',width:'100%',backgroundColor:tempColors[1]}}>
                    <div className='content-section' style={{paddingBottom:'10vh'}}>
                        <div className='reveal' style={{textAlign:'center',paddingTop:'5em'}}>
                            <h1 style={{fontSize:'42px',fontWeight:600,lineHeight:1.4,color:'white'}}>
                                <strong>Get Started!</strong> It's Free!
                            </h1>
                        </div>
                        <div style={{marginTop:'4vh',marginBottom:'4vh'}}>
                            <div className='reveal' style={{marginBottom:'5px'}}>
                                <div className='hover-scale-sm' style={{width:'fit-content',position:'relative',margin:'auto'}}>
                                    <Button type={'primary'} size='large' style={{color:tempColors[1],backgroundColor:'white',borderColor:'white',fontWeight:'600',fontSize:'18px',borderRadius:'20px',height:'auto'}}  onClick={clickOnboarding} >
                                        Grow Your Brand
                                    </Button>
                                    <div style={{position:'absolute',left:0,top:0,bottom:0,transform:'translateX(-105%)',display:'flex',alignItems:'center'}}>
                                        <img style={{filter:'invert(1)'}} src={arrowSVG} alt="WanderCart Action Arrow"  />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='full-width-page-section'>
                    <div className='content-section'>
                        <div className='reveal' style={{textAlign:'center',paddingTop:'5em'}}>
                            <h1 style={{fontSize:'42px',fontWeight:600,lineHeight:1.4}}>
                                <strong>Are You a Business?</strong>
                            </h1>
                        </div>
                        <div className='home-action-button-container'>
                            <div className='home-action-button reveal' onClick={clickOnboarding} >
                                <h1 style={{marginBottom:0}}>
                                    Create Your Brand
                                </h1>
                            </div>
                            <div className='home-action-button reveal' onClick={clickOnboarding} >
                                <h1 style={{marginBottom:0}}>
                                    Start Selling on WanderCart.
                                </h1>
                            </div>
                            <div className='home-action-button reveal' onClick={clickOnboarding} >
                                <h1 style={{marginBottom:0}}>
                                    Why Sell on WanderCart?
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='full-width-page-section'>
                    <div className='content-section'>
                        <div style={{width:'100%',marginBottom:'5px'}}>
                            <div className='reveal' style={{textAlign:'center'}}>
                                <h1 style={{fontSize:'3em',fontWeight:600,lineHeight:1.4}}>
                                    <strong>The Influencer's Marketplace </strong>{window.innerWidth < 1000 ? null : <br/>}
                                    Get Your Products in the Right Hands.
                                </h1>
                            </div>
                            <div className='reveal'>
                                <h2 style={{fontSize:'1.5em'}}>
                                    Sell Directly To Influencers Around the World.
                                </h2>
                            </div>
                        </div>
                        <div className='reveal home-business-info-container' style={{maxWidth:'1000px',margin:'auto'}}>
                            <div className='home-business-info-card' onClick={clickOnboarding} >
                                <UserCard username='TravelWithSteph' image={influencerTravel} bgImage={influencerTravelBG} location='Bali, ID' categories={['Lifestyle','Travel']} socials={userList[1]?.socials} metrics={userList[1]?.metrics} />
                            </div>
                            <div className='home-business-info-card' onClick={clickOnboarding} >
                                <UserCard username='NickRoyce' image={influencerBusiness} bgImage={influencerBusinessBG} location='New York City, US' categories={['Lifestyle','Education','Business','Technology & Gadget']} socials={userList[0]?.socials} metrics={userList[0]?.metrics}  />
                            </div>
                            <div className='home-business-info-card' onClick={clickOnboarding} >
                                <UserCard username='StylesByChu' image={influencerFashion} bgImage={influencerFashionBG} location='Singapore' categories={['Fashion','Lifestyle','Makeup']} socials={userList[2]?.socials} metrics={userList[2]?.metrics} />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer setIsDownloadModalVisible={setIsDownloadModalVisible} />
            </div>
            <HomePageBottomActionBar onClick={clickOnboarding} />
            <InfluencerOnboardingModal defaultUser={'business'} isInfluencerOnboardingModalVisible={isInfluencerOnboardingModalVisible} setIsInfluencerOnboardingModalVisible={setIsInfluencerOnboardingModalVisible} />
            <DownloadModal isDownloadModalVisible={isDownloadModalVisible} setIsDownloadModalVisible={setIsDownloadModalVisible} />
        </div>
    )
};

export default ForBusinessPage;