import axios from "axios";
const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}`;

class FollowServices {

    async addFollower({user, user_id, is_active=false}){
        const configAPIUrl = API_URL + `/user/follow`;

        if(user?.access) {
            var configuration = {
                credentials: 'include',
                method: "post",
                url: configAPIUrl,
                headers: {"Authorization" : `Bearer ${user.access}`},
                data:{
                    user_id: user_id,
                    is_active: is_active,
                }
            };
    
            // Send POST Query with Login Info
            return axios(configuration)
            .then(response => {
                return response.data
            })
            .catch((error) => {
                return error.response
                //error = new Error();
            });
        };
    }
    async checkFollowing({user, user_id, follower_id}){
        const configAPIUrl = API_URL + `/user/follow/check`;

        if(user?.access) {
            var configuration = {
                method: "post",
                url: configAPIUrl,
                data:{
                    follower_id: follower_id,
                    user_id: user_id,
                }
            };
    
            // Send POST Query with Login Info
            return axios(configuration)
            .then(response => {
                return response.data.data
            })
            .catch((error) => {
                return error.response
                //error = new Error();
            });
        }
        else{
            return false
        }
    }

    getFollowerList({user_id=false, follower_id=false, page=0, ordering='-updated_at'}){
        const limit = 12;
        const offset = limit * page || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/user/follows?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True`}
        else { configAPIUrl = API_URL + `/user/follows?limit=${limit}&ordering=${ordering}&is_active=True` };

        if(user_id){
            configAPIUrl = configAPIUrl + `&user_id=${user_id}`;
        }
        else if(follower_id){
            configAPIUrl = configAPIUrl + `&user_id=${follower_id}`;
        }   

        var configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            error = new Error();
        });
    }

}

export default new FollowServices();