import {  CloseCircleOutlined, MinusOutlined, PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Collapse, Form, Input, Select, Skeleton, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../utils/useAuth";
import BrandAvatarUpload from "../../Upload/BrandAvatarUpload";
import ProductImageUploadSquare from "../../Upload/ProductImageUploadSquare";
import noPhotoImage from '../../../images/no_image.webp';
import FontSelect from "../../Select/FontSelect";
import GenderSelect from "../../Select/GenderSelect";
import AgeSelect from "../../Select/AgeSelect";
import IncomeSelect from "../../Select/IncomeSelect";
import KeywordSelect from "../../Select/KeywordSelect";
import CountrySelect from "../../Select/CountrySelect";

const {Panel} = Collapse;

const filterOptions = [
    {
        name:'Types of Collabs',
        options:['Sponsored Posts','Brand Ambassadors','Product Collaborations','Influencer Gifting','Affiliate Marketing','Social Media Makeovers','Giveaways','Virtual Events','User-Generated Content','Sponsored Blog Posts','Other']
    },
    {
        name:'Categories',
        options:['Lifestyle','Gaming','Food & Drink','Fashion','Beauty','Travel','Parenting &\nFamily','Music','Humor & Comedy','Technology & Gadget','Education','Business','Health','Photography','Cooking']            
    },
    {
        name:'Channels',
        options:['Instagram','TikTok','Facebook','Lemon8','Twitter','YouTube','Twitch','LinkedIn']            
    },
    {
        name:'Offer',
        options:['Product Gifting','Exclusive Product Access','Discount & Discount Codes','Affiliate Partnerships','Sponsored Content','Giveaways and Contests','Brand Ambassadorships','Event Sponsorships','Content Creation Agreements','Influencer Takeovers','Product Collaborations','Social Media Challenges','Exclusivity Offers','Educational Collaborations','Cause Marketing Collaborations','Surprise Packages','Other']    
    }
];

function CreateBrandForm({form,formValues,setFormValues}){
    const {user} = useAuth();
    const [colorPalette,setColorPicker] = useState({primary:[],secondary:[]});
    const [brandLogo,setBrandLogo] = useState(formValues?.avatar?.image || null);
    const [uploading,setUploading] = useState(false);

    const addColor = (type) => {
        let newColors = {...colorPalette};
        newColors[type] = [...newColors[type],'#fff'];
        setFormValues({...formValues,color_palette:newColors});
        setColorPicker(newColors);
    }
    const clickColorPicker = (value,index,type) => {
        let newColors = {...colorPalette};
        newColors[type][index] = value.target.value;
        setFormValues({...formValues,color_palette:newColors});
        setColorPicker(newColors);
    }
    const removeColor = (index,type) => {
        let newColors = {...colorPalette};
        let newValue = [...newColors[type]];
        newValue.splice(index,1);
        newColors[type] = newValue;
        setFormValues({...formValues,color_palette:newColors});
        setColorPicker(newColors);
    }

    const changeDemographics = (value,type) => {
        let newDemographicValues = {...formValues?.demographic};
        console.log(value);
        console.log(type);
    }

    useEffect(() => {
        if(brandLogo){
            setFormValues({...formValues,avatar:brandLogo?.id});
        }
    },[brandLogo]);

    return(
        <Form
            name="create-brand-form"
            form={form}
            layout="vertical">
            <div style={{padding:'15px'}}>
                <div style={{marginBottom:'20px'}}>
                    <div style={{padding:'2px',flex:1}}>
                        <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Brand Name</div>
                        <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>*Required</div>
                    </div>
                    <Form.Item
                        name="name"
                        style={{marginBottom:'5px'}}
                        rules={[
                            {
                                required: true,
                                message: 'Name is Required.',
                            },
                        ]}
                        >
                        <Input style={{borderRadius:'10px'}} placeholder="WanderCart Inc" size='large' onChange={(value) => setFormValues({...formValues,name:value.target.value}) } />
                    </Form.Item>
                </div>
                <div style={{marginBottom:'20px'}}>
                    <div style={{padding:'2px',flex:1}}>
                        <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Tagline / Slogan</div>
                        <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>Optional</div>
                    </div>
                    <Form.Item
                        name="tagline"
                        style={{marginBottom:'5px'}}
                        >
                        <Input style={{borderRadius:'10px'}} placeholder="Reshaping Commerce through Authentic Collaborations" size='large' onChange={(value) => setFormValues({...formValues,tagline:value.target.value}) } />
                    </Form.Item>
                </div>
                <div style={{marginBottom:'20px'}}>
                    <div style={{padding:'2px',flex:1}}>
                        <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Categories</div>
                        <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>Optional</div>
                    </div>
                    <Form.Item
                        name="category"
                        style={{marginBottom:'5px'}}
                        rules={[
                            {
                                required: true,
                                message: 'Categories are Required.',
                            },
                        ]}
                        >
                        <Select
                            showSearch
                            size='large'
                            style={{ width:'100%',border:'1px solid rgba(0,0,0,0.2)',borderRadius:'10px'}}
                            mode='tags'
                            bordered={false}
                            autoFocus={true}
                            placeholder="Select Categories Type"
                            onChange={(value) => setFormValues({...formValues,category:value})}
                            >
                            {
                                filterOptions[1].options.map((value,index) => 
                                        <Select.Option key={`category-type-${index}`} value={value} className="home-onboarding-select-option" >
                                            <div style={{fontWeight:'normal'}}>{value}</div>
                                        </Select.Option>
                                    )
                            }
                        </Select>
                    </Form.Item>
                </div>
                <div style={{marginBottom:'20px'}}>
                    <div style={{padding:'2px',flex:1}}>
                        <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Short Description</div>
                        <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>Optional</div>
                    </div>
                    <Form.Item
                        name="description"
                        style={{marginBottom:'5px'}}
                        >
                        <Input.TextArea style={{borderRadius:'10px'}} placeholder="Looking for an influencer to help promote our new..." size='large' onChange={(value) => setFormValues({...formValues,description:value.target.value}) } />
                    </Form.Item>
                </div>
                <div style={{marginBottom:'30px'}}>
                    <div style={{padding:'2px',flex:1}}>
                        <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Add Your Logo</div>
                        <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>Optional</div>
                    </div>
                    <div style={{marginBottom:'10px',width:'70px'}}>
                        <BrandAvatarUpload brandLogo={brandLogo} setBrandLogo={setBrandLogo} user={user} />
                    </div>
                </div>
            </div>
            <Collapse
                bordered={false} 
                expandIconPosition={'end'} 
                defaultActiveKey={'1'}
                accordion={true}
                expandIcon={({ isActive }) => isActive ? <MinusOutlined />:<PlusOutlined />}
                style={{fontSize:'20px',fontWeight:'500',backgroundColor:'white',padding:0}}>
                <Panel header='Mission/Vision' key={'1'} >
                    <div style={{paddingTop:'20px',paddingBottom:'30px'}}>
                        <div style={{marginBottom:'20px'}}>
                            <div style={{padding:'2px',marginBottom:'5px'}}>
                                <div style={{fontWeight:'500',fontSize:'17px'}}>Vision Statement</div>
                                <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>A forward-looking statement that describes the brand's aspirations and long-term objectives.</div>
                            </div>
                            <Form.Item
                                name="vision"
                                style={{marginBottom:'5px'}}
                                >
                                <Input.TextArea style={{borderRadius:'10px',minHeight:'10vh'}} placeholder="Looking for an influencer to help promote our new..." size='large' onChange={(value) => setFormValues({...formValues,vision:value.target.value}) } />
                            </Form.Item>
                        </div>
                        <div style={{marginBottom:'20px'}}>
                            <div style={{padding:'2px',flex:1,marginBottom:'5px'}}>
                                <div style={{fontWeight:'500',fontSize:'17px'}}>Mission Statement</div>
                                <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>A concise statement outlining the purpose, values, and goals of the brand.</div>
                            </div>
                            <Form.Item
                                name="mission"
                                style={{marginBottom:'5px'}}
                                >
                                <Input.TextArea style={{borderRadius:'10px',minHeight:'10vh'}} placeholder="Looking for an influencer to help promote our new..." size='large' onChange={(value) => setFormValues({...formValues,mission:value.target.value}) } />
                            </Form.Item>
                        </div>
                    </div>
                </Panel>
                <Panel header='Brand Guide' key={'2'} >
                    <div style={{paddingTop:'20px',paddingBottom:'30px'}}>
                        <div style={{marginBottom:'20px'}}>
                            <div style={{padding:'2px',marginBottom:'5px'}}>
                                <div style={{fontWeight:'500',fontSize:'17px'}}>Brand Colors</div>
                                <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>The specific colors associated with the brand, which contribute to its visual identity.</div>
                            </div>
                            <div style={{display:'flex',width:'80%'}}>
                                <div style={{width:'50%'}}>
                                    <div style={{fontSize:'12px',fontWeight:'500'}}>
                                        Primary
                                    </div>
                                    <div style={{display:'flex',flexWrap:'wrap',alignItems:'center',minHeight:'40px',cursor:'pointer'}}>
                                        {
                                            formValues?.color_palette?.primary && formValues?.color_palette?.primary.length > 0 &&
                                                formValues?.color_palette?.primary.map((value,index) => 
                                                    <div style={{position:'relative'}}>
                                                        <input type="color" id='color_picker' value={value} onChange={(value) => clickColorPicker(value, index, 'primary')} />
                                                        <CloseCircleOutlined onClick={() => removeColor(index,'primary')} style={{position:'absolute',top:0,right:0,color:'red',backgroundColor:'white',borderRadius:'50%',overflow:'hidden',fontSize:'14px'}} />
                                                    </div>
                                                )
                                        }
                                        <div style={{paddingLeft:'5px'}}>
                                            <PlusCircleOutlined className="hover-scale-sm" onClick={() => addColor('primary')} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'50%'}}>
                                    <div style={{fontSize:'12px',fontWeight:'500'}}>
                                        Secondary
                                    </div>
                                    <div style={{display:'flex',flexWrap:'wrap',alignItems:'center',minHeight:'40px',cursor:'pointer'}}>
                                        {
                                            formValues?.color_palette?.secondary && formValues?.color_palette?.secondary.length > 0 &&
                                                formValues?.color_palette?.secondary.map((value,index) => 
                                                    <div style={{position:'relative'}}>
                                                        <input type="color" id='color_picker' value={value} onChange={(value) => clickColorPicker(value, index, 'secondary')} />
                                                        <CloseCircleOutlined onClick={() => removeColor(index,'secondary')} style={{position:'absolute',top:0,right:0,color:'red',backgroundColor:'white',borderRadius:'50%',overflow:'hidden',fontSize:'14px'}} />
                                                    </div>
                                                )
                                        }
                                        <div style={{paddingLeft:'5px'}}>
                                            <PlusCircleOutlined className="hover-scale-sm" onClick={() => addColor('secondary')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{marginBottom:'20px'}}>
                            <div style={{padding:'2px',marginBottom:'5px'}}>
                                <div style={{fontWeight:'500',fontSize:'17px'}}>Typography/Font</div>
                                <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>The chosen font or typography that is consistently used in brand communications.</div>
                            </div>
                            <div style={{width:'80%'}}>
                                <FontSelect defaultValue={formValues?.brand_font} onSelect={(value) => setFormValues({...formValues,brand_font:value})} />
                            </div>
                        </div>
                        <div style={{marginBottom:'20px'}}>
                            <div style={{padding:'2px',marginBottom:'5px'}}>
                                <div style={{fontWeight:'500',fontSize:'17px'}}>Brand Voice</div>
                                <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>The tone and style of communication that represents the brand's personality, whether it's formal, friendly, or humorous.</div>
                            </div>
                            <Form.Item
                                name="brand_voice"
                                style={{marginBottom:'5px'}}
                                >
                                <Input.TextArea style={{borderRadius:'10px',minHeight:'10vh'}} placeholder="Casual and friendly for..." size='large' onChange={(value) => setFormValues({...formValues,brand_voice:value.target.value}) } />
                            </Form.Item>
                        </div>
                    </div>
                </Panel>
                <Panel header='About' key={'3'} >
                    <div style={{paddingTop:'20px',paddingBottom:'30px'}}>
                        <div style={{marginBottom:'20px'}}>
                            <div style={{padding:'2px',marginBottom:'5px'}}>
                                <div style={{fontWeight:'500',fontSize:'17px'}}>Brand Story</div>
                                <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>The narrative that communicates the history, values, and purpose of the brand.</div>
                            </div>
                            <Form.Item
                                name="brand_story"
                                style={{marginBottom:'5px'}}
                                >
                                <Input.TextArea style={{borderRadius:'10px',minHeight:'10vh'}} placeholder="Looking for an influencer to help promote our new..." size='large' onChange={(value) => setFormValues({...formValues,brand_story:value.target.value}) } />
                            </Form.Item>
                        </div>
                        <div style={{marginBottom:'20px'}}>
                            <div style={{padding:'2px',marginBottom:'5px'}}>
                                <div style={{fontWeight:'500',fontSize:'17px'}}>Brand Values</div>
                                <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>The core principles and beliefs that guide the brand's actions and decisions.</div>
                            </div>
                            <Form.Item
                                name="brand_values"
                                style={{marginBottom:'5px'}}
                                >
                                <Input.TextArea style={{borderRadius:'10px',minHeight:'10vh'}} placeholder="Looking for an influencer to help promote our new..." size='large' onChange={(value) => setFormValues({...formValues,brand_values:value.target.value}) } />
                            </Form.Item>
                        </div>
                        <div style={{marginBottom:'20px'}}>
                            <div style={{padding:'2px',marginBottom:'5px'}}>
                                <div style={{fontWeight:'500',fontSize:'17px'}}>Unique Selling Proposition (USP)</div>
                                <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>The distinctive qualities or features that set the brand apart from competitors.</div>
                            </div>
                            <Form.Item
                                name="brand_value_proposition"
                                style={{marginBottom:'5px'}}
                                >
                                <Input.TextArea style={{borderRadius:'10px',minHeight:'10vh'}} placeholder="Looking for an influencer to help promote our new..." size='large' onChange={(value) => setFormValues({...formValues,brand_value_proposition:value.target.value}) } />
                            </Form.Item>
                        </div>
                    </div>
                </Panel>
                <Panel header='Target Audience' key={'4'} >
                    <div style={{paddingTop:'10px',paddingBottom:'30px'}}>
                        <div style={{marginBottom:'20px'}}>
                            <div style={{padding:'2px',marginBottom:'5px'}}>
                                <div style={{fontWeight:'500',fontSize:'17px'}}>Age</div>
                                <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>Narrow down your target age group.</div>
                            </div>
                            <div style={{width:'80%'}}>
                                <AgeSelect defaultValue={formValues?.demographic?.age} onSelect={(value) => changeDemographics(value,'age')} />
                            </div>
                        </div>
                        <div style={{marginBottom:'20px'}}>
                            <div style={{padding:'2px',marginBottom:'5px'}}>
                                <div style={{fontWeight:'500',fontSize:'17px'}}>Gender</div>
                                <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>Narrow down your target gender groups.</div>
                            </div>
                            <div style={{width:'80%'}}>
                                <GenderSelect defaultValue={formValues?.demographic?.gender} onSelect={(value) => changeDemographics(value,'gender')} />
                            </div>
                        </div>
                        <div style={{marginBottom:'20px'}}>
                            <div style={{padding:'2px',marginBottom:'5px'}}>
                                <div style={{fontWeight:'500',fontSize:'17px'}}>Income</div>
                                <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>Narrow down ideal target income levels.</div>
                            </div>
                            <div style={{width:'80%'}}>
                                <IncomeSelect defaultValue={formValues?.demographic?.income} onSelect={(value) => changeDemographics(value,'income')} />
                            </div>
                        </div>
                        <div style={{marginBottom:'20px'}}>
                            <div style={{padding:'2px',marginBottom:'5px'}}>
                                <div style={{fontWeight:'500',fontSize:'17px'}}>Locations</div>
                                <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>Narrow down target countries.</div>
                            </div>
                            <div style={{width:'80%'}}>
                                <CountrySelect defaultValue={formValues?.demographic?.location} onSelect={(value) => changeDemographics(value,'location')} />
                            </div>
                        </div>
                        <div style={{marginBottom:'20px'}}>
                            <div style={{padding:'2px',marginBottom:'5px'}}>
                                <div style={{fontWeight:'500',fontSize:'17px'}}>Keywords</div>
                                <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>Add keywords to help with search.</div>
                            </div>
                            <div style={{width:'80%'}}>
                                <KeywordSelect defaultValue={formValues?.demographic?.keyword} onSelect={(value) => changeDemographics(value,'keyword')} />
                            </div>
                        </div>
                    </div>
                </Panel>
                <Panel header='Media' key={'5'} >
                    <div style={{paddingTop:'10px',paddingBottom:'30px'}}>
                        <div style={{color:'rgba(0,0,0,0.5)',fontSize:'16px'}}>
                            Add Any Images Related to Your Brand.
                        </div>
                        <div style={{paddingTop:'20px',paddingBottom:'30px',fontSize:'16px'}}>
                            <div style={{display:'flex',flexWrap:'wrap'}}>
                                {
                                    formValues?.images && formValues?.images.length > 0 &&
                                        formValues?.images.map((value) => 
                                            <div style={{width:'20%',aspectRatio:1/1,padding:'5px'}}>
                                                <div className='post-image hover-scale-xs' style={{borderRadius:'5px',aspectRatio:1/1,backgroundImage:`url(${value?.image || noPhotoImage})`}} >
                                                </div>
                                            </div>
                                        )
                                }
                                {
                                    uploading &&
                                        <div style={{width:'20%',aspectRatio:1/1,padding:'5px'}}>
                                            <Skeleton.Button style={{aspectRatio:1/1,height:'100%',width:'100%',borderRadius:'5px'}} block={true} active={true}/>
                                        </div>
                                }
                                <div style={{width:'20%',padding:'5px'}}>
                                    <ProductImageUploadSquare imageList={formValues?.images || []} setImageList={(value) => setFormValues({...formValues,images:value})} user={user} setUploading={setUploading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Panel>
            </Collapse>
            <div style={{padding:'15px',color:'rgba(0,0,0,0.4)'}}>
                *You can always come back and fill in information!<br/>
                **The more detailed your profile. The better we can match you the right users.
            </div>
        </Form>
    )
}

export default CreateBrandForm;