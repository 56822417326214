import { Form, Input, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useEditableContext } from "./EditableTable";

function EditableTableCell({
        title,
        editable,
        children,
        dataIndex,
        record,
        handleSave,
        ...restProps
    }){
        
    const form = useEditableContext();
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
  
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex.toLowerCase()]: record[dataIndex.toLowerCase()] });
    };  

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
            message.error('Fix all row values to save!');
        }
    };

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);
  
    let childNode = children;
  
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex.toLowerCase()}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
                >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        )
        : 
            (
                <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                    {children}
                </div>
            );
    }
  
    return <td {...restProps}>{childNode}</td>;
}

export default EditableTableCell;
