import axios from "axios";
import Resizer from "react-image-file-resizer";

const SHOP_URL = `${process.env.REACT_APP_SERVER_URL}/shop/${process.env.REACT_APP_API_KEY}`;

const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 800, 800, 'JPEG', 100, 0,
        uri => {
        resolve(uri);
        }, 'file' );
});

class ProductServices {

    createEditProduct({values, user, product_id=false}){
        const configuration = {
            credentials: 'include',
            method: "post",
            url: SHOP_URL + "/product",
            data: values,
            headers: {"Authorization" : `Bearer ${user.access}`},
        };

        if(product_id){
            configuration['method'] = 'patch';
            configuration['url'] = SHOP_URL + `/product/${product_id}`;
        }

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }
    async postProductImage({image, name, user}) {
        let formData = new FormData();

        const imageResized = await resizeFile(image);        
        formData.append('image', imageResized, `${name}`);

        const configuration = {
            credentials: 'include',
            method: "post",
            url: SHOP_URL + "/product/image",
            data: formData,
            headers: {"Authorization" : `Bearer ${user.access}`},
        };
    
        // Send POST Query with Login Info
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }
    getProduct({product_id}) {
        const configuration = {
            method: "get",
            url: SHOP_URL + `/product/${product_id}`
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    };
    getProductList({page=0,limit=12, ordering='-updated_at',  user_id=false, username=false, category=false, search=false, collectionId=false}) {

        const offset = limit * page || 0;

        var configAPIUrl = SHOP_URL
        if (offset > 0){configAPIUrl = SHOP_URL + `/products?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True`}
        else { configAPIUrl = SHOP_URL + `/products?limit=${limit}&ordering=${ordering}&is_active=True` };

        if(user_id){
            configAPIUrl = configAPIUrl + `&user_id=${user_id}`;
        }
        if(username){
            configAPIUrl = configAPIUrl + `&user_id__username=${username}`;
        }
        if(category){
            configAPIUrl = configAPIUrl + `&category__name=${category}`;
        }
        if(search){
            configAPIUrl = configAPIUrl + `&search=${search}`;
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }


    async likeProduct({product_id, user, is_active=false}){
        const configAPIUrl = SHOP_URL + `/product/like`;

        if(user) {
            var configuration = {
                credentials: 'include',
                method: "post",
                url: configAPIUrl,
                headers: {"Authorization" : `Bearer ${user.access}`},
                data:{
                    product_id: product_id,
                    user_id: parseInt(user.user_id),
                    is_active: is_active,
                }
            };
    
            // Send POST Query with Login Info
            return axios(configuration)
            .then(response => {
                return response.data;
            })
            .catch((error) => {
                return error.response;
            });
        };
    }
    async checkProductSaved({product_id, user_id}){
        const configAPIUrl = SHOP_URL + `/product/like/${product_id}`;
        var configuration = {
            credentials: 'include',
            method: "post",
            url: configAPIUrl,
            data: {
                user_id: user_id
            }
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }

    async getProductLikeList({user_id=false, page=0, ordering='-updated_at'}){
        const limit = 12;
        const offset = limit * page || 0;
        if(user_id){

            var configAPIUrl = SHOP_URL
            if (offset > 0){configAPIUrl = SHOP_URL + `/product/likes?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True&user_id=${user_id}`}
            else { configAPIUrl = SHOP_URL + `/product/likes?limit=${limit}&ordering=${ordering}&is_active=True&user_id=${user_id}` };

            var configuration = {
                credentials: 'include',
                method: "get",
                url: configAPIUrl,
            };

            // Send POST Query with Login Info
            return axios(configuration)
            .then(response => {
                return response.data
            })
            .catch((error) => {
                error = new Error();
            });
        }
        else{
            return false
        }
    }
    getProductImages({user, page=0, ordering='-updated_at'}){

        const limit = 18;
        const offset = limit * page || 0;

        var configUrl = SHOP_URL
        if (offset > 0){configUrl = SHOP_URL + `/product/images?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True&user_id=${user.user_id}`}
        else { configUrl = SHOP_URL + `/product/images?limit=${limit}&ordering=${ordering}&is_active=True&user_id=${user.user_id}` };

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configUrl,
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            return error.response;
        });
    }

}

export default new ProductServices();