import { Select } from "antd"


function CurrencySelect({onSelect,defaultCurrency}){
    const { Option } = Select;

    const currencies = [
        {
            label:'USD ($)',
            value:'$',
        },
        {
            label:'IDR (Rp)',
            value:'Rp',
        },
        {
            label:'THB (฿)',
            value:'฿',
        },
        {
            label:'MYR (RM)',
            value:'RM',
        },
        {
            label:'SGD (S$)',
            value:'S$',
        },
        {
            label:'PHP (₱)',
            value:'₱',
        },
        {
            label:'JPY (¥)',
            value:'¥',
        },
        {
            label:'INR (₹)',
            value:'₹',
        },
    ]

    return(
        <Select 
            defaultValue={defaultCurrency} 
            value={defaultCurrency}
            onSelect={onSelect}
            size="large" 
            bordered={false} 
            className="currency-select">
            {
                currencies.map((value,index) => 
                    <Option key={`currency-option-${index}`} value={value.value}>{value.label}</Option>
                )
            }
        </Select>
    )
}

export default CurrencySelect

