import { createContext, useContext, useEffect, useMemo, useState } from "react";
import LocationServices from "../../utils/LocationServices";
import DiscoveryNavBar from "../NavBar/DiscoveryNavbar";
import { Outlet } from "react-router-dom";
import DiscoveryMobileNavBar from "../NavBar/DiscoveryMobileNavBar";
import CreateCollabDrawer from "../Drawers/Shop/CreateCollabDrawer";
import CreateProductDrawer from "../Drawers/Shop/CreateProductDrawer";
import RateCardModal from "../Modal/Profile/RateCardModal";
import OfferDrawer from "../Drawers/Profile/OfferDrawer";
import ApplicationDrawer from "../Drawers/Profile/ApplicationDrawer";
import { useAuth } from "../../utils/useAuth";
import { message } from "antd";

const LocationContext = createContext();

function DiscoveryLayout(){
    const {user} = useAuth();
    const [userCountry,setUserCountry] = useState(null);
    const [isCreateProductDrawerVisible,setIsCreateProductDrawerVisible] = useState(false);
    const [collabValues,setCollabValues] = useState(null);
    const [isCreateCollabDrawerVisible,setIsCreateCollabDrawerVisible] = useState(false);
    const [rateValues,setRateValues] = useState(null);
    const [isRateCardModalVisible,setIsRateCardModalVisible] = useState(false);
    const [offerValues,setOfferValues] = useState(null);
    const [isOfferDrawerVisible,setIsOfferDrawerVisible] = useState(false);
    const [applicationValue,setApplciationValue] = useState(null);
    const [isApplicationDrawerVisible,setIsApplicationDrawerVisible] = useState(false);

    const getCustomerLocation = async () => {
        const customerLocationReponse = await LocationServices.getUserCountry();        
        if(customerLocationReponse.status === 'success'){
            setUserCountry({country:customerLocationReponse.country_name,iso2:customerLocationReponse.country_code});
        }
    }
    const clickCreateCollab = (value) => {
        setCollabValues(value);
        setIsCreateCollabDrawerVisible(true);
    }
    const clickOffer = (value) => {
        if(user){
            setOfferValues(value);
            setIsOfferDrawerVisible(true);
        }
        else{
            message.info('Please Login to User this Feature!');
        }
    }
    const clickRate = (value) => {
        setRateValues(value);
        setIsRateCardModalVisible(true);
    }
    const clickApplication = (value) => {
        setApplciationValue(value);
        setIsApplicationDrawerVisible(true);
    }

    const value = useMemo(
        () => ({
            userCountry,
            setIsOfferDrawerVisible,
            setIsCreateProductDrawerVisible,
            clickCreateCollab,
            clickOffer, clickRate, clickApplication
        }),[userCountry,setIsOfferDrawerVisible,setIsCreateProductDrawerVisible,clickCreateCollab,clickOffer,clickRate,clickApplication]
    );

    useEffect(() => {
        if(!userCountry){
            getCustomerLocation();
        }
    },[]);
    
    return (
        <LocationContext.Provider value={value} className='full-page' style={{backgroundColor:'white'}}>
            <DiscoveryNavBar />
            <div>
                <Outlet />
            </div>
            <DiscoveryMobileNavBar />
            <CreateCollabDrawer initialValues={collabValues} isCreateCollabDrawerVisible={isCreateCollabDrawerVisible} setIsCreateCollabDrawerVisible={setIsCreateCollabDrawerVisible} />
            <CreateProductDrawer isCreateProductDrawerVisible={isCreateProductDrawerVisible} setIsCreateProductDrawerVisible={setIsCreateProductDrawerVisible} />
            <RateCardModal values={rateValues} isRateCardModalVisible={isRateCardModalVisible} setIsRateCardModalVisible={setIsRateCardModalVisible} />
            <OfferDrawer isOfferDrawerVisible={isOfferDrawerVisible} setIsOfferDrawerVisible={setIsOfferDrawerVisible} values={offerValues} />
            <ApplicationDrawer values={applicationValue} isApplicationDrawerVisible={isApplicationDrawerVisible} setIsApplicationDrawerVisible={setIsApplicationDrawerVisible} />
        </LocationContext.Provider>
    )
}

export default DiscoveryLayout;

export const useLocation = () => {
    return useContext(LocationContext);
};