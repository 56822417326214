import { ShoppingCartOutlined } from "@ant-design/icons";
import { Form, Table } from "antd";

const columns = [
    {
        title: 'Platform',
        dataIndex: 'platform',
        key: 'platform',
    },
    {
        title: 'Service',
        dataIndex: 'service',
        key: 'service',
    },
    {
        title: 'Rate',
        dataIndex: 'rate',
        key: 'rate',
    }
]; 

function RateTable({data,loading=false,pagination=false}){

    return(
        <Table
            bordered
            dataSource={data}
            columns={columns}
            pagination={pagination}
            style={{overflowY:'auto'}}
            loading={loading}
            />
    )
}

export default RateTable;
