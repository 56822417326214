import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import UserServices from "../../utils/UserServices";
import PostServices from "../../utils/PostServices";
import CollectionServices from "../../utils/CollectionServices";
import CollectionCard from "../../components/Card/CollectionCard";
import { Empty, Spin } from "antd";
import { AppstoreOutlined, LoadingOutlined, PicCenterOutlined, ShareAltOutlined } from "@ant-design/icons";
import PostGallery from "../../components/Gallery/PostGallery";
import PostGalleryLoading from "../../components/Gallery/PostGalleryLoading";
import FollowButton from "../../components/Button/FollowButton";
import { BsTiktok,BsInstagram,BsTwitter,BsSnapchat,BsWhatsapp,BsLinkedin,BsFacebook,BsEnvelope } from "react-icons/bs";
import ShareUserModal from "../../components/Modal/ShareUserModal";
import { useDashboard } from "../../components/Context/DashboardContext";
import i18next from '../../utils/i18n';
import {Helmet} from "react-helmet";
import MobileCreatorNavBar from "../../components/NavBar/MobileCreatorNavBar";
import ProductServices from "../../utils/ProductServices";
import { useEventTracker } from "../../components/Context/EventTrackingContext";
import NavBarLogin from "../../components/NavBar/NavBarLogin";
import ProductGallery from "../../components/Gallery/ProductGallery";
import WhatsAppServices from "../../utils/WhatsAppServices";

const tempTheme = {
    'color_1':'#222831',
    'color_2':'#393E46',
    'color_3':'#00ADB5',
    'color_4':'#EEEEEE',
}

function CreatorProfilePage(){
    const { username } = useParams();
    const scrollRef = useRef(null);
    const bottomRef = useRef(null);
    const navigate = useNavigate();
    const {clickProduct,clickPost} = useDashboard();
    const [userDetails,setUserDetails] = useState(false);
    const [postList,setPostList] = useState(null);
    const [productList,setProductList] = useState(null);
    const [savedList,setSavedList] = useState(null);
    const [collectionList,setCollectionList] = useState(false);
    const [pageTab,setPageTab] = useState('posts');
    const [isShareUserModalVisible, setIsShareUserModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [galleryLoading, setGalleryLoading] = useState(false);
    const [noMoreValues, setNoMoreValues] = useState(false);
    const [navBarVisible, setNavBarVisible] = useState(null);
    const [theme,setTheme] = useState(null)
    const [directionChangePosition, setDirectionChangePosition] = useState(0);
    const [page,setPage] = useState(false);
    const [mobileColumnCount, setMobileColumnCount] = useState(1);
    const {sendEvent} = useEventTracker();
    
    const getCollections = async ({user_id}) => {
        const collectionResponse = await CollectionServices.getCollections({user_id:user_id,page:0});
        setCollectionList(collectionResponse.results);
    }
    const getUserPosts = async ({user_id,pageValue}) => {
        const userPostResponse = await PostServices.getPostList({user_id:user_id,page:pageValue});
        return userPostResponse.results;
    }
    const getUserProducts = async ({user_id, pageValue}) => {
        const userProductResponse = await ProductServices.getProductList({page:pageValue,user_id:user_id});
        return userProductResponse.results;
    }
    const getSavedPosts = async ({user_id,pageValue}) => {
        const userSavedPostResponse = await PostServices.getPostSavedList({user_id:user_id,page:pageValue});
        const savedPosts = userSavedPostResponse.results.map((value) => value.post_id);
        return savedPosts;
    }
    const getInitialQuery = async ({user_id,queryValue}) => {
        setLoading(true);
        if(queryValue === 'posts'){
            if(postList){
                setPostList(postList);
            }
            else{
                const posts = await getUserPosts({user_id:user_id, pageValue:0});
                setPostList(posts);
            }
        }
        else if(queryValue === 'products'){
            if(productList){
                setProductList(productList);
            }
            else{
                const products = await getUserProducts({user_id:user_id, pageValue:0});
                setProductList(products);
            }
        }
        else if(queryValue === 'saved'){
            if(savedList){
                setSavedList(savedList);
            }
            else{
                const saved = await getSavedPosts({user_id:user_id, pageValue:0});
                setSavedList(saved);
            }
        }        
        setLoading(false);
    }
    const getMoreQuery = async () => {
        setGalleryLoading(true);
        let newPage = page + 1;

        if(pageTab === 'posts' && postList){
            const posts = await getUserPosts({user_id:userDetails?.user_id, pageValue:newPage});
            if(postList.length === [...postList, ...posts].length){
                setNoMoreValues(true);
            }
            else{
                setPostList([...postList, ...posts]);
                setPage(newPage);
            }
        }
        else if(pageTab === 'products' && productList){
            const products = await getUserProducts({user_id:userDetails?.user_id, pageValue:newPage});
            if(productList.length === [...productList, ...products].length){
                setNoMoreValues(true);
            }
            else{
                setProductList([...productList, ...products]);
                setPage(newPage);
            }
        }
        else if(pageTab === 'saved' && savedList){
            const saved = await getSavedPosts({user_id:userDetails?.user_id, pageValue:newPage});
            if(savedList.length === [...savedList, ...saved].length){
                setNoMoreValues(true);
            }
            else{
                setSavedList([...savedList, ...saved]);
                setPage(newPage);
            }
        }
        setGalleryLoading(false);
    }

    const getUser = async () => {
        const userResponse = await UserServices.getUserInfo({username:username});
        setUserDetails(userResponse.data);
        getInitialQuery({user_id: userResponse.data.user_id, queryValue:pageTab});
    }

    const clickTab = (value) => {
        if(value === 'share'){
            setIsShareUserModalVisible(true);
        }
        else{
            if(value !== pageTab){
                setNavBarVisible('fadeInJs');
                setNoMoreValues(false);
                setPageTab(value);
                getInitialQuery({user_id: userDetails?.user_id, queryValue: value});
            }
            scrollRef.current.scrollTo({top:0,behavior: 'smooth'});
        }
    }
    const clickShare = () => {
        setIsShareUserModalVisible(true);
    }
    const clickWhatsApp = (value) => {
        WhatsAppServices.sendMessage({phoneNumber:value});
    }

    const postSection = <div className="page-section">
        <div className="post-gallery">
            {
                false && collectionList && collectionList.length > 0
                ?
                    <div style={{marginBottom:'20px',paddingTop:'10px'}}>
                        <div style={{paddingLeft:'10px'}}>
                            <h2 className="color_4" style={{marginBottom:'0px',fontWeight:600}}>My Collections</h2>
                        </div>
                        <div className="horizontal-list">
                            {
                                collectionList.map((value,index) => (
                                    <CollectionCard values={value} key={index} />
                                ))
                            }
                        </div>
                    </div>
                : null
            }
            <div className="page-section">
                <div className="no-desktop color_1" style={{padding:'10px',paddingRight:0}}>
                    <div className="align-div">
                        <h2 className="color_4" style={{marginBottom:'0px',fontWeight:600}}>My Posts</h2>
                    </div>
                    <div className="align-div float-right no-desktop">
                        {
                            mobileColumnCount === 1
                            ? <div style={{paddingRight:'10px'}} onClick={() => setMobileColumnCount(2)}><AppstoreOutlined style={{fontSize:20,strokeWidth:3}} /></div>
                            : <div style={{paddingRight:'10px'}} onClick={() => setMobileColumnCount(1)}><PicCenterOutlined style={{fontSize:20,strokeWidth:3}} /></div>
                        }
                    </div>
                </div>
                {
                    postList && !loading
                    ?
                        postList.length > 0
                        ?
                            <div className="content-column-container">
                                <PostGallery theme={theme} values={postList} onClick={clickPost} clickProduct={clickProduct} loading={galleryLoading} columns={window.innerWidth > 1000 ? 3 : mobileColumnCount} />
                            </div>
                        :
                            <div style={{height:'80vh',paddingTop:'15vh', width:'100%'}}>
                                <Empty className="color_4" description='No Posts Yet!' image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                    : <PostGalleryLoading columns={window.innerWidth > 1000 ? 3 : mobileColumnCount} cardCount={5} />
                }
            </div>
        </div>
    </div>;

    const productSection = <div className="page-section" style={{paddingTop:0}}>
        <div className="no-desktop color_1" style={{padding:'10px',paddingRight:0}}>
            <div className="align-div">
                <h2 className="color_4" style={{marginBottom:'0px',fontWeight:600}}>My Products</h2>
            </div>
        </div>
        {
            productList && !loading
            ? 
                productList.length > 0
                ?
                    <div className="content-column-container">
                        <ProductGallery theme={theme} values={productList} clickProduct={(values) => {clickProduct(values);sendEvent({category:'product', action:'edit',label:'dashboard_product_page'});}} loading={galleryLoading} columns={window.innerWidth > 1000 ? 4 : 2} />
                    </div>
                :
                    <div style={{height:'80vh',paddingTop:'15vh', width:'100%'}}>
                        <Empty className="color_4" description='No Products Yet!' image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
            : <PostGalleryLoading columns={window.innerWidth > 1000 ? 3 : 2} aspectRatio={1/1} borderRadius={'10px'} />
        }
    </div>;

    const savedSection = <div className="page-section">
        <div className="no-desktop color_1" style={{padding:'10px',paddingRight:0}}>
            <div className="align-div">
                <h2 className="color_4" style={{marginBottom:'0px',fontWeight:600}}>My Saved</h2>
            </div>
            <div className="align-div float-right no-desktop">
                {
                    mobileColumnCount === 1
                    ? <div style={{paddingRight:'10px'}} onClick={() => setMobileColumnCount(2)}><AppstoreOutlined style={{fontSize:20,strokeWidth:3}} /></div>
                    : <div style={{paddingRight:'10px'}} onClick={() => setMobileColumnCount(1)}><PicCenterOutlined style={{fontSize:20,strokeWidth:3}} /></div>
                }
            </div>
        </div>
        <div className="page-section">
            {
                savedList && !loading
                ?
                    savedList.length > 0 
                    ?
                        <div className="content-column-container">
                            <PostGallery key={'save_gallery'} theme={theme} values={savedList} onClick={clickPost} clickProduct={clickProduct} loading={galleryLoading} columns={window.innerWidth > 1000 ? 3 : mobileColumnCount} />
                        </div>
                    :
                        <div style={{height:'80vh',paddingTop:'15vh', width:'100%'}}>
                            <Empty className="color_4" description='No Saved Posts Yet!' image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
                : <PostGalleryLoading columns={window.innerWidth > 1000 ? 3 : mobileColumnCount} cardCount={5} />
            }
        </div>
    </div>;

    const scrollPos = async () => {
        let { scrollHeight, scrollTop, clientHeight } = scrollRef.current;
        const scroll = scrollHeight - scrollTop - clientHeight;

        if(scroll < scrollHeight / 2 && (!galleryLoading || !loading) && (!noMoreValues)){
            await setGalleryLoading(true);
            await getMoreQuery();
        }
        if(window.innerWidth < 1000){
            if(scrollTop < 100) {
                setNavBarVisible('fadeInJs');
            }
            if(Math.abs(scrollTop - directionChangePosition) > 100){
                setDirectionChangePosition(scrollTop);
                let scrollDirection = scrollTop < directionChangePosition ? 'fadeInJs' : 'fadeOutJs';
                
                if(scrollDirection !== navBarVisible){
                    setNavBarVisible(scrollDirection);
                }
            }
        }
    }

    const setThemeColors = () => {
        if(theme && userDetails){
            document.getElementsByClassName('avatar')[0].style.borderColor = theme['color_3'];
            
            Array.from(document.getElementsByClassName('full-page-absolute')).forEach(elem => {
                elem.style.backgroundColor = theme['color_1'];
                elem.style.color = theme['color_3'];
            });
            Array.from(document.getElementsByClassName('color_1')).forEach(elem => {
                elem.style.backgroundColor = theme['color_1'];
            });
            Array.from(document.getElementsByClassName('color_2')).forEach(elem => {
                elem.style.backgroundColor = theme['color_2'];
            });
            Array.from(document.getElementsByClassName('color_4')).forEach(elem => {
                elem.style.color = theme['color_4'];
            });

            Array.from(document.getElementsByClassName('profile-social-button')).forEach(elem => {
                elem.style.backgroundColor = theme['color_3'];
            });
            Array.from(document.getElementsByClassName('profile-social-icon')).forEach(elem => {
                elem.style.color = theme['color_4'];
            });
        }        
    }

    useEffect(() => {
        getUser();
    },[]);

    useEffect(() => {
        setThemeColors();
    },[theme,userDetails]);

    return (
        <div className='full-page-absolute' >
            <Helmet>
                <meta charSet="utf-8" />
                <title>{username} on WanderCart </title>
                <meta name="description" content={`${username} on WanderCart! Discover new styles, products and more through your favorite creators!`} />
                <link rel="canonical" href="https://www.wanderc.art/" />
            </Helmet>
            <div className={navBarVisible} style={{zIndex:1, position:'absolute',top:0,left:0,right:0}}>
                <NavBarLogin theme={theme} useDarkLogo={true} username={username} hideMenu={true} width='100%' maxWidth="1300px" /> 
            </div>
            {
                userDetails 
                ?
                    <div className="new-user-main-container color_1">
                        <div className="new-user-page-right-navbar-container no-mobile color_1"> 
                            <div className="user-page-menu-item" onClick={() => clickTab('posts')}>
                                <h2 className="color_4" style={{fontWeight: pageTab === 'posts' ? 700 : 300}}>My Posts</h2>
                            </div>
                            <div className="user-page-menu-item" onClick={() => clickTab('products')}>
                                <h2 className="color_4" style={{fontWeight: pageTab === 'products' ? 700 : 300}}>My Products</h2>
                            </div>
                            <div className="user-page-menu-item" onClick={() => clickTab('saved')}>
                                <h2 className="color_4" style={{fontWeight: pageTab === 'saved' ? 700 : 300}}>My Likes</h2>
                            </div>
                            <div className="user-page-menu-item" onClick={clickShare}>
                                <ShareAltOutlined style={{fontSize:'1.2rem',strokeWidth:1}} />
                            </div>
                        </div>
                        <div className="new-user-page-container" onScroll={scrollPos} ref={scrollRef}>
                            <div className="new-user-page-left-container">
                                <div>
                                    <div style={{marginBottom:10}}>
                                        <div style={{marginBottom:15}}>
                                            <div className="align-div" style={{verticalAlign:'middle',width:'100%'}}>
                                                <div className="align-div" style={{verticalAlign:'middle'}}>
                                                    <img className="avatar" src={userDetails?.avatar_img || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReh8NuEJj15o7u5x7dW99KPdSO_rH0LKihMw&usqp=CAU'} />
                                                </div>
                                                <div className="align-div user-page-username-container" style={{verticalAlign:'middle'}}>
                                                    <div>
                                                        <h2 className="username-text color_4" style={{marginBottom:'3px'}}>{userDetails?.username}</h2>                                               
                                                    </div>
                                                    <div>
                                                        <div className="align-div user-stats-text color_4">
                                                            {userDetails?.followers > 1 ? i18next.t('dashboard.general.followersWithOptions',{val:userDetails?.followers}) : i18next.t('dashboard.general.followers',{count:userDetails?.followers})}
                                                        </div>
                                                        <div className="align-div user-stats-text color_4">
                                                            {userDetails?.likes > 1 ? i18next.t('dashboard.general.likesWithOptions',{val:userDetails?.likes}) : i18next.t('dashboard.general.likes',{count:userDetails?.likes})}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='align-div' style={{display:'flex',width:'100%',justifyContent:'center'}}>
                                                <FollowButton user_id={userDetails.user_id} username={userDetails.username} width={'100%'} size={window.innerWidth > 1000 ? 'middle' : 'large'} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{padding:'5px',marginBottom:5}}>
                                        <div className="user-description-text">
                                            <h4 className="description-text color_4" style={{lineHeight:1.1}}>
                                                {userDetails?.description}
                                            </h4>
                                        </div>
                                    </div>
                                    {
                                        userDetails?.link && userDetails?.link !== "" &&
                                            <div style={{marginBottom:'15px'}}>
                                                <div className="user-description-text">
                                                    <a href={userDetails?.link} target='_blank' className="color_4">{userDetails?.link.length > 40 ? userDetails?.link.slice(40) : userDetails?.link}</a> 
                                                </div>
                                            </div>
                                    }
                                    {
                                        userDetails?.socials &&
                                            <div className="user-description-text" style={{marginBottom:15}}>
                                                {userDetails?.socials?.whatsapp && userDetails?.socials?.whatsapp !== '' && <div className="align-div profile-social-button"><a onClick={() => clickWhatsApp(userDetails?.socials?.whatsapp)} className="profile-social-icon" target='_blank'><BsWhatsapp /></a></div>}
                                                {userDetails?.socials?.tiktok && userDetails?.socials?.tiktok !== '' && <div className="align-div profile-social-button"><a href={userDetails?.socials?.tiktok} className="profile-social-icon" target='_blank'><BsTiktok /></a></div>}
                                                {userDetails?.socials?.instagram && userDetails?.socials?.instagram !== '' && <div className="align-div profile-social-button"><a href={userDetails?.socials?.instagram} className="profile-social-icon" target='_blank'><BsInstagram /></a></div>}
                                                {userDetails?.socials?.facebook && userDetails?.socials?.facebook !== '' && <div className="align-div profile-social-button"><a href={userDetails?.socials?.facebook} className="profile-social-icon" target='_blank'><BsFacebook /></a></div>}
                                                {userDetails?.socials?.linkedin && userDetails?.socials?.linkedin !== '' && <div className="align-div profile-social-button"><a href={userDetails?.socials?.linkedin} className="profile-social-icon" target='_blank'><BsLinkedin /></a></div>}
                                                {userDetails?.socials?.email_social && userDetails?.socials?.email_social !== '' && <div className="align-div profile-social-button"><a href={userDetails?.socials?.email_social} className="profile-social-icon" target='_blank'><BsEnvelope /></a></div>}
                                                {userDetails?.socials?.twitter && userDetails?.socials?.twitter !== '' && <div className="align-div profile-social-button"><a href={userDetails?.socials?.twitter} className="profile-social-icon" target='_blank'><BsTwitter /></a></div>}
                                                {userDetails?.socials?.snapchat && userDetails?.socials?.snapchat !== '' && <div className="align-div profile-social-button"><a href={userDetails?.socials?.snapchat} className="profile-social-icon" target='_blank'><BsSnapchat /></a></div>}
                                            </div>
                                    }
                                </div>
                                <div className="no-mobile" style={{position:'fixed',bottom:10}}>
                                    {
                                        username &&
                                            <div onClick={() => navigate(`/`)} style={{cursor:'pointer'}}>
                                                <div className='align-div no-mobile' style={{textAlign:'left',verticalAlign:'middle',paddingLeft:'5px',paddingTop:'5px'}}>
                                                    <h5 className='color_4' style={{marginBottom:1,lineHeight:1}}>Powered by</h5>
                                                    <h3 className='color_4' style={{marginBottom:1,lineHeight:1.2}}>WanderCart</h3>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="new-user-page-right-container color_1">
                                <div>
                                    {
                                        {
                                            'posts':postSection,
                                            'products':productSection,
                                            'saved':savedSection,
                                        }[pageTab]
                                    }
                                </div>
                            </div>
                            <div ref={bottomRef}></div>
                        </div>
                        <div style={{position:'fixed',bottom:0,left:0,right:0}}>
                            
                        </div>
                    </div>
                :
                    <div className="full-page-center">
                        <div>
                            <Spin size="large" indicator={<LoadingOutlined style={{ fontSize:28 }} spin />} />
                            <div style={{paddingTop:'10px',paddingBottom:'20px'}}>
                                <h3>Welcome to <br/>{username}'s Page!</h3>
                            </div>
                        </div>
                    </div>
            }
            <ShareUserModal isShareUserModalVisible={isShareUserModalVisible} setIsShareUserModalVisible={setIsShareUserModalVisible} userDetails={userDetails} />
            <MobileCreatorNavBar user={userDetails} buttonClick={clickTab} selected={pageTab} />
        </div>
    )
};

export default CreatorProfilePage;