import { Form } from "antd";
import CategoryTagButton from "../../Tag/CategoryTagButton";
import { MdAccessibilityNew,MdChildFriendly,MdOutlineAirplanemodeActive } from "react-icons/md";
import { RiRestaurantFill,RiTShirt2Fill,RiGameFill,RiBrushFill,RiFireFill,RiTabletFill,RiHeartPulseFill,RiBookOpenFill,RiSuitcaseFill,RiCameraFill,RiMusic2Fill,RiEmotionLaughFill } from "react-icons/ri";
import i18next from "i18next";


const tagColors = {
    'Lifestyle':{icon:<MdAccessibilityNew style={{verticalAlign:'middle',marginRight:'3px',marginBottom:'2px'}} />,color:'magenta'}, 
    'Gaming':{icon:<RiGameFill style={{verticalAlign:'middle',marginRight:'3px',marginBottom:'2px'}} />,color:'red'}, 
    'Food & Drink':{icon:<RiRestaurantFill style={{verticalAlign:'middle',marginRight:'3px',marginBottom:'2px'}} />,color:'volcano'}, 
    'Fashion':{icon:<RiTShirt2Fill style={{verticalAlign:'middle',marginRight:'3px',marginBottom:'2px'}} />,color:'orange'},  
    'Beauty':{icon:<RiBrushFill style={{verticalAlign:'middle',marginRight:'3px',marginBottom:'2px'}} />,color:'gold'}, 
    'Travel':{icon:<MdOutlineAirplanemodeActive style={{verticalAlign:'middle',marginRight:'3px',marginBottom:'2px'}} />,color:'lime'}, 
    'Parenting &\nFamily':{icon:<MdChildFriendly style={{verticalAlign:'middle',marginRight:'3px',marginBottom:'2px'}} />,color:'green'},  
    'Music':{icon:<RiMusic2Fill style={{verticalAlign:'middle',marginRight:'3px',marginBottom:'2px'}} />,color:'cyan'},  
    'Humor & Comedy':{icon:<RiEmotionLaughFill style={{verticalAlign:'middle',marginRight:'3px',marginBottom:'2px'}} />,color:'blue'}, 
    'Technology & Gadget':{icon:<RiTabletFill style={{verticalAlign:'middle',marginRight:'3px',marginBottom:'2px'}} />,color:'geekblue'},  
    'Education':{icon:<RiBookOpenFill style={{verticalAlign:'middle',marginRight:'3px',marginBottom:'2px'}} />,color:'purple'}, 
    'Business':{icon:<RiSuitcaseFill style={{verticalAlign:'middle',marginRight:'3px',marginBottom:'2px'}} />,color:'red'}, 
    'Health':{icon:<RiHeartPulseFill style={{verticalAlign:'middle',marginRight:'3px',marginBottom:'2px'}} />,color:'magenta'}, 
    'Photography':{icon:<RiCameraFill style={{verticalAlign:'middle',marginRight:'3px',marginBottom:'2px'}} />,color:'volcano'}, 
    'Cooking':{icon:<RiFireFill style={{verticalAlign:'middle',marginRight:'3px',marginBottom:'2px'}} />,color:'green'}
}

function OnboadingForm3({formValues,setFormValues}){

    const selectCategory = async (value) => {
        if(formValues['categories'].includes(value)){
            const newCategoryList = await formValues['categories'].map((item) => {if(item !== value){return item}}).filter(item => {if(item !== undefined){return item}});
            setFormValues({...formValues,'categories':newCategoryList})
        }
        else{
            setFormValues({...formValues,'categories':[...formValues['categories'],value]})
        }
    }

    return(
        <div style={{width:'100%'}}>
            <div style={{width:'100%',paddingLeft:'15px',marginBottom:'5px'}}>
                <h2 style={{fontWeight:600,marginBottom:0,color:'rgba(0,0,0,0.8)'}}>
                    <strong>{i18next.t('onboarding.panel3.question')}</strong>
                </h2>
            </div>
            <Form.Item style={{width:'100%',marginBottom:0}}>
                <div style={{padding:'10px',textAlign:'center'}}>
                    {
                        Object.keys(tagColors).map((value,index) => 
                            <div key={`social_tag_${index}`} className="align-div" style={{padding:'5px'}}>
                                <CategoryTagButton name={value} icon={tagColors[value]?.icon} size='large' selected={formValues['categories'].includes(value)} onSelect={() => selectCategory(value)} />
                            </div>
                        )
                    }
                </div>
            </Form.Item>
        </div>
    )
}

export default OnboadingForm3;