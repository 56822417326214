import { createContext, useContext, useMemo, useState } from "react";
import ReactGA from "react-ga4";

const EventTrackingContext = createContext();

export const EventTrackingProvider = ({ children }) => {

    const sendEvent = ({category, action, label='', value=false, nonInteraction=false, transport='xhr'}) => {
        ReactGA.event({
            category: category,
            action: action,
            label: label,
            value: value,
            nonInteraction: nonInteraction,
            transport: transport,
        });
    }
    const sendPostEvent = ({post_id, user_id, type}) => {

        const customValue = {
            'post_id': post_id,
            'user_id':user_id,
        }

        let config = {
            category: 'post',
            label: JSON.stringify(customValue)
        }

        if(type === 'view'){
            config['action'] =  'post_view';
        }
        else if(type === 'engage'){
            config['action'] =  'post_engage';   
        }
        ReactGA.event(config);
    }
    const sendProductEvent = ({product_id, user_id, type}) => {

        const customValue = {
            'product_id':product_id,
            'user_id':user_id,
        }

        let config = {
            category: 'product',
            label: JSON.stringify(customValue)
        }

        if(type === 'view'){
            config['action'] =  'product_view';
        }
        else if(type === 'engage'){
            config['action'] =  'product_engage';   
        }
        ReactGA.event(config);
    }

    const sendAdEvent = ({ad_id, type}) => {

        const customValue = {
            'ad_id': ad_id
        }

        let config = {
            category: 'ad',
            label: JSON.stringify(customValue)
        }

        if(type === 'view'){
            config['action'] =  'ad_view';
        }
        else if(type === 'engage'){
            config['action'] =  'ad_engage';   
        }
        ReactGA.event(config);
    }

    const value = useMemo(
        () => ({
            sendEvent,
            sendPostEvent,
            sendProductEvent,
            sendAdEvent,
        }),[sendEvent,sendPostEvent,sendProductEvent,sendAdEvent,]
    );
    return <EventTrackingContext.Provider value={value}>{children}</EventTrackingContext.Provider>;
};

export const useEventTracker = () => {
    return useContext(EventTrackingContext);
};