import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4";
import './utils/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_KEY}`);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

reportWebVitals(SendAnalytics);
