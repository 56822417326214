import React, { useEffect, useState }  from 'react';
import { Modal } from "antd";
import RegisterForm from '../Form/RegisterForm';
import androidDownload from '../../images/download/android.webp';
import iosDownload from '../../images/download/ios.webp';

function RegisterModal({isRegisterModalVisible, setIsRegisterModalVisible, username=false}){
    return(
        <Modal             
            open={isRegisterModalVisible} 
            footer={null} 
            onCancel={() => setIsRegisterModalVisible(false)}
            style={{width:'100%',maxWidth:'350px',borderRadius:'10px',overflow:'hidden'}}
            centered
            destroyOnClose
            >
            <div className="modal-div" style={{padding:'15px 5px 10px 5px'}}>
                <div style={{textAlign:'center'}}>
                    <RegisterForm username={username} />
                </div>
            </div>
        </Modal>
    )
}
export default RegisterModal;