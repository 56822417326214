import { Select } from "antd"

const followerCounts = [
    {
        label:'~1K',
        value:'~1k'
    },
    {
        label:'1K - 10K',
        value:'1Kto10K'
    },
    {
        label:'10K - 50K',
        value:'10Kto50K'
    },
    {
        label:'50K - 100K',
        value:'50Kto100K'
    },
    {
        label:'100K - 500K',
        value:'100Kto500K'
    },
    {
        label:'500K - 1M',
        value:'500Kto1M'
    },
    {
        label:'1M - 10M',
        value:'1Mto10M'
    },
    {
        label:'10M - 100M',
        value:'1Mto10M'
    },
    {
        label:'100M+',
        value:'100M'
    }
];

function FollowerGroupSelect({onChange,value, placeholder='~1K', borderColor='rgba(0,0,0,0.2)'}){
    const { Option } = Select;

    return(
        <Select 
            defaultValue={value}
            onSelect={onChange}
            bordered={false} 
            size="large"
            className="social-media-field-input"
            placeholder={placeholder}
            style={{borderColor:borderColor}} >
            {
                followerCounts.map((item,index) => 
                    <Option key={`follower-group-select-${index}`} value={item.value} style={{color:'rgba(0,0,0,0.8)'}} >{item.label}</Option>
                )
            }
        </Select>
    )
}

export default FollowerGroupSelect;

