import React, {useState, useEffect} from 'react';
import { DownOutlined } from '@ant-design/icons';
import ProductImage from '../../components/Image/PostImage';
import ProductImageWBox from '../../components/Image/PostImageWBox';
import NavBarLogin from '../../components/NavBar/NavBarLogin';
import sampleImg from '../../images/couple.jpg';
import mockupImg from '../../images/homepage/mobileTop.webp';
import scrollImg from '../../images/homepage/scroll.webp';
import shareImg from '../../images/homepage/share.webp';
import shopImg from '../../images/homepage/shop.webp';
import iphoneImg from '../../images/homepage/iphoneOutline.png';
import iconImg from '../../images/logo/icon-gradient.jpeg';
import Footer from '../../components/Footer/Footer';
import androidDownload from '../../images/download/android.webp';
import iosDownload from '../../images/download/ios.webp';
import downloadQrImg from '../../images/homepage/download_qr.webp';
import HowItWorksCard from '../../components/Card/HowItWorksCard';
import { sampleBoxes } from '../../resources/SampleBoxes';
import { HomeImages } from '../../resources/HomeImages';
import DownloadModal from '../../components/Modal/DownloadModal';
import BottomButton from '../../components/Button/BottomButton';
import i18next from '../../utils/i18n';
import { withTranslation } from 'react-i18next';
import { Button, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

function HomePage() {
    const navigate = useNavigate();
    const [selectItem, setSelectItem] = useState(false);
    const [hoverItem, setHoverSelectItem] = useState(false);
    const [imageList, setImageList] = useState(false);
    const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
    const [navBarVisible,setNavBarVisible] = useState('fadeInBottomJs')
    let showPopup;

    const shuffle = (a) => {
        var j, x, i;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
        return a;
    }
    const selectChange = (value) => {
        sampleBoxes['Couple'][value]['index'] = value;
        setSelectItem(sampleBoxes['Couple'][value]);
    }
    const hoverImage = (value) => {
        setHoverSelectItem(imageList[value]['index']);
    }
    const hoverImageOut = () => {
        if(imageList){
            setHoverSelectItem(false);
        }
    }    
    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
    
        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 0;
        
            if (elementTop < windowHeight - elementVisible ) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
        setTimeout(() => {
            var waitlist = document.getElementById('show-waitlist-div');
            if(typeof waitlist !== 'undefined' && waitlist !== null){
                if(waitlist.getBoundingClientRect().top < window.innerHeight - 500){
                    if(showPopup){
                        showPopup=false;
                        setIsDownloadModalVisible(true);
                    }
                }
            }
        }, 1500);
    }
    const showBottomButton = () => {
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
        
        if(top < (doc.clientHeight/2)){
            if(navBarVisible !== 'fadeInBottomJs'){
                setNavBarVisible('fadeInBottomJs');
            }
        }
        else{
            if(navBarVisible !== 'fadeOutBottomJs'){
                setNavBarVisible('fadeOutBottomJs');
            }
        }


    }
    window.addEventListener("scroll", reveal);
    window.addEventListener("scroll", showBottomButton);

    useEffect(() => {
        sampleBoxes['Couple'].forEach((value, index) => value['items'].forEach((value1) => {value1['name'] = value['label_name']; value1['index'] = index; return value1;}));
        let tempList = sampleBoxes['Couple'].map((value) => {return value['items']}).flat(1);
        if(window.innerWidth > 1000){
            showPopup=true;
            setImageList(shuffle(tempList));
        }
        else{
            showPopup=false;
            setImageList(shuffle(tempList).slice(0,6));
        }

        if(selectItem){
            let newTempList = tempList.filter((value) => {return value.index === selectItem['items'][0].index});
            let otherTempList = tempList.filter((value) => {return value.index !== selectItem['items'][0].index});
            if(window.innerWidth > 1000){
                setImageList([...shuffle(newTempList), ...otherTempList]);
            }
            else{
                setImageList(shuffle(tempList).slice(0,6));
            }    
            document.getElementById('products-div').scrollTop = 0;
        }
    },[selectItem]);

    return(
        <div className='full-page'>
            <NavBarLogin setIsDownloadModalVisible={setIsDownloadModalVisible} hideMenu={true} />
            <div className='full-page-content-section'>
                <div className='full-width-page-section product-preview-container landing-title-fadein'>
                    <div className='full-page-content product-explore-sample' >
                        <div id='image-div' className='full-page-content-half'>
                            <div className='section-header home-banner-top'>
                                <div style={{verticalAlign:'top',lineHeight: 1.1}}>
                                    <h1 style={{fontSize:'50px',fontFamily:'sans-serif',fontWeight:600}}>{i18next.t('home_page.header')}</h1>
                                </div>
                                <div>
                                    <h2 style={{fontSize:'25px',fontFamily:'sans-serif',fontWeight:'600'}}>{i18next.t('home_page.subheader_1')}{window.innerWidth > 1000 && <br/>} {i18next.t('home_page.subheader_2')}</h2>
                                </div>
                                <div className='no-desktop'>
                                    <div style={{marginTop:'25px'}}>
                                        <div style={{fontSize:'18px',fontWeight:600, paddingBottom:'10px'}}>
                                            Get Started in Minutes:
                                        </div>
                                        <div style={{marginBottom:'25px'}}>
                                            <div className='align-div' style={{marginRight:'5px'}}>
                                                <Button onClick={() => navigate('/register')} type='primary' className="rounded-button" style={{backgroundColor:'#0891b2',borderColor:'#0891b2',fontSize:'18px'}} size='large'>Try the Web App</Button>
                                            </div>
                                            <div className='align-div'>
                                                <Button onClick={() => setIsDownloadModalVisible(true)} type='primary' ghost={true} className="rounded-button" style={{color:'#0891b2',borderColor:'#0891b2',fontSize:'18px'}} size='large'>Download</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{paddingTop:'20px',paddingBottom:'50px'}}>
                                        <img src={mockupImg} style={{width: '100%'}} />
                                    </div>
                                </div>
                                <div className='download-section no-mobile'>
                                    <h3>Get Started in Minutes:</h3>
                                    <div style={{marginBottom:'15px'}}>
                                        <div className='align-div' style={{marginRight:'5px'}}>
                                            <Button onClick={() => navigate('/register')} type='primary' ghost={true} className="rounded-button" style={{color:'#0891b2',borderColor:'#0891b2'}} size='large'>Try the Web App</Button>
                                        </div>
                                        <div className='align-div' style={{marginRight:'5px'}}>
                                            <Button onClick={() => setIsDownloadModalVisible(true)} type='primary' className="rounded-button" style={{backgroundColor:'#0891b2',borderColor:'#0891b2'}} size='large'>Download</Button>
                                        </div>
                                        <div className='align-div'>
                                            <img src={iconImg} className='icon-button' style={{width:'40px',height:'40px'}} />
                                        </div>
                                    </div>
                                </div>
                                <div className='section-header home-page-banner-bottom-text bounce'>
                                    <h1>{i18next.t('home_page.learn_more')} <DownOutlined/></h1>
                                </div>
                            </div>
                        </div>
                        <div className='full-page-content-half no-mobile'>
                            <div>
                                <img src={scrollImg} style={{height:'75vh',paddingTop:'3vh'}} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content-section'>
                    <div className='how-to-section'>
                        <div className='section-header' style={{textAlign:'center',marginTop:'0px'}}>
                            <h1>{i18next.t('home_page.how_it_works')}</h1>
                            <h2>
                                {i18next.t('home_page.how_it_works_details')}<br/>
                            </h2>
                        </div>
                        <div className='full-width-page-section'>
                            <div>
                                <div className='how-it-works-content reveal'>
                                    <HowItWorksCard 
                                        image={scrollImg}
                                        title={i18next.t('home_page.scroll_title')}
                                        description={i18next.t('home_page.scroll_details')}
                                        />
                                </div>
                                <div className='how-it-works-content reveal'>
                                    <HowItWorksCard 
                                        image={shopImg}
                                        title={i18next.t('home_page.shop_title')}
                                        description={i18next.t('home_page.shop_details')}
                                        />
                                </div>
                                <div className='how-it-works-content reveal'>
                                    <HowItWorksCard 
                                        image={shareImg}
                                        title={i18next.t('home_page.share_title')}
                                        description={i18next.t('home_page.share_details')}
                                        />
                                </div>
                            </div>
                            <div className='reveal' style={{paddingTop:'20px'}}>
                                <h2>
                                    {i18next.t('home_page.say_hello')}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className='full-width-page-section no-mobile reveal' style={{height:'auto',paddingTop:'15vh', marginBottom:'0', paddingBottom:'0'}}>
                        <div className='section-header' style={{textAlign:'center',paddingBottom:'20px'}}>
                            <div style={{paddingBottom:'10px'}}>
                                <img src={iconImg} className='icon-button' />
                                <div style={{display:'inline-block',verticalAlign:'top',lineHeight: 1.3}}>
                                    <h1>{i18next.t('home_page.subheader_smart_tagging')}</h1>
                                </div>
                            </div>
                            <h2>
                                {i18next.t('home_page.smart_tagging_detail1')}<br/>
                                {i18next.t('home_page.smart_tagging_detail2')}<br/>
                            </h2>
                        </div>
                    </div>
                    <div className='full-width-page-section reveal no-mobile' style={{borderBottom:'1px solid rgba(0,0,0,0.2)'}}>
                        <div className='full-page-content product-explore-sample' >
                            <div id='image-div' className='full-page-content-half'>
                                <div className='section-header'>
                                    <div>
                                        <h1 style={{lineHeight:1}}>{i18next.t('home_page.header_shop_anything')}</h1>
                                    </div>
                                    <div style={{paddingBottom:'15px'}}>
                                        <h3>
                                            {i18next.t('home_page.shop_anything_detail1')}<br/>
                                            {i18next.t('home_page.shop_anything_detail2')}
                                        </h3>
                                        <div style={{width:'fit-content',backgroundColor:'rgba(0,0,0,0.5)',padding:5,paddingLeft:8,paddingRight:8,borderRadius:7}}>
                                            <div style={{lineHeight:1,color:'white'}}>
                                                👚 {i18next.t('home_page.shop_anything_action')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='no-mobile' style={{position:'relative'}}>
                                    {sampleBoxes ? <ProductImageWBox imageUrl={sampleImg} boxes={sampleBoxes['Couple']} selectItem={selectItem} setSelectItem={setSelectItem} hoverItem={hoverItem} sticky={true} />: ''}
                                </div>
                            </div>
                            <div id='products-div' className='full-page-content-half no-mobile' >
                                <div className='iphone-preview-container'>
                                    <div className='iphone-overlay'>
                                        <img src={iphoneImg} className='iphone-mockup' />
                                        <div className='iphone-demo-view-container'>
                                            <div className='iphone-demo-view'>
                                                {
                                                    imageList
                                                    ?
                                                        imageList.map((value,index) => 
                                                            <div className='product-image-gallery' key={index} onClick={() => setIsDownloadModalVisible(true)} onMouseOver={() => hoverImage(index)} onMouseOut={() => hoverImageOut()} >
                                                                <ProductImage values={value} selectChange={selectChange} />
                                                            </div>
                                                        )
                                                    : ''
                                                }
                                                <div className='section-center'>
                                                    <a>{i18next.t('home_page.see_more')}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='home-page-download-section reveal' id='download'>
                        <div className='section-header' style={{textAlign:'center'}}>
                            <h1>
                                {i18next.t('home_page.download_and_wander')}
                            </h1>
                        </div>
                        <div style={{marginBottom:'20px'}}>
                            <div className='align-div'>
                                <img src={iconImg} className='icon-button' style={{width: '80px', height:'80px'}} />
                            </div>
                            <div className='align-div' style={{paddingLeft:'5px'}}>
                                <img src={downloadQrImg} className='icon-button' style={{width: '80px', height:'80px'}} />
                            </div>
                        </div>
                        <div style={{paddingBottom:'5px'}}>
                            <h2>
                                {i18next.t('home_page.download_availability')}
                            </h2>
                        </div>
                        <div>
                            <a href='https://apps.apple.com/us/app/wandercart/id6450434248' target='_blank' className='align-div'>
                                <img src={iosDownload} className='navbar-download-links' />
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=com.daiki.wandercart.shopapp' target='_blank' className='align-div'>
                                <img src={androidDownload} className='navbar-download-links' />
                            </a>
                        </div>
                    </div>
                    <div style={{paddingTop:'100px'}}>
                        <div className='section-header reveal' style={{textAlign:'center',paddingBottom:'20px'}}>
                            <h1>{i18next.t('home_page.start_explore')}</h1>
                        </div>
                        <div className='full-width-page-section'>
                            <div className='section-header reveal'>
                                <h1>{i18next.t('home_page.fashion_subheader')}</h1>
                                <h3 style={{marginBottom:'2vh'}}>
                                    {i18next.t('home_page.fashion_details')}
                                </h3>
                            </div>
                            <div className='reveal'>
                                <div>
                                    {
                                        HomeImages['fashion'].slice(0,4).map((value,index) =>
                                            <div key={index} className='home-page-image-card-container reveal-mobile' onClick={() => setIsDownloadModalVisible(true)}>
                                                <ProductImage values={value} widthPercent={0.2} preview={true} />
                                            </div>
                                        )
                                    }
                                </div>
                                <div className='section-center'>
                                    <a onClick={() => setIsDownloadModalVisible(true)}>{i18next.t('home_page.see_more')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='full-width-page-section'>
                        <div id='show-waitlist-div' className='section-header reveal'>
                            <h1>{i18next.t('home_page.home_subheader')}</h1>
                            <h3 style={{marginBottom:'2vh'}}>
                                {i18next.t('home_page.home_details')}
                            </h3>
                        </div>
                        <div className='reveal'>
                            <div>
                                {
                                    HomeImages['home'].slice(0,4).map((value, index) =>
                                        <div key={index} className='home-page-image-card-container reveal-mobile' onClick={() => setIsDownloadModalVisible(true)}>
                                            <ProductImage values={value} widthPercent={0.2} preview={true} />
                                        </div>
                                    )
                                }
                            </div>
                            <div className='section-center'>
                                <a onClick={() => setIsDownloadModalVisible(true)}>{i18next.t('home_page.see_more')}</a>
                            </div>
                        </div>
                    </div>
                    <div className='full-width-page-section'>
                        <div className='section-header reveal'>
                            <h1>{i18next.t('home_page.other_subheader')}</h1>
                            <h3 style={{marginBottom:'2vh'}}>
                                {i18next.t('home_page.other_details')}
                            </h3>
                        </div>
                        <div className='reveal'>
                            <div>
                                {
                                    HomeImages['other'].slice(0,4).map((value,index) =>
                                        <div key={index} className='home-page-image-card-container reveal-mobile'  onClick={() => setIsDownloadModalVisible(true)}>
                                            <ProductImage values={value} widthPercent={0.2} preview={true} />
                                        </div>
                                    )
                                }
                            </div>
                            <div className='section-center'>
                                <a onClick={() => setIsDownloadModalVisible(true)}>{i18next.t('home_page.see_more')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer setIsDownloadModalVisible={setIsDownloadModalVisible} />
            </div>
            <div className={navBarVisible} style={{position:'fixed',left:0,right:0}}>
                <BottomButton />
            </div>
            <DownloadModal message={<div style={{lineHeight:1.4}}>WanderCart is Live!</div>} isDownloadModalVisible={isDownloadModalVisible} setIsDownloadModalVisible={setIsDownloadModalVisible} />
        </div>
    )
}

export default withTranslation()(HomePage);