import { MoreOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";


function SectionHeader({name,description,onClick=false,onClickMore=false}){
    return(
        <div style={{width:'100%'}}>
            <div className="align-div">
                <h2>{name}</h2>
                <h4 style={{color:'rgba(0,0,0,0.6)'}}>{description}</h4>
            </div>
            <div className="align-div float-right" >
                <Space size={3}>
                    {onClick && <Button type="outline" style={{border:'1px solid rgba(0,0,0,0.2)',paddingLeft:'8px',paddingRight:'8px',borderRadius:'5px'}} onClick={onClick}>Edit {name}</Button>}
                    {onClickMore && <Button type="outline" style={{border:'1px solid rgba(0,0,0,0.2)',paddingLeft:'8px',paddingRight:'8px',borderRadius:'5px'}}  onClick={onClickMore}><MoreOutlined /></Button>}
                </Space>
            </div>
        </div>
    )
}

export default SectionHeader;