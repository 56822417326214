import { Select } from "antd"
import { useEffect, useState } from "react";
import LocationServices from "../../utils/LocationServices";


function CountrySelect({onSelect,defaultValue}){
    const { Option } = Select;
    const [countryList,setCountryList] = useState([]);
    
    const getCountryList = async () => {
        const countryResponse = await LocationServices.getCountryFlags();
        console.log(countryResponse.data);
        setCountryList(countryResponse.data);
    }
    
    useEffect(() => {
        getCountryList();
    },[])

    return(
        <Select 
            defaultValue={defaultValue} 
            onSelect={onSelect}
            showSearch={true}
            bordered={false} 
            mode='tags'
            size="large"
            placeholder='Select All Countries'
            className="font-select">
            {
                countryList.map((value,index) => 
                    <Option key={`country-select-${index}`} value={value.name} style={{height:'90%',maxHeight:'50px'}} >{value.name}</Option>
                )
            }
        </Select>
    )
}

export default CountrySelect;

