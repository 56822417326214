import { Select } from "antd"

function KeywordSelect({onSelect,defaultValue}){
    const { Option } = Select;
    
    return(
        <Select 
            defaultValue={defaultValue} 
            onSelect={onSelect}
            showSearch={true}
            bordered={false}
            mode='tags'
            size="large"
            placeholder='Arial'
            className="font-select"/>
    )
}

export default KeywordSelect;

