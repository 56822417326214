import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ShoppingCartOutlined, ShopOutlined, CameraOutlined } from '@ant-design/icons';
import androidDownload from '../../images/download/android.webp';
import iosDownload from '../../images/download/ios.webp';
import logo from '../../images/logo/logo.png';
import logoWide from '../../images/logo/logo-wide.png';
import '../../style/index.css';
import { Button, Space } from 'antd';

const pathKeys = {
    '':'1',
    'post':'2',
    'for-sellers':'3',
};

function NavBarGeneral({setIsInfluencerOnboardingModalVisible=false, createAccount=false}) {

    return(
        <div className='navbar-container' >
            <div className='navbar-general'>
                <div className='navbar-logo'>
                    <Link to='/'><img src={logoWide} alt="datarebel-logo" className="logo-style" /></Link>
                </div>
                <div className='navbar-button-container'>
                    {
                        createAccount
                        ? 
                            <div>
                                <Space className='no-mobile'>
                                    <Button type={'primary'} size='large' className='rounded-button' style={{color:'white',backgroundColor:'#ef3078',borderColor:'#ef3078'}} onClick={() => setIsInfluencerOnboardingModalVisible(true)}>Create Your Profile</Button>
                                    <Button type={'primary'} ghost={true} size='large' className='rounded-button' style={{color:'#ef3078',borderColor:'#ef3078'}} onClick={() => setIsInfluencerOnboardingModalVisible(true)}>Wander Deals</Button>
                                </Space>
                                <div className='no-desktop' style={{paddingBottom:'10px'}}>
                                    <a href='#download' style={{fontSize:'16px'}}>Get Started</a>
                                </div>
                            </div>
                        :
                            <div className='no-mobile'>
                                <a href='https://apps.apple.com/us/app/wandercart/id6450434248' target='_blank' className='align-div'>
                                    <img src={iosDownload} className='navbar-download-links' style={{height:'40px'}} />
                                </a>
                                <a href='https://play.google.com/store/apps/details?id=com.daiki.wandercart.shopapp' target='_blank' className='align-div'>
                                    <img src={androidDownload} className='navbar-download-links' style={{height:'40px'}} />
                                </a>
                            </div>
                    }
                </div>
            </div>
        </div>
    )

}
export default NavBarGeneral;