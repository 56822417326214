import { Button } from "antd";

const tagColors = {
    'Lifestyle':'magenta', 
    'Gaming':'red',
    'Food & Drink':'volcano',
    'Fashion':'orange', 
    'Beauty':'gold',
    'Travel':'lime',
    'Parenting & Family':'green', 
    'Music':'cyan', 
    'Humor & Comedy':'blue',
    'Technology & Gadget':'geekblue', 
    'Education':'purple',
    'Business':'red',
    'Health':'magenta',
    'Photography':'volcano',
    'Cooking':'green'
}

function CategoryTagButton({name,icon=false,color=false,onSelect=false,selected=false,size='medium'}){

    return(
        selected
        ? <Button type={'primary'} size={size} icon={icon && icon} className='rounded-button hover-scale-sm' style={{color:'white',backgroundColor: color ? color : '#ef3078',borderColor: color ? color : '#ef3078'}} onClick={() => onSelect && onSelect()}>{name}</Button>
        : <Button type={'primary'} ghost={true} icon={icon && icon} size={size} className='rounded-button hover-scale-sm' style={{color: color ? color : '#ef3078',borderColor: color ? color : '#ef3078'}} onClick={() => onSelect && onSelect()}>{name}</Button>
    )
}

export default CategoryTagButton;