import { Form, Table } from "antd";
import { createContext, useContext } from "react";
import EditableTableCell from "./EditableTableCell";

const EditableContext = createContext(null);

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableTable = ({data,defaultColumns,handleSave,loading=false,pagination=true}) => {
    
    const components = {
        body: {
            row: EditableRow,
            cell: EditableTableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
           return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });
    
    return(
        <Table
            bordered
            components={components}
            dataSource={data}
            columns={columns}
            pagination={pagination}
            rowClassName={() => 'editable-row'}
            style={{overflowY:'auto'}}
            loading={loading}
            />
    )
}

export default EditableTable;

export const useEditableContext = () => {
    return useContext(EditableContext);
};