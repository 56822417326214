import { Skeleton } from "antd";
import { useEffect, useState } from "react";

function PostSkeletonRandomCard({key='1'}) {
    const [height,setHeight] = useState('26vw')

    function getRandomArbitrary(width) {
        return width * (Math.random() + 1);
    }

    useEffect(() => {
        var width = document.getElementById('div-width').offsetWidth;
        var newHeight = getRandomArbitrary(width);
        setHeight(newHeight);
    },[]);
    
    return(
        <div key={key} className="post-skeleton" style={{width:'100%',padding:'5px'}} id='div-width'>
            <Skeleton.Button className="card-shadow" style={{width:'100%',height:height}} block={true} active={true} />
        </div>
    )
}

export default PostSkeletonRandomCard;