import { Select } from "antd"

const ageList = [
    {
        label:'18 to 24',
        value:'18 to 24'
    },
    {
        label:'25 to 34',
        value:'25 to 34'
    },
    {
        label:'35 to 44',
        value:'35 to 44'
    },
    {
        label:'45 to 54',
        value:'45 to 54'
    },
    {
        label:'55 to 64',
        value:'55 to 64'
    },
    {
        label:'65 or over',
        value:'65 or over'
    }
]

function AgeSelect({onSelect,defaultValue}){
    const { Option } = Select;

    return(
        <Select 
            defaultValue={defaultValue} 
            onSelect={onSelect}
            showSearch={true}
            bordered={false} 
            mode='multiple'
            size="large"
            placeholder='Select Age Ranges'
            className="font-select">
            {
                ageList.map((value,index) => 
                    <Option key={`language-select-${index}`} value={value.value} style={{height:'90%',maxHeight:'50px'}} >{value.label}</Option>
                )
            }
        </Select>
    )
}

export default AgeSelect;

