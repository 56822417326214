

import axios from "axios";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}`;

class LocationServices {

    getCountries() {
        const configuration = {
            method: "get",
            url: `https://restcountries.com/v3.1/all?status=true&fields=name,cca2`
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    };

    getCountryFlags() {
        const configuration = {
            method: "get",
            url: `https://countriesnow.space/api/v0.1/countries/flag/images`
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }
    getCountryCities({country=false,iso2=false}) {

        let filterData = {};
        if(country) filterData['country'] = country;
        if(iso2) filterData['iso2'] = iso2;

        const configuration = {
            method: "post",
            url: `https://countriesnow.space/api/v0.1/countries/cities`,
            data:filterData
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }
    getCountryStates({country=false,iso2=false}) {

        let filterData = {};
        if(country) filterData['country'] = country;
        if(iso2) filterData['iso2'] = iso2;

        const configuration = {
            method: "post",
            url: `https://countriesnow.space/api/v0.1/countries/states`,
            data: filterData
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }

    getUserCountry() {
        const configuration = {
            method: "get",
            url: `https://geolocation-db.com/json/`
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }
}

export default new LocationServices();