
function HowItWorksCard({title, description, image}) {

    return(
        <div className='how-it-works-card'>
            <div className="how-it-works-text">
                <h2>{title}</h2>
                <h3>{description}</h3>
            </div>
            <div className='how-it-works-image-container'>
                <img src={image} className='how-it-works-image' />                
            </div>
        </div>
    )

}

export default HowItWorksCard;