
export const sampleBoxes = {
        'Couple':[
            {
                'label_name': 'Cup 1',
                'bbox_x': 144,
                'bbox_y': 96,
                'bbox_width': 64,
                'bbox_height': 71,
                'image_width': 640,
                'image_height': 427,
                'items':[
                    {
                        'imgUrl':'https://i5.walmartimages.com/asr/2b85a789-347a-448d-bb9b-55df463e8641.dfa9ca337b4572837906ae48556aae16.png?odnHeight=612&odnWidth=612&odnBg=FFFFFF',
                        'url':'https://www.walmart.com/ip/Corelle-Coffee-Mug-Winter-Frost-White-11-Oz-Set-Of-6/224164475',
                        'price':'$20.00',
                    },
                    {
                        'imgUrl':'https://www.ikea.com/th/en/images/products/ikea-365-mug-white__0711130_pe727998_s5.jpg?f=xl',
                        'url':'https://www.ikea.com/th/en/p/ikea-365-mug-white-60278368/',
                        'price':'$3.00',
                    },
                    {
                        'imgUrl':'https://www.ikea.com/th/en/images/products/vaerdera-mug-white__51249_pe150726_s5.jpg?f=xl',
                        'url':'https://www.ikea.com/th/en/p/vaerdera-mug-white-30277365/',
                        'price':'$2.50',
                    },
                    {
                        'imgUrl':'https://lzd-img-global.slatic.net/g/p/7ae22e2de440414a37ceed096576f04a.jpg_720x720q80.jpg_.webp',
                        'url':'https://www.lazada.co.th/products/wall-cat-white-mug-11oz-ceramic-tea-cup-coffee-mug-friends-birthday-gift-cats-battle-cats-simple-cute-lovely-mobile-game-gaming-i4493830378-s18192394240.html?spm=a2o4m.tm80167383.2074280300.1.2891YJefYJef91.2891YJefYJef91&priceCompare=skuId%3A18192394240%3Bsource%3Alazada-om%3Bsn%3Ad327b702-7590-40fc-bb2a-b5b38ebec2d0%3BoriginPrice%3A38000%3BvoucherPrice%3A38000%3Btimestamp%3A1683494359146',
                        'price':'$10.00',
                    }
                ]
            },
            {
                'label_name': 'Cup 2',
                'bbox_x': 426,
                'bbox_y': 229,
                'bbox_width': 77,
                'bbox_height': 78,
                'image_width': 640,
                'image_height': 427,
                'items':[
                    {
                        'imgUrl':'https://i5.walmartimages.com/asr/2b85a789-347a-448d-bb9b-55df463e8641.dfa9ca337b4572837906ae48556aae16.png?odnHeight=612&odnWidth=612&odnBg=FFFFFF',
                        'url':'https://www.walmart.com/ip/Corelle-Coffee-Mug-Winter-Frost-White-11-Oz-Set-Of-6/224164475',
                        'price':'$20.00',
                    },
                    {
                        'imgUrl':'https://www.ikea.com/th/en/images/products/ikea-365-mug-white__0711130_pe727998_s5.jpg?f=xl',
                        'url':'https://www.ikea.com/th/en/p/ikea-365-mug-white-60278368/',
                        'price':'$3.00',
                    },
                    {
                        'imgUrl':'https://www.ikea.com/th/en/images/products/vaerdera-mug-white__51249_pe150726_s5.jpg?f=xl',
                        'url':'https://www.ikea.com/th/en/p/vaerdera-mug-white-30277365/',
                        'price':'$2.50',
                    },
                    {
                        'imgUrl':'https://www.yourdesign.co.uk/images/pictures/cut-square/personalsed-mug-yourdesgn-(product-player).jpg?mode=h',
                        'url':'https://www.yourdesign.co.uk/standard-mug',
                        'price':'$4.00',
                    }
                ]
            },
            {
                'label_name': 'Shoe 1',
                'bbox_x': 26,
                'bbox_y': 317,
                'bbox_width': 121,
                'bbox_height': 90,
                'image_width': 640,
                'image_height': 427,
                'items':[
                    {
                        'imgUrl':'https://m.media-amazon.com/images/I/71J5VV4IQwL._UX695_.jpg',
                        'url':'https://www.amazon.in/FAUSTO-Suede-Leather-Chelsea-Boots/dp/B085VWTLJQ?th=1&psc=1',
                        'price':'$20.00',
                    },
                    {
                        'imgUrl':'https://www.mrporter.com/variants/images/3024088872927384/in/w1200_q60.jpg',
                        'url':'https://www.mrporter.com/en-us/mens/product/common-projects/shoes/chelsea-boots/suede-chelsea-boots/3024088872927384',
                        'price':'$525.00',
                    },
                    {
                        'imgUrl':'https://i0.wp.com/theleatherposh.com/wp-content/uploads/2021/03/Tan-Suede-Chelsea-Boots-for-Men-Slip-o-Leather-Boots.jpeg?fit=1280%2C959&ssl=1',
                        'url':'https://theleatherposh.com/product/tan-suede-chelsea-boots-for-men-slip-on-leather-boots/',
                        'price':'$195.00',
                    },
                    {
                        'imgUrl':'https://m.media-amazon.com/images/I/51UZxpVa78L._AC_UY695_.jpg',
                        'url':'https://www.amazon.com/Sonoma-Suede-Chelsea-Boot-Size/dp/B08F3154C3/ref=sr_1_9?dchild=1&keywords=new+republic&qid=1615412850&sr=8-9&asc_source=verso&asc_campaign=5aaae6bd5bce6d4cf87088b0&asc_refurl=https%3A%2F%2Fwww.gq.com%2Fstory%2Fthe-best-chelsea-boots-to-wear-with-everything&ascsubtag=5aaae6bd5bce6d4cf87088b0&tag=gqgensqua-20',
                        'price':'$99.00',
                    },
                    {
                        'imgUrl':'https://lzd-img-global.slatic.net/g/p/55159f87f6151864d65bae42ae6495cf.jpg_720x720q80.jpg_.webp',
                        'url':'https://www.lazada.co.th/products/genuine-leather-chelsea-boots-for-men-cow-suede-ankle-boots-platform-shoes-casual-business-shoes-autumn-winter-slip-on-men-shoe-i4148513804-s16280849711.html?',
                        'price':'$30.00',
                    },
                    {
                        'imgUrl':'https://cdn.shopify.com/s/files/1/1723/3421/products/Dev_chelsea_boot_suede-Chelsea_Boots-STB1354-152_SAND_II_1800x1800.jpg?v=1671788988',
                        'url':'https://www.shoethebear.us/products/dev-suede-chelsea-boot-chelsea-boots-sand-ii',
                        'price':'$170.00',
                    }
                ]
            },
            {
                'label_name': 'Shoe 2',
                'bbox_x': 258,
                'bbox_y': 329,
                'bbox_width': 84,
                'bbox_height': 74,
                'image_width': 640,
                'image_height': 427,
                'items':[
                    {
                        'imgUrl':'https://cdn.shopify.com/s/files/1/0419/1525/products/1024x1024-Women-Duchess-Black-Side_1024x1024.jpg?v=1591637693',
                        'url':'https://thursdayboots.com/products/womens-duchess-black-chelsea-boot',
                        'price':'$160.00',
                    },
                    {
                        'imgUrl':'https://arthurknight.com/pub/media/catalog/product/cache/8723b12f6326e9bdc6bc191d0c49a6b8/t/a/tamaris-black-leather-womens-chelsea-bootie-ankle-high-1.jpg',
                        'url':'https://arthurknight.com/tamaris-black-leather-womens-chelsea-boots.html',
                        'price':'$96.00',
                    },
                    {
                        'imgUrl':'https://m.media-amazon.com/images/I/61oa-Lq2DTL._AC_UX695_.jpg',
                        'url':'https://www.amazon.com/Odema-Womens-Leather-Chelsea-Elastic/dp/B07CLWHGM6',
                        'price':'$39.99',
                    },
                    {
                        'imgUrl':'https://www.dianaferrari.com.au/media/catalog/product/cache/c36a32b6d66d14559eea2ad6988106fc/S/U/SU12454BLALE_3.jpg',
                        'url':'https://www.dianaferrari.com.au/cartright-black-leather-ankle-boots.html',
                        'price':'$219.99',
                    },
                    {
                        'imgUrl':'https://img.shopstyle-cdn.com/sim/c9/29/c9295e38e3a0392e9dba6de90c1b582c_best/duiza-flat-leather-ankle-boots.jpg',
                        'url':'https://www.shopstyle.com/g/women/isabel-marant/duiza-flat-leather-ankle-boots/891013997?imageId=c9295e38e3a0392e9dba6de90c1b582c',
                        'price':'$540.00',
                    }
                ]
            },
            {
                'label_name': 'Shoe 3',
                'bbox_x': 173,
                'bbox_y': 340,
                'bbox_width': 88,
                'bbox_height': 66,
                'image_width': 640,
                'image_height': 427,
                'items':[
                    {
                        'imgUrl':'https://cdn.shopify.com/s/files/1/0419/1525/products/1024x1024-Women-Duchess-Black-Side_1024x1024.jpg?v=1591637693',
                        'url':'https://thursdayboots.com/products/womens-duchess-black-chelsea-boot',
                        'price':'$160.00',
                    },
                    {
                        'imgUrl':'https://arthurknight.com/pub/media/catalog/product/cache/8723b12f6326e9bdc6bc191d0c49a6b8/t/a/tamaris-black-leather-womens-chelsea-bootie-ankle-high-1.jpg',
                        'url':'https://arthurknight.com/tamaris-black-leather-womens-chelsea-boots.html',
                        'price':'$96.00',
                    },
                    {
                        'imgUrl':'https://m.media-amazon.com/images/I/61oa-Lq2DTL._AC_UX695_.jpg',
                        'url':'https://www.amazon.com/Odema-Womens-Leather-Chelsea-Elastic/dp/B07CLWHGM6',
                        'price':'$39.99',
                    },
                    {
                        'imgUrl':'https://www.dianaferrari.com.au/media/catalog/product/cache/c36a32b6d66d14559eea2ad6988106fc/S/U/SU12454BLALE_3.jpg',
                        'url':'https://www.dianaferrari.com.au/cartright-black-leather-ankle-boots.html',
                        'price':'$219.99',
                    },
                    {
                        'imgUrl':'https://img.shopstyle-cdn.com/sim/c9/29/c9295e38e3a0392e9dba6de90c1b582c_best/duiza-flat-leather-ankle-boots.jpg',
                        'url':'https://www.shopstyle.com/g/women/isabel-marant/duiza-flat-leather-ankle-boots/891013997?imageId=c9295e38e3a0392e9dba6de90c1b582c',
                        'price':'$540.00',
                    }
                ]
            },
            {
                'label_name': 'Pants 1',
                'bbox_x': 227,
                'bbox_y': 188,
                'bbox_width': 254,
                'bbox_height': 186,
                'image_width': 640,
                'image_height': 427,
                'items':[
                    {
                        'imgUrl':'https://aasourcingltd.com/wp-content/uploads/2020/10/1-16-scaled.jpg',
                        'url':'https://aasourcingltd.com/product/womens-dark-grey-acid-wash-mom-jeans/',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'https://assets.ajio.com/medias/sys_master/root/20201029/t4dS/5f99b991f997dd8c837cc912/-473Wx593H-460566439-darkgrey-MODEL.jpg',
                        'url':'https://www.ajio.com/outryt-women-high-rise-skinny-fit-black-jeans/p/460566439_darkgrey',
                        'price':'$20.00',
                    },
                    {
                        'imgUrl':'https://cdn.shopify.com/s/files/1/2225/5907/products/018907223e2fde3c0247cadc20d720742d6d14d6d930790d4d93f7b35760f1b6.jpg?v=1666267626',
                        'url':'https://ca.mavi.com/products/maria-slit-hem-flare-in-mid-smoke-organic-blue',
                        'price':'$150.00',
                    },
                ]
            },
            {
                'label_name': 'Pants 2',
                'bbox_x': 0,
                'bbox_y': 178,
                'bbox_width': 247,
                'bbox_height': 174,
                'image_width': 640,
                'image_height': 427,
                'items':[
                    {
                        'imgUrl':'https://jeanstore.co.uk/cdn/shop/products/levi-s-501-original-regular-fit-mens-jeans-black-w28-l32-501016528r-5412456185401-levi-s-jeans-levis-501-original-regular-fit-mens-jeans-black-jeans-and-street-fashion-from-jeanstore_536x.jpg?v=1628336971',
                        'url':'https://jeanstore.co.uk/products/levis-501-original-regular-fit-mens-jeans-black',
                        'price':'$90.00',
                    },
                    {
                        'imgUrl':'https://lp2.hm.com/hmgoepprod?set=quality%5B79%5D%2Csource%5B%2F1b%2Ffd%2F1bfda8975f42e5dd99d6020490714bbf6737b1b1.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5B%5D%2Ctype%5BLOOKBOOK%5D%2Cres%5Bm%5D%2Chmver%5B1%5D&call=url[file:/product/main]',
                        'url':'https://www2.hm.com/en_us/productpage.1008549006.html',
                        'price':'$24.99',
                    },
                    {
                        'imgUrl':'https://target.scene7.com/is/image/Target/GUEST_967069e5-c1d4-45c2-bee3-5553840e4f15?wid=1000&hei=1000&qlt=80&fmt=webp',
                        'url':'https://www.target.com/p/levi-39-s-174-men-39-s-505-8482-straight-regular-fit-jeans-black-36x32/-/A-54332037',
                        'price':'$59.99',
                    },
                    {
                        'imgUrl':'https://images.wrangler.com/is/image/Wrangler/35002OB-HERO?$KDP-XXLARGE$',
                        'url':'https://www.wrangler.com/shop/wrangler-rugged-wear-relaxed-fit-jean-35002.html',
                        'price':'$39.99',
                    },
                    {
                        'imgUrl':'https://www.diffusiononline.co.uk/images/versace-jeans-regular-fit-mens-jean-black-p54092-410379_image.jpg',
                        'url':'https://www.diffusiononline.co.uk/men-c2/jeans-denim-c46/versace-jeans-regular-fit-mens-jean-black-p54092',
                        'price':'$90.00',
                    }
                ]
            },
            {
                'label_name': 'Jacket 1',
                'bbox_x': 21,
                'bbox_y': 106,
                'bbox_width': 227,
                'bbox_height': 162,
                'image_width': 640,
                'image_height': 427,
                'items':[
                    {
                        'imgUrl': 'https://lp2.hm.com/hmgoepprod?set=quality%5B79%5D%2Csource%5B%2Fd6%2F48%2Fd648135c33fe511484b9ccaf178847ac45ffdd59.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5B%5D%2Ctype%5BLOOKBOOK%5D%2Cres%5Bm%5D%2Chmver%5B1%5D&call=url[file:/product/main]',
                        'url':'https://www2.hm.com/en_asia5/productpage.0807005020.html',
                        'price':'$30.00',
                    },
                    {
                        'imgUrl': 'https://lp2.hm.com/hmgoepprod?set=quality%5B79%5D%2Csource%5B%2F3c%2Fa8%2F3ca83f556025c4999979ffe267d34cdd166649af.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5Bmen_jacketscoats_puffer%5D%2Ctype%5BLOOKBOOK%5D%2Cres%5Bm%5D%2Chmver%5B1%5D&call=url[file:/product/main]',
                        'url':'https://www2.hm.com/en_asia5/productpage.0819231003.html',
                        'price':'$49.99',
                    },
                    {
                        'imgUrl':'https://cdn.shopify.com/s/files/1/1008/6090/products/American-Tall-Men-PufferJacket-Black-front.jpg?v=1630423674',
                        'url':'https://americantall.com/products/mens-tall-polyfill-jacket-in-black',
                        'price':'$119.00',
                    },
                    {
                        'imgUrl':'https://image1.superdry.com/static/images/optimised/zoom/upload9223368955666108130.jpg',
                        'url':'https://www.superdry.com/mens/jackets/details/234288/microfibre-mountain-puffer-jacket-black',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'https://cdn13.nnnow.com/web-images/large/styles/726T2VHTXUR/1660739777563/1.jpg',
                        'url':'https://uspoloassn.nnnow.com/us-polo-assn-denim-co-men-black-all-over-brand-print-detachable-hood-nylon-puffer-jacket-726T2VHTXUR',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'https://i.ebayimg.com/images/g/d08AAOSw6gxiShGm/s-l1600.jpg',
                        'url':'https://www.ebay.com/itm/313942219743',
                        'price':'$99.99',
                    },
                    {
                        'imgUrl':'https://assets.ajio.com/medias/sys_master/root/20210305/UIF1/60412becaeb26969817820cc/-1117Wx1400H-460841838-black-MODEL.jpg',
                        'url':'https://www.ajio.com/u-s-polo-assn--quilted-zip-front-puffer-jacket/p/460841838001',
                        'price':'$60.00',
                    },
                    {
                        'imgUrl':'https://assets.myntassets.com/h_720,q_90,w_540/v1/assets/images/19020562/2022/12/5/dcc413a1-f06a-4eab-bce3-c96bd91a01df1670217576282-US-Polo-Assn-Men-Black-Solid-Brand-Logo-Puffer-Jacket-346167-1.jpg',
                        'url':'https://www.myntra.com/jackets/us-polo-assn/us-polo-assn-men-black-solid-brand-logo-puffer-jacket/19020562/buy',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'https://cdn.shopify.com/s/files/1/2516/3312/products/gb02007000_blk_09_1200x1800_880f86f5-d062-47b9-aa20-83d7650dfd76.jpg?v=1610754033',
                        'url':'https://us.globebrand.com/products/prime-down-jacket-black',
                        'price':'$379.95',
                    },
                    {
                        'imgUrl':'https://lp2.hm.com/hmgoepprod?set=quality%5B79%5D%2Csource%5B%2Fc2%2F78%2Fc278f3d0879703a78f2595ee16ab9dd5731f4883.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5B%5D%2Ctype%5BLOOKBOOK%5D%2Cres%5Bm%5D%2Chmver%5B1%5D&call=url[file:/product/main]',
                        'url':'https://www2.hm.com/en_us/productpage.0985359001.html',
                        'price':'$64.99',
                    }
                ]
            },
            {
                'label_name': 'Jacket 2',
                'bbox_x': 503,
                'bbox_y': 194,
                'bbox_width': 136,
                'bbox_height': 171,
                'image_width': 640,
                'image_height': 427,
                'items':[
                    {
                        'imgUrl':'https://m.media-amazon.com/images/I/81YKjX5JK9L._AC_UX679_.jpg',
                        'url':'https://www.amazon.com/Levis-Womens-Classic-Leather-Motorcycle/dp/B07C683NX8/ref=sr_1_7?c=ts&keywords=Women%27s+Leather+%26+Faux+Leather+Jackets+%26+Coats&qid=1683497382&s=apparel&sr=1-7&ts_id=2348892011',
                        'price':'$69.99',
                    },
                    {
                        'imgUrl':'https://m.media-amazon.com/images/I/71u2+WPluaL._AC_UY879_.jpg',
                        'url':'https://www.amazon.com/Bellivera-Womens-Leather-Casual-Jacket/dp/B0745B7CC4/ref=sr_1_10?c=ts&keywords=Women%27s+Leather+%26+Faux+Leather+Jackets+%26+Coats&qid=1683497382&s=apparel&sr=1-10&ts_id=2348892011',
                        'price':'$59.99',
                    },
                    {
                        'imgUrl':'https://cdn.media.amplience.net/i/rb/WAW19F4047LP41-001-A/Mack-Leather-Jacket-001?$zoom$&fmt=auto',
                        'url':'https://www.rag-bone.com/womens/coats-jackets/mack-leather-jacket-WAW19F4047LP41.html?gc=TH',
                        'price':'$1200.00',
                    },
                    {
                        'imgUrl':'https://i.etsystatic.com/28827645/r/il/4f5529/3246508005/il_1588xN.3246508005_sb25.jpg',
                        'url':'https://www.etsy.com/uk/listing/1001458283/womens-leather-jacket-womens-black',
                        'price':'$120.00',
                    },
                    {
                        'imgUrl':'https://m.media-amazon.com/images/I/71+r6HQ6R5L._SL1500_.jpg',
                        'url':'https://www.amazon.com/Womens-Italian-Leather-Jacket-Genuine/dp/B07NNSX1PL',
                        'price':'$250.00',
                    },
                    {
                        'imgUrl':'https://www.allsaints.com/dw/image/v2/BHHD_PRD/on/demandware.static/-/Sites-allsaints-us-master-catalog/default/dw606e4e9d/images/large/WL153W/5108/WL153W-5108-1.jpg?sw=3000&sh=3000&sm=fit&q=70',
                        'url':'https://www.allsaints.com/us/women/leather/leather-jackets/dalby-slim-fit-gold-leather-biker-jacke/USWL153W-5108.html',
                        'price':'$240.00',
                    },
                    {
                        'imgUrl':'https://assets.myntassets.com/h_720,q_90,w_540/v1/assets/images/2013724/2017/9/13/11505286112949-ONLY-Women-Black-Solid-Biker-Jacket-5751505286112748-1.jpg',
                        'url':'https://www.myntra.com/jackets/only/only-women-black-solid-biker-jacket/2013724/buy',
                        'price':'$40.00',
                    }
                ]
            },
            {
                'label_name': 'Scarf',
                'bbox_x': 444,
                'bbox_y': 127,
                'bbox_width': 188,
                'bbox_height': 138,
                'image_width': 640,
                'image_height': 427,
                'items':[
                    {
                        'imgUrl':'https://cld.accentuate.io/7487093112996/1670925901728/SS23_NPA000005_SAH_2.jpg?v=1670925901728&options=w_504,h_576',
                        'url':'https://www.npeal.com/products/womens-pashmina-cashmere-shawl-sahara-brown',
                        'price':'$360.00',
                    },
                    {
                        'imgUrl':'https://img.repeatcashmere.com/dw/image/v2/BJDJ_PRD/on/demandware.static/-/Sites-repeat-master-catalog/default/dwbc07d9a3/700006-1301-2.jpg?sw=900&sm=fit',
                        'url':'https://www.repeatcashmere.com/us/oversized-cashmere-scarf/700006-1301-0.html',
                        'price':'$245.00',
                    },
                    {
                        'imgUrl':'https://m.media-amazon.com/images/I/71BYNn4LTKL._AC_SX679_.jpg',
                        'url':'https://www.amazon.sa/-/en/Cashmere-Pashmina-Fashion-Scarves-Oversized/dp/B07WJL6R23',
                        'price':'$140.00',
                    },
                    {
                        'imgUrl':'https://catherinerobinsoncashmere.com/wp-content/uploads/2020/01/tan.jpg',
                        'url':'https://catherinerobinsoncashmere.com/product/cashmere-oversized-wrap-soft-tan/',
                        'price':'$360.00',
                    },
                    {
                        'imgUrl':'https://i.ebayimg.com/images/g/3bsAAOSws8Bi61iZ/s-l500.jpg',
                        'url':'https://www.ebay.com/itm/363931857554',
                        'price':'$14.00',
                    },
                    {
                        'imgUrl':'https://ae01.alicdn.com/kf/S9bf431d61add40cf8d489ed968bdd72dQ/Faux-Pashmina-Foulard.jpg_640x640.jpg',
                        'url':'https://th.aliexpress.com/i/1005004634651326.html?gatewayAdapt=glo2tha',
                        'price':'$12.00',
                    },
                    {
                        'imgUrl':'https://cdn.shopify.com/s/files/1/0212/9404/products/IMG_6673_1680x.jpg?v=1665725995',
                        'url':'https://www.heirloomcashmere.com.au/products/sofia-cashmere-scarf-camel',
                        'price':'$150.00',
                    },
                    {
                        'imgUrl':'https://cdn.shopify.com/s/files/1/0601/3809/4820/products/APA-SCAR-CSH-N2_2.jpg?v=1677162883',
                        'url':'https://riseandfall.co/products/unisex-mongolian-cashmere-scarf',
                        'price':'$80.00',
                    }
                ]
            },
            {
                'label_name': 'Shirt',
                'bbox_x': 106,
                'bbox_y': 127,
                'bbox_width': 62,
                'bbox_height': 140,
                'image_width': 640,
                'image_height': 427,
                'items':[
                    {
                        'imgUrl':'https://cdn.shopify.com/s/files/1/1231/6442/products/M-TSHIRT-15907-19957-LIGHTGREY_2.jpg?v=1631599261&width=1280',
                        'url':'https://cottonworld.net/products/m-tshirt-15907-19957-light-grey',
                        'price':'$6.00',
                    },
                    {
                        'imgUrl':'https://i5.walmartimages.com/asr/cc967d88-fc47-4877-91d1-49a929f89718.3feb7f05585289862b2c8f3f26ec47d3.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF',
                        'url':'https://www.walmart.com/ip/Gildan-Men-Grey-T-Shirts-Value-Pack-Shirts-Single-OR-6-12-T-shirts-Gray-T-shirt-Casual-Shirt-Basic/820475952',
                        'price':'$10.00',
                    },
                    {
                        'imgUrl':'https://image.goxip.com/-CDmtiA0ufFZptNMa9qRW7PMEUQ=/fit-in/1000x1000/filters:format(jpg):quality(80)/https:%2F%2Fimage.s5a.com%2Fis%2Fimage%2Fsaksoff5th%2F0400016557649%3Fwid=900&hei=1200&fmt=jpeg&qlt=90%252C0&op_sharpen=1&resMode=trilin&op_usm=0.8%252C1.0%252C6%252C0&iccEmbed=0',
                        'url':'https://www.goxip.co.th/th/designers/threads-4-thought/threads-4-thought-men-s-bicycle-print-heathered-t-shirt-grey-size-xl-82370209',
                        'price':'$12.00',
                    },
                    {
                        'imgUrl':'https://cache.mrporter.com/variants/images/1473020371423811/pr/w1000.jpg',
                        'url':'https://www.goxip.co.th/th/designers/james-perse/james-perse-melange-combed-cotton-jersey-t-shirt-men-gray-51674815',
                        'price':'$100.00',
                    },
                    {
                        'imgUrl':'https://cdn.shopify.com/s/files/1/0207/9406/products/t_shirt_grey_marl_model_hero_2000x2000px.jpg?v=1644327068&width=1200',
                        'url':'https://spoke-london.com/products/grey-marl-organic-t-shirts',
                        'price':'$50.00',
                    },
                    {
                        'imgUrl':'https://cdn.shopify.com/s/files/1/0262/4484/1547/products/2021_06_07SOCIALCITIZEN24171_1300x-greyheather_1300x.jpg?v=1667578584',
                        'url':'https://socialcitizen.shop/products/sweat-proof-shirt-grey',
                        'price':'$36.00',
                    }
                ]
            },
        ],
        'livingroom':[
            {
                'label_name': 'Table',
                'bbox_x': 274,
                'bbox_y': 241,
                'bbox_width': 220,
                'bbox_height': 83,
                'image_width': 640,
                'image_height': 423,
                'items':[
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    }
                ]
            },
            {
                'label_name': 'Plant',
                'bbox_x': 150,
                'bbox_y': 46,
                'bbox_width': 33,
                'bbox_height': 47,
                'image_width': 640,
                'image_height': 423,
                'items':[
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    }
                ]
            },
            {
                'label_name': 'Plant',
                'bbox_x': 535,
                'bbox_y': 169,
                'bbox_width': 102,
                'bbox_height': 100,
                'image_width': 640,
                'image_height': 423,
                'items':[
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    }
                ]
            },
            {
                'label_name': 'Pillow',
                'bbox_x': 153,
                'bbox_y': 184,
                'bbox_width': 72,
                'bbox_height': 50,
                'image_width': 640,
                'image_height': 423,
                'items':[
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    }
                ]
            },
            {
                'label_name': 'Pillow',
                'bbox_x': 249,
                'bbox_y': 180,
                'bbox_width': 55,
                'bbox_height': 48,
                'image_width': 640,
                'image_height': 423,
                'items':[
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    }
                ]
            },
            {
                'label_name': 'Pillow',
                'bbox_x': 348,
                'bbox_y': 186,
                'bbox_width': 50,
                'bbox_height': 41,
                'image_width': 640,
                'image_height': 423,
                'items':[
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    }
                ]
            },
            {
                'label_name': 'Plant',
                'bbox_x': 306,
                'bbox_y': 192,
                'bbox_width': 47,
                'bbox_height': 72,
                'image_width': 640,
                'image_height': 423,
                'items':[
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    }
                ]
            },
            {
                'label_name': 'Drawer',
                'bbox_x': 441,
                'bbox_y': 189,
                'bbox_width': 94,
                'bbox_height': 72,
                'image_width': 640,
                'image_height': 423,
                'items':[
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    }
                ]
            },
            {
                'label_name': 'Lamp',
                'bbox_x': 4,
                'bbox_y': 49,
                'bbox_width': 141,
                'bbox_height': 366,
                'image_width': 640,
                'image_height': 423,
                'items':[
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    }
                ]
            },
            {
                'label_name': 'Couch',
                'bbox_x': 55,
                'bbox_y': 172,
                'bbox_width': 390,
                'bbox_height': 169,
                'image_width': 640,
                'image_height': 423,
                'items':[
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    },
                    {
                        'imgUrl':'',
                        'url':'',
                        'price':'$40.00',
                    }
                ]
            }

        ]
};
