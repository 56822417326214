import { useState } from "react";
import { Drawer, Form, message } from "antd";
import { useAuth } from "../../../utils/useAuth";
import { useLocation } from "../../Layout/DiscoveryLayout";
import OfferForm from "../../Form/Collab/OfferForm";
import UserCard from "../../Card/User/UserCard";
import OfferServices from "../../../utils/OfferServices";

function OfferDrawer({isOfferDrawerVisible, setIsOfferDrawerVisible,values=false}){
    const [form] = Form.useForm();
    const {user,refresh} = useAuth();
    const {clickRate} = useLocation();
    const [miscValues,setMiscValues] = useState({});

    const onFinish = async () => {
        const formValues = form.getFieldsValue();
        var offerValues = {...formValues,...miscValues};
        offerValues['sender_brand_id'] = offerValues?.brand_id;

        if(values?.id){
            offerValues['receiver_id'] = values?.id;
        }

        offerValues['budget'] = {
            value: offerValues?.price,
            currency: miscValues?.currency || 'USD'
        }
        if(values?.application_id){
            offerValues['application_id'] = values?.application_id;
        }

        let offerResponse = await OfferServices.createEditOffer({values:offerValues,user:user});
        if(offerResponse.status !== 'success'){
            const newUser = await refresh();
            offerResponse = await OfferServices.createEditOffer({values:offerValues,user:newUser});
        }
        if(offerResponse.status === 'success'){
            message.success('Offer Sent!');
            onClose();
        }
    }

    const onClose = () => {
        setIsOfferDrawerVisible(false);
        form.resetFields();
        setMiscValues({});
    }
    
    return(
        <Drawer
            open={isOfferDrawerVisible} 
            bodyStyle={{ maxHeight:'95vh'}}
            footer={null} 
            onClose={onClose}
            placement="bottom"
            width={'100%'}
            height={window.innerWidth > 1000 ? '60vh' : '90vh'}
            centered={true}
            destroyOnClose={true} >
            <div className='create-post-modal-container' style={{overflowY:'auto'}}>
                <div style={{padding:'5px',marginBottom:'5px',textAlign:'center'}}>
                    <h2 style={{marginBottom:0}}>Send {values?.username} an Offer</h2>
                </div>
                <div style={{width:'60%',margin:'auto',marginBottom:'15px'}}>
                    <UserCard image={values?.avatar_img || values?.avatar?.image} username={values?.username} isOffer={true} clickRate={() => clickRate(values)} />
                </div>
                <OfferForm form={form} formValues={miscValues} setFormValues={setMiscValues} onFinish={onFinish} />
            </div>
        </Drawer>
    )
}
export default OfferDrawer;