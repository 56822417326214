import { LoadingOutlined } from '@ant-design/icons';

function LoadingPage() {

    return (
        <div className='full-page-center'>
            <LoadingOutlined style={{fontSize:'40px',color:'#ef3078'}} />
        </div>
    )
}

export default LoadingPage;
