import { LinkOutlined, PictureOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Button, Carousel } from "antd";
import { useEffect, useState } from "react";
import PricePreview from "../Price/PricePreview";
import LikeProductButton from "../Button/LikeProductButton";
import i18next from '../../utils/i18n';

function ProductCard({values, key, clickProduct=false, clickEditProduct=false, width='100%', theme=false}) {
    const [showEdit, setShowEdit] = useState(false);
    const [hostName, setHostName] = useState(false);

    const getHostname = () => {
        const url = new URL(values?.link);
        setHostName(url.hostname);
    }

    const setThemeColors = () => {
        if(theme){
             
            Array.from(document.getElementsByClassName('color_2')).forEach(elem => {
                elem.style.backgroundColor = theme['color_2'];
            });
            Array.from(document.getElementsByClassName('color_3')).forEach(elem => {
                elem.style.color = theme['color_3'];
                elem.style.borderColor = theme['color_3'];
            });
            Array.from(document.getElementsByClassName('color_4')).forEach(elem => {
                elem.style.color = theme['color_4'];
            });
        }        
    }

    useEffect(() => {
        setThemeColors();
    },[theme]);

    useEffect(() => {
        if(values?.link){
            getHostname();
        }
    },[])

    return(
        <div key={`product_${key}`} className='product-card-container' style={{width:width}} >
            <div className="product-card color_2">
                <div style={{position:'relative'}} onMouseEnter={() => {if(clickEditProduct){setShowEdit(true)}}} onMouseLeave={() => setShowEdit(false)}>
                    <div onClick={() => {let temp={'products':values}; clickProduct(temp);}}>
                        {
                            values?.images.length > 0
                            ? 
                                <Carousel >
                                    {
                                        values.images.map((value,index) => 
                                            <div>
                                                <div className='product-image-square' key={index} style={{backgroundImage:`url(${value.image})`}} ></div>
                                            </div>
                                        )
                                    }
                                </Carousel>
                            : 
                            <div className="post-image-full square color_3">
                                <div className="center-full">
                                    <div style={{textAlign:'center'}}>
                                        <PictureOutlined style={{fontSize:'30px',color:'rgba(0,0,0,0.7)'}} />
                                        <div style={{marginTop:'5px'}}>
                                            No Photo
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !clickEditProduct &&
                                <div className="post-like-container">
                                    <LikeProductButton product_id={values?.id} fontSize={'13px'} />
                                </div>
                        }
                    </div>
                    {
                        showEdit &&
                            <div className="product-card-edit">
                                <Button size='small' type="primary" onClick={() => clickEditProduct(values)} style={{borderRadius:'10px',fontSize:'12px'}}>Edit Product</Button>
                            </div>
                    }
                </div>
                <div style={{padding:'5px',paddingBottom:'0px',paddingTop:'10px'}} onClick={() => {let temp={'products':values}; clickProduct(temp);}}>
                    <div>
                        <h3 className="color_4" style={{lineHeight:1.1}}>{values?.name.length > 30 ? `${values?.name.slice(0,30)}...` : values?.name}</h3>
                    </div>
                    {
                        values?.tag.length > 0
                        ? 
                            <div>
                                {
                                    values.tag.map((value,index) => 
                                        <div key={index} className='post-horizontal-tags align-div' >
                                            {value.tag}
                                        </div>
                                    )
                                }
                            </div>
                        : null
                    }
                    <div>
                        <h4 className="color_4"><PricePreview price={values?.price || 0} currency={values?.currency || '$'} /></h4>
                    </div>
                </div>
                {
                    clickEditProduct
                    ?
                        <div>
                            {
                                hostName 
                                ?
                                    <div onClick={() => clickEditProduct(values)} style={{color:'rgba(0,0,0,0.6)',fontSize:'12px',marginBottom:'5px',border:'1px solid rgba(0,0,0,0.2)',borderRadius:'10px',padding:'5px',width:'fit-content'}}>
                                        <LinkOutlined style={{fontSize:'12px',color:'rgba(0,0,0,0.5)'}} /> {hostName}
                                    </div>
                                : <div style={{marginBottom:'10px',paddingLeft:'5px'}}><a onClick={() => clickEditProduct(values)} style={{fontSize:'12px'}}>Add Link</a></div>
                            }
                            <div>
                                <Button type="default" className="rounded-button color_3" style={{width:'100%'}} onClick={() => clickEditProduct(values)} >Edit</Button>
                            </div>
                        </div>
                    :
                        <div style={{paddingBottom:'10px',width:'100%'}}>
                            <Button type='primary' ghost={true} className='rounded-button color_3' style={{width:'100%'}} onClick={() => {let temp={'products':values}; clickProduct(temp);}}><ShoppingCartOutlined />{i18next.t('dashboard.product.product_shop')}</Button>
                        </div>
                }
            </div>
        </div>
    )

}

export default ProductCard;