import { ConsoleSqlOutlined, DeleteOutlined, EditOutlined, LoadingOutlined, MoreOutlined, PlusCircleOutlined, TagOutlined } from "@ant-design/icons";
import { Button, Popconfirm, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LikeButton from "../Button/LikeButton";
import PostServices from "../../utils/PostServices";
import { useAuth } from "../../utils/useAuth";
import ProductImagePreview from "../Image/ProductImagePreview";
import noPhotoImg from '../../images/no_image.webp';
import FollowButton from "../Button/FollowButton";
import { useEventTracker } from "../Context/EventTrackingContext";

function PostCardGallery({values, keyValue='1', editPost=false, hideProducts=false, addProducts=false, onlyImage=false,contest=false, onClick=false, clickProduct=false, showTag=false,setShowTag=false, removePost=false, autoOpenTags=false, numColumns=4, theme=false}) {
    const navigate = useNavigate();
    const {user,refresh} = useAuth();
    const {sendEvent} = useEventTracker();
    const [showTags, setShowTags] = useState(false);
    const [isDeleting,setIsDeleting] = useState(false);

    const clickPost = () => {
        if(onClick){
            onClick(values);
        }
        else{
            if(!editPost){
                if(contest){
                    navigate(`/contest/${contest}/post/${values.id}`);
                }
                else{
                    navigate(`/${values.username}/post/${values.id}`);
                }
            }
            else{
                editPost(values);
            }
        }
    }
    const productClick = (clickValue) => {
        if(clickProduct){
            clickProduct(clickValue);
        }
        else if(editPost){
            addProducts(values)
        }
    }
    const clickAddTags = () => {
        addProducts(values);
    }

    const hoverTag = (value) => {
        setShowTags(value);
        if(setShowTag){
            setShowTag(value);
        }
    }
    const onDelete = async () => {
        setIsDeleting(true);
        const deactivateValues = {
            is_active: false
        }
        let postPostResponse = await PostServices.createEditPost({values:deactivateValues,user:user,post_id:values.id});
        if(postPostResponse.status !== 'success'){
            const newUser = await refresh();
            postPostResponse = await PostServices.createEditPost({values:deactivateValues,user:newUser,post_id:values.id});
        }
        if(postPostResponse.status === 'success'){
            removePost(values.id);
            message.success('Post Delete Successfully!');
        }
        else{
            message.error('There was an Error! Please try again!');
        }
        setIsDeleting(false);
    }

    const hoverImageIn = () => {
        document.getElementById(`post-image-${values.id}`).style.filter = 'brightness(0.95)';
    }
    const hoverImageOut = () => {
        document.getElementById(`post-image-${values.id}`).style.filter = 'brightness(1)';
    }

    
    const setThemeColors = () => {
        if(theme){            
            Array.from(document.getElementsByClassName('color_4')).forEach(elem => {
                elem.style.color = theme['color_4'];
            });
        }        
    }
    useEffect(() => {
        setThemeColors();
    },[theme,numColumns]);

    useEffect(() => {
        if(autoOpenTags && window.innerWidth < 1000){
            setTimeout(() => {
                setShowTags('openAll');
            },1000);
        }
        if(!editPost && values){
            sendEvent({category:'post', action:'post_impression', label: `{post_id: ${values?.id}, user_id:${values?.user_id}}`});
        }
    },[values?.tags]);

    return(
        <div id='post-div' key={keyValue ? keyValue : '1'} className='post-card' style={{width: `100%`}} >
            {
                numColumns === 1 &&
                    <div style={{padding:'2px'}}>
                        <div className="align-div" style={{verticalAlign:'middle',padding:'3px'}} onClick={() => {window.open(`/${values.username}`,'_blank')}}>
                            <div className="align-div" style={{verticalAlign:'middle'}}>
                                <img style={{height:'40px',width:'40px',verticalAlign:'middle'}} src={values?.avatar?.image || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReh8NuEJj15o7u5x7dW99KPdSO_rH0LKihMw&usqp=CAU'} className="avatar" />
                            </div>
                            <div className="align-div" style={{verticalAlign:'middle',paddingLeft:'5px'}}>
                                <h2 className="color_4" style={{fontWeight:600,lineHeight:1}}>{values?.username}</h2>
                            </div>
                        </div>
                        <div className="align-div float-right"  style={{verticalAlign:'middle'}}>
                            <FollowButton user_id={values?.user_id} username={values?.username} size={'medium'} />
                        </div>
                    </div>
            }
            <div className='post-image-container' onMouseEnter={hoverImageIn} onMouseLeave={hoverImageOut} >
                <div onClick={clickPost}>
                    <img id={`post-image-${values.id}`} src={values?.image[0]?.image || noPhotoImg} className="post-image-full"/>
                </div>
                {
                    values?.producttags.length > 0 &&
                        values?.producttags.map((value,index) => 
                            <div key={`tag_${index}`} style={{position:'absolute',top:`${value?.coord_y*100}%`,left: `${value?.coord_x*100}%`}} onClick={() => productClick(value)} onPointerEnter={() => hoverTag(value?.products?.id)} onPointerLeave={() => hoverTag(false)}>
                                {
                                    showTags === value?.products?.id || showTag === value?.products?.id || showTags === 'openAll'
                                    ? <div className="product-tag product-tag-full">
                                            <TagOutlined size={12} /> {value?.products?.name.length > 40 ? `${value?.products?.name.slice(0,40)}...` : value?.products?.name}
                                        </div>
                                    : <Button icon={<TagOutlined size={12} />} shape={'round'} className="product-tag" ></Button>
                                }
                            </div>
                        )
                }
                <div className="post-top-left-button-container no-desktop">
                    {
                        values?.producttags.length > 0 
                        ?
                            showTags === 'openAll'
                            ? <div className='post-show-tags-button' onClick={() => setShowTags(false)}><TagOutlined /> Hide</div>
                            : <div className='post-show-tags-button' onClick={() => setShowTags('openAll')}><TagOutlined /></div>
                        : null
                    }
                </div>
                {
                    !editPost &&
                        <div className="post-like-container">
                            <LikeButton post_id={values.id} />
                        </div>
                }
                {
                    editPost && addProducts &&
                        <div className="post-product-count-container">
                            <TagOutlined /> {values?.producttags.length}
                        </div>

                }
                {
                    editPost && addProducts &&
                        <div id={`display-hover-${values.id}`} className="post-hover-description-container center-full" style={{transition:'ease-in 0.5s',zIndex:20}}>
                            <div>
                                {
                                    values?.description &&
                                        <div className="post-hover-description no-mobile">
                                            {values.description}
                                        </div>
                                }
                                <div>
                                    <div className="align-div" style={{padding:'5px'}}>
                                        <Button icon={<TagOutlined style={{color:'white'}} />} onClick={clickAddTags} className="post-hover-setting-button" />
                                    </div>
                                    <div className="align-div" style={{padding:'5px'}}>
                                        <Button icon={<EditOutlined style={{color:'white'}} />} onClick={() => editPost(values)} className="post-hover-setting-button" />
                                    </div>
                                    <div className="align-div" style={{padding:'5px'}}>
                                        <Popconfirm
                                            title={`Are you sure you want to delete this post?`}
                                            onConfirm={onDelete}
                                            okText="Yes"
                                            cancelText="No"
                                            >
                                            {
                                                isDeleting
                                                ? <Button icon={<LoadingOutlined style={{color:'white'}} />} className="post-hover-setting-button" />
                                                : <Button icon={<DeleteOutlined style={{color:'white'}} />} className="post-hover-setting-button" />
                                            }
                                        </Popconfirm>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
            {
                !editPost &&
                    !onlyImage &&
                        numColumns !== 1 &&
                            <div>
                                <div className="align-div" style={{verticalAlign:'middle',padding:'3px'}} onClick={() => {window.open(`/${values.username}`,'_blank')}}>
                                    <div className="align-div" style={{verticalAlign:'middle'}}>
                                        <img style={{height:'25px',width:'25px',verticalAlign:'middle'}} src={values?.avatar?.image || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReh8NuEJj15o7u5x7dW99KPdSO_rH0LKihMw&usqp=CAU'} className="avatar" />
                                    </div>
                                    <div className="align-div color_4" style={{verticalAlign:'middle',paddingLeft:'5px',fontSize:'12px',color:'rgba(0,0,0,0.5)'}}>
                                        {values?.username}
                                    </div>
                                </div>
                                <div className="align-div float-right">
                                    <Button icon={<MoreOutlined rotate={90} size={12} className="color_4" style={{strokeWidth:'30'}} />} style={{border:'0px',boxShadow:'0',backgroundColor:'transparent'}} ></Button>
                                </div>
                            </div>
            }
            {
                (!hideProducts || editPost) && values?.producttags && values?.producttags.length > 0 &&
                    <div className="horizontal-list" style={{paddingTop:'5px'}}>
                        {
                            values?.producttags.reduce(function (p, c) {
                                if (!p.some(function (el) { return el?.product_id?.id === c?.product_id?.id; })) p.push(c);
                                return p;
                            }, []).map((value,index) => 
                                <div key={index} className='align-div post-product-preview-container' onClick={() => productClick(value)} onPointerEnter={() => hoverTag(value?.products?.id)} onPointerLeave={() => hoverTag(false)}>
                                    <ProductImagePreview values={value?.product_id} isSelected={showTags === value?.product_id?.id} />
                                    <div className="product-add-button-container">
                                        <PlusCircleOutlined style={{fontSize:'16px',backgroundColor:'white'}} />
                                    </div>
                                </div>
                            )
                        }
                    </div>
            }
            {
                numColumns === 1 &&
                    <div style={{padding:'2px',paddingTop:'5px',paddingBottom:'10px'}}>
                        <h3 className="color_4" style={{lineHeight:1}}>
                            <strong style={{fontSize:'18px'}}>{values?.username}</strong> {values?.description}
                        </h3>
                    </div>
            }

        </div>
    )

}

export default PostCardGallery;