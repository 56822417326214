import { Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import i18next from "i18next";

function OnboardingFormLandingSimple({clickStart}){

    return(
        <div style={{width:'100%',paddingBottom:'3vh'}}>
            <div style={{textAlign:'left',paddingBottom:'10vh'}}>
                <div style={{marginBottom:'2vh'}}>
                    <h1 style={{fontWeight:500,marginBottom:0,color:'#ef3078'}}>
                        <strong>
                            {i18next.t('onboarding.panel0.hook')}<br/>
                            in 2024?
                        </strong>
                    </h1>
                </div>
                <div style={{marginBottom:'3vh'}}>
                    <h2 style={{fontWeight:500,marginBottom:0,color:'rgba(0,0,0,0.8)'}}>
                        {i18next.t('onboarding.panel0.description')}
                    </h2>
                </div>
                <div>
                    <Button type={'primary'} size='large' className='rounded-button' icon={<RightOutlined />} style={{backgroundColor:'#ef3078',borderColor:'#ef3078',fontWeight:'600'}} onClick={clickStart}></Button>
                </div>
            </div>
        </div>
    )
}

export default OnboardingFormLandingSimple;




