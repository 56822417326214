import axios from "axios";
import Resizer from "react-image-file-resizer";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}`;
const SHOP_URL = `${process.env.REACT_APP_SERVER_URL}/shop/${process.env.REACT_APP_API_KEY}`;

const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 800, 800, 'JPEG', 100, 0,
        uri => {
        resolve(uri);
        }, 'file' );
});

class PostServices {

    async uploadPostImage({image, name, user}) {
        let formData = new FormData();

        const imageResized = await resizeFile(image);        
        formData.append('image', imageResized, `${name}`);

        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + "/image",
            data: formData,
            headers: {
                "Authorization" : `Bearer ${user.access}`,
            },
        };
    
        // Send POST Query with Login Info
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }
    createEditPost({values, user, post_id=false}){

        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + "/post",
            data: values,
            headers: {"Authorization" : `Bearer ${user.access}`},
        };

        if(post_id){
            configuration['method'] = 'patch';
            configuration['url'] = API_URL + `/post/${post_id}`;
        }
        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }
    getPost({post_id}) {
        const configuration = {
            method: "get",
            url: API_URL + `/post/${post_id}`
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    };
    getPostList({page=0, ordering='-updated_at', limit=12, user_id=false, username=false, category=false, search=false, collectionId=false, date=false}) {

        const offset = limit * page || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/posts?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True`}
        else { configAPIUrl = API_URL + `/posts?limit=${limit}&ordering=${ordering}&is_active=True` };

        if(user_id){
            configAPIUrl = configAPIUrl + `&user_id=${user_id}`;
        }
        if(username){
            configAPIUrl = configAPIUrl + `&user_id__username=${username}`;
        }
        if(category){
            configAPIUrl = configAPIUrl + `&category__name=${category}`;
        }
        if(search){
            configAPIUrl = configAPIUrl + `&search=${search}`;
        }
        if(collectionId){
            configAPIUrl = configAPIUrl + `&collection__id=${collectionId}`;
        }
        if(date){
            var startTime = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0,0,0));
            var endTime = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()+1, 0,0,0));
            configAPIUrl = configAPIUrl + `&created_at__gte=${startTime.toISOString()}`;
            configAPIUrl = configAPIUrl + `&created_at__lte=${endTime.toISOString()}`;
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }

    getFollowingPostList({page=0, ordering='-updated_at', limit=12, user_id=false}) {

        const offset = limit * page || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/posts/following?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True`}
        else { configAPIUrl = API_URL + `/posts/following?limit=${limit}&ordering=${ordering}&is_active=True` };

        if(user_id){
            configAPIUrl = configAPIUrl + `&follower_id=${user_id}`;
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }

    async archivePost({post_id, user_id, is_active=false}){
        const configAPIUrl = API_URL + `/post/${post_id}`;

        if(user_id) {
            var configuration = {
                credentials: 'include',
                method: "patch",
                url: configAPIUrl,
                headers: {"Authorization" : `Bearer ${user_id.access}`},
                data:{
                    is_active: is_active,
                }
            };
    
            // Send POST Query with Login Info
            return axios(configuration)
            .then(response => {
                return response.data
            })
            .catch((error) => {
                error = new Error();
            });
        };
    }

    async savePost({post_id, user, is_active=false}){
        const configAPIUrl = API_URL + `/post/like`;

        if(user) {
            var configuration = {
                credentials: 'include',
                method: "post",
                url: configAPIUrl,
                headers: {"Authorization" : `Bearer ${user.access}`},
                data:{
                    post_id: post_id,
                    user_id: parseInt(user.user_id),
                    is_active: is_active,
                }
            };
    
            // Send POST Query with Login Info
            return axios(configuration)
            .then(response => {
                return response.data;
            })
            .catch((error) => {
                return error.response;
            });
        };
    }
    async checkPostSaved({post_id, user_id}){
        const configAPIUrl = API_URL + `/post/like/${post_id}`;
        var configuration = {
            credentials: 'include',
            method: "post",
            url: configAPIUrl,
            data: {
                user_id: user_id
            }
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }

    async getPostSavedList({user_id=false, page=0, ordering='-updated_at'}){
        const limit = 12;
        const offset = limit * page || 0;
        if(user_id){

            var configAPIUrl = API_URL
            if (offset > 0){configAPIUrl = API_URL + `/post/likes?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True&user_id=${user_id}`}
            else { configAPIUrl = API_URL + `/post/likes?limit=${limit}&ordering=${ordering}&is_active=True&user_id=${user_id}` };

            var configuration = {
                credentials: 'include',
                method: "get",
                url: configAPIUrl,
            };

            // Send POST Query with Login Info
            return axios(configuration)
            .then(response => {
                return response.data;
            })
            .catch((error) => {
                error = new Error();
            });
        }
        else{
            return false
        }
    }

    getProductPostList({product_id, page=0, ordering='-updated_at'}){

        const limit = 12;
        const offset = limit * page || 0;

        var configUrl = SHOP_URL
        if (offset > 0){configUrl = SHOP_URL + `/product/posts?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True&product_id__is_active=True&post_id__is_active=True`}
        else { configUrl = SHOP_URL + `/product/posts?limit=${limit}&ordering=${ordering}&is_active=True&product_id__is_active=True&post_id__is_active=True` };

        if(product_id){
            configUrl = configUrl + `&product_id=${product_id}`;
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configUrl,
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            return error.response;
        });
    }

    getContestPostList({page=0, ordering='-updated_at', username=false, user_id=false}) {
        const limit = 12;
        const offset = limit * page || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/contest-post/list?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True`}
        else { configAPIUrl = API_URL + `/contest-post/list?limit=${limit}&ordering=${ordering}&is_active=True` };

        if(username){
            configAPIUrl = configAPIUrl + `&post__user_id__username=${username}`;
        }
        if(user_id){
            configAPIUrl = configAPIUrl + `&post__user_id=${user_id}`;
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }

    getPostImages({user, page=0, ordering='-updated_at'}){

        const limit = 18;
        const offset = limit * page || 0;

        var configUrl = API_URL
        if (offset > 0){configUrl = API_URL + `/images?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True&user_id=${user.user_id}`}
        else { configUrl = API_URL + `/images?limit=${limit}&ordering=${ordering}&is_active=True&user_id=${user.user_id}` };

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configUrl,
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            return error.response;
        });
    }
}

export default new PostServices();