import { Form } from "antd";
import { useEffect, useState } from "react";
import ProductOptionsTable from "../../Table/ProductOptionsTable";

function ProductOptionsForm({formValues,setFormValues, closeDrawer=false}){
    const [dataValues, setDataValues] = useState(formValues?.product_options || [{name:'',options:[]}]);

    useEffect(() => {
        if(dataValues){
            let newValue = dataValues.filter(value => (value.name !== '' && value.options.length > 0));
            if(newValue.length > 0){
                setFormValues({...formValues,product_options:dataValues});
            }
        }
    },[dataValues]);

    return(
        <Form
            name="product-options-form"
            layout="vertical">
            <div style={{marginBottom:'10px'}}>
                <div>
                    <ProductOptionsTable dataValues={dataValues} setDataValues={setDataValues} closeDrawer={closeDrawer} />
                </div>
            </div>
        </Form>
    )
}

export default ProductOptionsForm;