import React, { createContext, useContext, useMemo, useState } from "react";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { useEventTracker } from "./EventTrackingContext";

const EditProfileDrawer = React.lazy(() => import("../Drawers/EditProfileDrawer"));
const DownloadModal = React.lazy(() => import("../Modal/DownloadModal"));
const ProductModal = React.lazy(() => import("../Modal/ProductModal"));
const PostModal = React.lazy(() => import("../Modal/PostModal"));
const CreatePostModal = React.lazy(() => import("../Modal/CreatePostModal"));
const PostTaggingModal = React.lazy(() => import("../Modal/PostTaggingModal"));
const MediaLibraryModal = React.lazy(() => import("../Modal/MediaLibraryModal"));
const RegisterModal = React.lazy(() => import("../Modal/RegisterModal"));

const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
    const {sendEvent} = useEventTracker();
    const [lastHash,setLastHash] = useState(false);
    const [homeList, setHomeList] = useLocalStorage("home", false);
    const [trendingList, setTrendingList] = useLocalStorage("trending", false);
    const [postList, setPostList] = useLocalStorage("post", false);
    const [productList,setProductList] = useLocalStorage("product", false);
    const [collections,setCollections] = useLocalStorage("collection", false);
    const [myCollections,setMyCollections] = useLocalStorage("mycollection", false);
    const [creators,setCreators] = useLocalStorage("creator", false);
    const [searchList, setSearchList] = useLocalStorage("search", false);
    const [savedList,setSavedList] = useLocalStorage("saved", false);
    const [isEditProfileDrawerVisible,setIsEditProfileDrawerVisible] = useLocalStorage("editprofiledrawer", false);
    const [isDownloadModalVisible,setIsDownloadModalVisible] = useLocalStorage("downloadmodal", false);
    const [isRegisterModalVisible,setIsRegisterModalVisible] = useLocalStorage("registermodal", false);
    const [postValue,setPostValue] = useLocalStorage("postValue", false);
    const [productValue,setProductValue] = useLocalStorage("productValue", false);
    const [isPostModalVisible,setIsPostModalVisible] = useLocalStorage("postModal", false);
    const [isProductModalVisible,setIsProductModalVisible] = useLocalStorage("productModal", false);
    const [postValues,setPostValues] = useLocalStorage("postValues", false);
    const [productValues,setProductValues] = useLocalStorage("productValues", false);
    const [postTaggingValues,setPostTaggingValues] = useLocalStorage("postTags", false);
    const [isCreatePostModalVisible,setIsCreatePostModalVisible] = useLocalStorage("postCreateModal", false);
    const [isCreateProductModalVisible,setIsCreateProductModalVisible] = useLocalStorage("productCreateModal", false);
    const [isPostTaggingModalVisible,setIsPostTaggingModalVisible] = useLocalStorage("productTagModal", false);
    const [isMediaLibraryModalVisible,setIsMediaLibraryModalVisible] = useLocalStorage("mediaLibraryModal", false);

    const clickProduct = (value) => {
        window.location.hash = '#product';
        setProductValue(value);
        setIsProductModalVisible(true);
        sendEvent({category:'product', action:'product_click', label: `{product_id:${value?.id}, user_id:${value?.user_id}}`});
    }
    const clickPost = async (value) => {
        window.location.hash = '#post';
        setPostValue(value);
        setIsPostModalVisible(true);
        sendEvent({category:'post', action:'post_click', label: `{post_id:${value?.id}, user_id:${value?.user_id}}`});
    }
    const addProductTags = (value) => {
        window.location.hash = '#producttag';
        setPostTaggingValues(value);
        setIsPostTaggingModalVisible(true);
    }
    const clickEditPost = (value) => {
        window.location.hash = '#createpost';
        setPostValues(value);
        setIsCreatePostModalVisible(true);
    }
    const clickEditProduct = (value) => {
        window.location.hash = '#createproduct';
        setProductValues(value);
        setIsCreateProductModalVisible(true);
    }

    const clearValues = () => {
        setHomeList(false);
        setSearchList(false);
        setTrendingList(false);
        setPostList(false);
        setProductList(false);
        setCollections(false);
        setMyCollections(false);
        setCreators(false);
        setSavedList(false);
    }

    window.onhashchange = function() {
        setLastHash(window.location.hash);
        
        if(lastHash === '#product'){
            if(window.location.hash !== '#post'){
                setIsProductModalVisible(false);
            }
        }
        else if(lastHash === '#post'){
            if(window.location.hash !== '#product'){
                setIsPostModalVisible(false);
            }
        }
        else if(lastHash === '#createpost'){
            setIsCreatePostModalVisible(false);
        }
        else if(lastHash === '#createproduct'){
            setIsCreateProductModalVisible(false);
        }
        else if(lastHash === '#producttag'){
            if(window.location.hash !== '#createproduct'){
                setIsPostTaggingModalVisible(false);
            }
        }
    };

    const value = useMemo(
        () => ({
            homeList,setHomeList,
            trendingList, setTrendingList,
            searchList, setSearchList,
            postList, setPostList,
            productList,setProductList,
            collections,setCollections,
            myCollections,setMyCollections,
            creators,setCreators,
            savedList,setSavedList,
            isEditProfileDrawerVisible,setIsEditProfileDrawerVisible,
            setIsMediaLibraryModalVisible,
            setIsDownloadModalVisible,
            clickProduct, clickPost,
            addProductTags, clickEditProduct, clickEditPost,
            setIsRegisterModalVisible,
            clearValues
        }),[homeList,setHomeList,trendingList,setTrendingList,searchList,setSearchList,postList,setPostList,productList,setProductList,collections,setCollections,myCollections,setMyCollections,creators,setCreators,savedList,setSavedList,isEditProfileDrawerVisible,setIsEditProfileDrawerVisible,setIsDownloadModalVisible,setIsMediaLibraryModalVisible,setIsRegisterModalVisible,clickProduct, clickPost,addProductTags,clickEditProduct,clickEditPost,clearValues]
    );
    return <DashboardContext.Provider value={value}>
        {children}
        <EditProfileDrawer isEditProfileDrawerVisible={isEditProfileDrawerVisible} setIsEditProfileDrawerVisible={setIsEditProfileDrawerVisible} />
        <PostModal values={postValue} isPostModalVisible={isPostModalVisible} setIsPostModalVisible={setIsPostModalVisible} />
        <ProductModal values={productValue} isProductModalVisible={isProductModalVisible} setIsProductModalVisible={setIsProductModalVisible} />
        <PostTaggingModal values={postTaggingValues} createProduct={clickEditProduct} isPostTaggingModalVisible={isPostTaggingModalVisible} setIsPostTaggingModalVisible={setIsPostTaggingModalVisible} />
        <CreatePostModal values={postValues} isCreatePostModalVisible={isCreatePostModalVisible} setIsCreatePostModalVisible={setIsCreatePostModalVisible} addProducts={addProductTags} />
        <MediaLibraryModal isMediaLibraryModalVisible={isMediaLibraryModalVisible} setIsMediaLibraryModalVisible={setIsMediaLibraryModalVisible} />
        <RegisterModal isRegisterModalVisible={isRegisterModalVisible} setIsRegisterModalVisible={setIsRegisterModalVisible} />
        <DownloadModal isDownloadModalVisible={isDownloadModalVisible} setIsDownloadModalVisible={setIsDownloadModalVisible} />
    </DashboardContext.Provider>;
};

export const useDashboard = () => {
    return useContext(DashboardContext);
};