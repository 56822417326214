import { BellOutlined, MailOutlined, MenuOutlined, PlusOutlined, SettingOutlined, ShopOutlined, ShoppingOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Popover, Space } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import noPhotoImage from '../../images/no_image.webp';
import { useAuth } from "../../utils/useAuth";

function NavBarMorePopover(){
    const {user} = useAuth();
    const [openSettings,setOpenSettings] = useState(false);
    const [navSelect,setNavSelect] = useState(window.location.pathname.split('/')[1]);

    const moreMenu = (
        <div className='navbar-dropdown-container' style={{textAlign:'left'}}>
            {
                user &&
                    <div className='navbar-dropdown-section'>
                        <div style={{display:'flex',alignItems:'center',marginBottom:'5px'}}>
                            <div style={{flex:1}}>
                                <h2 style={{marginBottom:0,lineHeight:1,color:'rgba(0,0,0,0.8)'}}>{user?.username}</h2>
                            </div>
                            <div style={{paddingRight:'5px'}}>
                                <Avatar src={user?.avatar_img || noPhotoImage} size='default' style={{cursor:'pointer'}} />
                            </div>
                        </div>
                        <Space>
                            <Link to='/work?tab=Collabs'>
                                <div className='navbar-dropdown-link' style={{paddingRight:'10px'}}>
                                    <BellOutlined />
                                </div>
                            </Link>
                            <Link to='/work?tab=Collabs'>
                                <div className='navbar-dropdown-link' style={{paddingRight:'10px'}}>    
                                    <MailOutlined/>
                                </div>
                            </Link>
                            <Link to='/work?tab=Collabs'>
                                <div className='navbar-dropdown-link' style={{paddingRight:'10px'}}>    
                                    <SettingOutlined />
                                </div>
                            </Link>
                        </Space>
                    </div>
            }
            <div className='navbar-dropdown-section'>
                <div>
                    <h2 style={{marginBottom:'5px',color:'rgba(0,0,0,0.8)'}}>For Influencers</h2>
                </div>
                <Link to='/work?tab=Collabs' onClick={() => setNavSelect('product')}>
                    <div className='navbar-dropdown-link'>
                        <TeamOutlined/> Find Collaboration
                    </div>
                </Link>
                <Link to='/work?tab=Collabs' onClick={() => setNavSelect('product')}>
                    <div className='navbar-dropdown-link'>
                        <ShoppingOutlined/> Explore Brands
                    </div>
                </Link>
                <Link to='/profile?tab=Storefront' onClick={() => setNavSelect('profile')}>
                    <div className='navbar-dropdown-link' style={{color:'rgb(76,204,132)'}}>
                        <PlusOutlined/> Create Storefront
                    </div>
                </Link>
            </div>
            <div className='navbar-dropdown-section'>
                <div>
                    <h2 style={{marginBottom:'5px',color:'rgba(0,0,0,0.8)'}}>Marketplace</h2>
                </div>
                <Link to='/shop?tab=Fashion' onClick={() => setNavSelect('product')}>
                    <div className='navbar-dropdown-link'>
                        <ShoppingOutlined/> Shop Exclusive Deals
                    </div>
                </Link>
                <Link to='/profile?tab=Product'>
                    <div className='navbar-dropdown-link'>
                        <ShopOutlined /> Sell to Influencers
                    </div>
                </Link>
                <Link to='/profile?tab=Product' onClick={() => setNavSelect('profile')}>
                    <div className='navbar-dropdown-link' style={{color:'rgb(76,204,132)'}}>
                        <PlusOutlined/> Add New Product
                    </div>
                </Link>
            </div>
            <div className='navbar-dropdown-section'>
                <div>
                    <h2 style={{marginBottom:'5px',color:'rgba(0,0,0,0.8)'}}>For Brands</h2>
                </div>
                <Link to='/work?tab=Collabs'>
                    <div className='navbar-dropdown-link'>    
                        <TeamOutlined/> Collaborations
                    </div>
                </Link>
                <Link to='/work?tab=Influencers'>
                    <div className='navbar-dropdown-link'>
                        <UserOutlined/> Find Influencers
                    </div>
                </Link>
                <div className='navbar-dropdown-link' style={{color:'rgb(76,204,132)'}}>
                    <PlusOutlined/> Create New Collab
                </div>
            </div>
        </div>
    )
    
    return(
        <Popover trigger="click" placement='bottomRight' open={openSettings} onOpenChange={setOpenSettings} content={moreMenu} >
            <Button type='text' size='large' icon={<MenuOutlined />} ></Button>
        </Popover>
    )
}

export default NavBarMorePopover;