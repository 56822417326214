import { useEffect, useState } from "react";
import { useAuth } from "../../utils/useAuth";
import { Button, Popconfirm, message } from "antd";
import { HeartFilled, HeartOutlined, LoadingOutlined } from "@ant-design/icons";
import ProductServices from "../../utils/ProductServices";
import i18next from '../../utils/i18n';

function LikeProductButton({product_id, isText=false, fontSize='14px'}){
    const {user,refresh} = useAuth();
    const [isLiked,setIsLiked] = useState(false);
    const [loading,setLoading] = useState(false);

    const clickUnlike = async () => {
        setIsLiked(false);
        let unlikeResponse = await ProductServices.likeProduct({product_id:product_id,user:user,is_active:false});
        if(unlikeResponse.status !== 'success') {
            const newUser = await refresh();
            unlikeResponse = await ProductServices.likeProduct({product_id:product_id,user:newUser,is_active:false});
        }
        if(unlikeResponse.status === 'success'){
            setIsLiked(false);
            setLoading(false);
            message.success(i18next.t('dashboard.alerts.product_unlike_success'));
        }
        else{
            setLoading(false);
            message.info(i18next.t('dashboard.alerts.update_error'));
        }
    }
    const clickLike = async () => {
        if(user){
            setLoading(true);
            let likeResponse = await ProductServices.likeProduct({product_id:product_id,user:user,is_active:true});
            if(likeResponse.status !== 'success') {
                const newUser = await refresh();
                likeResponse = await ProductServices.likeProduct({product_id:product_id,user:newUser,is_active:true});
            }
            if(likeResponse.status === 'success'){
                setIsLiked(true);
                setLoading(false);
                message.success(i18next.t('dashboard.alerts.product_added'));
            }
            else{
                setLoading(false);
                message.info(i18next.t('dashboard.alerts.update_error'));
            }
        }
        else{
            message.info(`Login for Full Access!`);
        }
    }
    const checkLiked = async () => {
        const checkLikedResponse = await ProductServices.checkProductSaved({product_id:product_id,user_id:user?.user_id});
        if(checkLikedResponse.data.is_liked){
            setIsLiked(true);
            setLoading(false);
        }
        else{
            setIsLiked(false);
            setLoading(false);
        }
    }

    useEffect(() => {
        checkLiked();
    },[])

    return(
        !isText
        ?
            loading
            ? <Button icon={<LoadingOutlined size={12} />} disabled shape="circle" ></Button>
            :
                isLiked
                ? 
                    <Popconfirm
                        title={i18next.t('dashboard.product.product_unlike')}
                        onConfirm={clickUnlike}
                        okText={i18next.t('dashboard.general.yes')}
                        cancelText={i18next.t('dashboard.general.no')}
                        >
                        <Button icon={<HeartFilled size={12} style={{color:'red'}} />} shape="circle" className="like-button" ></Button>
                    </Popconfirm>
                : <Button icon={<HeartOutlined size={12} />} shape="circle" onClick={clickLike} className="like-button" ></Button>
        :
            loading
            ? <Button type="link" className="rounded-button" style={{width:'100%'}} icon={<LoadingOutlined size={12} />} disabled ></Button>
            :
                isLiked
                ? 
                    <Popconfirm
                        title={i18next.t('dashboard.product.product_unlike')}
                        onConfirm={clickUnlike}
                        okText={i18next.t('dashboard.general.yes')}
                        cancelText={i18next.t('dashboard.general.no')}
                        >
                        <Button type="text" className="rounded-button" style={{width:'100%',fontSize:fontSize}} >{i18next.t('dashboard.product.product_saved')}</Button>
                    </Popconfirm>
                : <Button type="link" className="rounded-button" style={{width:'100%',fontSize:fontSize}} onClick={clickLike}>{i18next.t('dashboard.product.product_save')}</Button>
    )
}

export default LikeProductButton;


