import { Skeleton } from "antd";
import { useEffect, useState } from "react";

function PostSkeletonCard({key=0, width='25%', borderRadius='20px', aspectRatio=3/2}) {
    
    return(
        <div key={key} className="align-div post-skeleton" style={{width:width,aspectRatio:aspectRatio}} id='div-width'>
            <Skeleton.Button className="card-shadow" style={{width:'100%',height:'100%',borderRadius:borderRadius,aspectRatio:aspectRatio}} block={true} active={true} />
        </div>
    )
}

export default PostSkeletonCard;