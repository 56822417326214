import { Switch } from "antd";
import { useState } from "react";

function PricingTierForm(){
    const [isActive,setIsActive] = useState(false);

    return (
        <div>
            <Switch checked={isActive} onChange={() => setIsActive(!isActive)} style={{backgroundColor: isActive ? '#4ade80' : 'rgba(0,0,0,0.3)'}}  />
            <div>
                
            </div>
        </div>
    )
}

export default PricingTierForm;