import { Form, Input } from "antd";
import PermissionSwitch from "../../Switch/PermissionSwitch";

function OnboardingForm5({formValues,setFormValues}){
    
    const toggleSwitch = (value,status,index) => {
        let newGoals = [...formValues.goals];
        newGoals[index] = {...value,status:status};
        setFormValues({...formValues,goals:newGoals});
    }
    return(
        <div style={{width:'100%'}}>
            <div style={{width:'fit-content',margin:'auto'}}>
                <div style={{width:'100%',paddingLeft:'10px',marginBottom:'20px'}}>
                    <h2 style={{fontWeight:600,marginBottom:0,color:'rgba(0,0,0,0.8)'}}>
                        <strong>
                            {
                                formValues?.name && formValues?.name !== ''
                                ? `Goals for ${formValues?.name}`
                                : `Your Goals`
                            }
                        </strong>
                    </h2>
                </div>
                <div style={{width:'100%',paddingBottom:'5px'}}>
                    <div style={{width:'100%',padding:'10px',paddingBottom:0}}>
                        {
                            formValues?.goals.map((value,index) =>         
                                <div key={`goals_${index}`} style={{width:'100%',marginBottom:value['value'] != 'Other' ? '7px' : 0}}>
                                    <PermissionSwitch permissionValue={value} toggleSwitch={(status) => toggleSwitch(value,status,index)} />
                                </div>
                            )
                        }
                    </div>
                    {
                        formValues?.goals[formValues?.goals.length - 1]['status'] &&
                            <div style={{width:'100%',paddingLeft:'10px',paddingTop:'5px'}}>
                                <Input className="social-media-field-input new-field-input" bordered={false} size='large' autoFocus={true} placeholder={'Add Other Goals'} onChange={(value) => setFormValues({...formValues,'password':value.target.value})} style={{borderColor:'rgba(0,0,0,0.2)'}} />
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default OnboardingForm5;