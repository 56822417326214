import { Select } from "antd"

const currencies = [
    {
        label:'English',
        value:'en',
        iso2:'US'
    },
    {
        label:'Indonesian',
        value:'id',
        iso2:'ID'
    },
];

function LanguageSelect({onSelect,defaultValue, onlyImage=false}){
    const { Option } = Select;

    return(
        <Select 
            defaultValue={defaultValue} 
            onSelect={onSelect}
            bordered={false} 
            size="xl"
            className="language-select">
            {
                currencies.map((value,index) => 
                    <Option key={`language-select-${index}`} value={value.value} style={{height:'90%',maxHeight:'50px'}} ><img src={`https://flagsapi.com/${value.iso2}/flat/64.png`} style={{height:'90%',paddingBottom:'2px'}} />{!onlyImage && ` ${value.label}`}</Option>
                )
            }
        </Select>
    )
}

export default LanguageSelect;

