import { Form, Radio } from "antd";
import i18next from "i18next";

function OnboardingForm1({formValues,setFormValues,defaultUser='influencer'}){

    return(
        <Form.Item style={{width:'100%',paddingBottom:'2vh'}}>
            <div style={{paddingLeft:'10px',marginBottom:window.innerWidth > 1000 ? 0 : '10px'}}>
                <h2 style={{fontWeight:600,marginBottom:0,color:'rgba(0,0,0,0.8)'}}>
                    <strong>{i18next.t('onboarding.panel1.question')}</strong>
                </h2>
            </div>
            <Radio.Group style={{width:'100%'}} defaultValue={defaultUser} value={formValues?.type} onChange={(value) => setFormValues({...formValues,'type':value.target.value})}>
                <div className="align-div home-onboarding-radio-container">
                    <Radio value="influencer" className="home-onboarding-radio card" >{i18next.t('onboarding.panel1.influencer')} </Radio>
                </div>
                <div className="align-div home-onboarding-radio-container">
                    <Radio value="brand" className="home-onboarding-radio card" >{i18next.t('onboarding.panel1.brand')} </Radio>
                </div>
            </Radio.Group>
        </Form.Item>
    )
}

export default OnboardingForm1;