import { DeleteOutlined, QuestionCircleOutlined, SafetyOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Popover } from "antd";
import { useState } from "react";


function DeletePopconfirm({onDelete,type='task',iconOnly=false}){
    const [loading,setLoading] = useState(false);

    return(
        <Popconfirm 
            title={`Delete this ${type}`}
            description={`Are you sure to delete this ${type}?`}
            onConfirm={() => {onDelete();setLoading(true);}}
            okText="Yes"
            cancelText="No"
            >
            <Button type='link' icon={<DeleteOutlined />} loading={loading} size="sm" style={{color:'red',padding:0}}>{!iconOnly && 'Delete'}</Button>
        </Popconfirm>
    )
}

export default DeletePopconfirm;