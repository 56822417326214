import { MinusCircleOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Button, Checkbox, Collapse, Form, Input, InputNumber, Select, Skeleton, message } from "antd";
import LocationServices from "../../../utils/LocationServices";
import { useAuth } from "../../../utils/useAuth";
import { useLocation } from "../../Layout/DiscoveryLayout";
import ProductImageUploadSquare from "../../Upload/ProductImageUploadSquare";
import BrandSelect from "../../Select/BrandSelect";
import noPhotoImage from '../../../images/no_image.webp';
import CurrencySelect from "../../Select/CurrencySelect";
import ServiceServices from "../../../utils/ServiceServices";
import DeletePopconfirm from "../../Popconfirm/DeletePopconfirm";

const {Panel} = Collapse;

const filterOptions = [
    {
        name:'Types of Collabs',
        options:['Affiliate Marketing', 'Brand Ambassadors', 'Giveaways', 'Influencer Gifting', 'Product Collaborations', 'Social Media Makeovers', 'Sponsored Blog Posts', 'Sponsored Posts', 'User-Generated Content', 'Virtual Events', 'Other']
    },
    {
        name:'Categories',
        options:['Beauty', 'Business', 'Cooking', 'Education', 'Fashion', 'Food & Drink', 'Gaming', 'Health', 'Humor & Comedy', 'Lifestyle', 'Music', 'Parenting & Family', 'Photography', 'Technology & Gadget', 'Travel']
    },
    {
        name:'Channels',
        options:['Instagram','TikTok','Facebook','Lemon8','Twitter','YouTube','Twitch','LinkedIn']            
    },
    {
        name:'Offer',
        options:['Affiliate Partnerships', 'Brand Ambassadorships', 'Cause Marketing Collaborations', 'Content Creation Agreements', 'Discount & Discount Codes', 'Educational Collaborations', 'Event Sponsorships', 'Exclusive Product Access', 'Exclusivity Offers', 'Giveaways and Contests', 'Influencer Takeovers', 'Product Collaborations', 'Product Gifting', 'Social Media Challenges', 'Sponsored Content', 'Surprise Packages', 'Other']
    }
];

const applicationQuestions = [
    'Can you briefly describe your primary audience and their key interests or demographics?',
    'What is your unique approach to content creation, and how would you describe your overall content style?',
    'What types of collaborations do you prefer, and are there specific formats or platforms where you feel most effective?',
    'How do you measure the success of your content, and what are your typical engagement rates?',
    'How do you ensure that collaborations align with your personal brand, and are there specific values or causes that are important to you?'
]

function CreateCollabForm({form,formValues,setFormValues,successDelete}){
    const {user} = useAuth();
    const {userCountry} = useLocation();
    const [countryList,setCountryList] = useState([]);
    const [cityList,setCityList] = useState([]);
    const [provinceList,setProvinceList] = useState([]);
    const [uploading,setUploading] = useState(false);
    const [useBudgetRange,setUseBudgetRange] = useState(false);

    const getCountryValues = async () => {
        const countryResponse = await LocationServices.getCountryFlags();
        setCountryList(countryResponse.data);
    }
    const getRegionValues = async (country) => {
        const cityResponse = await LocationServices.getCountryCities({country:country});
        setCityList(cityResponse.data);

        const provinceResponse = await LocationServices.getCountryStates({country:country});
        const provinceCleaned = await provinceResponse.data.states.map(value => value.name);
        setProvinceList(provinceCleaned);
    }

    const selectCountry = (value) => {
        getRegionValues(value);
        setFormValues({...formValues,country:value});
    }
    const onChangeDescriptionMore = (value) => {
        if(formValues?.description_more && formValues?.description_more.length > 0){
            var descriptionMoreList = formValues?.description_more.map((value) => value.name);
            var valueIndex = descriptionMoreList.indexOf(value.name);

            if(valueIndex !== -1){
                var newDescriptionMore = [...formValues?.description_more];
                newDescriptionMore[valueIndex] = value;
                setFormValues({...formValues,description_more:newDescriptionMore});
            }
            else{
                setFormValues({...formValues,description_more:[...formValues?.description_more,value]});
            }
        }
        else{
            setFormValues({...formValues,description_more:[value]});
        }        
    }
    const clickSampleQuestion = (value) => {
        const fields = form.getFieldsValue()
        const { questions } = fields;        
        form.setFieldsValue({questions:[...questions,value]});
    }

    const onDelete = async () => {
        const deleteResponse = await ServiceServices.deleteService({service_id:formValues?.id})
        if(deleteResponse.status === 204){
            message.success('Your Collab was Deleted Successfully!');
            successDelete(formValues?.id);
        }
    }

    useEffect(() => {
        getCountryValues();
        if(userCountry){
            getRegionValues(userCountry.country);
        }
    },[userCountry]);

    return(
        <Form
            name="influencer-payment-details"
            className="create-product-form"
            form={form}
            scrollToFirstError={true}
            layout="vertical">
            <div style={{padding:window.innerWidth > 1000 ? '15px' : 0,marginTop:'15px',marginBottom:'10px'}}>
                <div style={{marginBottom:'20px'}}>
                    <div style={{padding:'2px',flex:1}}>
                        <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Name Your Collab</div>
                        <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>*Required</div>
                    </div>
                    <Form.Item
                        name="name"
                        style={{marginBottom:'5px'}}
                        initialValue={formValues?.name}
                        autoFocus={true}
                        rules={[
                            {
                                required: true,
                                message: 'Name is Required.',
                            },
                        ]}
                        >
                        <Input value={formValues?.name} style={{borderRadius:'10px'}} placeholder="Instagram Post Sharing New Clothing Line!" size='large' onChange={(value) => setFormValues({...formValues,name:value.target.value}) } />
                    </Form.Item>
                </div>
                <div style={{marginBottom:'20px'}}>
                    <div style={{padding:'2px',flex:1}}>
                        <div style={{fontWeight:'500',fontSize:'17px'}}>Social Media Channels</div>
                    </div>
                    <Form.Item
                        name="socials"
                        style={{marginBottom:'5px'}}
                        >
                        <Select
                            showSearch
                            size='large'
                            style={{ width:'100%',border:'1px solid rgba(0,0,0,0.2)',borderRadius:'10px'}}
                            bordered={false}
                            mode="multiple"
                            placeholder="Add Your Target Channels"
                            onChange={(value) => setFormValues({...formValues,socials:value})}
                            >
                            {
                                filterOptions[2].options.map((value,index) => 
                                        <Select.Option key={`social-type-${index}`} value={value} className="home-onboarding-select-option" >
                                            <div style={{fontWeight:'normal'}}>{value}</div>
                                        </Select.Option>
                                    )
                            }
                        </Select>
                    </Form.Item>
                </div>
                <div style={{display:'flex',marginBottom:'20px'}}>
                    <div style={{flexBasis:'50%',paddingRight:'4px'}}>
                        <div style={{padding:'2px',flex:1}}>
                            <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Collaboration Type</div>
                            <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>*Required</div>
                        </div>
                        <Form.Item
                            name="type"
                            style={{marginBottom:'5px'}}
                            initialValue={formValues?.type}
                            rules={[
                                {
                                    required: true,
                                    message: 'Collaboration Type is Required.',
                                },
                            ]}>
                            <Select
                                showSearch
                                size='large'
                                style={{width:'100%',border:'1px solid rgba(0,0,0,0.2)',borderRadius:'10px'}}
                                bordered={false}
                                placeholder="Select Collaboration Type"
                                onChange={(value) => setFormValues({...formValues,type:value})}
                                >
                                {
                                    filterOptions[0].options.map((value,index) => 
                                            <Select.Option key={`collab-type-${index}`} value={value} className="home-onboarding-select-option" >
                                                <div style={{fontWeight:'normal'}}>{value}</div>
                                            </Select.Option>
                                        )
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis:'50%',paddingLeft:'2px'}}>
                        <div style={{padding:'2px',flex:1}}>
                            <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Categories</div>
                            <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>*Required</div>
                        </div>
                        <Form.Item
                            name="category"
                            style={{marginBottom:'5px'}}
                            rules={[
                                {
                                    required: true,
                                    message: 'Categories are Required.',
                                },
                            ]}
                            >
                            <Select
                                showSearch
                                size='large'
                                style={{ width:'100%',border:'1px solid rgba(0,0,0,0.2)',borderRadius:'10px'}}
                                mode='multiple'
                                bordered={false}
                                placeholder="Select Categories Type"
                                onChange={(value) => setFormValues({...formValues,category:value})}
                                >
                                {
                                    filterOptions[1].options.map((value,index) => 
                                            <Select.Option key={`category-type-${index}`} value={value} className="home-onboarding-select-option" >
                                                <div style={{fontWeight:'normal'}}>{value}</div>
                                            </Select.Option>
                                        )
                                }
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <div style={{marginBottom:'20px'}}>
                    <div style={{padding:'2px',flex:1}}>
                        <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Description</div>
                        <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>Optional</div>
                    </div>
                    <Form.Item
                        name="description"
                        style={{marginBottom:'5px'}}
                        >
                        <Input.TextArea style={{borderRadius:'10px',minHeight:'20vh'}} placeholder="The key features and unique selling points are ..." size='large' onChange={(value) => setFormValues({...formValues,description:value.target.value}) } />
                    </Form.Item>
                </div>
                <div style={{marginBottom:'25px'}}>
                    <div style={{padding:'2px',marginBottom:'10px'}}>
                        <div style={{fontWeight:'500',fontSize:'16px'}}>Are You a Business?</div>
                        <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>Publish as your brand for more interest!</div>
                    </div>
                    <Form.Item
                        name="brand_id"
                        style={{marginBottom:'5px',width:'50%'}}
                        >
                        <BrandSelect defaultValue={formValues?.brand_id?.id} onSelect={(value) => setFormValues({...formValues,brand_id:value})} />
                    </Form.Item>
                </div>
            </div>
            <Collapse
                bordered={false} 
                expandIconPosition={'end'} 
                defaultActiveKey={'0'}
                accordion={true}
                expandIcon={({ isActive }) => isActive ? <MinusOutlined />:<PlusOutlined />}
                style={{fontSize:'20px',fontWeight:'500',backgroundColor:'white',padding:0}}>
                <Panel header='Budget & Compensation' key={'0'}>
                    <div style={{paddingTop:'20px',paddingBottom:'30px'}}>
                        <div style={{marginBottom:'25px'}}>
                            <div style={{marginBottom:'15px'}}>
                                {
                                    useBudgetRange
                                    ?
                                        <div style={{width:'100%',display:window.innerWidth > 1000 ? 'flex' : 'block',marginBottom:'15px'}}>
                                            <div style={{width:window.innerWidth > 1000 ? '50%' : '100%',paddingRight:'4px',marginBottom:window.innerWidth > 1000 ? '0' : '10px'}}>
                                                <div style={{padding:'2px',marginBottom:'5px'}}>
                                                    <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Min Range</div>
                                                    <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>Optional</div>
                                                </div>
                                                <div className="currency-select-container">
                                                    <CurrencySelect onSelect={(value) => setFormValues({...formValues,currency:value})} defaultCurrency={formValues?.currency || '$'} />
                                                    <InputNumber 
                                                        value={formValues?.budget?.min_value ? formValues?.budget?.min_value : ''} 
                                                        onChange={(value) => setFormValues({...formValues,budget:{...formValues?.budget,min_value:value}})} 
                                                        bordered={false} className='currency-input' 
                                                        placeholder={'100,000'} 
                                                        size='large'  
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        style={{overflow:'hidden'}}/>
                                                </div>
                                            </div>
                                            <div style={{width:window.innerWidth > 1000 ? '50%' : '100%',paddingLeft:'2px'}}>
                                                <div style={{padding:'2px',marginBottom:'5px'}}>
                                                    <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Max Range</div>
                                                    <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>Optional</div>
                                                </div>
                                                <div className="currency-select-container">
                                                    <CurrencySelect onSelect={(value) => setFormValues({...formValues,currency:value})} defaultCurrency={formValues?.currency || '$'} />
                                                    <InputNumber 
                                                        value={formValues?.budget?.max_value ? formValues?.budget?.max_value : ''} 
                                                        onChange={(value) => setFormValues({...formValues,budget:{...formValues?.budget,max_value:value}})} 
                                                        bordered={false} 
                                                        className='currency-input' 
                                                        placeholder={'1,000,000'} 
                                                        size='large' 
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        style={{overflow:'hidden'}} />
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        <div style={{width:window.innerWidth > 1000 ? '50%' : '100%'}}>
                                            <div style={{padding:'2px',marginBottom:'5px'}}>
                                                <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Offer Budget</div>
                                                <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>Optional</div>
                                            </div>
                                            <div className="currency-select-container">
                                                <CurrencySelect onSelect={(value) => setFormValues({...formValues,currency:value})} defaultCurrency={formValues?.currency || '$'} />
                                                <InputNumber
                                                    value={formValues?.budget?.value ? formValues?.budget?.value : ''} 
                                                    onChange={(value) => setFormValues({...formValues,budget:{...formValues?.budget,value:value}})} 
                                                    bordered={false} 
                                                    className='currency-input' 
                                                    placeholder={'Budget: 1,000,000'} 
                                                    size='large'
                                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                    style={{overflow:'hidden'}}/>
                                            </div>
                                        </div>
                                }
                            </div>
                            <div style={{padding:'2px',marginBottom:'15px'}}>
                                <div style={{fontWeight:'500',fontSize:'17px'}}>Budget</div>
                                <div>
                                    <Checkbox checked={useBudgetRange} onClick={() => setUseBudgetRange(!useBudgetRange)}>Use Budget Range</Checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </Panel>
                <Panel header='Objectives & Requirements' key={'1'} >
                    <div style={{color:'rgba(0,0,0,0.5)',fontSize:'16px',fontWeight:'normal'}}>
                        Clear Objectives and Requirements help to connect you with the right influencers.
                    </div>
                    <div style={{paddingTop:'40px',paddingBottom:'30px'}}>
                        <div style={{marginBottom:'25px'}}>
                            <div style={{padding:'2px',flex:1}}>
                                <div style={{lineHeight:1,fontWeight:'500',fontSize:'17px'}}>What's the main goal for the collaboration?</div>
                                <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>Optional</div>
                            </div>
                            <Form.Item
                                name="description_more_1"
                                style={{marginBottom:'5px'}}
                                >
                                <Input.TextArea style={{borderRadius:'10px',minHeight:'20vh'}} placeholder="I want more brand exposure / sales / feedback ..." size='large' onChange={(value) => onChangeDescriptionMore({name:"What's the main goal for the collaboration?",value:value.target.value,order:1}) } />
                            </Form.Item>
                        </div>
                        <div style={{marginBottom:'25px'}}>
                            <div style={{padding:'2px',flex:1}}>
                                <div style={{lineHeight:1,fontWeight:'500',fontSize:'17px'}}>What product / service are you hoping to promote?</div>
                                <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>Optional</div>
                            </div>
                            <Form.Item
                                name="description_more_2"
                                style={{marginBottom:'5px'}}
                                >
                                <Input.TextArea style={{borderRadius:'10px',minHeight:'20vh'}} placeholder="The key features and unique selling points are ..." size='large' onChange={(value) => onChangeDescriptionMore({name:"What product / service are you hoping to promote?",value:value.target.value,order:2}) } />
                            </Form.Item>
                        </div>
                        <div style={{marginBottom:'25px'}}>
                            <div style={{padding:'2px',flex:1}}>
                                <div style={{lineHeight:1,fontWeight:'500',fontSize:'17px'}}>Other Details / Notes</div>
                                <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>Optional</div>
                            </div>
                            <Form.Item
                                name="description_more_3"
                                style={{marginBottom:'5px'}}
                                >
                                <Input.TextArea style={{borderRadius:'10px',minHeight:'20vh'}} placeholder="Looking for an influencer to help promote our new..." size='large' onChange={(value) => onChangeDescriptionMore({name:"Other Details / Notes",value:value.target.value,order:3}) } />
                            </Form.Item>
                        </div>
                    </div>
                </Panel>
                <Panel header='Target Demographics' key={'2'} >
                    <div style={{color:'rgba(0,0,0,0.5)',fontSize:'16px'}}>
                        Choose your ideal target for this collaboration.<br/>
                        We will help match you with the best fit partners!
                    </div>
                    <div style={{paddingTop:'20px',paddingBottom:'30px'}}>
                        <div style={{marginBottom:'15px'}}>
                            <div style={{padding:'2px',marginBottom:'15px'}}>
                                <div style={{fontWeight:'500',fontSize:'17px'}}>Target Regions (Locations)</div>
                            </div>
                            <div>
                                <div style={{marginBottom:'15px'}}>
                                    <div style={{padding:'2px'}}>
                                        <div style={{lineHeight:1,fontWeight:'normal',fontSize:'14px'}}>Country</div>
                                        <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>Optional</div>
                                    </div>
                                    <Form.Item
                                        name="country"
                                        style={{marginBottom:'5px'}}
                                        initialValue={userCountry?.country}
                                        >
                                        <Select
                                            showSearch
                                            size='large'
                                            style={{ width:'100%',border:'1px solid rgba(24, 144, 255, 0.6)',borderRadius:'10px'}}
                                            bordered={false}
                                            placeholder="Search to Select Country"
                                            onChange={selectCountry}
                                            >
                                            {
                                                countryList.length > 0 &&
                                                    countryList.map((value,index) => 
                                                        <Select.Option key={`country-value-${index}`} value={value.name} className="home-onboarding-select-option" >
                                                            <div className="home-onboarding-flag-img" style={{backgroundImage:`url(${value?.flag})`}}  /> {value?.name}
                                                        </Select.Option>
                                                    )
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div style={{width:'100%',display:'flex',marginBottom:'15px'}}>
                                    <div style={{flexBasis:'50%',paddingRight:'4px'}}>
                                        <div style={{padding:'2px'}}>
                                            <div style={{lineHeight:1,fontWeight:'normal',fontSize:'14px'}}>City</div>
                                            <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>Optional</div>
                                        </div>
                                        <Form.Item
                                            name="city"
                                            style={{marginBottom:'5px'}}
                                            >
                                            <Select
                                                showSearch
                                                size='large'
                                                style={{ width:'100%',border:'1px solid rgba(24, 144, 255, 0.6)',borderRadius:'10px'}}
                                                bordered={false}
                                                placeholder="City"
                                                onChange={(value) => setFormValues({...formValues,city:value})}
                                                >
                                                {
                                                    cityList.length > 0 &&
                                                        cityList.map((value,index) => 
                                                            <Select.Option key={`city-select-${index}`} value={value} className="home-onboarding-select-option" >
                                                                {value}
                                                            </Select.Option>
                                                        )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div style={{flexBasis:'50%',paddingLeft:'2px'}}>
                                        <div style={{padding:'2px'}}>
                                            <div style={{lineHeight:1,fontWeight:'normal',fontSize:'14px'}}>Province</div>
                                            <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>Optional</div>
                                        </div>
                                        <Form.Item
                                            name="province_state"
                                            style={{marginBottom:'5px'}}
                                            initialValue={formValues?.province_state !== '' ? formValues?.province_state : null}
                                            >
                                            <Select
                                                showSearch
                                                size='large'
                                                style={{ width:'100%',border:'1px solid rgba(24, 144, 255, 0.6)',borderRadius:'10px'}}
                                                bordered={false}
                                                placeholder="Province / State"
                                                onChange={(value) => setFormValues({...formValues,province_state:value})}
                                                >
                                                {
                                                    provinceList.length > 0 &&
                                                        provinceList.map((value,index) => 
                                                            <Select.Option key={`state-value-${index}`} value={value} className="home-onboarding-select-option" >
                                                                {value}
                                                            </Select.Option>
                                                        )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Panel>
                <Panel header='Media' key={'4'}>
                    <div style={{color:'rgba(0,0,0,0.5)',fontSize:'16px'}}>
                        Want to share examples or any resources? Add Images here!
                    </div>
                    <div style={{paddingTop:'20px',paddingBottom:'30px',fontSize:'16px'}}>
                        <div style={{display:'flex',flexWrap:'wrap'}}>
                            {
                                formValues?.images && formValues?.images.length > 0 &&
                                    formValues?.images.map((value) => 
                                        <div style={{width:window.innerWidth > 1000 ? '20%' : '50%',aspectRatio:1/1,padding:'5px'}}>
                                            <div className='post-image hover-scale-xs' style={{borderRadius:'5px',aspectRatio:1/1,backgroundImage:`url(${value?.image || noPhotoImage})`}} >
                                            </div>
                                        </div>
                                    )
                            }
                            {
                                uploading &&
                                    <div style={{width:window.innerWidth > 1000 ? '20%' : '50%',aspectRatio:1/1,padding:'5px'}}>
                                        <Skeleton.Button style={{aspectRatio:1/1,height:'100%',width:'100%',borderRadius:'5px'}} block={true} active={true}/>
                                    </div>
                            }
                            <div style={{width:window.innerWidth > 1000 ? '20%' : '50%',padding:'5px'}}>
                                <ProductImageUploadSquare imageList={formValues?.images || []} setImageList={(value) => setFormValues({...formValues,images:value})} user={user} setUploading={setUploading} />
                            </div>
                        </div>
                    </div>
                </Panel>
                <Panel header='Application Questions' key={'5'}>
                    <div style={{color:'rgba(0,0,0,0.5)',fontSize:'16px'}}>
                        Add Application Questions to learn more about the influencers who want to collaborate with you!
                    </div>
                    <div style={{paddingTop:'30px',paddingBottom:'30px'}}>
                        <Form.List name="questions">
                            {
                            (fields, { add, remove }, { errors }) => (
                                <>
                                {fields.map((field, index) => (
                                    <div
                                        key={`app-question-key-${index}`}
                                        style={{display:'flex',alignItems:'center',width:'100%',marginBottom:'10px'}}>
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur']}
                                            style={{width:'100%',marginBottom:0}}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Please add a question or delete this field.",
                                                },
                                            ]}>
                                            <Input value={field.name} placeholder="Add an Application Question" style={{borderRadius:'10px'}} size='large' />
                                        </Form.Item>
                                        {fields.length > 1 ? (
                                            <div style={{padding:'4px'}}>
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => remove(field.name)}
                                                    style={{color:'rgba(0,0,0,0.5)'}}/>
                                            </div>
                                        ) : null}
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        style={{ width: '100%' }}
                                        icon={<PlusOutlined />}
                                        >
                                        Add another question
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                                {
                                    fields.length > 0 &&
                                        <div>
                                            <div style={{padding:'2px',marginBottom:'5px'}}>
                                                <div style={{fontSize:'14px'}}>Use Sample Questions</div>
                                            </div>
                                            {
                                                applicationQuestions.map((value) =>
                                                    <div className="hover-scale-xs" onClick={() => clickSampleQuestion(value)} style={{fontSize:'12px',color:'rgba(0,0,0,0.6)',borderRadius:'15px',border:'1px solid rgba(0,0,0,0.2)',padding:'2px 8px 2px 8px',width:'fit-content',marginBottom:'5px',cursor:'pointer'}}>
                                                        {value}
                                                    </div>
                                                )
                                            }
                                        </div>
                                }
                                </>
                            )}
                        </Form.List>
                    </div>
                </Panel>
                {
                    formValues?.id &&
                        <Panel header='Settings' key={'6'}>
                            <div>
                                <div style={{color:'rgba(0,0,0,0.7)',fontSize:'14px',paddingBottom:'5px'}}>
                                    Do you want to delete this collab?
                                </div>
                                <DeletePopconfirm type='Collab' onDelete={onDelete} />
                            </div>
                        </Panel>
                }
            </Collapse>
        </Form>
    )
}

export default CreateCollabForm;