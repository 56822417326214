import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, Space, message } from "antd";
import { useAuth } from '../../../utils/useAuth';
import logo from '../../../images/logo/icon-no-bg.webp';
import CreateNewProductForm from '../../Form/Shop/CreateNewProductForm';
import ProductServices from '../../../utils/ProductServices';

function CreateProductDrawer({isCreateProductDrawerVisible, setIsCreateProductDrawerVisible, values=null, addItem=false, editItem=false}){
    const {user,refresh} = useAuth();
    const [form] = Form.useForm();
    const [formValues,setFormValues] = useState({});
    const [isLoading,setIsLoading] = useState(false);
    const [isLoadingDraft,setIsLoadingDraft] = useState(false);

    const onPublish = async ({isDraft}) => {
        if(isDraft){ setIsLoadingDraft(true) }
        else{ setIsLoading(true)};

        let collabValues = {
            ...formValues,
            is_draft:isDraft
        }
        if(collabValues?.socials && collabValues?.socials.length > 0){
            collabValues['socials'] = collabValues['socials'].map((value) => {return {type:value,username:user.username}});
        }
        if(collabValues?.category && collabValues?.category.length > 0){
            collabValues['category'] = [collabValues['category']].map((value) => {return {name:value}});   
        }
        if(collabValues?.subcategory && collabValues?.subcategory.length > 0){
            collabValues['subcategory'] = [collabValues['subcategory']].map((value) => {return {name:value}});   
        }
        if(collabValues?.tag && collabValues?.tag.length > 0){
            collabValues['tag'] = [...collabValues['tag']].map((value) => {return {name:value}});   
        }
        if(collabValues?.images && collabValues?.images.length > 0){
            collabValues['images'] = collabValues?.images.map((value) => value.id);   
        }
        if(collabValues?.variants && collabValues?.variants.length > 0){
            let variantValue = formValues?.variants.map((variant) => {
                let variantOptions = Object.keys(variant).map((value) => {
                    if(!['index','currency','price','name'].includes(value)){return {name:value,value:variant[value]}}
                }).filter((value) => value);
                return {
                    name: variant['name'],
                    price: variant['price'],
                    currency: variant['currency'],
                    options: variantOptions
                }
            });
            collabValues['variants'] = variantValue;
        }

        let request_config = {values:collabValues,user:user};
        if(formValues?.id) request_config['product_id'] = formValues?.id;

        let createProductResponse = await ProductServices.createEditProduct(request_config);
        if(createProductResponse.status !== 'success'){
            const newUser = await refresh();
            request_config['user'] = newUser;
            createProductResponse = await ProductServices.createEditProduct(request_config);
        }
        if(createProductResponse.status === 'success'){
            if(formValues?.id){
                message.success('Product Updated Created!');
                editItem(createProductResponse.data);
            }
            else{
                message.success('Product Successfully Created!');
                addItem(createProductResponse.data);
            }
            closeDrawer();
        }

        if(isDraft){ setIsLoadingDraft(false) }
        else{ setIsLoading(false)};
    }

    const closeDrawer = () => {
        setIsCreateProductDrawerVisible(false);
        setFormValues({});
        form.resetFields();
    }

    useEffect(() => {
        if(values){
            let updatedValues = {...values};
            if(updatedValues && Object.keys(updatedValues).length > 0){
                if(updatedValues?.socials && updatedValues?.socials.length > 0){
                    updatedValues['socials'] = updatedValues?.socials.map(value => value.type);
                }
                if(updatedValues?.category && updatedValues?.category.length > 0){
                    updatedValues['category'] = updatedValues?.category.map(value => value.name);
                }
                if(updatedValues?.subcategory && updatedValues?.subcategory.length > 0){
                    updatedValues['subcategory'] = updatedValues?.subcategory.map(value => value.name);
                }
                if(updatedValues?.tag && updatedValues?.tag.length > 0){
                    updatedValues['tag'] = updatedValues?.tag.map(value => value.name);
                }
                if(updatedValues?.variants && updatedValues?.variants.length > 0){
                    let optionsList = {};

                    updatedValues['variants'] = updatedValues?.variants.map((value) => {
                        let variantOption = {};
                        value.option.forEach((item) => {
                            variantOption[`${item['name']}`] = item['value'];
                            optionsList[`${item['name']}`] = optionsList[`${item['name']}`] ? [...optionsList[`${item['name']}`],item['value']] : [item['value']];
                        });
                        return {name:value?.name,currency:value?.currency,price:value?.price,...variantOption}
                    });
                    updatedValues['product_options'] = Object.keys(optionsList).map((value) => {
                        return {
                            name: value,
                            options: optionsList[value].filter((item,index) => optionsList[value].indexOf(item) === index)
                        }
                    });
                }

                form.setFieldsValue(updatedValues);
                setFormValues(updatedValues);
            }
            else {
                form.resetFields();
                setFormValues({});
            }
        }
        else {
            form.resetFields();
            setFormValues({});
        }
    },[values,isCreateProductDrawerVisible]);

    return(
        <Drawer             
            open={isCreateProductDrawerVisible} 
            bodyStyle={{ overflowY:'auto',maxHeight:'95vh'}}
            footer={null} 
            onClose={closeDrawer}
            width={'100%'}
            centered={true}
            destroyOnClose={true}
            extra={
                <Space size={0}>
                    <Button type={'link'} ghost={true} className='rounded-button' loading={isLoadingDraft} style={{color:'#ef3078'}} onClick={() => onPublish({isDraft:true})}>Save as Draft</Button>
                    <Button type={'primary'} className='rounded-button' loading={isLoading} style={{color:'white',backgroundColor:'#ef3078',borderColor:'#ef3078'}} onClick={() => onPublish({isDraft:false})}>{values?.id ? 'Save' : 'Create'}</Button>
                </Space>
            }>
            <div id='post-modal'></div>
            <div className="content-wide-container" style={{paddingBottom:'20vh',maxWidth:'800px',textAlign:'left'}}>
                <div style={{padding:window.innerWidth > 1000 ? '15px' : 0,paddingBottom:'15px',paddingTop:window.innerWidth > 1000 ? '30px' : '10px'}}>
                    <Space style={{display:'flex',alignItems:'center',marginBottom:'5px'}}>
                        <div>
                            <img src={logo} alt="datarebel-logo" style={{width:'28px',aspectRatio:1/1}} />
                        </div>
                        <div>
                            <h1 style={{marginBottom:0,lineHeight:1}}>{values?.id ? 'Edit This' : 'Create a'} Product</h1>
                        </div>
                    </Space>
                    <div>
                        <h3 style={{marginBottom:0,color:'rgba(0,0,0,0.6)',fontWeight:'400'}}>
                            Sell a Product or Create an Affiliate Product w/ Link to Earn on WanderCart.
                        </h3>
                    </div>
                </div>
                <div>
                    <CreateNewProductForm form={form} formValues={formValues} setFormValues={setFormValues} />
                </div>
            </div>
            <div className='no-desktop' style={{position:'fixed',bottom:0,left:0,right:0,padding:'10px',backgroundColor:'white'}}>
                <Button type={'primary'} className='rounded-button' size="large" loading={isLoading} style={{color:'white',backgroundColor:'#ef3078',borderColor:'#ef3078',width:'100%'}} onClick={() => onPublish({isDraft:false})}>{values?.id ? 'Save' : 'Create'} Product</Button>
            </div>
        </Drawer>
    )
}
export default CreateProductDrawer;