import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Input, InputNumber, Select, Skeleton, Space, message } from "antd";
import { useEffect, useState } from "react";
import LocationServices from "../../../utils/LocationServices";
import { useAuth } from "../../../utils/useAuth";
import { useLocation } from "../../Layout/DiscoveryLayout";
import ProductImageUploadSquare from "../../Upload/ProductImageUploadSquare";
import BrandSelect from "../../Select/BrandSelect";
import ProductOptionsDrawer from "../../Drawers/Shop/ProductOptionsDrawer";
import ProductVariantsDrawer from "../../Drawers/Shop/ProductVariantsDrawer";
import ProductOptionCard from "../../Card/ProductOptionCard";
import ProductVariantTable from "../../Table/ProductVariantTable";
import noPhotoImage from '../../../images/no_image.webp';
import CurrencySelect from "../../Select/CurrencySelect";

const {Panel} = Collapse;

const filterOptions = [
    {
        name:'Types of Collabs',
        options:['Sponsored Posts','Brand Ambassadors','Product Collaborations','Influencer Gifting','Affiliate Marketing','Social Media Makeovers','Giveaways','Virtual Events','User-Generated Content','Sponsored Blog Posts','Other']
    },
    {
        name:'Categories',
        options:['Lifestyle','Gaming','Food & Drink','Fashion','Beauty','Travel','Parenting &\nFamily','Music','Humor & Comedy','Technology & Gadget','Education','Business','Health','Photography','Cooking']
    },
    {
        name:'Channels',
        options:['Instagram','TikTok','Facebook','Lemon8','Twitter','YouTube','Twitch','LinkedIn']            
    },
    {
        name:'Offer',
        options:['Product Gifting','Exclusive Product Access','Discount & Discount Codes','Affiliate Partnerships','Sponsored Content','Giveaways and Contests','Brand Ambassadorships','Event Sponsorships','Content Creation Agreements','Influencer Takeovers','Product Collaborations','Social Media Challenges','Exclusivity Offers','Educational Collaborations','Cause Marketing Collaborations','Surprise Packages','Other']    
    }
];

const subcategoryOptions = {
    "Lifestyle": ["Fashion", "Fitness", "Health & Wellness", "Home Decor", "Relationships", "Self-Care", "Travel", "Work-Life Balance", "Yoga", "Zero Waste Living"],
    "Gaming": ["Action", "Adventure", "Esports", "Indie Games", "Multiplayer", "Role-Playing", "Simulation", "Strategy", "Virtual Reality", "Casual"],
    "Food & Drink": ["Baking", "Cocktails", "Dining Out", "Healthy Eating", "International Cuisine", "Meal Prep", "Recipes", "Street Food", "Vegetarian & Vegan", "Wine"],
    "Fashion": ["Accessories", "Casual Wear", "Formal Wear", "Jewelry", "Shoes", "Streetwear", "Sustainable Fashion", "Swimwear", "Vintage Fashion", "Workwear"],
    "Beauty": ["Hair Care", "Makeup Tutorials", "Nail Art", "Natural Beauty", "Skincare Routine", "Beauty Tips", "Cruelty-Free", "Korean Beauty", "Anti-Aging", "Fragrances"],
    "Travel": ["Adventure Travel", "Cultural Experiences", "Family Travel", "Luxury Travel", "Outdoor Activities", "Solo Travel", "Travel Tips", "Budget Travel", "Road Trips", "Wildlife Safaris"],
    "Parenting &\nFamily": ["Balancing Work and Family", "Education Activities", "Family Travel", "Parenting Tips", "Pregnancy", "Sibling Relationships", "Single Parenting", "Teenagers", "Toddler Care", "Positive Discipline"],
    "Music": ["Classical", "Electronic", "Hip-Hop", "Indie", "Jazz", "Pop", "Rock", "Country", "Reggae", "World Music"],
    "Humor & Comedy": ["Stand-up Specials", "Sketch Comedy", "Satirical News", "Comedy Podcasts", "Parody", "Improv", "Funny Anecdotes", "Humorous Essays", "Web Comics", "Meme Culture"],
    "Technology & Gadget": ["Artificial Intelligence", "Cybersecurity", "Gaming Gadgets", "Innovations", "Mobile Apps", "Programming", "Robotics", "Smart Home", "Virtual Reality", "Wearable Tech"],
    "Education": ["Distance Learning", "Educational Apps", "Language Learning", "STEM Education", "Study Abroad", "Teaching Strategies", "Online Courses", "Learning Resources", "Study Tips", "Academic Writing"],
    "Business": ["Entrepreneurship", "Finance", "Leadership", "Marketing", "Networking", "Productivity", "Startups", "Small Business", "Social Entrepreneurship", "Workplace Culture"],
    "Health": ["Fitness Tips", "Mental Health", "Nutrition", "Preventive Care", "Sleep Hygiene", "Stress Management", "Vegan Lifestyle", "Weight Loss", "Wellness Retreats", "Yoga Practices"],
    "Photography": ["Camera Gear", "Composition Techniques", "Digital Editing", "Fine Art Photography", "Landscape Photography", "Portrait Photography", "Street Photography", "Travel Photography", "Wildlife Photography", "Night Photography"],
    "Cooking": ["Baking", "Desserts", "Grilling", "International Cuisine", "Meal Prep", "Plant-Based Recipes", "Quick and Easy Meals", "Slow Cooking", "Soups and Stews", "Vegetarian Cooking"]
}

function CreateNewProductForm({form, formValues,setFormValues}){
    const {user} = useAuth();
    const {userCountry} = useLocation();
    const [countryList,setCountryList] = useState([]);
    const [uploading,setUploading] = useState(false);
    const [isProductOptionsDrawerVisible,setIsProductOptionsDrawerVisible] = useState(false);
    const [isProductVariantsDrawerVisible,setIsProductVariantsDrawerVisible] = useState(false);
    const [initialVariantValues,setInitialVariantValues] = useState(null);

    const getCountryValues = async () => {
        const countryResponse = await LocationServices.getCountryFlags();
        setCountryList(countryResponse.data);
    }
    const autoCreateVariants = () => {
        if(formValues?.product_options && formValues?.product_options.length > 0){
            const cartesian = ([xs, ...xss]) => xs == undefined ? [[]] : xs.flatMap(x => cartesian(xss).map(ys => [x, ...ys]))
            const combine = (properties) => cartesian(properties.map(({name, options}) => options.map((value) => ({[name.toLowerCase().split(' ').join('_')]: value})))).map(ps => Object.assign({}, ...ps)).map((value,index) => {return {...value,name:`Variant ${index}`,currency:formValues?.currency || '$',price:formValues?.price || 0}});
            let newValue = combine(formValues?.product_options);
            setFormValues({...formValues,'variants':newValue.map((value,index) => {return {...value,index:index}})});
        }
        else{
            message.error('Create Options to auto-create Variants')
        }
    }
    const onEditVariant = (value) => {
        setInitialVariantValues(value);
        setIsProductVariantsDrawerVisible(true);
    }

    useEffect(() => {
        getCountryValues();
    },[userCountry]);

    return(
        <div>
            <Form
                name="influencer-payment-details"
                className="create-product-form"
                form={form}
                layout="vertical">
                <div style={{padding:window.innerWidth > 1000 ? '15px' : 0,marginTop:'15px',marginBottom:'10px'}}>
                    {
                        formValues?.images && formValues?.images.length > 0 &&
                            <div style={{marginBottom:'20px'}}>
                                <div style={{padding:'2px',flex:1}}>
                                    <div style={{fontWeight:'500',fontSize:'16px'}}>Product Images</div>
                                </div>
                                <div style={{display:'flex',flexWrap:'wrap'}}>
                                    <div style={{width:'50%'}}>
                                        <div style={{width:'100%',aspectRatio:1/1,padding:'5px'}}>
                                            <div className='post-image hover-scale-xs' style={{backgroundImage:`url(${formValues?.images[0]?.image || noPhotoImage})`,borderRadius:'5px',aspectRatio:1/1}} >
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{flexWrap:'wrap',width:'50%'}}>
                                        {
                                            formValues?.images && formValues?.images.length > 1 &&
                                                formValues?.images.splice(1,0).map((value) => 
                                                    <div style={{width:'50%',aspectRatio:1/1,padding:'5px'}}>
                                                        <div className='post-image hover-scale-xs' style={{borderRadius:'5px',aspectRatio:1/1,backgroundImage:`url(${value?.image || noPhotoImage})`}} >
                                                        </div>
                                                    </div>
                                                )
                                        }
                                        {
                                            uploading &&
                                                <div style={{width:'50%',aspectRatio:1/1,padding:'5px'}}>
                                                    <Skeleton.Button style={{aspectRatio:1/1,height:'100%',width:'100%',borderRadius:'5px'}} block={true} active={true}/>
                                                </div>
                                        }
                                        <div style={{width:'50%',aspectRatio:1/1,padding:'5px'}}>
                                            <ProductImageUploadSquare imageList={formValues?.images || []} setImageList={(value) => setFormValues({...formValues,images:value})} user={user} setUploading={setUploading} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    <div style={{marginBottom:'20px'}}>
                        <div style={{padding:'2px',flex:1}}>
                            <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Product Name</div>
                            <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>*Required</div>
                        </div>
                        <Form.Item
                            name="name"
                            style={{marginBottom:'5px'}}
                            initialValue={formValues?.name}
                            autoFocus={true}
                            rules={[
                                {
                                    required: true,
                                    message: 'Name is Required.',
                                },
                            ]}
                            >
                            <Input value={formValues?.name} style={{borderRadius:'10px'}} placeholder="WanderCart Basic - WanderSocks" size='large' onChange={(value) => setFormValues({...formValues,name:value.target.value}) } />
                        </Form.Item>
                    </div>
                    <div style={{marginBottom:'20px'}}>
                        <div style={{padding:'2px',flex:1}}>
                            <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Short Description</div>
                            <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>*Required</div>
                        </div>
                        <Form.Item
                            name="short_description"
                            style={{marginBottom:'5px'}}
                            >
                            <Input value={formValues?.short_description} style={{borderRadius:'10px'}} placeholder="Perfect Socks to Wander in" size='large' onChange={(value) => setFormValues({...formValues,short_description:value.target.value}) } />
                        </Form.Item>
                    </div>
                    <div style={{marginBottom:'25px'}}>
                        <div style={{padding:'2px',flex:1}}>
                            <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Description</div>
                            <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>Optional</div>
                        </div>
                        <Form.Item
                            name="description"
                            style={{marginBottom:'5px'}}
                            >
                            <Input.TextArea style={{borderRadius:'10px',minHeight:'20vh'}} placeholder="The key features and unique selling points are ..." size='large' onChange={(value) => setFormValues({...formValues,description:value.target.value}) } />
                        </Form.Item>
                    </div>
                    <div style={{marginBottom:'20px'}}>
                        <div style={{padding:'2px',flex:1}}>
                            <div style={{fontWeight:'500',fontSize:'16px'}}>Default Link</div>
                            <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>Add more details links to variants.</div>
                        </div>
                        <Form.Item
                            name="link"
                            style={{marginBottom:'5px'}}
                            >
                            <Input value={formValues?.link} style={{borderRadius:'10px'}} placeholder="https://www.shopee.com/socks" size='large' onChange={(value) => setFormValues({...formValues,link:value.target.value}) } />
                        </Form.Item>
                    </div>
                    <div style={{marginBottom:'20px'}}>
                        <div style={{padding:'2px',marginBottom:'10px'}}>
                            <div style={{fontWeight:'500',fontSize:'16px'}}>Default Price</div>
                            <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>You can also assign prices by product variant.</div>
                        </div>
                        <div style={{marginBottom:'5px',width:window.innerWidth > 1000 ? '50%' : '100%'}}>
                            <div className="currency-select-container">
                                <CurrencySelect onSelect={(value) => setFormValues({...formValues,currency:value})} defaultCurrency={formValues?.currency || '$'} />
                                <InputNumber 
                                    value={formValues?.price ? formValues?.price : ''} 
                                    onChange={(value) => setFormValues({...formValues,price:value})} 
                                    bordered={false} className='currency-input' 
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    placeholder={'Product Price'} 
                                    size='large' />
                            </div>
                        </div>
                    </div>
                    <div style={{marginBottom:'20px'}}>
                        <div style={{padding:'2px',marginBottom:'10px'}}>
                            <div style={{fontWeight:'500',fontSize:'16px'}}>Are You a Business?</div>
                            <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>Publish as your brand for more interest!</div>
                        </div>
                        <Form.Item
                            name="brand"
                            style={{marginBottom:'5px',width:window.innerWidth > 1000 ? '50%' : '100%'}}
                            >
                            <BrandSelect defaultValue={formValues?.brand_id?.id} onSelect={(value) => setFormValues({...formValues,brand_id:value})} />
                        </Form.Item>
                    </div>
                </div>
                <Collapse
                    bordered={false} 
                    defaultActiveKey={'1'}
                    accordion={true}
                    expandIconPosition={'end'} 
                    expandIcon={({ isActive }) => isActive ? <MinusOutlined />:<PlusOutlined />}
                    style={{fontSize:'18px',fontWeight:'500',backgroundColor:'white',padding:0}}>
                    <Panel header='Organize' key={'1'}>
                        <div style={{padding:'5vh 0 5vh 0'}}>
                            <div style={{display:window.innerWidth > 1000 ? 'flex' : 'block',marginBottom:'20px'}}>
                                <div style={{width:window.innerWidth > 1000 ? '50%' : '100%',paddingRight:'4px',marginBottom:window.innerWidth > 1000 ? '0px' :'20px'}}>
                                    <div style={{padding:'2px',flex:1}}>
                                        <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Categories</div>
                                        <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>*Required</div>
                                    </div>
                                    <Form.Item
                                        name="category"
                                        style={{marginBottom:'5px'}}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Category are Required.',
                                            },
                                        ]}
                                        >
                                        <Select
                                            showSearch
                                            size='large'
                                            style={{ width:'100%',border:'1px solid rgba(0,0,0,0.2)',borderRadius:'10px'}}
                                            bordered={false}
                                            placeholder="Select Category Type"
                                            onChange={(value) => setFormValues({...formValues,category:value})}
                                            >
                                            {
                                                filterOptions[1].options.map((value,index) => 
                                                        <Select.Option key={`category-type-${index}`} value={value} className="home-onboarding-select-option" >
                                                            <div style={{fontWeight:'normal'}}>{value}</div>
                                                        </Select.Option>
                                                    )
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div style={{width:window.innerWidth > 1000 ? '50%' : '100%',paddingLeft:'2px'}}>
                                    <div style={{padding:'2px',flex:1}}>
                                        <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Subcategories</div>
                                        <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>Optional</div>
                                    </div>
                                    <Form.Item
                                        name="subcategory"
                                        style={{marginBottom:'5px'}}
                                        >
                                        <Select
                                            showSearch
                                            size='large'
                                            style={{ width:'100%',border:'1px solid rgba(0,0,0,0.2)',borderRadius:'10px'}}
                                            bordered={false}
                                            placeholder="Select Subcategory"
                                            onChange={(value) => setFormValues({...formValues,subcategory:value})}
                                            >
                                            {
                                                formValues?.category && filterOptions[1].options.includes(formValues?.category) &&
                                                    subcategoryOptions[`${formValues?.category}`].map((value,index) => 
                                                        <Select.Option key={`subcategory-type-${index}`} value={value} className="home-onboarding-select-option" >
                                                            <div style={{fontWeight:'normal'}}>{value}</div>
                                                        </Select.Option>
                                                    )
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                            <div style={{marginBottom:'20px'}}>
                                <div style={{padding:'2px',flex:1}}>
                                    <div style={{fontWeight:'500',fontSize:'16px'}}>Tags</div>
                                </div>
                                <Form.Item
                                    name="tag"
                                    style={{marginBottom:'5px'}}
                                    >
                                    <Select
                                        showSearch
                                        size='large'
                                        style={{ width:'100%',border:'1px solid rgba(0,0,0,0.2)',borderRadius:'10px'}}
                                        mode='tags'
                                        bordered={false}
                                        placeholder="Add Tags!"
                                        onChange={(value) => setFormValues({...formValues,tag:value})}
                                        />
                                </Form.Item>
                            </div>
                        </div>
                    </Panel>
                    <Panel header='Variants' key={'2'} >
                        <div style={{fontSize:'16px',lineHeight:1.5,color:'rgba(0,0,0,0.5)',marginBottom:'10px'}}>
                            Add variations of this product.<br/>
                            Offer different options for price, color, size, shape, etc.
                        </div>
                        <div style={{padding:'5vh 0 5vh 0'}}>
                            <div>
                                <div style={{display:'flex',alignItems:'center',padding:'5px',marginBottom:'5px'}}>
                                    <div style={{flex:1}}>
                                        <div style={{padding:'2px',marginBottom:'5px',width:'80%'}}>
                                            <div style={{fontWeight:'500',fontSize:'16px'}}>Product Options</div>
                                            <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>Product options refer to customizable features or choices offered within a single product listing.</div>
                                        </div>
                                    </div>
                                    <div>
                                        {
                                            formValues?.product_options && formValues?.product_options.length > 0
                                            ? <Button type='default' className="rounded-button" onClick={() => setIsProductOptionsDrawerVisible(true)} >Edit Options</Button>
                                            : <Button type='default' className="rounded-button" onClick={() => setIsProductOptionsDrawerVisible(true)} >Create Options</Button>
                                        }
                                    </div>
                                </div>
                                {
                                    formValues?.product_options && formValues?.product_options.length > 0 &&
                                        <div style={{padding:'0 0 15px 15px',marginBottom:'2vh'}}>
                                            {
                                                formValues?.product_options.map((value,index) => 
                                                    <ProductOptionCard index={index} name={value.name} options={value.options} />
                                                )
                                            }
                                        </div>
                                }
                            </div>
                            <div>
                                <div style={{display:'flex',alignItems:'center',padding:'5px'}}>
                                    <div style={{flex:1}}>
                                        <div style={{padding:'2px',marginBottom:'5px',width:'80%'}}>
                                            <div style={{fontWeight:'500',fontSize:'16px'}} >Product Variants</div>
                                            <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>Product variants are distinct versions or iterations of a base product, each representing a different combination of customizable options.</div>
                                        </div>
                                    </div>
                                    <Space direction="vertical" size={0} style={{textAlign:'right'}}>
                                        <Button type='default' className="rounded-button" onClick={() => setIsProductVariantsDrawerVisible(true)}>Create Variant</Button>    
                                        <Button type='text' onClick={autoCreateVariants} >Auto-Create</Button>
                                    </Space>
                                </div>
                                <div style={{padding:'2vh 0 3vh 0'}}>
                                    {
                                        formValues?.variants && formValues?.variants.length > 0 &&
                                            <ProductVariantTable formValues={formValues} setFormValues={setFormValues} onEdit={onEditVariant} />
                                    }
                                </div>
                            </div>
                        </div>
                    </Panel>
                    <Panel header='Location' key={'3'} >
                        <div style={{color:'rgba(0,0,0,0.5)'}}>
                            Choose your ideal target for this collaboration.<br/>
                            We will help match you with the best fit partners!
                        </div>
                        <div style={{paddingTop:'20px',paddingBottom:'30px'}}>
                            <div style={{marginBottom:'15px'}}>
                                <div style={{padding:'2px',marginBottom:'15px'}}>
                                    <div style={{fontWeight:'500',fontSize:'17px'}}>Target Regions (Locations)</div>
                                </div>
                                <div>
                                    <div style={{marginBottom:'15px'}}>
                                        <div style={{padding:'2px'}}>
                                            <div style={{lineHeight:1,fontWeight:'normal',fontSize:'14px'}}>Country</div>
                                            <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>Optional</div>
                                        </div>
                                        <Form.Item
                                            name="country"
                                            style={{marginBottom:'5px'}}
                                            initialValue={userCountry?.country}
                                            >
                                            <Select
                                                showSearch
                                                size='large'
                                                style={{ width:'100%',border:'1px solid rgba(24, 144, 255, 0.6)',borderRadius:'10px'}}
                                                bordered={false}
                                                autoFocus={true}
                                                mode='multiple'
                                                placeholder="Search to Select Country"
                                                onChange={(value) => setFormValues({...formValues,country:value})}
                                                >
                                                {
                                                    countryList.length > 0 &&
                                                        countryList.map((value,index) => 
                                                            <Select.Option key={`country-value-${index}`} value={value.name} className="home-onboarding-select-option" >
                                                                {value?.name}
                                                            </Select.Option>
                                                        )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Panel>
                    <Panel header='Media' key={'4'}>
                        <div style={{color:'rgba(0,0,0,0.5)'}}>
                            Choose your ideal target for this collaboration and we will help match you with the best fit partners.
                        </div>
                        <div style={{paddingTop:'20px',paddingBottom:'30px'}}>
                            <div style={{display:'flex',flexWrap:'wrap'}}>
                                {
                                    formValues?.images && formValues?.images.length > 0 &&
                                        formValues?.images.map((value) => 
                                            <div style={{width:window.innerWidth > 1000 ? '20%' : '45%',aspectRatio:1/1,padding:'5px'}}>
                                                <div className='post-image hover-scale-xs' style={{borderRadius:'5px',aspectRatio:1/1,backgroundImage:`url(${value?.image || noPhotoImage})`}} >
                                                </div>
                                            </div>
                                        )
                                }
                                {
                                    uploading &&
                                        <div style={{width:'20%',aspectRatio:1/1,padding:'5px'}}>
                                            <Skeleton.Button style={{aspectRatio:1/1,height:'100%',width:'100%',borderRadius:'5px'}} block={true} active={true}/>
                                        </div>
                                }
                                <div style={{width:window.innerWidth > 1000 ? '20%' : '45%',aspectRatio:1/1,padding:'5px'}}>
                                    <ProductImageUploadSquare imageList={formValues?.images || []} setImageList={(value) => setFormValues({...formValues,images:value})} user={user} setUploading={setUploading} />
                                </div>
                            </div>
                        </div>
                    </Panel>
                </Collapse>
            </Form>
            <ProductOptionsDrawer formValues={formValues} setFormValues={setFormValues} isProductOptionsDrawerVisible={isProductOptionsDrawerVisible} setIsProductOptionsDrawerVisible={setIsProductOptionsDrawerVisible} />
            <ProductVariantsDrawer initialValue={initialVariantValues} formValues={formValues} setFormValues={setFormValues} isProductVariantsDrawerVisible={isProductVariantsDrawerVisible} setIsProductVariantsDrawerVisible={setIsProductVariantsDrawerVisible} setIsProductOptionsDrawerVisible={setIsProductOptionsDrawerVisible} />
        </div>
    )
}

export default CreateNewProductForm;