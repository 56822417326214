import fashion1 from '../images/fashion/fashion1.webp';
import fashion2 from '../images/fashion/fashion2.webp';
import fashion3 from '../images/fashion/fashion3.webp';
import fashion4 from '../images/fashion/fashion4.webp';
import fashion5 from '../images/fashion/fashion5.webp';
import fashion6 from '../images/fashion/fashion6.webp';
import fashion7 from '../images/fashion/fashion7.webp';
import fashion8 from '../images/fashion/fashion8.webp';
import fashion9 from '../images/fashion/fashion9.webp';
import fashion10 from '../images/fashion/fashion10.webp';
import fashion11 from '../images/fashion/fashion11.webp';
import fashion12 from '../images/fashion/fashion12.webp';
import home1 from '../images/home/home1.webp';
import home2 from '../images/home/home2.webp';
import home3 from '../images/home/home3.webp';
import home4 from '../images/home/home4.webp';
import home5 from '../images/home/home5.webp';
import home6 from '../images/home/home6.webp';
import home7 from '../images/home/home7.webp';
import home8 from '../images/home/home8.webp';
import home9 from '../images/home/home9.webp';
import home10 from '../images/home/home10.webp';
import home11 from '../images/home/home11.webp';
import home12 from '../images/home/home12.webp';
import other1 from '../images/other/other1.webp';
import other2 from '../images/other/other2.webp';
import other3 from '../images/other/other3.webp';
import other4 from '../images/other/other4.webp';
import other5 from '../images/other/other5.webp';
import other6 from '../images/other/other6.webp';
import other7 from '../images/other/other7.webp';
import other8 from '../images/other/other8.webp';
import other9 from '../images/other/other9.webp';
import other10 from '../images/other/other10.webp';
import other11 from '../images/other/other11.webp';
import other12 from '../images/other/other12.webp';

export const HomeImages = {
    'fashion':[
        {
            'name':'Fashion',
            'imgUrl': fashion1,
            'productCount': 3,
            'boxes':[
                {
                    'label_name': 'Shirt',
                    'bbox_x': 91,
                    'bbox_y': 156,
                    'bbox_width': 225,
                    'bbox_height': 202,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Belt',
                    'bbox_x': 89,
                    'bbox_y': 310,
                    'bbox_width': 153,
                    'bbox_height': 54,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Pants',
                    'bbox_x': 0,
                    'bbox_y': 329,
                    'bbox_width': 243,
                    'bbox_height': 171,
                    'image_width': 334,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Fashion',
            'imgUrl': fashion2,
            'productCount': 5,
            'boxes':[
                {
                    'label_name': 'Purse',
                    'bbox_x': 47,
                    'bbox_y': 356,
                    'bbox_width': 74,
                    'bbox_height': 76,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Shoes',
                    'bbox_x': 119,
                    'bbox_y': 442,
                    'bbox_width': 62,
                    'bbox_height': 54,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Shoes',
                    'bbox_x': 272,
                    'bbox_y': 388,
                    'bbox_width': 54,
                    'bbox_height': 54,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Socks',
                    'bbox_x': 246,
                    'bbox_y': 369,
                    'bbox_width': 48,
                    'bbox_height': 43,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Socks',
                    'bbox_x': 132,
                    'bbox_y': 407,
                    'bbox_width': 36,
                    'bbox_height': 46,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Coat',
                    'bbox_x': 59,
                    'bbox_y': 209,
                    'bbox_width': 174,
                    'bbox_height': 207,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Dress',
                    'bbox_x': 89,
                    'bbox_y': 218,
                    'bbox_width': 120,
                    'bbox_height': 142,
                    'image_width': 334,
                    'image_height': 500
                },
            ]
        },
        {
            'name':'Fashion',
            'imgUrl': fashion3,
            'productCount': 5,
            'boxes':[
                {
                    'label_name': 'Pants',
                    'bbox_x': 103,
                    'bbox_y': 236,
                    'bbox_width': 117,
                    'bbox_height': 219,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Sunglasses',
                    'bbox_x': 137,
                    'bbox_y': 126,
                    'bbox_width': 39,
                    'bbox_height': 19,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Shirt',
                    'bbox_x': 65,
                    'bbox_y': 146,
                    'bbox_width': 166,
                    'bbox_height': 103,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Disco Ball',
                    'bbox_x': 159,
                    'bbox_y': 397,
                    'bbox_width': 80,
                    'bbox_height': 79,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Car',
                    'bbox_x': 10,
                    'bbox_y': 170,
                    'bbox_width': 314,
                    'bbox_height': 196,
                    'image_width': 334,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Fashion',
            'imgUrl': fashion4,
            'productCount': 9,
            'boxes':[
                {
                    'label_name': 'Sunglasses',
                    'bbox_x': 403,
                    'bbox_y': 57,
                    'bbox_width': 69,
                    'bbox_height': 40,
                    'image_width': 750,
                    'image_height': 500
                },
                {
                    'label_name': 'Bag',
                    'bbox_x': 228,
                    'bbox_y': 233,
                    'bbox_width': 91,
                    'bbox_height': 68,
                    'image_width': 750,
                    'image_height': 500
                },
                {
                    'label_name': 'Heels',
                    'bbox_x': 455,
                    'bbox_y': 369,
                    'bbox_width': 74,
                    'bbox_height': 103,
                    'image_width': 750,
                    'image_height': 500
                },
                {
                    'label_name': 'Heels',
                    'bbox_x': 385,
                    'bbox_y': 390,
                    'bbox_width': 84,
                    'bbox_height': 71,
                    'image_width': 750,
                    'image_height': 500
                },
                {
                    'label_name': 'Pants',
                    'bbox_x': 311,
                    'bbox_y': 233,
                    'bbox_width': 224,
                    'bbox_height': 147,
                    'image_width': 750,
                    'image_height': 500
                },
                {
                    'label_name': 'Shirt',
                    'bbox_x': 313,
                    'bbox_y': 100,
                    'bbox_width': 178,
                    'bbox_height': 128,
                    'image_width': 750,
                    'image_height': 500
                },
                {
                    'label_name': 'Beanbag',
                    'bbox_x': 0,
                    'bbox_y': 346,
                    'bbox_width': 302,
                    'bbox_height': 154,
                    'image_width': 750,
                    'image_height': 500
                },
                {
                    'label_name': 'Coffee Table',
                    'bbox_x': 105,
                    'bbox_y': 266,
                    'bbox_width': 330,
                    'bbox_height': 184,
                    'image_width': 750,
                    'image_height': 500
                },
                {
                    'label_name': 'Chair',
                    'bbox_x': 0,
                    'bbox_y': 163,
                    'bbox_width': 173,
                    'bbox_height': 201,
                    'image_width': 750,
                    'image_height': 500
                },
                {
                    'label_name': 'Chair',
                    'bbox_x': 161,
                    'bbox_y': 135,
                    'bbox_width': 168,
                    'bbox_height': 133,
                    'image_width': 750,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Fashion',
            'imgUrl': fashion5,
            'productCount': 4,
            'boxes':[
                {
                    'label_name': 'Purse',
                    'bbox_x': 163,
                    'bbox_y': 47,
                    'bbox_width': 154,
                    'bbox_height': 214,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Watch',
                    'bbox_x': 205,
                    'bbox_y': 0,
                    'bbox_width': 49,
                    'bbox_height': 18,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Shoes',
                    'bbox_x': 134,
                    'bbox_y': 351,
                    'bbox_width': 155,
                    'bbox_height': 131,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Shoes',
                    'bbox_x': 15,
                    'bbox_y': 365,
                    'bbox_width': 161,
                    'bbox_height': 97,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Dress',
                    'bbox_x': 0,
                    'bbox_y': 0,
                    'bbox_width': 329,
                    'bbox_height': 330,
                    'image_width': 334,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Fashion',
            'imgUrl': fashion6,
            'productCount': 3,
            'boxes':[
                {
                    'label_name': 'Hat',
                    'bbox_x': 99,
                    'bbox_y': 69,
                    'bbox_width': 181,
                    'bbox_height': 106,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Coat',
                    'bbox_x': 41,
                    'bbox_y': 174,
                    'bbox_width': 277,
                    'bbox_height': 314,
                    'image_width': 334,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Fashion',
            'imgUrl': fashion7,
            'productCount': 6,
            'boxes':[
                {
                    'label_name': 'Coat',
                    'bbox_x': 68,
                    'bbox_y': 159,
                    'bbox_width': 201,
                    'bbox_height': 87,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Shirt',
                    'bbox_x': 156,
                    'bbox_y': 180,
                    'bbox_width': 48,
                    'bbox_height': 62,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Pants',
                    'bbox_x': 116,
                    'bbox_y': 161,
                    'bbox_width': 136,
                    'bbox_height': 246,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Shoes',
                    'bbox_x': 243,
                    'bbox_y': 106,
                    'bbox_width': 53,
                    'bbox_height': 78,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Shoes',
                    'bbox_x': 133,
                    'bbox_y': 414,
                    'bbox_width': 62,
                    'bbox_height': 42,
                    'image_width': 334,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Fashion',
            'imgUrl': fashion8,
            'productCount': 5,
            'boxes':[
                {
                    'label_name': 'Sunglasses',
                    'bbox_x': 130,
                    'bbox_y': 32,
                    'bbox_width': 49,
                    'bbox_height': 37,
                    'image_width': 331,
                    'image_height': 500
                },
                {
                    'label_name': 'Shirt',
                    'bbox_x': 121,
                    'bbox_y': 103,
                    'bbox_width': 68,
                    'bbox_height': 96,
                    'image_width': 331,
                    'image_height': 500
                },
                {
                    'label_name': 'Dress',
                    'bbox_x': 56,
                    'bbox_y': 96,
                    'bbox_width': 232,
                    'bbox_height': 404,
                    'image_width': 331,
                    'image_height': 500
                },
                {
                    'label_name': 'Shorts',
                    'bbox_x': 99,
                    'bbox_y': 186,
                    'bbox_width': 122,
                    'bbox_height': 106,
                    'image_width': 331,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Fashion',
            'imgUrl': fashion9,
            'productCount': 13,
            'boxes':[
                {
                    'label_name': 'Hat',
                    'bbox_x': 98,
                    'bbox_y': 60,
                    'bbox_width': 70,
                    'bbox_height': 43,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Hat',
                'bbox_x': 203,
                'bbox_y': 86,
                'bbox_width': 95,
                'bbox_height': 36,
                'image_width': 334,
                'image_height': 500},
                {
                    'label_name': 'Sunglasses',
                    'bbox_x': 214,
                    'bbox_y': 119,
                    'bbox_width': 51,
                    'bbox_height': 21,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Sunglasses',
                    'bbox_x': 114,
                    'bbox_y': 97,
                    'bbox_width': 46,
                    'bbox_height': 24,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Shirt',
                    'bbox_x': 189,
                    'bbox_y': 147,
                    'bbox_width': 123,
                    'bbox_height': 110,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Shirt',
                    'bbox_x': 38,
                    'bbox_y': 111,
                    'bbox_width': 134,
                    'bbox_height': 99,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Pants',
                    'bbox_x': 36,
                    'bbox_y': 191,
                    'bbox_width': 141,
                    'bbox_height': 135,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Shorts',
                    'bbox_x': 117,
                    'bbox_y': 231,
                    'bbox_width': 169,
                    'bbox_height': 88,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Shoes',
                    'bbox_x': 41,
                    'bbox_y': 411,
                    'bbox_width': 123,
                    'bbox_height': 79,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Shoes',
                    'bbox_x': 38,
                    'bbox_y': 364,
                    'bbox_width': 89,
                    'bbox_height': 70,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Shoes',
                    'bbox_x': 52,
                    'bbox_y': 320,
                    'bbox_width': 53,
                    'bbox_height': 39,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Socks',
                    'bbox_x': 103,
                    'bbox_y': 361,
                    'bbox_width': 68,
                    'bbox_height': 87,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Socks',
                    'bbox_x': 88,
                    'bbox_y': 324,
                    'bbox_width': 36,
                    'bbox_height': 71,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Radio',
                    'bbox_x': 53,
                    'bbox_y': 62,
                    'bbox_width': 65,
                    'bbox_height': 94,
                    'image_width': 334,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Fashion',
            'imgUrl': fashion10,
            'productCount': 4,
            'boxes':[
                {
                    'label_name': 'Radio',
                    'bbox_x': 92,
                    'bbox_y': 131,
                    'bbox_width': 93,
                    'bbox_height': 99,
                    'image_width': 331,
                    'image_height': 500
                },
                {
                    'label_name': 'Pants',
                    'bbox_x': 77,
                    'bbox_y': 221,
                    'bbox_width': 236,
                    'bbox_height': 113,
                    'image_width': 331,
                    'image_height': 500
                },
                {
                    'label_name': 'Shoes',
                    'bbox_x': 95,
                    'bbox_y': 342,
                    'bbox_width': 114,
                    'bbox_height': 95,
                    'image_width': 331,
                    'image_height': 500
                },
                {
                    'label_name': 'Shoes',
                    'bbox_x': 165,
                    'bbox_y': 295,
                    'bbox_width': 76,
                    'bbox_height': 70,
                    'image_width': 331,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Fashion',
            'imgUrl': fashion11,
            'productCount': 6,
            'boxes':[
                {
                    'label_name': 'Watch',
                    'bbox_x': 157,
                    'bbox_y': 367,
                    'bbox_width': 44,
                    'bbox_height': 32,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Jacket',
                    'bbox_x': 15,
                    'bbox_y': 170,
                    'bbox_width': 190,
                    'bbox_height': 185,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Jacket',
                    'bbox_x': 144,
                    'bbox_y': 194,
                    'bbox_width': 189,
                    'bbox_height': 177,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Dress',
                    'bbox_x': 32,
                    'bbox_y': 214,
                    'bbox_width': 123,
                    'bbox_height': 249,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Pants',
                    'bbox_x': 164,
                    'bbox_y': 305,
                    'bbox_width': 169,
                    'bbox_height': 195,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Light',
                    'bbox_x': 152,
                    'bbox_y': 26,
                    'bbox_width': 85,
                    'bbox_height': 83,
                    'image_width': 333,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Fashion',
            'imgUrl': fashion12,
            'productCount': 6,
            'boxes':[
                {
                    'label_name': 'Bag',
                    'bbox_x': 36,
                    'bbox_y': 111,
                    'bbox_width': 181,
                    'bbox_height': 120,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Jacket',
                    'bbox_x': 84,
                    'bbox_y': 118,
                    'bbox_width': 235,
                    'bbox_height': 144,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Jacket',
                    'bbox_x': 3,
                    'bbox_y': 220,
                    'bbox_width': 279,
                    'bbox_height': 98,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Shoes',
                    'bbox_x': 0,
                    'bbox_y': 360,
                    'bbox_width': 68,
                    'bbox_height': 117,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Pants',
                    'bbox_x': 30,
                    'bbox_y': 267,
                    'bbox_width': 208,
                    'bbox_height': 143,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Shoes',
                    'bbox_x': 134,
                    'bbox_y': 376,
                    'bbox_width': 108,
                    'bbox_height': 51,
                    'image_width': 333,
                    'image_height': 500
                }
            ]
        }
    ],
    'home':[
        {
            'name':'Home',
            'imgUrl': home1,
            'productCount': 9,
            'boxes':[
                {
                    'label_name': 'Lamp',
                     'bbox_x': 321,
                     'bbox_y': 69,
                     'bbox_width': 150,
                     'bbox_height': 186,
                     'image_width': 481,
                     'image_height': 500
                },
                {
                    'label_name': 'Painting',
                    'bbox_x': 86,
                    'bbox_y': 14,
                    'bbox_width': 312,
                    'bbox_height': 168,
                    'image_width': 481,
                    'image_height': 500
                },
                {
                    'label_name': 'Stool',
                    'bbox_x': 83,
                    'bbox_y': 325,
                    'bbox_width': 96,
                    'bbox_height': 151,
                    'image_width': 481,
                    'image_height': 500
                },
                {
                    'label_name': 'Headphones',
                    'bbox_x': 143,
                    'bbox_y': 445,
                    'bbox_width': 85,
                    'bbox_height': 48,
                    'image_width': 481,
                    'image_height': 500
                },
                {
                    'label_name': 'Pillow',
                    'bbox_x': 21,
                    'bbox_y': 239,
                    'bbox_width': 460,
                    'bbox_height': 197,
                    'image_width': 481,
                    'image_height': 500
                },
                {
                    'label_name': 'Couch',
                    'bbox_x': 370,
                    'bbox_y': 262,
                    'bbox_width': 106,
                    'bbox_height': 76,
                    'image_width': 481,
                    'image_height': 500
                },
                {
                    'label_name': 'Blanket',
                    'bbox_x': 381,
                    'bbox_y': 343,
                    'bbox_width': 100,
                    'bbox_height': 157,
                    'image_width': 481,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Home',
            'imgUrl': home2,
            'productCount': 7,
            'boxes':[
                {
                    'label_name': 'Chair',
                    'bbox_x': 158,
                    'bbox_y': 276,
                    'bbox_width': 114,
                    'bbox_height': 149,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Chair',
                    'bbox_x': 48,
                    'bbox_y': 274,
                    'bbox_width': 116,
                    'bbox_height': 149,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Chair',
                    'bbox_x': 35,
                    'bbox_y': 258,
                    'bbox_width': 63,
                    'bbox_height': 131,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Plant',
                    'bbox_x': 115,
                    'bbox_y': 216,
                    'bbox_width': 64,
                    'bbox_height': 71,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Table',
                    'bbox_x': 74,
                    'bbox_y': 264,
                    'bbox_width': 191,
                    'bbox_height': 103,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Rug',
                    'bbox_x': 9,
                    'bbox_y': 330,
                    'bbox_width': 314,
                    'bbox_height': 116,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Cabinet',
                    'bbox_x': 248,
                    'bbox_y': 259,
                    'bbox_width': 85,
                    'bbox_height': 89,
                    'image_width': 333,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Home',
            'imgUrl': home3,
            'productCount': 5,
            'boxes':[
                {
                    'label_name': 'Coffee Table',
                    'bbox_x': 0,
                    'bbox_y': 293,
                    'bbox_width': 216,
                    'bbox_height': 182,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Couch',
                    'bbox_x': 91,
                    'bbox_y': 205,
                    'bbox_width': 242,
                    'bbox_height': 186,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Pillow',
                    'bbox_x': 153,
                    'bbox_y': 224,
                    'bbox_width': 101,
                    'bbox_height': 72,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Plant',
                    'bbox_x': 209,
                    'bbox_y': 106,
                    'bbox_width': 77,
                    'bbox_height': 121,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Plant',
                    'bbox_x': 275,
                    'bbox_y': 106,
                    'bbox_width': 58,
                    'bbox_height': 111,
                    'image_width': 333,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Home',
            'imgUrl': home4,
            'productCount': 6,
            'boxes':[
                {
                    'label_name': 'Rug',
                    'bbox_x': 73,
                    'bbox_y': 354,
                    'bbox_width': 260,
                    'bbox_height': 97,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Pillow',
                    'bbox_x': 89,
                    'bbox_y': 351,
                    'bbox_width': 126,
                    'bbox_height': 54,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Pillow',
                    'bbox_x': 201,
                    'bbox_y': 245,
                    'bbox_width': 74,
                    'bbox_height': 63,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Pillow',
                    'bbox_x': 257,
                    'bbox_y': 264,
                    'bbox_width': 75,
                    'bbox_height': 42,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Plant',
                    'bbox_x': 269,
                    'bbox_y': 230,
                    'bbox_width': 51,
                    'bbox_height': 96,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Couch',
                    'bbox_x': 22,
                    'bbox_y': 227,
                    'bbox_width': 311,
                    'bbox_height': 158,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Coffee Table',
                    'bbox_x': 228,
                    'bbox_y': 316,
                    'bbox_width': 105,
                    'bbox_height': 113,
                    'image_width': 333,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Home',
            'imgUrl': home5,
            'productCount': 10,
            'boxes':[
                {
                    'label_name': 'Light',
                    'bbox_x': 71,
                    'bbox_y': 0,
                    'bbox_width': 62,
                    'bbox_height': 259,
                    'image_width': 324,
                    'image_height': 500
                },
                {
                    'label_name': 'Side Table',
                    'bbox_x': 23,
                    'bbox_y': 321,
                    'bbox_width': 121,
                    'bbox_height': 161,
                    'image_width': 324,
                    'image_height': 500
                },
                {
                    'label_name': 'Bed',
                    'bbox_x': 104,
                    'bbox_y': 242,
                    'bbox_width': 220,
                    'bbox_height': 258,
                    'image_width': 324,
                    'image_height': 500
                },
                {
                    'label_name': 'Pillow',
                    'bbox_x': 223,
                    'bbox_y': 263,
                    'bbox_width': 100,
                    'bbox_height': 99,
                    'image_width': 324,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Home',
            'imgUrl': home6,
            'productCount': 8,
            'boxes':[
                {
                    'label_name': 'Light',
                    'bbox_x': 81,
                    'bbox_y': 76,
                    'bbox_width': 69,
                    'bbox_height': 96,
                    'image_width': 400,
                    'image_height': 500
                },
                {
                    'label_name': 'Light',
                    'bbox_x': 131,
                    'bbox_y': 109,
                    'bbox_width': 86,
                    'bbox_height': 54,
                    'image_width': 400,
                    'image_height': 500
                },
                {
                    'label_name': 'Light',
                    'bbox_x': 94,
                    'bbox_y': 0,
                    'bbox_width': 144,
                    'bbox_height': 191,
                    'image_width': 400,
                    'image_height': 500
                },
                {
                    'label_name': 'Table',
                    'bbox_x': 9,
                    'bbox_y': 255,
                    'bbox_width': 277,
                    'bbox_height': 206,
                    'image_width': 400,
                    'image_height': 500
                },
                {
                    'label_name': 'Chair',
                    'bbox_x': 232,
                    'bbox_y': 284,
                    'bbox_width': 139,
                    'bbox_height': 211,
                    'image_width': 400,
                    'image_height': 500
                },
                {
                    'label_name': 'Shelves',
                    'bbox_x': 230,
                    'bbox_y': 107,
                    'bbox_width': 170,
                    'bbox_height': 312,
                    'image_width': 400,
                    'image_height': 500
                },
                {
                    'label_name': 'Bowl',
                    'bbox_x': 259,
                    'bbox_y': 204,
                    'bbox_width': 101,
                    'bbox_height': 44,
                    'image_width': 400,
                    'image_height': 500
                },
                {
                    'label_name': 'Photo',
                    'bbox_x': 289,
                    'bbox_y': 268,
                    'bbox_width': 59,
                    'bbox_height': 61,
                    'image_width': 400,
                    'image_height': 500
                },
                {
                    'label_name': 'Photo',
                    'bbox_x': 338,
                    'bbox_y': 252,
                    'bbox_width': 53,
                    'bbox_height': 82,
                    'image_width': 400,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Home',
            'imgUrl': home7,
            'productCount': 6,
            'boxes':[
                {
                    'label_name': 'Photo',
                    'bbox_x': 53,
                    'bbox_y': 117,
                    'bbox_width': 101,
                    'bbox_height': 122,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Photo',
                    'bbox_x': 156,
                    'bbox_y': 114,
                    'bbox_width': 100,
                    'bbox_height': 128,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Photo',
                    'bbox_x': 52,
                    'bbox_y': 32,
                    'bbox_width': 68,
                    'bbox_height': 81,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Photo',
                    'bbox_x': 122,
                    'bbox_y': 30,
                    'bbox_width': 65,
                    'bbox_height': 84,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Photo',
                    'bbox_x': 189,
                    'bbox_y': 32,
                    'bbox_width': 72,
                    'bbox_height': 79,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Stool',
                    'bbox_x': 61,
                    'bbox_y': 282,
                    'bbox_width': 101,
                    'bbox_height': 137,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Pillow',
                    'bbox_x': 166,
                    'bbox_y': 292,
                    'bbox_width': 101,
                    'bbox_height': 88,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Pillow',
                    'bbox_x': 235,
                    'bbox_y': 323,
                    'bbox_width': 98,
                    'bbox_height': 75,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Bed',
                    'bbox_x': 158,
                    'bbox_y': 361,
                    'bbox_width': 175,
                    'bbox_height': 101,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Mug',
                    'bbox_x': 226,
                    'bbox_y': 381,
                    'bbox_width': 38,
                    'bbox_height': 36,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Teddy Bear',
                    'bbox_x': 0,
                    'bbox_y': 329,
                    'bbox_width': 44,
                    'bbox_height': 80,
                    'image_width': 333,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Home',
            'imgUrl': home8,
            'productCount': 6,
            'boxes':[
                {
                    'label_name': 'Fireplace',
                    'bbox_x': 151,
                    'bbox_y': 253,
                    'bbox_width': 101,
                    'bbox_height': 108,
                    'image_width': 337,
                    'image_height': 500
                },
                {
                    'label_name': 'Firewood Rack',
                    'bbox_x': 72,
                    'bbox_y': 183,
                    'bbox_width': 90,
                    'bbox_height': 207,
                    'image_width': 337,
                    'image_height': 500
                },
                {
                    'label_name': 'Blanket',
                    'bbox_x': 21,
                    'bbox_y': 395,
                    'bbox_width': 164,
                    'bbox_height': 92,
                    'image_width': 337,
                    'image_height': 500
                },
                {
                    'label_name': 'Stool',
                    'bbox_x': 153,
                    'bbox_y': 403,
                    'bbox_width': 141,
                    'bbox_height': 97,
                    'image_width': 337,
                    'image_height': 500
                },
                {
                    'label_name': 'Rug',
                    'bbox_x': 1,
                    'bbox_y': 387,
                    'bbox_width': 222,
                    'bbox_height': 113,
                    'image_width': 337,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Home',
            'imgUrl': home9,
            'productCount': 5,
            'boxes':[
                {
                    'label_name': 'Painting',
                    'bbox_x': 122,
                    'bbox_y': 43,
                    'bbox_width': 117,
                    'bbox_height': 138,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Plant',
                    'bbox_x': 195,
                    'bbox_y': 223,
                    'bbox_width': 102,
                    'bbox_height': 98,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Chair',
                    'bbox_x': 6,
                    'bbox_y': 294,
                    'bbox_width': 197,
                    'bbox_height': 204,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Table',
                    'bbox_x': 74,
                    'bbox_y': 280,
                    'bbox_width': 259,
                    'bbox_height': 220,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Chair',
                    'bbox_x': 62,
                    'bbox_y': 271,
                    'bbox_width': 136,
                    'bbox_height': 198,
                    'image_width': 333,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Home',
            'imgUrl': home10,
            'productCount': 12,
            'boxes':[
                {
                    'label_name': 'Photo',
                    'bbox_x': 0,
                    'bbox_y': 56,
                    'bbox_width': 94,
                    'bbox_height': 120,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Photo',
                    'bbox_x': 93,
                    'bbox_y': 27,
                    'bbox_width': 72,
                    'bbox_height': 72,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Laptop',
                    'bbox_x': 65,
                    'bbox_y': 270,
                    'bbox_width': 125,
                    'bbox_height': 102,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Pillow',
                    'bbox_x': 40,
                    'bbox_y': 168,
                    'bbox_width': 159,
                    'bbox_height': 129,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Slippers',
                    'bbox_x': 203,
                    'bbox_y': 420,
                    'bbox_width': 106,
                    'bbox_height': 65,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Vase',
                    'bbox_x': 243,
                    'bbox_y': 242,
                    'bbox_width': 90,
                    'bbox_height': 149,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Photo',
                    'bbox_x': 99,
                    'bbox_y': 107,
                    'bbox_width': 87,
                    'bbox_height': 69,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Stool',
                    'bbox_x': 202,
                    'bbox_y': 238,
                    'bbox_width': 88,
                    'bbox_height': 145,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Bed',
                    'bbox_x': 0,
                    'bbox_y': 168,
                    'bbox_width': 209,
                    'bbox_height': 332,
                    'image_width': 333,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Home',
            'imgUrl': home11,
            'productCount': 11,
            'boxes':[
                {
                    'label_name': 'Bed',
                    'bbox_x': 123,
                    'bbox_y': 250,
                    'bbox_width': 44,
                    'bbox_height': 82,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Chair',
                    'bbox_x': 11,
                    'bbox_y': 297,
                    'bbox_width': 91,
                    'bbox_height': 201,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Chair',
                    'bbox_x': 71,
                    'bbox_y': 321,
                    'bbox_width': 106,
                    'bbox_height': 179,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Chair',
                    'bbox_x': 160,
                    'bbox_y': 374,
                    'bbox_width': 145,
                    'bbox_height': 126,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Kettle',
                    'bbox_x': 268,
                    'bbox_y': 303,
                    'bbox_width': 62,
                    'bbox_height': 84,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Plant',
                    'bbox_x': 175,
                    'bbox_y': 280,
                    'bbox_width': 37,
                    'bbox_height': 49,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Plant',
                    'bbox_x': 200,
                    'bbox_y': 288,
                    'bbox_width': 56,
                    'bbox_height': 61,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Table',
                    'bbox_x': 7,
                    'bbox_y': 292,
                    'bbox_width': 326,
                    'bbox_height': 176,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Light',
                    'bbox_x': 254,
                    'bbox_y': 4,
                    'bbox_width': 74,
                    'bbox_height': 121,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Light',
                    'bbox_x': 140,
                    'bbox_y': 38,
                    'bbox_width': 55,
                    'bbox_height': 93,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Light',
                    'bbox_x': 183,
                    'bbox_y': 61,
                    'bbox_width': 38,
                    'bbox_height': 90,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Light',
                    'bbox_x': 225,
                    'bbox_y': 43,
                    'bbox_width': 51,
                    'bbox_height': 82,
                    'image_width': 333,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Home',
            'imgUrl': home12,
            'productCount': 4,
            'boxes':[
                {
                    'label_name': 'Light',
                    'bbox_x': 57,
                    'bbox_y': 63,
                    'bbox_width': 93,
                    'bbox_height': 138,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Light',
                    'bbox_x': 0,
                    'bbox_y': 0,
                    'bbox_width': 65,
                    'bbox_height': 88,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Plant',
                    'bbox_x': 150,
                    'bbox_y': 44,
                    'bbox_width': 129,
                    'bbox_height': 106,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Plant',
                    'bbox_x': 143,
                    'bbox_y': 177,
                    'bbox_width': 158,
                    'bbox_height': 111,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Plant',
                    'bbox_x': 46,
                    'bbox_y': 196,
                    'bbox_width': 122,
                    'bbox_height': 261,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Plant',
                    'bbox_x': 36,
                    'bbox_y': 373,
                    'bbox_width': 75,
                    'bbox_height': 83,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Table',
                    'bbox_x': 10,
                    'bbox_y': 431,
                    'bbox_width': 242,
                    'bbox_height': 69,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Chair',
                    'bbox_x': 243,
                    'bbox_y': 410,
                    'bbox_width': 90,
                    'bbox_height': 90,
                    'image_width': 333,
                    'image_height': 500
                }
            ]
        },
    ],
    'other':[
        {
            'name':'Other',
            'imgUrl': other1,
            'productCount': 7,
            'boxes':[
                {
                    'label_name': 'Glasses',
                    'bbox_x': 111,
                    'bbox_y': 163,
                    'bbox_width': 161,
                    'bbox_height': 125,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Glasses',
                    'bbox_x': 71,
                    'bbox_y': 109,
                    'bbox_width': 116,
                    'bbox_height': 67,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Glasses',
                    'bbox_x': 48,
                    'bbox_y': 241,
                    'bbox_width': 157,
                    'bbox_height': 88,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Glasses',
                    'bbox_x': 282,
                    'bbox_y': 222,
                    'bbox_width': 50,
                    'bbox_height': 131,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Purse',
                    'bbox_x': 169,
                    'bbox_y': 49,
                    'bbox_width': 120,
                    'bbox_height': 104,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Glasses Case',
                    'bbox_x': 22,
                    'bbox_y': 45,
                    'bbox_width': 144,
                    'bbox_height': 87,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Glasses Case',
                    'bbox_x': 277,
                    'bbox_y': 67,
                    'bbox_width': 57,
                    'bbox_height': 154,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Glasses Case',
                    'bbox_x': 137,
                    'bbox_y': 302,
                    'bbox_width': 118,
                    'bbox_height': 144,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Glasses Case',
                    'bbox_x': 147,
                    'bbox_y': 0,
                    'bbox_width': 168,
                    'bbox_height': 58,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Glasses Case',
                    'bbox_x': 4,
                    'bbox_y': 0,
                    'bbox_width': 138,
                    'bbox_height': 82,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Glasses Case',
                    'bbox_x': 17,
                    'bbox_y': 182,
                    'bbox_width': 81,
                    'bbox_height': 139,
                    'image_width': 334,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Other',
            'imgUrl': other2,
            'productCount': 10,
            'boxes':[
                {
                    'label_name': 'Mouse',
                    'bbox_x': 143,
                    'bbox_y': 335,
                    'bbox_width': 62,
                    'bbox_height': 21,
                    'image_width': 334,
                    'image_height': 500
                },
                {
                    'label_name': 'Plant',
                    'bbox_x': 137,
                    'bbox_y': 257,
                    'bbox_width': 43,
                    'bbox_height': 54,
                    'image_width': 334,
                    'image_height': 500
                },
                    {
                    'label_name': 'Lamp',
                    'bbox_x': 95,
                    'bbox_y': 212,
                    'bbox_width': 82,
                    'bbox_height': 90,
                    'image_width': 334,
                    'image_height': 500
                },
                    {
                    'label_name': 'Laptop',
                    'bbox_x': 126,
                    'bbox_y': 250,
                    'bbox_width': 108,
                    'bbox_height': 89,
                    'image_width': 334,
                    'image_height': 500
                },
                    {
                    'label_name': 'Tablet',
                    'bbox_x': 214,
                    'bbox_y': 273,
                    'bbox_width': 87,
                    'bbox_height': 79,
                    'image_width': 334,
                    'image_height': 500
                },
                    {
                    'label_name': 'Keyboard',
                    'bbox_x': 88,
                    'bbox_y': 310,
                    'bbox_width': 57,
                    'bbox_height': 34,
                    'image_width': 334,
                    'image_height': 500
                },
                    {
                    'label_name': 'Mousepad',
                    'bbox_x': 72,
                    'bbox_y': 301,
                    'bbox_width': 192,
                    'bbox_height': 71,
                    'image_width': 334,
                    'image_height': 500
                },
                    {
                    'label_name': 'Desk',
                    'bbox_x': 44,
                    'bbox_y': 290,
                    'bbox_width': 290,
                    'bbox_height': 199,
                    'image_width': 334,
                    'image_height': 500
                },
                    {
                    'label_name': 'Chair',
                    'bbox_x': 0,
                    'bbox_y': 250,
                    'bbox_width': 125,
                    'bbox_height': 249,
                    'image_width': 334,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Other',
            'imgUrl': other3,
            'productCount': 6,
            'boxes':[
                {
                    'label_name': 'Camera',
                    'bbox_x': 100,
                    'bbox_y': 229,
                    'bbox_width': 108,
                    'bbox_height': 71,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Photo',
                    'bbox_x': 137,
                    'bbox_y': 145,
                    'bbox_width': 143,
                    'bbox_height': 114,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Photo Album',
                    'bbox_x': 95,
                    'bbox_y': 7,
                    'bbox_width': 111,
                    'bbox_height': 137,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Table',
                    'bbox_x': 0,
                    'bbox_y': 286,
                    'bbox_width': 330,
                    'bbox_height': 213,
                    'image_width': 333,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Other',
            'imgUrl': other4,
            'productCount': '15+',
            'boxes':[
                {
                    'label_name': 'Basket',
                    'bbox_x': 136,
                    'bbox_y': 388,
                    'bbox_width': 99,
                    'bbox_height': 94,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Hanger Rack',
                    'bbox_x': 15,
                    'bbox_y': 133,
                    'bbox_width': 208,
                    'bbox_height': 328,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Coat',
                    'bbox_x': 50,
                    'bbox_y': 149,
                    'bbox_width': 67,
                    'bbox_height': 287,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Side Table',
                    'bbox_x': 3,
                    'bbox_y': 296,
                    'bbox_width': 70,
                    'bbox_height': 122,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Coat',
                    'bbox_x': 75,
                    'bbox_y': 149,
                    'bbox_width': 57,
                    'bbox_height': 263,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Coat',
                    'bbox_x': 164,
                    'bbox_y': 157,
                    'bbox_width': 44,
                    'bbox_height': 209,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Coat',
                    'bbox_x': 105,
                    'bbox_y': 158,
                    'bbox_width': 51,
                    'bbox_height': 227,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Coat',
                    'bbox_x': 140,
                    'bbox_y': 167,
                    'bbox_width': 30,
                    'bbox_height': 190,
                    'image_width': 333,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Other',
            'imgUrl': other5,
            'productCount': 12,
            'boxes':[
                {
                    'label_name': 'Coat',
                    'bbox_x': 59,
                    'bbox_y': 296,
                    'bbox_width': 96,
                    'bbox_height': 64,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Shirt',
                    'bbox_x': 228,
                    'bbox_y': 304,
                    'bbox_width': 103,
                    'bbox_height': 83,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Jeans',
                    'bbox_x': 60,
                    'bbox_y': 368,
                    'bbox_width': 96,
                    'bbox_height': 132,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Bag',
                    'bbox_x': 70,
                    'bbox_y': 318,
                    'bbox_width': 76,
                    'bbox_height': 64,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Backpack',
                    'bbox_x': 226,
                    'bbox_y': 314,
                    'bbox_width': 47,
                    'bbox_height': 86,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Jeans',
                    'bbox_x': 235,
                    'bbox_y': 376,
                    'bbox_width': 85,
                    'bbox_height': 99,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Hat',
                    'bbox_x': 73,
                    'bbox_y': 253,
                    'bbox_width': 56,
                    'bbox_height': 31,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Hat',
                    'bbox_x': 250,
                    'bbox_y': 262,
                    'bbox_width': 53,
                    'bbox_height': 41,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Bird',
                    'bbox_x': 0,
                    'bbox_y': 7,
                    'bbox_width': 85,
                    'bbox_height': 147,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Bird',
                    'bbox_x': 88,
                    'bbox_y': 33,
                    'bbox_width': 39,
                    'bbox_height': 107,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Bird',
                    'bbox_x': 163,
                    'bbox_y': 58,
                    'bbox_width': 43,
                    'bbox_height': 90,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Bird',
                    'bbox_x': 199,
                    'bbox_y': 74,
                    'bbox_width': 48,
                    'bbox_height': 72,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Bird',
                    'bbox_x': 299,
                    'bbox_y': 62,
                    'bbox_width': 34,
                    'bbox_height': 93,
                    'image_width': 333,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Other',
            'imgUrl': other6,
            'productCount': 10,
            'boxes':[
                {
                    'label_name': 'Monitor',
                    'bbox_x': 196,
                    'bbox_y': 116,
                    'bbox_width': 141,
                    'bbox_height': 234,
                    'image_width': 750,
                    'image_height': 500
                },
                    {
                    'label_name': 'Monitor',
                    'bbox_x': 329,
                    'bbox_y': 163,
                    'bbox_width': 187,
                    'bbox_height': 147,
                    'image_width': 750,
                    'image_height': 500
                },
                    {
                    'label_name': 'Light',
                    'bbox_x': 223,
                    'bbox_y': 30,
                    'bbox_width': 99,
                    'bbox_height': 104,
                    'image_width': 750,
                    'image_height': 500
                },
                    {
                    'label_name': 'Light',
                    'bbox_x': 570,
                    'bbox_y': 43,
                    'bbox_width': 105,
                    'bbox_height': 152,
                    'image_width': 750,
                    'image_height': 500
                },
                    {
                    'label_name': 'Mic',
                    'bbox_x': 525,
                    'bbox_y': 220,
                    'bbox_width': 102,
                    'bbox_height': 250,
                    'image_width': 750,
                    'image_height': 500
                },
                    {
                    'label_name': 'Computer',
                    'bbox_x': 496,
                    'bbox_y': 207,
                    'bbox_width': 175,
                    'bbox_height': 206,
                    'image_width': 750,
                    'image_height': 500
                },
                    {
                    'label_name': 'Keyboard',
                    'bbox_x': 267,
                    'bbox_y': 347,
                    'bbox_width': 115,
                    'bbox_height': 53,
                    'image_width': 750,
                    'image_height': 500
                },
                    {
                    'label_name': 'Mouse',
                    'bbox_x': 391,
                    'bbox_y': 370,
                    'bbox_width': 58,
                    'bbox_height': 38,
                    'image_width': 750,
                    'image_height': 500
                },
                    {
                    'label_name': 'Phone',
                    'bbox_x': 292,
                    'bbox_y': 276,
                    'bbox_width': 48,
                    'bbox_height': 68,
                    'image_width': 750,
                    'image_height': 500
                },
                    {
                    'label_name': 'Plant',
                    'bbox_x': 456,
                    'bbox_y': 330,
                    'bbox_width': 58,
                    'bbox_height': 72,
                    'image_width': 750,
                    'image_height': 500
                },
                    {
                    'label_name': 'Mousepad',
                    'bbox_x': 197,
                    'bbox_y': 354,
                    'bbox_width': 300,
                    'bbox_height': 67,
                    'image_width': 750,
                    'image_height': 500
                },
                    {
                    'label_name': 'Speaker',
                    'bbox_x': 135,
                    'bbox_y': 113,
                    'bbox_width': 82,
                    'bbox_height': 119,
                    'image_width': 750,
                    'image_height': 500
                },
                    {
                    'label_name': 'Desk',
                    'bbox_x': 124,
                    'bbox_y': 328,
                    'bbox_width': 624,
                    'bbox_height': 172,
                    'image_width': 750,
                    'image_height': 500
                },
                    {
                    'label_name': 'Chair',
                    'bbox_x': 22,
                    'bbox_y': 258,
                    'bbox_width': 303,
                    'bbox_height': 242,
                    'image_width': 750,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Other',
            'imgUrl': other7,
            'productCount': 12,
            'boxes':[
                {
                    'label_name': 'Light',
                    'bbox_x': 52,
                    'bbox_y': 108,
                    'bbox_width': 177,
                    'bbox_height': 84,
                    'image_width': 348,
                    'image_height': 500
                },
                    {
                    'label_name': 'Chair',
                    'bbox_x': 75,
                    'bbox_y': 319,
                    'bbox_width': 45,
                    'bbox_height': 119,
                    'image_width': 348,
                    'image_height': 500
                },
                    {
                    'label_name': 'Chair',
                    'bbox_x': 62,
                    'bbox_y': 302,
                    'bbox_width': 39,
                    'bbox_height': 108,
                    'image_width': 348,
                    'image_height': 500
                },
                    {
                    'label_name': 'Cutting Board',
                    'bbox_x': 257,
                    'bbox_y': 260,
                    'bbox_width': 39,
                    'bbox_height': 34,
                    'image_width': 348,
                    'image_height': 500
                },
                    {
                    'label_name': 'Oven',
                    'bbox_x': 243,
                    'bbox_y': 291,
                    'bbox_width': 44,
                    'bbox_height': 112,
                    'image_width': 348,
                    'image_height': 500
                },
                    {
                    'label_name': 'Refrigerator',
                    'bbox_x': 289,
                    'bbox_y': 188,
                    'bbox_width': 59,
                    'bbox_height': 267,
                    'image_width': 348,
                    'image_height': 500
                },
                    {
                    'label_name': 'Oven',
                    'bbox_x': 0,
                    'bbox_y': 245,
                    'bbox_width': 66,
                    'bbox_height': 83,
                    'image_width': 348,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Other',
            'imgUrl': other8,
            'productCount': 8,
            'boxes':[
                {
                    'label_name': 'Scrabble',
                    'bbox_x': 93,
                    'bbox_y': 159,
                    'bbox_width': 74,
                    'bbox_height': 82,
                    'image_width': 667,
                    'image_height': 500
                },
                    {
                    'label_name': 'Scrabble',
                    'bbox_x': 153,
                    'bbox_y': 166,
                    'bbox_width': 66,
                    'bbox_height': 73,
                    'image_width': 667,
                    'image_height': 500
                },
                    {
                    'label_name': 'Scrabble',
                    'bbox_x': 209,
                    'bbox_y': 172,
                    'bbox_width': 65,
                    'bbox_height': 67,
                    'image_width': 667,
                    'image_height': 500
                },
                    {
                    'label_name': 'Scrabble',
                    'bbox_x': 271,
                    'bbox_y': 167,
                    'bbox_width': 59,
                    'bbox_height': 71,
                    'image_width': 667,
                    'image_height': 500
                },
                    {
                    'label_name': 'Scrabble',
                    'bbox_x': 329,
                    'bbox_y': 167,
                    'bbox_width': 59,
                    'bbox_height': 68,
                    'image_width': 667,
                    'image_height': 500
                },
                    {
                    'label_name': 'Scrabble',
                    'bbox_x': 386,
                    'bbox_y': 166,
                    'bbox_width': 59,
                    'bbox_height': 67,
                    'image_width': 667,
                    'image_height': 500
                },
                    {
                    'label_name': 'Scrabble',
                    'bbox_x': 442,
                    'bbox_y': 167,
                    'bbox_width': 54,
                    'bbox_height': 63,
                    'image_width': 667,
                    'image_height': 500
                },
                    {
                    'label_name': 'Scrabble',
                    'bbox_x': 498,
                    'bbox_y': 169,
                    'bbox_width': 61,
                    'bbox_height': 65,
                    'image_width': 667,
                    'image_height': 500
                },
                    {
                    'label_name': 'Scrabble',
                    'bbox_x': 440,
                    'bbox_y': 275,
                    'bbox_width': 69,
                    'bbox_height': 62,
                    'image_width': 667,
                    'image_height': 500
                },
                    {
                    'label_name': 'Scrabble',
                    'bbox_x': 391,
                    'bbox_y': 274,
                    'bbox_width': 57,
                    'bbox_height': 67,
                    'image_width': 667,
                    'image_height': 500
                },
                    {
                    'label_name': 'Scrabble',
                    'bbox_x': 341,
                    'bbox_y': 274,
                    'bbox_width': 54,
                    'bbox_height': 69,
                    'image_width': 667,
                    'image_height': 500
                },
                    {
                    'label_name': 'Scrabble',
                    'bbox_x': 291,
                    'bbox_y': 273,
                    'bbox_width': 60,
                    'bbox_height': 70,
                    'image_width': 667,
                    'image_height': 500
                },
                    {
                    'label_name': 'Scrabble',
                    'bbox_x': 239,
                    'bbox_y': 273,
                    'bbox_width': 62,
                    'bbox_height': 74,
                    'image_width': 667,
                    'image_height': 500
                },
                    {
                    'label_name': 'Scrabble',
                    'bbox_x': 186,
                    'bbox_y': 274,
                    'bbox_width': 57,
                    'bbox_height': 67,
                    'image_width': 667,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Other',
            'imgUrl': other9,
            'productCount': 6,
            'boxes':[
                {
                    'label_name': 'Scrabble',
                    'bbox_x': 1,
                    'bbox_y': 0,
                    'bbox_width': 332,
                    'bbox_height': 159,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Camera',
                    'bbox_x': 176,
                    'bbox_y': 271,
                    'bbox_width': 75,
                    'bbox_height': 79,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Makeup Case',
                    'bbox_x': 226,
                    'bbox_y': 300,
                    'bbox_width': 105,
                    'bbox_height': 82,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Suitcase',
                    'bbox_x': 24,
                    'bbox_y': 162,
                    'bbox_width': 220,
                    'bbox_height': 129,
                    'image_width': 333,
                    'image_height': 500
                },
                    {
                    'label_name': 'Bag',
                    'bbox_x': 246,
                    'bbox_y': 242,
                    'bbox_width': 83,
                    'bbox_height': 51,
                    'image_width': 333,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Other',
            'imgUrl': other10,
            'productCount': 11,
            'boxes':[
                {
                    'label_name': 'Shelves',
                    'bbox_x': 37,
                    'bbox_y': 77,
                    'bbox_width': 207,
                    'bbox_height': 34,
                    'image_width': 309,
                    'image_height': 500
                },
                {
                    'label_name': 'Computer',
                    'bbox_x': 112,
                    'bbox_y': 265,
                    'bbox_width': 119,
                    'bbox_height': 124,
                    'image_width': 309,
                    'image_height': 500
                },
                {
                    'label_name': 'Shelves',
                    'bbox_x': 38,
                    'bbox_y': 140,
                    'bbox_width': 204,
                    'bbox_height': 30,
                    'image_width': 309,
                    'image_height': 500
                },
                {
                    'label_name': 'Shelves',
                    'bbox_x': 40,
                    'bbox_y': 204,
                    'bbox_width': 200,
                    'bbox_height': 34,
                    'image_width': 309,
                    'image_height': 500
                },
                {
                    'label_name': 'Plant',
                    'bbox_x': 95,
                    'bbox_y': 12,
                    'bbox_width': 71,
                    'bbox_height': 74,
                    'image_width': 309,
                    'image_height': 500
                },
                {
                    'label_name': 'Plant',
                    'bbox_x': 43,
                    'bbox_y': 102,
                    'bbox_width': 40,
                    'bbox_height': 54,
                    'image_width': 309,
                    'image_height': 500
                },
                {
                    'label_name': 'Keyboard',
                    'bbox_x': 173,
                    'bbox_y': 407,
                    'bbox_width': 63,
                    'bbox_height': 48,
                    'image_width': 309,
                    'image_height': 500
                },
                {
                    'label_name': 'Phone',
                    'bbox_x': 180,
                    'bbox_y': 452,
                    'bbox_width': 70,
                    'bbox_height': 25,
                    'image_width': 309,
                    'image_height': 500
                },
                {
                    'label_name': 'Mouse',
                    'bbox_x': 164,
                    'bbox_y': 386,
                    'bbox_width': 42,
                    'bbox_height': 21,
                    'image_width': 309,
                    'image_height': 500
                },
                {
                    'label_name': 'Desk',
                    'bbox_x': 0,
                    'bbox_y': 361,
                    'bbox_width': 309,
                    'bbox_height': 139,
                    'image_width': 309,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Other',
            'imgUrl': other11,
            'productCount': 3,
            'boxes':[
                {
                    'label_name': 'Plant',
                    'bbox_x': 181,
                    'bbox_y': 216,
                    'bbox_width': 52,
                    'bbox_height': 149,
                    'image_width': 379,
                    'image_height': 500
                },
                {
                    'label_name': 'Toilet',
                    'bbox_x': 238,
                    'bbox_y': 243,
                    'bbox_width': 78,
                    'bbox_height': 179,
                    'image_width': 379,
                    'image_height': 500
                },
                {
                    'label_name': 'Toilet Paper',
                    'bbox_x': 283,
                    'bbox_y': 225,
                    'bbox_width': 34,
                    'bbox_height': 32,
                    'image_width': 379,
                    'image_height': 500
                },
                {
                    'label_name': 'Art',
                    'bbox_x': 180,
                    'bbox_y': 88,
                    'bbox_width': 106,
                    'bbox_height': 92,
                    'image_width': 379,
                    'image_height': 500
                },
                {
                    'label_name': 'Soap',
                    'bbox_x': 40,
                    'bbox_y': 197,
                    'bbox_width': 37,
                    'bbox_height': 56,
                    'image_width': 379,
                    'image_height': 500
                },
                {
                    'label_name': 'Sink',
                    'bbox_x': 32,
                    'bbox_y': 243,
                    'bbox_width': 133,
                    'bbox_height': 57,
                    'image_width': 379,
                    'image_height': 500
                },
                {
                    'label_name': 'Faucet',
                    'bbox_x': 70,
                    'bbox_y': 211,
                    'bbox_width': 32,
                    'bbox_height': 44,
                    'image_width': 379,
                    'image_height': 500
                },
                {
                    'label_name': 'Mirror',
                    'bbox_x': 17,
                    'bbox_y': 76,
                    'bbox_width': 112,
                    'bbox_height': 130,
                    'image_width': 379,
                    'image_height': 500
                },
                {
                    'label_name': 'Rug',
                    'bbox_x': 136,
                    'bbox_y': 438,
                    'bbox_width': 216,
                    'bbox_height': 62,
                    'image_width': 379,
                    'image_height': 500
                }
            ]
        },
        {
            'name':'Other',
            'imgUrl': other12,
            'productCount': 8,
            'boxes':[
                {
                    'label_name': 'Wine Glass',
                    'bbox_x': 104,
                    'bbox_y': 179,
                    'bbox_width': 42,
                    'bbox_height': 99,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Wine Glass',
                    'bbox_x': 116,
                    'bbox_y': 209,
                    'bbox_width': 44,
                    'bbox_height': 89,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Cutting Board',
                    'bbox_x': 72,
                    'bbox_y': 212,
                    'bbox_width': 181,
                    'bbox_height': 51,
                    'image_width': 333,
                    'image_height': 500
                },
                {
                    'label_name': 'Fire Pit',
                    'bbox_x': 34,
                    'bbox_y': 88,
                    'bbox_width': 278,
                    'bbox_height': 191,
                    'image_width': 333,
                    'image_height': 500
                }
            ]
        },
    ],
};
