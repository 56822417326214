import { Button } from "antd";
import { BsTiktok,BsInstagram,BsTwitter,BsLinkedin,BsFacebook, BsYoutube, BsTwitch } from "react-icons/bs";
import lemon8Icon from '../../images/homepage/for-influencers/lemon8-icon.png';

const socials = {
    'Instagram':{
        'icon': <BsInstagram fontSize={16} style={{verticalAlign:'middle',lineHeight:1}} color='#cd486b'  />,
        color:'#cd486b'
    },
    'Tiktok':{
        'icon': <BsTiktok fontSize={16} style={{verticalAlign:'middle',lineHeight:1}} color='black'  />,
        color:'black'
    },
    'Facebook':{
        'icon': <BsFacebook fontSize={16} style={{verticalAlign:'middle',lineHeight:1}} color='#1877F2'  />,
        color:'#1877F2'
    },
    'Lemon8':{
        'icon': <img src={lemon8Icon} style={{width:'16px',aspectRatio:1/1}} />,
        color:'#FAE933'
    },
    'Twitter':{
        'icon': <BsTwitter fontSize={16} style={{verticalAlign:'middle',lineHeight:1}} color='#1DA1F2'  />,
        color:'#1DA1F2'
    },
    'YouTube':{
        icon: <BsYoutube fontSize={16} style={{verticalAlign:'middle',lineHeight:1}} color='red'  />,
        color:'red'
    },
    'Twitch':{
        'icon': <BsTwitch fontSize={16} style={{verticalAlign:'middle',lineHeight:1}} color='#6441a5'  />,
        color:'#6441a5'
    },
    'LinkedIn':{
        'icon': <BsLinkedin fontSize={16} style={{verticalAlign:'middle',lineHeight:1}} color='#0072b1'  />,
        color:'#0072b1'
    }
}
function SocialTag({name,followers=0}){
    return(
        <div className='rounded-button hover-scale-sm user-card-social-tag' style={{color: socials[name]?.color ? socials[name]?.color : '#ef3078',borderColor: socials[name]?.color ? socials[name]?.color : '#ef3078'}}>
            <div className="align-div" style={{verticalAlign:'middle',marginRight:'5px'}}>
                {socials[name].icon}
            </div>
            <div className="align-div" style={{verticalAlign:'middle'}}>
                {followers}
            </div>
        </div>
    )

}

export default SocialTag;