export const translations = {
    'en':{
        'home_page': {
                'header': 'Shop Your Favorite Creators',
                'download_today':'Download Today!',
                'download_the_app': 'Download the App:',
                'download_and_wander': 'Download & Start\nWandering!',
                'download_availability':'Available on iOS and Android!',
                'fashion_details': "Shop your favorite creators and discover new ways to style your waredrobe or buy products and shop styles from around the world you didn't even know about! WanderCart allows you to explore global styles and shop local stores.", 'fashion_subheader': 'Explore Fashion',
                'fashion_subheader': 'Explore Fashion',
                'header_shop_anything': 'Shop Anything AI.',
                'home_details': 'Get home products that redefine the meaning of style and functionality. From exquisite decor accents to innovative gadgets, discover the pieces that transform your living space into a reflection of your individuality.',
                'home_subheader': 'Explore Home',
                'how_it_works': 'How It Works.',
                'how_it_works_details': 'Bringing Serendipity to Shopping.',
                'learn_more': 'Learn More',
                'other_details': 'From tech gadgets to beauty essentials, from fitness gear to gourmet treats, indulge your curiosity and explore categories of endless trajectories',
                'other_subheader': 'Explore Other',
                'say_hello': 'Say Hello to WanderCart.',
                'scroll_details': 'Imagine wandering a mall, market or local store. Explore your favorite creators and collections or just Wander.',
                'scroll_title': 'Scroll.',
                'see_more': 'See More',
                'share_details': "Are You a Creator? Share posts and help other people discover new fashion, gadgets, home goods and anything you can't live without.", 'share_title': 'Share.',
                'shop_anything_action': 'Click Any Item to Try it Out',
                'shop_anything_detail1': 'WanderCart AI helps find every item in every post.',
                'shop_anything_detail2': 'Explore styles from around the world but shop products near you!',
                'shop_details': 'Ever see something you like but not sure where to get it? WanderCart AI helps you find any visible item in any post so you can shop anything.',
                'shop_title': 'Shop.',
                'subheader_1': 'Discover, Shop, and Share Products',
                'subheader_2': 'in a Whole New Way!',
                'smart_tagging_detail1': 'We Find Every Product in Every Image.',
                'smart_tagging_detail2': 'You Just Post.',
                'start_explore': 'Start Exploring.',
                'subheader_smart_tagging': 'Smart Product Tagging.'
            },
        'sidebar':{
            'home':'Home',
            'trending':'Trending',
            'posts':'Posts',
            'products':'Products',
            'profile':'Profile',
            'saved':'Saved',
            'analytics':'Analytics',
            'settings':'Settings',
        },
        'dashboard':{
                'alerts':{
                    'update_profile_success':'Profile Updated Successfully!',
                    'update_error':'There was an Error. Please try again!',
                    'update_socials_success':'Socials Updated Successfully!',
                    'update_avatar_success':'Avatar Updated!',
                    'upload_error':'Issue with Upload. Please try again!',
                    'delete_account_success':'Account has Been Deleted!',
                    'delete_account_error':'An Issue Occured while Deleting Your Account. Please try again!',
                    'follow_login':'Login to Follow',
                    'follow_success':'You are now following',
                    'unfollow_success':'No longer following',
                    'post_create_success':'Post Created Successfully!',
                    'post_edit_success':'Post Editted Successfully!',
                    'product_create_success':'Product Created Successfully!',
                    'product_edit_success':'Product Editted Successfully!',
                    'product_tag_saved_success':'Tags Saved Successfully!',
                    'product_unlike_success':'Photo unliked',
                    'product_added':'Product has been added to your Likes!',
                    'copy_clipboard':'Copy Clipboard!',
                    'save_language_success':'Language Successfully Changed to',
                },
                'general': {
                    'edit':'Edit',
                    'filter': 'Filter',
                    'follow': 'Follow',
                    'following': 'Following',
                    'followers_one': '{{count}} follower',
                    'followers_other': '{{count}} followers',
                    'followersWithOptions': '{{val, numbers_shorten(val)}} followers',
                    'likes_one': '{{count}} like',
                    'likes_other': '{{count}} likes',
                    'likesWithOptions': '{{val, numbers_shorten(val)}} likes',
                    'no': 'No',
                    'no_photo':'No Photo',
                    'post_empty': 'No Posts Yet',
                    'product_empty': 'No Products Yet',
                    'creators': 'Creators',
                    'login': 'Login',
                    'login_register': 'Login/Register',
                    'create_account': 'Create an Account',
                    'search': 'Search',
                    'save_changes': 'Save Changes',
                    'unfollow_confirm': 'Are you sure you want to unfollow ',
                    'yes': 'Yes',
                    'more_user_posts':'More posts by',
                    'more_user_products':'More products by',
                    'delete_image':'Are you sure you want to detele this image?',
                    'confirm':'Confirm',
                    'cancel':'Cancel',
                    'log_out_success':'Successful Log Out!',
                    'login_expire':'Hello! Sorry your login has expired for security reasons! Please Login Again!',
                },
                'home': {
                    'explore_creators': 'Explore Creators',
                    'feed': 'For You',
                    'feed_details': 'Start Following Creators\nfor Personalized Feed!',
                    'title': 'Home'
                },
                'posts': {
                    'add_new_post': 'Add New Post',
                    'edit_post': 'Edit This Post',
                    'add_post_image': 'Click to Add Photo',
                    'caption': 'Caption',
                    'caption_placeholder': 'Post Caption',
                    'collection': 'Collection',
                    'collection_placeholder': 'Add Collections',
                    'create_button_one': 'Create Post',
                    'create_button': 'Create Posts',
                    'create_first': 'Create My First Post',
                    'delete_post':'Are you sure you want to detele this post?',
                    'link': 'Link',
                    'link_placeholder': 'Post Links',
                    'title': 'My Posts'
                },
                'product': {
                    'add_product': 'Add Product',
                    'add_new_product': 'Add New Product',
                    'advanced': 'Advanced',
                    'create_product': 'Create Product',
                    'edit_product': 'Edit This Product',
                    'hide_advanced': 'Hide Advanced Options',
                    'product_name': 'Product Name',
                    'product_link': 'Product Link',
                    'product_photos': 'Product Photos',
                    'product_currency': 'Product Currency',
                    'product_price': 'Product Price',
                    'product_description': 'Product Description',
                    'save_changes': 'Save Changes',
                    'show_advanced': 'Show Advanced Options',
                    'delete_product':'Are you sure you want to detele this product?',
                    'product':'Products',
                    'title': 'My Products',
                    'product_shop':'Shop',
                    'product_style':'Product Style',
                    'add_a_style':'Add a Style',
                    'product_similar':'Similar Products',
                    'product_save':'Save Product',
                    'product_saved':'Product is Saved.',
                    'product_unlike':'Are you sure you want to unlike this product?',
                },
                'profile':{
                    'title':'My Profile',
                    'setup_account':'Setup Your Profile',
                    'edit_my_bio':'Edit My Bio',
                    'onboarding_step_one':'First Step: Add an Avatar',
                    'onboarding_step_two':'Second Step: Fill out Profile',
                    'onboarding_step_three':'Last Step: Add your First Post',
                },
                'settings': {
                    'about': 'About You',
                    'change_avatar': 'Change Avatar',
                    'delete_account': 'Delete My Account',
                    'log_out': 'Log Out',
                    'edit_login': 'Edit Login',
                    'save_login': 'Save Changes',
                    'save_profile': 'Save Profile',
                    'save_socials': 'Save Links',
                    'save_language': 'Save',
                    'subheader_login': 'Login Details',
                    'subheader_profile': 'Your Profile',
                    'subheader_socials': 'Socials & Links',
                    'subheader_languages': 'Languages',
                    'hide_socials': 'Minimize Socials',
                    'show_socials': 'Show All Socials',
                    'title': 'Settings',
                    'link':'Link',
                },
                'trending': {
                    'collections': 'Trending Collections',
                    'creators': 'Top Creators',
                    'more_collections': 'Explore More Collections',
                    'more_creators': 'Explore More Creators',
                    'more_posts': 'Explore More Posts',
                    'posts': 'Trending Posts',
                    'title': 'Trending'
                },
                'auth':{
                    'email': 'Email',
                    'password': 'Password',
                    'username': 'Username',
                    'username_description':'What do you want others to call you?',
                    'username_error_missing':'Please input your name!',
                    'username_error_underscore':'Sorry! Username cannot contain the following: _',
                    'username_error_space':'Sorry! Username cannot contain spaces.',
                    'username_error_exists':'Sorry! This Username Already Exists!',
                    'email_error_invalid':'The input is not valid E-mail!',
                    'email_error_missing':'Please input your E-mail!',
                    'email_error_exists':'Sorry! This Email Already Exists!',
                    'password_error_missing':'Please input your password!',
                    'password_forget':'Forgot password',
                    'create_account':"Don't have an account?",
                    'login':"Log in",
                    'register_title':'Sign Up to Get Started.',
                    'privacy_agree':'By signing up, you agree to our',
                    'privacy_link':'Terms & Privacy Policy',
                    'sign_up':'Sign Up',
                    'existing_account':'Already have an account?',
                },
                'product_tag':{
                    'title':'Add Product Tags',
                    'product_tag_detail':'Click Anywhere to Add Product',
                    'product_tag_reminder':'Click the Image to Add the Tag Location!',
                    'product_remove':'Remove this Product?',
                    'save_tag':'Save Tags',
                    'select_product':'Select Product',
                    'select_product_detail':'Select Product to Tag',
                    'add_first_product':'Add My First Product',
                },
                'share':{
                    'copy_to_clipboard':'Copy to Clipboard',
                    'share_profile':'Share My Page!',
                    'copy_my_link':'Copy My Link',
                },
                'delete':{
                    'delete_confirm_details':'Are you sure you want to delete your account?',
                    'delete_instructions':'Confirm Field Must Match Your Username!',
                    'delete_confirm_1':'Type',
                    'delete_confirm_2':'to confirm',
                    'deleting':'Deleting...',
                    'delete_confirm_error':'Confirm Field Must Match Your Username!',
                    'delete_details_1':'No recovery available for deleted accounts!',
                    'delete_details_2':'All work and media will be lost.',
                }
            },
            'onboarding':{
                'panel0':{
                    'hook':'Ready to Grow',
                    'description':'WanderCart is the New Way for Influencers and Businesses to Connect and Collaborate.'
                },
                'panel1':{
                    'question':'Who Are You?',
                    'influencer':'An Influencer',
                    'brand':'A Brand'
                },
                'panel2':{
                    'question':'Where Are You From?',
                    'country_title':'Your Country',
                    'country_placeholder':'Search to Select Country',
                    'region_title':'Your Region (Optional)',
                    'region_placeholder':'Search to Select Region',
                },
                'panel3':{
                    'question':'Select Your Categories'
                },
                'panel4':{
                    'question':'Are You on Social?',
                    'question_detail':'*Must add at least 1 Account!',
                    'add_another':'Add Another Account'
                }
            },
            'categories':{
                'lifestyle':'Lifestyle',
                'gaming':'Gaming',
                'food':'Food & Drink',
                'fashion':'Fashion',
                'beauty':'Beauty',
                'travel':'Travel',
                'parenting':'Parenting &\nFamily',
                'music':'Music',
                'humor':'Humor & Comedy',
                'technology':'Technology & Gadget',
                'education':'Education',
                'business':'Business',
                'health':'Health',
                'photography':'Photography',
                'cooking':'Cooking'
            }
    },
    'id':{
        'home_page': {
            'header': 'Belanja dari Kreator Favoritmu',
            'subheader_1': 'Temukan, Belanja, dan Bagikan Produk dengan',
            'subheader_2': 'Cara yang Baru dan Menarik!',
            'download_and_wander': 'Download & Start\nWandering!',
            'download_today':'Unduh hari ini!',
            'download_the_app': 'Download Aplikasi',
            'download_availability':'Tersedia di iOS dan Android!!',
            'fashion_details': 'Belanja dari kreator favoritmu dan temukan gaya baru untuk lemari pakaianmu, atau beli produk dan gaya dari seluruh dunia yang mungkin belum kamu ketahui sebelumnya! WanderCart memungkinkan kamu menjelajahi gaya-gaya global dan berbelanja di toko-toko lokal.',
            'fashion_subheader': 'Explore Fashion',
            'header_shop_anything': 'Shop Anything AI.',
            'home_details': 'Dapatkan produk-produk untuk rumah yang mendefinisikan kembali gaya dan fungsionalitas. Mulai dari aksen dekorasi yang indah hingga perangkat inovatif, temukan benda-benda yang mengubah ruanganmu sesuai dengan kepribadianmu.',
            'home_subheader': 'Explore Rumah',
            'how_it_works': 'Bagaimana Cara Kerjanya',
            'how_it_works_details': 'Membawa Kejutan dalam Berbelanja',
            'learn_more': 'Selengkapnya',
            'other_details': 'Dari gadget teknologi hingga kebutuhan kecantikan, dari perlengkapan kebugaran hingga hidangan gurmet, penuhi rasa ingin tahumu dan jelajahi kategori dengan pilihan tak terbatas.',
            'other_subheader': 'Explore lebih lanjut',
            'say_hello': 'Say Hello to WanderCart.',
            'scroll_details': 'Bayangkan berkeliling di mal, pasar, atau toko lokal. Jelajahi kreator dan koleksi favoritmu atau hanya Berkeliling',
            'scroll_title': 'Scroll.',
            'see_more': 'Lihat Lebih Banyak',
            'share_details': 'Apakah Kamu Seorang Creator? Bagikan postingan dan bantu orang lain menemukan mode, gadget, barang rumah, dan apapun yang tidak bisa Kamu lewatkan.',
            'share_title': 'Share.',
            'shop_anything_action': 'Klik Item Apapun untuk Mencoba',
            'shop_anything_detail1': 'WanderCart AI membantu menemukan setiap barang dalam setiap postingan',
            'shop_anything_detail2': 'Jelajahi gaya dari seluruh dunia, tetapi belanja produk di dekatmu!',
            'shop_details': 'Pernah melihat sesuatu yang kamu suka tapi tidak yakin di mana mendapatkannya? WanderCart AI bisa membantu kamu menemukan barang apapun yang terlihat dalam setiap postingan sehingga kamu bisa membeli apapun.',
            'shop_title': 'Shop.',
            'smart_tagging_detail1': 'Kami Temukan Setiap Produk dalam Setiap Gambar',
            'smart_tagging_detail2': 'Kamu Hanya Perlu Posting',
            'start_explore': 'Start Exploring',
            'subheader_smart_tagging': 'Smart Product Tagging.'
        },
        'sidebar':{
            'home':'Home',
            'trending':'Trending',
            'posts':'Posting',
            'products':'Produk',
            'analytics':'Analytics',
            'settings':'Pengaturan',
        },
        'dashboard': {
            'alerts':{
                'update_profile_success':'Profil diperbarui dengan sukses!',
                'update_error':'Ada kesalahan. Tolong coba lagi!',
                'update_socials_success':'Sosial berhasil diperbarui!',
                'update_avatar_success':'Avatar diperbarui!',
                'upload_error':'Masalah dengan unggahan. Tolong coba lagi!',
                'delete_account_success':'Akun telah dihapus!',
                'delete_account_error':'Masalah terjadi saat menghapus akun Anda. Tolong coba lagi!',
                'follow_login':'Masuk untuk mengikuti',
                'follow_success':'Anda sekarang mengikuti',
                'unfollow_success':'Tidak lagi mengikuti',
                'post_create_success':'Posting berhasil dibuat!',
                'post_edit_success':'Posting diedit dengan sukses!',
                'product_create_success':'Produk berhasil dibuat!',
                'product_edit_success':'Produk yang diedit dengan sukses!',
                'product_tag_saved_success':'Tag disimpan dengan sukses!',
                'copy_clipboard':'Salin Clipboard!',
                'save_language_success':'Bahasa berhasil diubah menjadi ',
            },
            'general': {
                'edit':'Edit',
                'filter': 'Filter',
                'follow': 'Ikuti',
                'following': 'Mengikuti',
                'followers_one': '{{count}} pengikut',
                'followers_other': '{{count}} pengikut',
                'followersWithOptions': '{{val, numbers_shorten(val)}} pengikut',
                'likes_one': '{{count}} penyuka',
                'likes_other': '{{count}} penyuka',
                'likesWithOptions': '{{val, numbers_shorten(val)}} penyuka',
                'no': 'TIDAK',
                'no_photo':'Tidak ada foto',
                'post_empty': 'belum ada postingan',
                'product_empty': 'Belum ada produk',
                'creators': 'Pencipta',
                'login': 'Login',
                'login_register': 'Login/Daftar',
                'create_account': 'Buat sebuah akun',
                'search': 'Mencari',
                'delete_image':'Apakah Anda yakin ingin mendetele gambar ini??',
                'unfollow_confirm': 'Anda yakin ingin berhenti mengikuti ',
                'yes': 'Ya',
                'more_user_posts':'More posts by',
                'confirm':'Mengonfirmasi',
                'cancel':'Membatalkan',
                'log_out_success':'Sukses Log Out!',
                'login_expire':'Halo! Maaf login Anda telah kedaluwarsa karena alasan keamanan! Harap masuk lagi!',
            },
            'home': {
                'explore_creators': 'Jelajahi kreator',
                'feed': 'Untukmu',
                'feed_details': 'Mulai ikuti creator untuk feed personal mereka!',
                'title': 'Home'
            },
            'posts': {
                'add_new_post': 'Tambahkan postingan baru',
                'edit_post': 'Edit This Post',
                'add_post_image': 'Klik untuk menambahkan foto',
                'caption': 'Caption',
                'caption_placeholder': 'Post caption',
                'collection': 'Koleksi',
                'collection_placeholder': 'Tambahkan koleksi',
                'create_button_one': 'Buat post',
                'create_button': 'Buat posts',
                'create_first': 'Buat postingan pertama saya',
                'delete_post':'Apakah Anda yakin ingin mendetele post ini??',
                'link': 'Tautan',
                'link_placeholder': 'Tautan posting',
                'title': 'Posting'
            },
            'product': {
                'add_product': 'Tambahkan produk',
                'add_new_product': 'Tambahkan Produk Baru',
                'create_product': 'Buat Produk',
                'advanced': 'Lebih lanjut',
                'edit_product': 'Edit produk ini',
                'hide_advanced': 'Sembunyikan opsi lanjutan',
                'product_link': 'Tautan Produk',
                'product_name': 'Nama Produk',
                'product_photos': 'Foto Produk',
                'product_currency': 'Mata Uang Produk',
                'product_price': 'Harga Produk',
                'product_description': 'Deskripsi Produk',
                'delete_product':'Are you sure you want to detele this produk?',
                'save_changes': 'Simpan perubahan',
                'show_advanced': 'Tampilkan opsi lanjutan',
                'product':'Produk',
                'title': 'Produk',
                'product_shop':'Toko',
                'product_style':'Gaya produk',
                'add_a_style':'Tambahkan gaya',
                'product_similar':'Produk Sejenis',
                'product_save':'Simpan Produk',
                'product_saved':'Produk disimpan!',
                'product_unlike':'Anda yakin ingin tidak seperti produk ini?',
            },
            'settings': {
                'about': 'Tentang Anda',
                'change_avatar': 'Mengubah avatar',
                'delete_account': 'Hapus akun Saya',
                'log_out': 'Keluar',
                'edit_login': 'Edit login',
                'save_login': 'Simpan perubahan',
                'save_profile': 'Simpan Profil',
                'save_socials': 'Simpan Tautan',
                'save_language':'Simpan',
                'subheader_login': 'Detail login',
                'subheader_profile': 'Profil kamu',
                'subheader_socials': 'Sosial & Tautan',
                'subheader_languages': 'Bahasa',
                'hide_socials': 'Sembunyikan opsi sosial',
                'show_socials': 'Tampilkan opsi sosial',
                'link':'Tautan',
                'title': 'Pengaturan',
            },
            'profile':{
                'title':'Profil Saya',
                'setup_account':'Siapkan Profil Anda',
                'edit_my_bio':'Edit Bio Saya',
                'onboarding_step_one':'Langkah Pertama: Tambahkan Avatar',
                'onboarding_step_two':'Langkah Kedua: Isi Profil',
                'onboarding_step_three':'Langkah Terakhir: Tambahkan Posting Pertama Anda',
            },
            'trending': {
                'collections': 'Koleksi trending',
                'creators': 'Kreator paling top',
                'more_collections': 'Lebih banyak koleksi',
                'more_creators': 'Lebih banyak kreator',
                'more_posts': 'Lebih banyak post',
                'posts': 'Paling trending',
                'title': 'Trending'
            },
            'auth':{
                'email': 'Email',
                'password': 'Kata sandi',
                'username': 'Nama pengguna',
                'username_description':'Apa yang Anda ingin orang lain menelepon Anda?',
                'username_error_missing':'Harap masukkan nama Anda!',
                'username_error_underscore':'Maaf! Nama pengguna tidak dapat berisi yang berikut: ',
                'username_error_space':'Maaf! Nama pengguna tidak dapat berisi spasi.',
                'username_error_exists':'Maaf! Nama pengguna ini sudah ada!',
                'email_error_invalid':'Inputnya bukan email yang valid!',
                'email_error_missing':'Harap masukkan email Anda!',
                'email_error_exists':'Maaf! Email ini sudah ada!',
                'password_error_missing':'Harap masukkan kata sandi Anda!',
                'password_forget':'Tidak ingat kata sandi!',
                'create_account':"Tidak punya akun?",
                'login':"Gabung",
                'register_title':'Daftar untuk memulai.',
                'privacy_agree':'Dengan mendaftar, Anda menyetujui ',
                'privacy_link':'Ketentuan & Kebijakan Privasi',
                'sign_up':'Mendaftar',
                'existing_account':'Sudah memiliki akun?',
            },
            'product_tag':{
                'title':'Tambahkan Tag Produk',
                'product_tag_detail':'Klik di mana saja untuk menambahkan produk',
                'product_tag_reminder':'Klik gambar untuk menambahkan lokasi tag',
                'product_remove':'Hapus produk ini?',
                'save_tag':'Simpan Tag',
                'select_product':'Pilih Produk untuk Tag',
                'add_first_product':'Tambahkan Produk Pertama Saya',
            },
            'share':{
                'copy_to_clipboard':'Menyalin ke Clipboard',
                'share_profile':'Bagikan halaman saya!',
                'copy_my_link':'Salin tautan saya',
            },
            'delete':{
                'delete_confirm_details':'Apakah Anda yakin ingin menghapus akun Anda?',
                'delete_instructions':'Konfirmasi Lapangan Harus Cocokkan Nama Pengguna Anda!',
                'delete_confirm_1':'Ketik',
                'delete_confirm_2':'untuk mengonfirmasi',
                'deleting':'Menghapus ...',
                'delete_confirm_error':'Konfirmasi Lapangan Harus Cocokkan Nama Pengguna Anda!',
                'delete_details_1':'Tidak ada pemulihan yang tersedia untuk akun yang dihapus!',
                'delete_details_2':'Semua pekerjaan dan media akan hilang.',
            }
        },
        'onboarding': {
            'panel0': {
                'hook': 'Siap untuk Tumbuh',
                'description': 'WanderCart adalah Cara Baru bagi Influencer dan Bisnis untuk Terhubung dan Berkolaborasi.'
            },
            'panel1': {
                'question': 'Siapa Anda?',
                'influencer': 'Seorang Influencer',
                'brand': 'Sebuah Merek'
            },
            'panel2': {
                'question': 'Dari Mana Anda?',
                'country_title': 'Negara Anda',
                'country_placeholder': 'Cari untuk Memilih Negara',
                'region_title': 'Wilayah Anda (Opsional)',
                'region_placeholder': 'Cari untuk Memilih Wilayah',
            },
            'panel3': {
                'question': 'Pilih Kategori Anda'
            },
            'panel4': {
                'question': 'Apakah Anda Aktif di Media Sosial?',
                'question_detail': '*Harus menambahkan setidaknya 1 Akun!',
                'add_another': 'Tambahkan Akun Lainnya'
            }
        },
    }
};