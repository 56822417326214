import { MinusOutlined, PlusOutlined, UpOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Button, Checkbox, Collapse, Form, Input, InputNumber, Select, Space, Tag, message } from "antd";
import CurrencySelect from "../../Select/CurrencySelect";
import BrandSelect from "../../Select/BrandSelect";

const offerTypeOptions = ['Affiliate Partnerships', 'Brand Ambassadorships', 'Cause Marketing Collaborations', 'Content Creation Agreements', 'Discount & Discount Codes', 'Educational Collaborations', 'Event Sponsorships', 'Exclusive Product Access', 'Exclusivity Offers', 'Giveaways and Contests', 'Influencer Takeovers', 'Product Collaborations', 'Product Gifting', 'Social Media Challenges', 'Sponsored Content', 'Surprise Packages', 'Other'];

function OfferForm({form,formValues,setFormValues,onFinish}){
    const [uploading,setUploading] = useState(false);
    const [currency,setCurrency] = useState('$');

    const changeCurrency = (value) => {
        setCurrency(value);
        setFormValues({...formValues,'currency':value});
    }
    const clickFinish = async () => {
        setUploading(true);

        form.validateFields().then(() => {
            onFinish();
        }).catch(() => {
            message.info('Please fill in all fields');
            setUploading(false);
        });
    }

    return(
        <Form
            name="apply-to-collab-form"
            className="create-product-form"
            form={form}
            scrollToFirstError={true}
            layout="vertical">
            <div style={{padding:window.innerWidth > 1000 ? '5px 0 5px 5px' : 0, marginBottom:'10px'}}>
                <div style={{paddingTop:'20px',textAlign:'left'}}>
                    <div style={{marginBottom:'15px'}}>
                        <div style={{padding:'2px',flex:1}}>
                            <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Offer Title</div>
                            <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>*Required</div>
                        </div>
                        <Form.Item
                            name="name"
                            style={{marginBottom:'5px'}}
                            autoFocus={true}
                            rules={[
                                {
                                    required: true,
                                    message: 'Offer Title is Required.',
                                },
                            ]}
                            >
                            <Input style={{borderRadius:'10px'}} placeholder="Instagram Post Sharing New Clothing Line!" size='large' />
                        </Form.Item>
                    </div>
                    <div style={{marginBottom:'20px'}}>
                        <div style={{padding:'2px',flex:1}}>
                            <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Offer Type</div>
                            <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>*Required</div>
                        </div>
                        <Form.Item
                            name="type"
                            style={{marginBottom:'5px'}}
                            rules={[
                                {
                                    required: true,
                                    message: 'Offer Type is Required.',
                                },
                            ]}>
                            <Select
                                showSearch
                                size='large'
                                style={{width:'100%',border:'1px solid rgba(0,0,0,0.2)',borderRadius:'10px'}}
                                bordered={false}
                                placeholder="Select Offer Type"
                                >
                                {
                                    offerTypeOptions.map((value,index) => 
                                        <Select.Option key={`collab-type-${index}`} value={value} className="home-onboarding-select-option" >
                                            <div style={{fontWeight:'normal'}}>{value}</div>
                                        </Select.Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div style={{marginBottom:'20px'}}>
                        <div style={{padding:'2px',flex:1}}>
                            <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Budget</div>
                            <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>*Required</div>
                        </div>

                        <div className="currency-select-container">
                            <CurrencySelect onSelect={(value) => changeCurrency(value)} defaultCurrency={currency || '$'} />
                            <Form.Item
                                name="price"
                                style={{marginBottom:'5px',flex:1}}
                                autoFocus={true}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Budget is Required.',
                                    },
                                ]}
                                >
                                <InputNumber 
                                    bordered={false} 
                                    className='currency-input' 
                                    placeholder={'100,000'} 
                                    size='large' 
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    style={{overflow:'hidden',width:'100%'}}/>
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{marginBottom:'20px'}}>
                        <div style={{padding:'2px',flex:1}}>
                            <div style={{lineHeight:1,fontWeight:'500',fontSize:'16px'}}>Description</div>
                            <div style={{fontSize:'10px',color:'rgba(0,0,0,0.5)'}}>Optional</div>
                        </div>
                        <Form.Item
                            name="description"
                            style={{marginBottom:'5px'}}
                            >
                            <Input.TextArea style={{borderRadius:'10px',minHeight:'20vh'}} placeholder="Provide details about your offer, including key information, benefits, and any specific requirements." size='large' />
                        </Form.Item>
                    </div>
                    <div style={{marginBottom:'25px'}}>
                        <div style={{padding:'2px',marginBottom:'5px'}}>
                            <div style={{fontWeight:'500',fontSize:'16px'}}>Are You a Business?</div>
                            <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>Send offer as your brand for more interest!</div>
                        </div>
                        <Form.Item
                            name="brand_id"
                            style={{marginBottom:'5px',width:'80%'}}
                            >
                            <BrandSelect defaultValue={formValues?.brand_id?.id} onSelect={(value) => setFormValues({...formValues,brand_id:value})} />
                        </Form.Item>
                    </div>
                    <div>
                        <Button type={'outline'} className='rounded-button hover-scale-sm' loading={uploading} onClick={clickFinish} style={{color:'white',borderColor:'#ef3078',backgroundColor:'#ef3078'}} >Send Offer</Button>
                    </div>
                </div>
            </div>
        </Form>
    )
}

export default OfferForm;
