import { Select } from "antd"
import { useEffect, useState } from "react";
import BrandServices from "../../utils/BrandServices";


function FontSelect({onSelect,defaultValue}){
    const { Option } = Select;
    const [fontList,setFontList] = useState([]);
    
    const getFontList = async () => {
        const fontResponse = await BrandServices.getFonts();
        setFontList(fontResponse?.items || []);
    }
    
    useEffect(() => {
        getFontList();
    },[])

    return(
        <Select 
            defaultValue={defaultValue} 
            onSelect={onSelect}
            showSearch={true}
            bordered={false} 
            mode='tags'
            size="large"
            placeholder='Arial'
            className="font-select">
            {
                fontList.map((value,index) => 
                    <Option key={`language-select-${index}`} value={value.family} style={{height:'90%',maxHeight:'50px'}} >{value.family}</Option>
                )
            }
        </Select>
    )
}

export default FontSelect;

