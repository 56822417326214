import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { message } from "antd";
import AuthServices from "./AuthServices";
import UserServices from "./UserServices";
import AnalyticsServices from "./AnalyticsServices";
import i18next from './i18n';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useLocalStorage("user", null);
    const navigate = useNavigate();

    const login = async ({username, password,setLoading=false}) => {
        let loginResponse = await AuthServices.login({username:username, password:password});
        if(loginResponse !== 201 && loginResponse !== 401){
            const userResponse = await UserServices.getUser({user_id:loginResponse.user_id});
            let userFull = await Object.assign(userResponse.data, loginResponse);
            var updateDate = new Date();
            userFull['updated_at'] = updateDate;
            setUser(userFull);
            await AnalyticsServices.updateAnalyticsUser({user_id: loginResponse.user_id})
            navigate("/profile?tab=Profile");
        }
        if(setLoading){
            setLoading(false);
        }
    };

    const logout = () => {
        setUser(null);
        navigate("/login");
        message.success(i18next.t('dashboard.general.log_out_success'));
    };

    const register = async ({username, email, password, loginAfter=true}) => {
        const registerResponse = await AuthServices.register({username:username, email:email, password:password});
        if(loginAfter){
            message.success('Successful Registration!');
            message.info('Logging in...');
            login({username:username, password:password});
        }
        else{
            return registerResponse;
        }        
    }

    const refresh = async () => {
        if(user?.updated_at){
            var updateThreshold = Date.parse("2023-09-01");
            
            if(Date.parse(user?.updated_at) < updateThreshold){
                logout();
                message.info('Recent Update! Please login again!')
            }
            else{
                const refreshResponse = await AuthServices.refreshToken(user);
                if(refreshResponse?.status && refreshResponse?.status === 401){
                    message.info(i18next.t('dashboard.general.login_expire'));
                    setTimeout(() => {
                        logout();
                    },500);
                }
                else {
                    const userResponse = await UserServices.getUser({user_id:refreshResponse.user_id});
                    let userFull = await Object.assign(refreshResponse, userResponse.data);
                    userFull['updated_at'] = user?.updated_at;
                    setUser(userFull);
                    return userFull
                }   
            }
        }
        else{
            logout();
            message.info('Recent Update! Please login again!')
        }
    }

    const value = useMemo(
        () => ({
            i18next,
            user,
            setUser,
            register,
            login,
            logout,
            refresh
        }),[user]
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};