import { useEffect, useState } from "react";
import FollowServices from "../../utils/FollowServices";
import { useAuth } from "../../utils/useAuth";
import { Button, Popconfirm, message } from "antd";
import i18next from '../../utils/i18n';

function FollowButton({user_id, username, size='middle',width='auto', disabled=false}){
    const {user,refresh} = useAuth();
    const [isFollowing,setIsFollowing] = useState(false);
    const [loading,setLoading] = useState(true);

    const addFollow = async () => {
        if(user){
            setLoading(true);
            let changeFollowResponse = await FollowServices.addFollower({user:user, user_id:user_id,is_active:true})
            if(changeFollowResponse.status !== 'success') {
                const newUser = await refresh();
                changeFollowResponse = await FollowServices.addFollower({user:newUser, user_id:user_id,is_active:true});
            }
            if(changeFollowResponse.status === 'success'){
                setIsFollowing(true);
                message.success(`${i18next.t('dashboard.alerts.follow_success')} ${username}!`);
            }
            setLoading(false);
        }
        else{
            message.info(`${i18next.t('dashboard.alerts.follow_login')} ${username}!`);
        }
    }
    const removeFollow = async () => {
        if(user){
            setLoading(true);
            let changeFollowResponse = await FollowServices.addFollower({user:user, user_id:user_id,is_active:false})
            if(changeFollowResponse.status !== 'success') {
                const newUser = await refresh();
                changeFollowResponse = await FollowServices.addFollower({user:newUser, user_id:user_id,is_active:false});
            }
            if(changeFollowResponse.status === 'success'){
                message.success(`${i18next.t('dashboard.alerts.unfollow_success')} ${username}!`);
                setIsFollowing(false);
            }
            setLoading(false);
        }
        else{
            message.info(`${i18next.t('dashboard.alerts.follow_login')} ${username}!`);
        }
    }

    const checkFollowing = async () => {
        if(user){
            setLoading(true);
            const checkFollowResponse = await FollowServices.checkFollowing({user:user, user_id:user_id, follower_id:user.id})
            setIsFollowing(checkFollowResponse.is_following);
        }
        setLoading(false);
    }

    useEffect(() => {
        checkFollowing();
    },[])

    return(
        user_id !== user?.id &&
            isFollowing
            ? 
                <Popconfirm
                    title={`${i18next.t('dashboard.general.unfollow_confirm')} ${username}?`}
                    onConfirm={removeFollow}
                    okText={i18next.t('dashboard.general.yes')}
                    cancelText={i18next.t('dashboard.general.no')}
                    >
                    <Button type='primary' ghost={true} className="rounded-button" style={{color:'#0891b2',borderColor:'#0891b2',width:width}} size={size} loading={loading} disabled={disabled} >{i18next.t('dashboard.general.following')}</Button>
                </Popconfirm>
            : <Button type='primary' className="rounded-button" style={{backgroundColor:'#0891b2',borderColor:'#0891b2',width:width}} size={size} loading={loading} onClick={addFollow} disabled={disabled} >{i18next.t('dashboard.general.follow')}</Button>
    )
}

export default FollowButton;


