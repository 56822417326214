import { CloseCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import React, { useEffect, useState } from "react";

function PostImageWBox({imageUrl, boxes, selectItem, setSelectItem, hoverItem, sticky=false}){
    const [boxList, setBoxList] = useState(false);
    const [isShowing,setIsShowing] = useState(true);
    const [selectedBox, setSelectedBox] = useState(false);

    const boxHover = (clickIndex, isClick=false) => {
        boxes.map((element,index) => {
            const box = document.getElementById(`box-${index}`);
            if(index === clickIndex) {
                box.style.display = 'block';
            }
            else{
                box.style.display = 'none';        
            }
            box.style.height = box.height * 1.1
            if(!isClick){
                box.classList.remove('landing-title-fadein');
                box.classList.add('landing-title-fadein-fast');    
            }
        });
    }
    const boxHoverOut = () => {
        if(selectedBox === false){
            boxes.map((element,index) => {
                const box = document.getElementById(`box-${index}`);
                box.style.display = 'block';
                box.style.height = box.height * 0.8
            });
        }
    }

    const boxClick = (boxIndex) => {
        if(boxIndex){
            boxHover(boxIndex, true);
            setSelectedBox(boxIndex);
            setSelectItem(boxes[boxIndex]); 
        }
    }
    const boxClear = () => {
        setSelectedBox(false);
        boxes.map((element,index) => {
            const box = document.getElementById(`box-${index}`);
            box.style.display = 'block';
        });
    }

    const resizeBox = () => {
        let heightRatio = 400 / boxes[0]['image_height'];

        if(window.innerWidth < 1000){
            heightRatio = (window.innerWidth*0.95)/ boxes[0]['image_width'];
        }
        boxes.forEach((value) => {
            value['width'] = value['bbox_width'] * heightRatio;
            value['height'] = value['bbox_height'] * heightRatio;
            value['top'] = value['bbox_y'] * heightRatio;
            value['left'] = value['bbox_x'] * heightRatio;
        });
        setBoxList(boxes);
    }
    window.addEventListener("resize", function () {
        resizeBox();
    });

    useEffect(() => {
        resizeBox();
    },[]);

    useEffect(() => {
        if(selectItem){
            boxClick(selectItem['index']);
        }
        if(hoverItem){
            boxHover(hoverItem);
        }
        else {
            if(selectItem){
                boxHoverOut();
            }
        }
    },[selectItem,hoverItem]);

    return(
        <div className={`product-image-container ${sticky ? 'mobile-sticky' : ''}`}>
            <div className='product-image-div' >
                <img src={imageUrl} className='product-image-for-box' id='image-div' />
            </div>
            <div className="product-image-toggle" onClick={() => setIsShowing(!isShowing)}>
                {isShowing ? 'Hide' : `Show ${boxes.length} Products`}
            </div>
            {
                selectedBox
                ?
                    <div className="product-image-toggle product-image-clear-boxes" onClick={() => boxClear()}>
                        Clear Selected
                    </div>
                :''
            }
            {
                boxList && isShowing
                ?
                    
                    boxList.map((value,index) => 
                        <div key={index} id={`box-${index}`} className='landing-title-fadein' style={{position: 'absolute', top: value['top'], left: value['left']}} >
                            <div className="product-bounding-box"  style={{minHeight: value['height'], minWidth: value['width']}} onClick={() => {selectedBox ? boxClear() : boxClick(index)}} onMouseOver={() => boxHover(index)} onMouseOut={() => boxHoverOut(index)}>
                            </div>
                            <div className="product-bounding-box-name">
                                {value['label_name']}
                            </div>
                            {index === selectedBox ? <CloseCircleOutlined className="product-box-close" onClick={() => boxClear()} /> : ''}
                        </div>
                    )
                : ''
            }
        </div>
    )

}

export default PostImageWBox;