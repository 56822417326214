import { Avatar, Button, Drawer, Form, Modal, Space } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "../../Layout/DiscoveryLayout";
import UserCardContent from "../../Card/User/UserCardContent";
import { useAuth } from "../../../utils/useAuth";
import noImage from '../../../images/no_image.webp';
import { CloseOutlined } from "@ant-design/icons";

function ApplicationDrawer({values, isApplicationDrawerVisible, setIsApplicationDrawerVisible}){
    const [form] = Form.useForm();
    const {user} = useAuth();
    const {clickRate,clickOffer} = useLocation();
    const [miscValues,setMiscValues] = useState({});

    const onClose = () => {
        setIsApplicationDrawerVisible(false);
        form.resetFields();
        setMiscValues({});
    }

    return(        
        <Drawer
            open={isApplicationDrawerVisible} 
            bodyStyle={{maxHeight:'95vh'}}
            footer={null} 
            onClose={onClose}
            placement="bottom"
            width={'100%'}
            height={window.innerWidth > 1000 ? '60vh' : '95vh'}
            centered={true}
            destroyOnClose={true} >
            <div id='post-modal'></div>
            <div className='create-post-modal-container'>
                <div style={{maxWidth:'300px',margin:'auto'}}>
                    <div style={{fontSize:'18px',fontWeight:'500'}}>
                        {
                            values?.service_id &&
                                <div style={{marginBottom:'30px'}}>
                                    <div style={{lineHeight:1.3,textAlign:'center',marginBottom:'15px'}}>
                                        <h3>{values?.service_id?.name}</h3>
                                    </div>
                                    <div style={{textAlign:'center'}}>
                                        <div>
                                            Collab:
                                        </div>
                                        <Space>
                                            <div>
                                                <Avatar size={50} src={values?.sender_id?.avatar_img || noImage} />
                                            </div>
                                            <div>
                                                <CloseOutlined />
                                            </div>
                                            <div >
                                                <Avatar size={50} src={user?.avatar_img || noImage} />
                                            </div>
                                        </Space>
                                    </div>
                                </div>
                        }
                    </div>
                    <div style={{width:'100%',margin:'auto',marginBottom:'25px'}}>
                        <div className="user-card-container">
                            <div style={{padding:'10px'}}>
                                <div style={{marginBottom:'5px'}}>
                                    <UserCardContent user={values?.sender_id} hideButtons={true} />
                                </div>
                                <div>
                                    <Button type={'outline'} onClick={() => clickRate(values?.sender_id)} className='rounded-button hover-scale-sm' clickOffer={() => clickOffer(values?.sender_id)} style={{color:'#ef3078',backgroundColor:'white',borderColor:'#ef3078',borderRadius:'50px',fontSize:'12px',width:'100%',padding:0}} >
                                        View Rates
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{padding:'10px',margin:'auto',paddingBottom:'120px'}}>
                        <div style={{fontSize:'18px'}}>
                            {
                                values?.description && values?.description !== '' &&
                                    <div style={{marginBottom:'35px'}}>
                                        <div style={{marginBottom:'8px',fontWeight:'500'}}>
                                            Message:
                                        </div>
                                        <div style={{lineHeight:1.3,color:'rgba(0,0,0,0.6)'}}>
                                            {values?.description}
                                        </div>
                                    </div>
                            }
                            {
                                values?.answers && values?.answers.length > 0 &&
                                    <div>
                                        {
                                            values?.answers.map((value) => 
                                                <div style={{marginBottom:'35px'}}>
                                                    <div style={{lineHeight:1.3,marginBottom:'8px',fontWeight:'500'}}>
                                                        {value?.question}:
                                                    </div>
                                                    <div style={{color:'rgba(0,0,0,0.6)'}}>
                                                        {value?.value}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                            }
                        </div>
                    </div>
                    <div style={{width:'100%',position:'absolute',bottom:0,left:0,right:0,padding:'10px',backgroundColor:'white'}}>
                        <Button type={'primary'} size="large" onClick={() => clickOffer(values?.sender_id)} className='rounded-button hover-scale-sm' style={{color:'white',backgroundColor:'#ef3078',border:0,borderRadius:'50px',fontWeight:'600',fontSize:'14px',width:'100%',padding:0}} >
                            Work with {values?.sender_id?.username}
                        </Button>
                    </div>
                </div>
            </div>
        </Drawer>
    )
}
export default ApplicationDrawer;