import {Button,Modal} from "antd";
import RateTable from "../../Table/RateTable";
import { useLocation } from "../../Layout/DiscoveryLayout";

function RateCardModal({username, values, isRateCardModalVisible, setIsRateCardModalVisible}){
    
    const {clickOffer} = useLocation();

    const selectOffer = () => {
        clickOffer(values);
        setIsRateCardModalVisible(false);
    }

    const onClose = () => {
        setIsRateCardModalVisible(false);
    }
    
    return(
        <Modal             
            open={isRateCardModalVisible} 
            style={{marginTop:'10px',maxWidth:'650px',borderRadius:'15px',overflow:'hidden'}}
            width={'100%'}
            bodyStyle={{paddingTop:'40px'}}
            footer={null} 
            onCancel={onClose}
            centered={true}
            destroyOnClose={true}
            >
            <div id='post-modal'></div>
            <div className='create-post-modal-container' style={{paddingBottom:'10px'}}>
                <div style={{padding:'5px',marginBottom:'5px'}}>
                    <h2 style={{marginBottom:0}}>{username || values?.username}'s Rate Card</h2>
                </div>
                <div>
                    {
                        values?.rate && values?.rate.length > 0
                        ?
                            <div>
                                <div style={{marginBottom:'10px'}}>
                                    <RateTable data={values?.rate} />
                                </div>
                                <div style={{textAlign:'right'}}>
                                    <Button type={'outline'} onClick={selectOffer} className='rounded-button hover-scale-sm' style={{color:'#ef3078',backgroundColor:'white',borderColor:'#ef3078'}} >
                                        Send Offer
                                    </Button>
                                </div>
                            </div>
                        :
                            <div style={{paddingTop:'20px',paddingBottom:'15px'}}>
                                <div>
                                    <Button type={'outline'} size={'large'} onClick={selectOffer} className='rounded-button hover-scale-sm' style={{color:'#ef3078',backgroundColor:'white',borderColor:'#ef3078',width:'100%'}} >
                                        Send Offer
                                    </Button>
                                </div>
                                <div style={{marginTop:'5px',marginBottom:'5px',textAlign:'center'}}>
                                    <h3 style={{fontWeight:'400'}}>Not Rates for this User yet!</h3>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </Modal>
    )
}
export default RateCardModal;