import { Button, Checkbox, Form, Input } from "antd";
import { useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import logoDarkWide from '../../images/logo/logo.png';
import { Link } from "react-router-dom";
import { useAuth } from "../../utils/useAuth";
import i18next from '../../utils/i18n';
import { useEventTracker } from "../Context/EventTrackingContext";

function LoginForm({closeModal=false, setLoggedIn=false}) {
    const {login} = useAuth();
    const [loading, setLoading] = useState(false);
    const {sendEvent} = useEventTracker();

    const forgotPassword = () => {
        sendEvent({category:'login', action:'forgot_password'});
        window.location.href = 'mailto:team@wanderc.art';
    }
    const onFinish = (values) => {
        // Login with Auth Service
        setLoading(true);
        sendEvent({category:'login', action:'submit'});
        login({username: values.username, password:values.password,setLoading:setLoading});
    };

    return(
        <div style={{paddingBottom:'3vh'}}>
            <div>
                <img src={logoDarkWide} alt="wandercart-logo" style={{height:'3rem'}} />
            </div>
            <Form
                name="normal_login"
                className="login-form"
                layout="vertical"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                style={{textAlign:'left'}}
                >
                <Form.Item
                    name="username"
                    style={{marginTop:'10px',marginBottom:'5px'}}
                    rules={[
                        {
                            required: true,
                            message: i18next.t('dashboard.auth.username_error_missing'),
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined style={{color:'rgba(0,0,0,0.4)'}} />} style={{borderRadius:'10px'}} placeholder={i18next.t('dashboard.auth.username')} size='large' />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: i18next.t('dashboard.auth.password_error_missing'),
                        },
                    ]}
                    style={{marginBottom:'0'}}
                    hasFeedback
                    >
                    <Input.Password
                        prefix={<LockOutlined style={{color:'rgba(0,0,0,0.4)'}} />}
                        type="password"
                        placeholder={i18next.t('dashboard.auth.password')}
                        size='large'
                        style={{borderRadius:'10px'}} 
                        />
                </Form.Item>
                <div style={{marginBottom:'2vh'}}>
                    <a className="login-form-forgot" style={{fontSize:'12px',padding:'5px'}} onClick={forgotPassword}>
                        {i18next.t('dashboard.auth.password_forget')}
                    </a>
                </div>
                <div>
                    <div>
                        <Button type="primary" htmlType="submit" className="rounded-button login-form-button" loading={loading}>{i18next.t('dashboard.auth.login')}</Button>
                    </div>
                    <div style={{paddingTop:'3vh',textAlign:'center'}}>
                        <Link to='/register'>
                            {i18next.t('dashboard.auth.create_account')}
                        </Link>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default LoginForm;