import { Select } from "antd";

const genderList = [
    {
        label:'Male',
        value:'Male'
    },
    {
        label:'Female',
        value:'Female'
    },
    {
        label:'Transgender',
        value:'Transgender'
    },
    {
        label:'Other',
        value:'Other'
    }
]

function GenderSelect({onSelect,defaultValue}){
    const { Option } = Select;

    return(
        <Select 
            defaultValue={defaultValue} 
            onSelect={onSelect}
            showSearch={true}
            bordered={false} 
            mode='tags'
            size="large"
            placeholder='Enter All Relevant Genders Here'
            className="font-select">
            {
                genderList.map((value,index) => 
                    <Option key={`gender-select-${index}`} value={value.value} style={{height:'90%',maxHeight:'50px'}} >{value.label}</Option>
                )
            }
        </Select>
    )
}

export default GenderSelect;

