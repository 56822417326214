import { useEffect, useState } from "react";
import PostSkeletonCard from "../Skeleton/PostSkeletonCard";
import ProductCard from "../Card/ProductCard";

function ProductGallery({values, key='gallery', theme=false, showEdit=false, clickProduct=false, clickEditProduct=false, columns=4,loading=false}){
    const [list1,setList1]=useState(false);
    const [list2,setList2]=useState(false);
    const [list3,setList3]=useState(false);
    const [list4,setList4]=useState(false);

    const splitValues = async () => {
        let newArr1 = [...values];
        newArr1 = await newArr1.filter((_,i) => i % columns === 0);
        setList1(newArr1);

        if(columns > 1 && columns !== 3){
            let newArr2 = [...values];
            newArr2 = await newArr2.filter((_,i) => (i+3)% columns === 0);
            setList2(newArr2);
        }
        if(columns > 2){
            let newArr3 = [...values];
            newArr3 = await newArr3.filter((_,i) => (i+2)% columns === 0);
            setList3(newArr3);
        }
        if(columns > 3 || columns === 3){
            let newArr4 = [...values];
            newArr4 = await newArr4.filter((_,i) => (i+1)% columns === 0);
            setList4(newArr4);
        }
    };

    useEffect(() => {
        splitValues();
    },[values,columns]);

    return(
        <div className="gallery-row" style={{flex:1,width:'100%'}}>
            {
                list1 &&
                    <div className="gallery-column" style={{width:`${100/columns}%`}}>
                        {
                            list1.map((value,index) => 
                                <ProductCard key={`${key}_col1_${index}`} theme={theme} values={value} showEdit={showEdit} clickProduct={clickProduct} clickEditProduct={clickEditProduct} />
                            )
                        }
                        {
                            loading &&
                                Array.from(Array(5).keys()).map((_,index) => 
                                    <PostSkeletonCard key={`col1_${index}_loading`} aspectRatio={1/1} width="100%" />
                                )
                        }
                    </div>
            }
            {
                list2 &&
                    <div className="gallery-column" style={{width:`${100/columns}%`}}>
                        {
                            list2.map((value,index) => 
                                <ProductCard key={`${key}_col2_${index}`} theme={theme} values={value} showEdit={showEdit} clickProduct={clickProduct} clickEditProduct={clickEditProduct} />
                            )
                        }
                        {
                            loading &&
                                Array.from(Array(5).keys()).map((_,index) => 
                                    <PostSkeletonCard key={`col2_${index}_loading`} aspectRatio={1/1} width="100%" />
                                )
                        }
                    </div>
            }
            {
                list3 &&
                    <div className="gallery-column" style={{width:`${100/columns}%`}}>
                        {
                            list3.map((value,index) => 
                                <ProductCard key={`${key}_col3_${index}`} theme={theme} values={value} showEdit={showEdit} clickProduct={clickProduct} clickEditProduct={clickEditProduct} />
                            )
                        }
                        {
                            loading &&
                                Array.from(Array(5).keys()).map((_,index) => 
                                    <PostSkeletonCard key={`col3_${index}_loading`} aspectRatio={1/1} width="100%" />
                                )
                        }
                    </div>
            }
            {
                list4 &&
                    <div className="gallery-column" style={{width:`${100/columns}%`}}>
                        {
                            list4.map((value,index) => 
                                <ProductCard key={`${key}_col4_${index}`} theme={theme} values={value} showEdit={showEdit} clickProduct={clickProduct} clickEditProduct={clickEditProduct} />
                            )
                        }
                        {
                            loading &&
                                Array.from(Array(5).keys()).map((_,index) => 
                                    <PostSkeletonCard key={`col4_${index}_loading`} aspectRatio={1/1} width="100%" />
                                )
                        }
                    </div>
            }
        </div>
    )
}

export default ProductGallery;