import axios from "axios";

const SHOP_URL = `${process.env.REACT_APP_SERVER_URL}/shop/${process.env.REACT_APP_API_KEY}`;

class BrandServices {
    createEditBrand({values, user, brand_id=false}){
        const configuration = {
            credentials: 'include',
            method: "post",
            url: SHOP_URL + "/brand",
            data: values,
            headers: {"Authorization" : `Bearer ${user.access}`},
        };

        if(brand_id){
            configuration['method'] = 'patch';
            configuration['url'] = SHOP_URL + ` /brand/${brand_id}`;
        }

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }
    getBrand({brand_id}) {
        const configuration = {
            method: "get",
            url: SHOP_URL + `/brand/${brand_id}`
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    };
    getBrandsList({page=0, ordering='-updated_at',  user_id=false, brand_id=false, service_id=false, filters=false}) {

        const limit = 12;
        const offset = limit * page || 0;

        var configAPIUrl = SHOP_URL
        if (offset > 0){configAPIUrl = SHOP_URL + `/brands?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True`}
        else { configAPIUrl = SHOP_URL + `/brands?limit=${limit}&ordering=${ordering}&is_active=True` };

        if(user_id){
            configAPIUrl = configAPIUrl + `&user_id=${user_id}`;
        }
        if(brand_id){
            configAPIUrl = configAPIUrl + `&brand_id=${brand_id}`;
        }
        if(service_id){
            configAPIUrl = configAPIUrl + `&service_id=${service_id}`;
        }
        if(filters?.search){
            configAPIUrl = configAPIUrl + `&search=${filters?.search.join(',')}`;
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    } 

    getFonts(){

        const configuration = {
            method: "get",
            url: `https://webfonts.googleapis.com/v1/webfonts?key=${process.env.REACT_APP_GOOGLE_FONT_API_KEY}`
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }
}

export default new BrandServices();