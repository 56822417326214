import axios from "axios";

const SHOP_URL = `${process.env.REACT_APP_SERVER_URL}/shop/${process.env.REACT_APP_API_KEY}`;

class ShopServices {

    createEditBrand({values, user, brand_id=false}){
        const configuration = {
            credentials: 'include',
            method: "post",
            url: SHOP_URL + "/brand",
            data: values,
            headers: {"Authorization" : `Bearer ${user.access}`},
        };

        if(brand_id){
            configuration['method'] = 'patch';
            configuration['url'] = SHOP_URL + `/brand/${brand_id}`;
        }

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }
    getBrand({brand_id=false}){
        const configuration = {
            credentials: 'include',
            method: "get",
            url: SHOP_URL + "/brands"
        };

        if(brand_id){
            configuration['url'] = SHOP_URL + `/brand/${brand_id}`;
        }

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }
    getBrandList({page=0, limit=12, ordering='-updated_at', user_id=false}){

        let queryUrl;

        const offset = limit * page || 0;

        if (offset > 0){queryUrl = SHOP_URL + `/brands?limit=${limit}&offset=${offset}&ordering=${ordering}`}
        else { queryUrl = SHOP_URL + `/brands?limit=${limit}&ordering=${ordering}` };


        if(user_id){
            queryUrl = queryUrl + `&user_id=${user_id}`;
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: queryUrl
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }
}

export default new ShopServices();