function ProductOptionCard({index,name,options}) {

    return(
        <div key={`product-option-${index}`} style={{display:'flex',alignItems:'center'}}>
            <div className="settings-button-icon-container">
                <div className="settings-button-icon" style={{fontSize:'13px',lineHeight:1.2,color:'rgba(0,0,0,0.8)'}}>
                    #{index}
                </div>
            </div>
            <div style={{padding:'10px'}}>
                <div style={{fontSize:'15px',lineHeight:1.2,color:'rgba(0,0,0,0.8)'}}>
                    {name}
                </div>
                <div style={{fontSize:'13px',color:'rgba(0,0,0,0.5)'}}>
                    {options.join(',')}
                </div>
            </div>
        </div>
    )

}

export default ProductOptionCard;