
function CollectionCard({values,key}) {

    return(
        <div className='collection-card'  key={key}>
            <div className='post-image-container' style={{borderRadius:'30px'}} >
                <div className='collection-image' style={{backgroundImage:`url(${values?.imgUrl})`}} ></div>
            </div>
            <div className="collection-text">
                {values?.name}
            </div>
        </div>
    )
}

export default CollectionCard;