import { Tag } from "antd";

const tagColors = {
    'Lifestyle':'magenta', 
    'Gaming':'red',
    'Food & Drink':'volcano',
    'Fashion':'orange', 
    'Beauty':'gold',
    'Travel':'lime',
    'Parenting & Family':'green', 
    'Music':'cyan', 
    'Humor & Comedy':'blue',
    'Technology & Gadget':'geekblue', 
    'Education':'purple',
    'Business':'red',
    'Health':'magenta',
    'Photography':'volcano',
    'Cooking':'green'
}

function CategoryTag({value,onClick=false}){

    return(
        <Tag color={tagColors[value]} className="hover-scale-sm" onClick={() => onClick && onClick(value)} style={{borderRadius:'25px',marginLeft:0,marginRight:'5px'}}>{value}</Tag>
    )
}
export default CategoryTag;

