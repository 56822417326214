import { Button, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import AuthServices from "../../utils/AuthServices";
import logoDarkWide from '../../images/logo/logo.png';
import logo from '../../images/logo/icon-no-bg.webp';
import { Link } from "react-router-dom";
import { useAuth } from "../../utils/useAuth";
import { CheckCircleFilled, CloseCircleOutlined, LoadingOutlined, LockOutlined, MailOutlined,UserOutlined } from "@ant-design/icons";
import i18next from '../../utils/i18n';
import { useEventTracker } from "../Context/EventTrackingContext";
import UserServices from "../../utils/UserServices";

function RegisterForm({username=false,startOnboarding=false}){
    const {register,setUser} = useAuth();
    const {sendEvent} = useEventTracker();
    const [form1] = Form.useForm();
    const [usernameValue,setUsernameValue] = useState('');
    const [emailValue,setEmailValue] = useState('');
    const [userValidating, setUserValidating] = useState(false);
    const [emailValidating, setEmailValidating] = useState(false);
    const [userValidated, setUserValidated] = useState(false);
    const [emailValidated, setEmailValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    const usernameChange = (value) => {
        setUsernameValue(value.target.value);
    }
    const emailChange = (value) => {
        setEmailValue(value.target.value);
    }

    const showError = (values) => {
        values.forEach((value) => {
            message.error(value);
        });
    }

    const onFinish = async (values) => {
        setLoading(true);
        form1.validateFields();

        if(startOnboarding){
            const registerResponse = await AuthServices.register({username:usernameValue, email:values.email, password:values.password});
            if(registerResponse.status === 400 && Object.keys(registerResponse.data).length > 0){
                if(registerResponse.data?.username){
                    showError(registerResponse.data?.username);
                }
                if(registerResponse.data?.email){
                    showError(registerResponse.data?.email);
                }
                if(registerResponse.data?.password){
                    showError(registerResponse.data?.password);
                }
            }
            else{
                if(registerResponse.status === 201){
                    let loginResponse = await AuthServices.login({username:usernameValue, password:values.password, noMessage:true});
                    if(loginResponse !== 201 && loginResponse !== 401){
                        const userResponse = await UserServices.getUser({user_id:loginResponse.user_id});
                        let userFull = await Object.assign(userResponse.data, loginResponse);
                        var updateDate = new Date();
                        userFull['updated_at'] = updateDate;
                        startOnboarding(userFull);
                        setUser(userFull);
                    }
                }
            }
            setLoading(false);
        }
        else{
            register({username: usernameValue, email: values.email, password: values.password});
        }
        //sendEvent({category:'register', action:'submit'});
    };

    useEffect(() => {
        if(username){
            form1.setFieldsValue({username:username});
            setUsernameValue(username);
            setUserValidated(true);
        }
    },[username]);

    return(
        <div style={{paddingBottom:'3vh'}}>
            <div style={{paddingTop:'2vh',paddingBottom:'1vh'}}>
                <div className="align-div" style={{verticalAlign:'middle'}}>
                    <img src={logo} alt="wandercart-logo" style={{height:'36px',aspectRatio:1/1,verticalAlign:'middle',marginRight:'5px'}} />
                </div>
                <div className="align-div" style={{verticalAlign:'middle'}}>
                    <h1 style={{fontSize:'32px',color:'rgba(0,0,0,0.8)',verticalAlign:'middle',lineHeight:1,marginBottom:0}}><strong>WanderCart</strong></h1>
                </div>
            </div>
            <div style={{marginBottom:'3vh'}}>
                <h3 style={{color:'rgba(0,0,0,0.5)'}}>
                    {
                        startOnboarding
                        ? `Create User Account to Complete Onboarding!`
                        : i18next.t('dashboard.auth.register_title')
                    }
                </h3>
            </div>
            <Form
                form={form1}
                name="register"
                layout="vertical"
                onFinish={(value) => {setLoading(true);onFinish(value)}}
                scrollToFirstError
                >
                <Form.Item
                    name="username"
                    tooltip={i18next.t('dashboard.auth.username_description')}
                    style={{marginBottom:'5px'}}
                    rules={[
                        {
                            required: true,
                            message: i18next.t('dashboard.auth.username_error_missing'),
                            whitespace: true,
                        },
                        {
                            validator: async (_, value) => {
                                setUserValidating(true);
                                const check = await AuthServices.checkRegister({'username': value});
                                setUserValidating(false);
                                if(value.includes('_')){
                                    setUserValidated(false);
                                    return Promise.reject(i18next.t('dashboard.auth.username_error_underscore'));
                                }
                                else if(value.includes(' ')){
                                    setUserValidated(false);
                                    return Promise.reject(i18next.t('dashboard.auth.username_error_space'));
                                }
                                else if(check.usernameUnique){
                                    setUserValidated(true);
                                    return Promise.resolve();
                                } 
                                else {
                                    setUserValidated(false);
                                    return Promise.reject(i18next.t('dashboard.auth.username_error_exists'));
                                }
                            }
                        }
                    ]}
                    >
                    <Input size='large' suffix={usernameValue !== '' ? userValidating ? <LoadingOutlined style={{fontSize:'14px'}} /> : userValidated ? <CheckCircleFilled style={{color:'#52c41a',fontSize:'14px'}} /> : <CloseCircleOutlined style={{color:'#f5222d',fontSize:'14px'}} /> : null} prefix={<UserOutlined style={{color:'rgba(0,0,0,0.4)',paddingRight:'5px'}} />} placeholder={i18next.t('dashboard.auth.username')} value={usernameValue} onChange={usernameChange} style={{borderRadius:'10px'}} />
                </Form.Item>
                <Form.Item
                    name="email"
                    style={{marginBottom:'5px'}}
                    rules={[
                        {
                            type: 'email',
                            message: i18next.t('dashboard.auth.email_error_invalid'),
                        },
                        {
                            required: true,
                            message: i18next.t('dashboard.auth.email_error_missing'),
                        },
                        {
                            validator: async (_, value) => {
                                setEmailValidating(true);
                                const check = await AuthServices.checkRegister({'email': value});
                                setEmailValidating(false);
                                if (check.emailUnique) {
                                    setEmailValidated(true);
                                    return Promise.resolve();
                                } else {
                                    setEmailValidated(false);
                                    return Promise.reject(i18next.t('dashboard.auth.email_error_exists'));
                                }
                            }
                        }
                    ]}
                    >
                    <Input size='large' suffix={emailValue !== '' ? emailValidating ? <LoadingOutlined style={{fontSize:'14px'}} /> : emailValidated ? <CheckCircleFilled style={{color:'#52c41a',fontSize:'14px'}} /> : <CloseCircleOutlined style={{color:'#f5222d',fontSize:'14px'}} /> : null} prefix={<MailOutlined style={{color:'rgba(0,0,0,0.4)',paddingRight:'5px'}} />} placeholder={i18next.t('dashboard.auth.email')} style={{borderRadius:'10px'}} value={emailValue} onChange={emailChange}  />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: i18next.t('dashboard.auth.password_error_missing'),
                        },
                    ]}
                    style={{marginBottom:'0'}}
                    hasFeedback
                    >
                    <Input.Password size='large' suffix={<CheckCircleFilled style={{color:'#52c41a',fontSize:'14px'}} />}  prefix={<LockOutlined style={{color:'rgba(0,0,0,0.4)',paddingRight:'5px'}} />} placeholder={i18next.t('dashboard.auth.password')} style={{borderRadius:'10px'}} />
                </Form.Item>
                <div style={{padding:'10px',marginBottom:'2vh'}}>
                    <div style={{width:'90%',margin:'auto',fontSize:'13px'}}>
                        {i18next.t('dashboard.auth.privacy_agree')} <a href='/privacy' target="_blank">{i18next.t('dashboard.auth.privacy_link')}</a>.
                    </div>
                </div>
                <Form.Item>
                    <Button type="primary" className="rounded-button login-form-button" htmlType="submit" loading={loading} >
                        {
                            startOnboarding
                            ? 'Complete'
                            : i18next.t('dashboard.auth.sign_up')
                        }
                    </Button>
                    {
                        !startOnboarding &&
                            <div style={{padding:'5px'}}>
                                <Link to='/login'>
                                    {i18next.t('dashboard.auth.existing_account')}
                                </Link>
                            </div>
                    }
                </Form.Item>
            </Form>
        </div>
    )
}

export default RegisterForm;