import axios from "axios";
import Resizer from "react-image-file-resizer";

const SHOP_URL = `${process.env.REACT_APP_SERVER_URL}/shop/${process.env.REACT_APP_API_KEY}`;

const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 800, 800, 'JPEG', 100, 0,
        uri => {
        resolve(uri);
        }, 'file' );
});

class ServiceServices {
    createEditService({values, user, service_id=false}){
        const configuration = {
            credentials: 'include',
            method: "post",
            url: SHOP_URL + "/service",
            data: values,
            headers: {"Authorization" : `Bearer ${user.access}`},
        };

        if(service_id){
            configuration['method'] = 'patch';
            configuration['url'] = SHOP_URL + `/service/${service_id}`;
        }

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }
    getService({service_id}) {
        const configuration = {
            method: "get",
            url: SHOP_URL + `/service/${service_id}`
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    };
    getServiceList({page=0, ordering='-updated_at',  user_id=false, username=false, filters=false, status=false}) {

        const limit = 12;
        const offset = limit * page || 0;

        var configAPIUrl = SHOP_URL
        if (offset > 0){configAPIUrl = SHOP_URL + `/services?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True`}
        else { configAPIUrl = SHOP_URL + `/services?limit=${limit}&ordering=${ordering}&is_active=True` };

        if(user_id){
            configAPIUrl = configAPIUrl + `&user_id=${user_id}`;
        }
        if(username){
            configAPIUrl = configAPIUrl + `&user_id__username=${username}`;
        }
        if(filters?.type && filters?.type.length > 0){
            configAPIUrl = configAPIUrl + `&type=${filters?.type.join(',')}`;
        }
        if(filters?.socials && filters?.socials.length > 0){
            configAPIUrl = configAPIUrl + `&socials__type=${filters?.socials.join(',')}`;
        }
        if(filters?.category && filters?.category.length > 0){
            configAPIUrl = configAPIUrl + `&category__name=${filters?.category.join(',')}`;
        }
        if(filters?.search){
            configAPIUrl = configAPIUrl + `&search=${filters?.search.join(',')}`;
        }
        if(status){
            configAPIUrl = configAPIUrl + `&status=${status}`;
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }

    deleteService({service_id}) {
        const configuration = {
            method: "delete",
            url: SHOP_URL + `/service/${service_id}`
        };

        return axios(configuration)
        .then(response => {
            return response;
        })
        .catch((error) => {
            error = new Error();
        });
    };    
}

export default new ServiceServices();