import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {TwitterOutlined,FacebookOutlined,InstagramOutlined,LinkedinOutlined, MailOutlined, MessageOutlined, DatabaseOutlined, BarChartOutlined, LaptopOutlined, UserOutlined, BulbOutlined} from '@ant-design/icons';
import androidDownload from '../../images/download/android.webp';
import iosDownload from '../../images/download/ios.webp';
import '../../style/index.css';

function Footer({setIsDownloadModalVisible, noReveal=false}){
    var user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();

    return(
        <div style={{width:'100%',paddingBottom:'150px', backgroundColor:'rgb(24,144,255,0.02)',borderTop:'1px solid rgb(0,0,0,0.2)'}} >
            <div className={`home-page-footer-container ${!noReveal && 'reveal'}`}>
                <div>
                    <p className='home-page-title' style={{marginBottom:'20px'}}>Download</p>
                    <div style={{marginBottom:'25px'}}>
                        <img src={iosDownload} className='align-div download-links' onClick={() => setIsDownloadModalVisible(true)} />
                        <img src={androidDownload} className='align-div download-links' onClick={() => setIsDownloadModalVisible(true)} />
                    </div>
                </div>
                <div>
                    <p className='home-page-title' style={{marginTop:'50px',marginBottom:'10px'}}>Socials.</p>
                    <div style={{width: '100%', marginBottom:'20px'}}>
                        <a href='https://twitter.com/' target="_blank" rel="noreferrer" ><TwitterOutlined className='home-page-social-icon' title='Twitter' /></a>
                        <a href='https://www.facebook.com/' target="_blank" rel="noreferrer" ><FacebookOutlined className='home-page-social-icon' title='Facebook' /></a>
                        <a href='https://www.instagram.com/' target="_blank" rel="noreferrer" ><InstagramOutlined className='home-page-social-icon' title='Instagram' /></a>
                        <a href='https://www.linkedin.com/company/' target="_blank" rel="noreferrer" ><LinkedinOutlined className='home-page-social-icon' title='LinkedIn' /></a>
                    </div>
                </div>
                <div >
                    <p className='home-page-title' style={{marginTop:'50px',marginBottom:'10px'}}>Contact.</p>
                    <div>
                        <a href='https://discord.gg/' target="_blank" rel="noreferrer" ><MessageOutlined className='home-page-social-icon' title='Discord' /></a>
                        <a href='mailto:daiki@datarebel.io' target="_blank" rel="noreferrer" ><MailOutlined className='home-page-social-icon' title='Email' /></a>
                    </div>
                </div>
            </div>
        </div>
   )
};

export default Footer;