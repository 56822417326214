import { useEffect, useState } from "react";
import { useAuth } from "../../utils/useAuth";
import { Button, Popconfirm, message } from "antd";
import { HeartFilled, HeartOutlined, LoadingOutlined } from "@ant-design/icons";
import PostServices from "../../utils/PostServices";
import i18next from '../../utils/i18n';

function LikeButton({post_id, size='middle'}){
    const {user,refresh} = useAuth();
    const [isLiked,setIsLiked] = useState(false);
    const [loading,setLoading] = useState(false);

    const clickUnlike = async () => {
        setIsLiked(false);
        let unlikeResponse = await PostServices.savePost({post_id:post_id,user:user,is_active:false});
        if(unlikeResponse.status !== 'success') {
            const newUser = await refresh();
            unlikeResponse = await PostServices.savePost({post_id:post_id,user:newUser,is_active:false});
        }
        if(unlikeResponse.status === 'success'){
            setIsLiked(false);
            setLoading(false);
            message.success('Photo unliked.');
        }
        else{
            setLoading(false);
            message.info('There was an issue! Please try again.');
        }
    }
    const clickLike = async () => {
        if(user){
            setLoading(true);
            let likeResponse = await PostServices.savePost({post_id:post_id,user:user,is_active:true});
            if(likeResponse.status !== 'success') {
                const newUser = await refresh();
                likeResponse = await PostServices.savePost({post_id:post_id,user:newUser,is_active:true});
            }
            if(likeResponse.status === 'success'){
                setIsLiked(true);
                setLoading(false);
                message.success('Photo has been added to Saved!');
            }
            else{
                setLoading(false);
                message.info('There was an issue! Please try again.');
            }
        }
        else{
            message.info(`Login for Full Access!`);
        }
    }
    const checkLiked = async () => {
        const checkLikedResponse = await PostServices.checkPostSaved({post_id:post_id,user_id:user?.user_id});
        if(checkLikedResponse.data.is_liked){
            setIsLiked(true);
            setLoading(false);
        }
        else{
            setIsLiked(false);
            setLoading(false);
        }
    }

    useEffect(() => {
        checkLiked();
    },[])

    return(
        loading
        ? <Button icon={<LoadingOutlined size={12} />} disabled shape="circle" ></Button>
        :
            isLiked
            ? 
                <Popconfirm
                    title={`Are you sure you want to unlike?`}
                    onConfirm={clickUnlike}
                    okText="Yes"
                    cancelText="No"
                    >
                    <Button icon={<HeartFilled size={12} style={{color:'red'}} />} shape="circle" className="like-button" ></Button>
                </Popconfirm>
            : <Button icon={<HeartOutlined size={12} />} shape="circle" onClick={clickLike} className="like-button" ></Button>
    )
}

export default LikeButton;


