import { Select } from "antd"

const incomeList = [
    {
        label:'$',
        value:'$'
    },
    {
        label:'$$',
        value:'$$'
    },
    {
        label:'$$$',
        value:'$$$'
    },
    {
        label:'$$$$',
        value:'$$$$'
    }
]

function IncomeSelect({onSelect,defaultValue}){
    const { Option } = Select;

    return(
        <Select 
            defaultValue={defaultValue} 
            onSelect={onSelect}
            showSearch={true}
            bordered={false} 
            mode='tags'
            size="large"
            placeholder='Select All Income Ranges'
            className="font-select">
            {
                incomeList.map((value,index) => 
                    <Option key={`income-select-${index}`} value={value.value} style={{height:'90%',maxHeight:'50px'}} >{value.label}</Option>
                )
            }
        </Select>
    )
}

export default IncomeSelect;

