import { Form, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import LocationServices from "../../../utils/LocationServices";
import { LoadingOutlined } from "@ant-design/icons";
import i18next from "i18next";

function OnboadingForm2({formValues,setFormValues}){
    const [countryList,setCountryList] = useState([]);
    const [regionList,setRegionList] = useState([]);
    const [regionLoading,setRegionLoading] = useState(false);

    const getCountryValues = async () => {
        const countryResponse = await LocationServices.getCountryFlags();
        setCountryList(countryResponse.data);
    }
    const getRegionValues = async (country) => {
        setRegionLoading(true);
        const regionResponse = await LocationServices.getCountryCities({country:country});
        const regionStateResponse = await LocationServices.getCountryStates({country:country});
        const statesCleaned = await regionStateResponse.data.states.map(value => value.name);
        const cityStateList = [...regionResponse.data,...statesCleaned].sort();
        setRegionList(cityStateList);
        setRegionLoading(false);
    }

    const selectCountry = (value) => {
        setFormValues({...formValues,'country':value});
        getRegionValues(value);
    }

    useEffect(() => {
        if(formValues?.country){
            getRegionValues(formValues?.country);
        }
        else{
            getCountryValues();
        }
    },[])

    return(
        <div style={{width:'100%',paddingBottom:'5vh',textAlign:'left'}}>
            <div style={{width:'100%',paddingLeft:'10px',marginBottom:'10px'}}>
                <h2 style={{fontWeight:600,marginBottom:0,color:'rgba(0,0,0,0.8)'}}>
                    <strong>📍 {i18next.t('onboarding.panel2.question')}</strong>
                </h2>
            </div>
            <Form.Item style={{width:'100%',marginBottom:0}}>
                <div style={{padding:'10px'}}>
                    <div>
                        <h4>{i18next.t('onboarding.panel2.country_title')}:</h4>
                    </div>
                    <Select
                        showSearch
                        size='large'
                        style={{ width:'100%',border:'1px solid rgba(24, 144, 255, 0.6)',borderRadius:'10px'}}
                        bordered={false}
                        autoFocus={true}
                        placeholder={i18next.t('onboarding.panel2.country_placeholder')}
                        defaultValue={formValues?.country !== '' ? formValues?.country : null}
                        onChange={selectCountry}
                        >
                        {
                            countryList.length > 0 &&
                                countryList.map((value,index) => 
                                    <Select.Option key={`language-select-${index}`} value={value.name} className="home-onboarding-select-option" >
                                        <div className="home-onboarding-flag-img" style={{backgroundImage:`url(${value?.flag})`}}  /> {value?.name}
                                    </Select.Option>
                                )
                        }
                    </Select>
                </div>
            </Form.Item>
            {
                formValues?.country !== '' &&
                    <Form.Item style={{width:'100%'}}>
                        <div style={{padding:'10px'}}>
                            <div>
                                <h4 className="align-div" style={{verticalAlign:'top'}}>{i18next.t('onboarding.panel2.region_title')}:</h4>
                                {
                                    regionLoading && 
                                        <LoadingOutlined className="align-div" style={{fontSize:12,color:'#ef3078',verticalAlign:'top',marginLeft:'5px' }} spin={true} />
                                }
                            </div>
                            <Select
                                showSearch
                                size='large'
                                autoFocus={true}
                                style={{ width:'100%',border:'1px solid rgba(24,144,255,0.6)',borderRadius:'10px'}}
                                bordered={false}
                                placeholder={i18next.t('onboarding.panel2.region_placeholder')}
                                defaultValue={formValues?.region !== '' ? formValues?.region : null}
                                loading={regionLoading}
                                disabled={regionLoading}
                                onChange={(value) => setFormValues({...formValues,'region':value})}
                                >
                                {
                                    regionList.length > 0 &&
                                        regionList.map((value) => 
                                            <Select.Option key={`${value}`} value={value} className="home-onboarding-select-option" >
                                                {value}
                                            </Select.Option>
                                        )
                                }
                            </Select>
                        </div>
                    </Form.Item>
            }
        </div>
    )
}

export default OnboadingForm2;