import { Avatar, Button, Divider, Select, Space } from "antd"
import { useEffect, useState } from "react";
import { useAuth } from "../../utils/useAuth";
import ShopServices from "../../utils/ShopServices";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import CreateBrandDrawer from "../Drawers/Shop/CreateBrandDrawer";

const { Option } = Select;

const currencies = [
    {
        label:'English',
        value:'en',
        iso2:'US'
    },
    {
        label:'Indonesian',
        value:'id',
        iso2:'ID'
    },
];

function BrandSelect({onSelect,defaultValue}){
    const {user} = useAuth();
    const [brandList, setBrandList] = useState([]);
    const [loading,setLoading] = useState(true);
    const [isCreateBrandDrawerVisible,setIsCreateBrandDrawerVisible] = useState(false);

    const getBrands = async () => {
        setLoading(true)
        if(user){
            const brandListResponse = await ShopServices.getBrand({user_id: user.user_id});
            setBrandList(brandListResponse.data);
        }
        setLoading(false);
    }
    const addItem = (value) => {
        setBrandList([value,...brandList]);
    }

    useEffect(() => {
        getBrands();
    },[]);

    return(
        <div>
            {
                loading
                ?
                    <div style={{ width:'100%',border:'1px solid rgba(0,0,0,0.1)',borderRadius:'10px',display:'flex',alignItems:'center',height:'40px',paddingLeft:'15px',color:'rgba(0,0,0,0.4)'}}>
                        <div>
                            <LoadingOutlined  />
                        </div>
                        <div style={{paddingLeft:'10px',fontSize:'16px',flex:1}}>
                            Loading Brands...
                        </div>
                    </div>
                :
                    <Select 
                        defaultValue={defaultValue} 
                        onSelect={onSelect}
                        bordered={false} 
                        size="large"
                        style={{ width:'100%',border:'1px solid rgba(0,0,0,0.1)',borderRadius:'10px',paddingTop:0,height:'auto'}}
                        placeholder="Select Your Brand"
                        dropdownRender={menu => (
                            <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <div style={{padding:'0 4px 4px 0',textAlign:'right'}}>
                                    <Button type="text" size="small" icon={<PlusOutlined />} onClick={() => setIsCreateBrandDrawerVisible(true)} style={{color:'#ef3078',fontWeight:'500'}} >
                                        Add a Brand
                                    </Button>
                                </div>
                            </>
                        )}
                        >
                        {
                            brandList.length > 0 &&
                                brandList.map((value,index) => 
                                    <Option key={`brand-select-${index}`} value={value?.id} style={{height:'90%',maxHeight:'50px'}} >
                                        <Space >
                                            <Avatar src={value?.avatar?.image} style={{border:'1px solid rgba(0,0,0,0.2)'}} />
                                            {value.name}
                                        </Space>
                                    </Option>
                                )
                        }
                    </Select>
            }
            <CreateBrandDrawer isCreateBrandDrawerVisible={isCreateBrandDrawerVisible} setIsCreateBrandDrawerVisible={setIsCreateBrandDrawerVisible} addItem={addItem} />
        </div>            
    )
}

export default BrandSelect;

