import PostSkeletonCard from "../Skeleton/PostSkeletonCard";
import PostSkeletonRandomCard from "../Skeleton/PostSkeletonRandomCard";

function PostGalleryLoading({columns=4,cardCount=3,aspectRatio=false,borderRadius='20px'}){

    return(
        <div className="gallery-row" style={{flex:1,width: '100%'}}>
            {
                Array.from(Array(columns).keys()).map((value,index) => 
                    <div className="gallery-column" key={`${value}_${index}`} style={{width: `${100/columns}%`}}>
                        {
                            aspectRatio
                            ?
                                Array.from(Array(cardCount).keys()).map((cardValue) => 
                                    <div style={{aspectRatio:aspectRatio,padding:'5px'}}>
                                        <PostSkeletonCard key={`${value}_${index}_${cardValue}`} width="100%" borderRadius={borderRadius} aspectRatio={aspectRatio} />
                                    </div>
                                )
                            :
                                Array.from(Array(cardCount).keys()).map((cardValue) => 
                                    <PostSkeletonRandomCard key={`${value}_${index}_${cardValue}`} />
                                )
                        }
                    </div>
                )
            }
        </div>
    )
}

export default PostGalleryLoading;