import { Button } from "antd";

function OnboardingForm0({clickStart, onComplete=false}){

    return(
        <div style={{width:'100%',paddingBottom:'3vh'}}>
            <div style={{textAlign:'center'}}>
                <div style={{marginBottom:'2vh'}}>
                    <h2 style={{fontWeight:500,marginBottom:0,color:'rgba(0,0,0,0.8)'}}>
                        {
                            onComplete
                            ? <strong>Create Your Profile!</strong>
                            : <strong>Welcome to WanderCart!</strong>
                        }
                    </h2>
                </div>
                <div style={{marginBottom:'3vh'}}>
                    <h3 style={{fontWeight:500,marginBottom:0,color:'rgba(0,0,0,0.8)'}}>
                        <strong>2 Minutes to Complete.</strong>
                    </h3>
                </div>
                <div>
                    <Button type={'primary'} size='large' className='rounded-button' style={{backgroundColor:'#ef3078',borderColor:'#ef3078',fontWeight:'600'}} onClick={clickStart}>Get Started</Button>
                </div>
            </div>
        </div>
    )
}

export default OnboardingForm0;











