import React from 'react';
import { Modal } from "antd";
import logo from '../../images/logo/icon-no-bg.webp';
import OnboardingForm from '../Form/OnboardingForm';

function InfluencerOnboardingModal({isInfluencerOnboardingModalVisible, setIsInfluencerOnboardingModalVisible,defaultUser='influencer',onComplete=false,startPage=0}){

    return(
        <Modal
            open={isInfluencerOnboardingModalVisible} 
            bodyStyle={{ overflowY: 'auto', padding:window.innerWidth > 1000 ? '10px':0}}
            footer={null} 
            onCancel={() => setIsInfluencerOnboardingModalVisible(false)}
            width={window.innerWidth > 1000 ? '550px' : '100%'}
            maskStyle={{backgroundColor:'rgba(0,0,0,0.8)'}}
            destroyOnClose={true}
            centered={true} >
            <div className="modal-div" style={{paddingBottom:'35px',height:'90vh'}}>
                <div style={{width:'90%',margin:'auto',height:'100%'}}>
                    <OnboardingForm defaultUser={defaultUser} onComplete={onComplete} startPage={startPage} />
                </div>
            </div>
            <div style={{position:'absolute',bottom:0,left:0,right:0,backgroundColor:'rgba(255,255,255,0)',padding:'10px'}}>
                <img src={logo} alt="datarebel-logo" className="logo-style" />
            </div>
        </Modal>
    )
}
export default InfluencerOnboardingModal;