import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, Space } from "antd";
import SectionHeader from '../../Header/SectionHeader';
import CreateBrandForm from '../../Form/Shop/CreateBrandForm';
import ShopServices from '../../../utils/ShopServices';
import { useAuth } from '../../../utils/useAuth';

function CreateBrandDrawer({isCreateBrandDrawerVisible, setIsCreateBrandDrawerVisible, initialValue=false, addItem=false}){
    const [form] = Form.useForm();
    const {user,refresh} = useAuth();
    const [formValues, setFormValues] = useState({});
    const [isLoading,setIsLoading] = useState(false);
    const [isLoadingDraft,setIsLoadingDraft] = useState(false);

    const onFinish = async ({isDraft=false}) => {
        if(isDraft){ setIsLoadingDraft(true) }
        else{ setIsLoading(true)};

        let brandValues = {
            ...formValues,
            key:formValues?.name.toLowerCase().split(' ').join('_'),
            is_draft:isDraft
        }

        const postBrandResponse = await ShopServices.createEditBrand({values:brandValues,user:user});
        if(postBrandResponse.status !== 'success'){
            const newUser = await refresh();
            postBrandResponse = await ShopServices.createEditBrand({values:brandValues,user:newUser});
        }
        if(postBrandResponse.status === 'success'){
            addItem(postBrandResponse.data);
            setIsCreateBrandDrawerVisible(false);
        }

        if(isDraft){ setIsLoadingDraft(false) }
        else{ setIsLoading(false)};
    }

    const closeDrawer = () => {
        setIsCreateBrandDrawerVisible(false);
    }

    useEffect(() => {
        if(initialValue){
            console.log(initialValue);
            form.setFieldsValue(initialValue);
            setFormValues(initialValue);
        }
    },[initialValue]);

    return(
        <Drawer             
            open={isCreateBrandDrawerVisible} 
            bodyStyle={{ overflowY:'auto',maxHeight:'95vh'}}
            footer={null} 
            onClose={closeDrawer}
            width={window.innerWidth > 1000 ? '600px' :'100%'}
            centered={true}
            destroyOnClose={true}
            extra={
                <Space size={0}>
                    <Button type={'link'} ghost={true} className='rounded-button' loading={isLoadingDraft} style={{color:'#ef3078'}} onClick={() => onFinish({draft:true})}>Save as Draft</Button>
                    <Button type={'primary'} className='rounded-button' loading={isLoading} style={{color:'white',backgroundColor:'#ef3078',borderColor:'#ef3078'}} onClick={() => onFinish({draft:false})}>Create</Button>
                </Space>
            }>
            <div id='post-modal'></div>
            <div style={{padding:'5px',paddingBottom:'120px'}}>
                <div style={{padding:'15px'}}>
                    <SectionHeader name='Create New Brand' description='Brand will allow you to align values with audience, buyers and influencers!' />
                </div>
                <div>
                    <CreateBrandForm form={form} formValues={formValues} setFormValues={setFormValues} addItem={addItem} />
                </div>
            </div>
        </Drawer>
    )
}
export default CreateBrandDrawer;