import React, { useState } from 'react';
import { Button, Drawer, Space } from "antd";
import SectionHeader from '../../Header/SectionHeader';
import ShopServices from '../../../utils/ShopServices';
import { useAuth } from '../../../utils/useAuth';
import ProductOptionsForm from '../../Form/Shop/ProductOptionsForm';

function ProductOptionsDrawer({formValues,setFormValues, isProductOptionsDrawerVisible, setIsProductOptionsDrawerVisible, addItem=false}){
    const {user,refresh} = useAuth();
    const [isLoading,setIsLoading] = useState(false);
    const [isLoadingDraft,setIsLoadingDraft] = useState(false);


    const closeDrawer = () => {
        setIsProductOptionsDrawerVisible(false);
        var removeNullOptions = formValues?.product_options.filter(value => value.name !== null && value.options.length > 0);
        setFormValues({...formValues,product_options:removeNullOptions});
    }
    return(
        <Drawer             
            open={isProductOptionsDrawerVisible} 
            bodyStyle={{ overflowY:'auto',maxHeight:'95vh'}}
            footer={null} 
            onClose={closeDrawer}
            extra={
                <Button type={'primary'} className='rounded-button' onClick={closeDrawer} style={{color:'white',backgroundColor:'#ef3078',borderColor:'#ef3078'}} >
                    Save Changes
                </Button>
            }
            width={window.innerWidth > 1000 ? '550px' :'100%'}
            centered={true}
            destroyOnClose={true}
            >
            <div id='post-modal'></div>
            <div style={{padding:'5px',paddingBottom:'120px'}}>
                <div style={{paddingBottom:'20px'}}>
                    <SectionHeader name='Create Product Options' description='' />
                </div>
                <div>
                    <ProductOptionsForm formValues={formValues} setFormValues={setFormValues} addItem={addItem} closeDrawer={closeDrawer} />
                </div>
            </div>
        </Drawer>
    )
}
export default ProductOptionsDrawer;