class WhatsAppServices {
    
    sendMessage({phoneNumber, message=''}){

        const cleanPhoneNumber = phoneNumber.replace(/[^a-z0-9]/gi, '');
        const cleanMessage = encodeURIComponent(message);

        let cleanLink = `https://wa.me/${cleanPhoneNumber}?text=${cleanMessage}`;
        window.open(cleanLink);
    }
}

export default new WhatsAppServices();