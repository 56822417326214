
import { useEffect, useState } from "react";
import { BackgroundImages } from '../../../resources/BackgroundImages';
import { HeartOutlined, LeftOutlined } from "@ant-design/icons";
import RegisterForm from "../RegisterForm";
import { Button } from "antd";


function OnboardingFormLogin({formValues,onFinish,goBack}){
    const [backgroundImages,setBackgroundImages] = useState([]);

    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;
        while (currentIndex != 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        } 
        return array;
    }
    const setupArrays = async () => {
        let imageValues = [];
        const shuffle1 = await shuffle([...BackgroundImages]);
        imageValues = [...imageValues,shuffle1];
        const shuffle2 = await shuffle([...BackgroundImages]);
        imageValues = [...imageValues,shuffle2];
        if(window.innerWidth > 1000){
            const shuffle3 = await shuffle([...BackgroundImages]);
            imageValues = [...imageValues,shuffle3];
        }
        setBackgroundImages(imageValues);
    }
    useEffect(() => {
        setupArrays();
    },[]);
    return(
        <div>
            <div style={{position:'absolute',top:'65px',left:0,right:0,bottom:0,overflowY:'hidden',opacity:0.1}}>
                <div style={{height:'100%'}}>
                    {
                        backgroundImages.map((value,index) => 
                            <div key={index} className='align-div' style={{width: window.innerWidth > 1000 ? `${100/3}%` :'50%', marginTop: index % 2 === 0 ? '0px':'-200px'}}>
                                {
                                    value.slice(5).map((postValue,postIndex) => 
                                        <div key={postIndex} className='post-image-container' style={{padding:5}}>
                                            <div className='post-image' style={{backgroundImage:`url(${postValue.image})`}} >
                                            </div>
                                            <div className="post-like-container">
                                                <Button icon={<HeartOutlined size={12}/>} shape="circle" ></Button>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
            <div style={{width:'100%',textAlign:'center'}}>
                <div className="bordered" style={{width:window.innerWidth > 1000 ? '80%' : '98%',margin:'auto',position:'relative',backgroundColor:'white',padding:'25px',paddingBottom:'15px'}}>
                    <RegisterForm startOnboarding={onFinish} username={formValues?.username} />
                </div>
            </div>
            <div style={{position:'absolute',top:'85px',left:'20px'}}>
                <Button type="circle" icon={<LeftOutlined size={32} color={'rgba(0,0,0,0.8)'} style={{strokeWidth:'5'}} />} onClick={goBack} style={{border:'1px solid rgba(0,0,0,0.2)',backgroundColor:'white'}} size='large' />
            </div>
        </div>
    )
}

export default OnboardingFormLogin;