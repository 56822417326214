import { CloseCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import React, { useEffect, useState } from "react";

function PostImage({values, selectChange}){
    const [boxList, setBoxList] = useState(false);
    const [isShowing, setIsShowing] = useState(false);

    const mouseOver = (value) => {
        if(window.innerWidth > 1000){
            setIsShowing(value);
        }
    }

    const imageClick = () => {

    }
    
    const resizeBox = (boxes) => {
        var imgCard = document.getElementById('image-card');

        let heightRatio = imgCard.offsetHeight / boxes[0]['image_height'];

        boxes.forEach((value) => {
            value['width'] = value['bbox_width'] * heightRatio;
            value['height'] = value['bbox_height'] * heightRatio;
            value['top'] = value['bbox_y'] * heightRatio;
            value['left'] = value['bbox_x'] * heightRatio;
        });
        setBoxList(boxes);
    }
    window.addEventListener("resize", function () {
        if(Object.keys(values).includes('boxes')){
            resizeBox(values['boxes']);
        }
    });

    useEffect(() => {
        if(Object.keys(values).includes('boxes')){
            resizeBox(values['boxes']);
        }
    },[]);

    return(
        <div id='image-card' className="product-image-card" onClick={() => imageClick()} onMouseOver={() => mouseOver(true)} onMouseOut={() => mouseOver(false)}>
            {
                Object.keys(values).includes('name')
                ?
                    <div className="product-bounding-box-name product-image-card-tag" onClick={() => selectChange(values['index'])}>
                        {values.name}
                    </div>
                : ''
            }
            {
                Object.keys(values).includes('price')
                ?
                    <div className="product-bounding-box-name product-image-card-price-tag">
                        {values.price}
                    </div>
                : ''
            }
            {
                Object.keys(values).includes('productCount')
                ?
                    <div className="product-bounding-box-name product-image-card-show-products">
                        Shop {boxList ? boxList.length : ''} Products
                    </div>
                : ''
            }
            {
                Object.keys(values).includes('boxes')
                ?
                    <div style={{position:'relative'}}>
                        {
                            values['boxes'][0]['image_width'] / values['boxes'][0]['image_height'] > 2/3
                            ? <img src={values['imgUrl']} style={{height: '400px', width: 'auto'}} />
                            : <img src={values['imgUrl']} style={{height: 'auto', width: '100%'}} />
                        }
                        {
                            boxList && isShowing
                            ?
                                boxList.map((value,index) => 
                                    <div key={index} id={`box-${index}`} className='landing-title-fadein-fast reveal-mobile' style={{position: 'absolute', top: value['top'], left: value['left']}} >
                                        <div className="product-bounding-box-preview"  style={{height: value['height'], width: value['width']}} >
                                        </div>
                                        <div className="product-bounding-box-name product-bounding-box-name-preview">
                                            {value['label_name']}
                                        </div>
                                    </div>
                                )
                            : ''
                        }
                    </div>
                : <img src={values['imgUrl']} className='product-image-full' />
            }
        </div>
    )

}

export default PostImage;