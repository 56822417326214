import { CalendarOutlined, DownloadOutlined, HomeOutlined, MenuUnfoldOutlined, RiseOutlined, ShopOutlined, ShoppingCartOutlined, ShoppingOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/useAuth";
import androidDownload from '../../images/download/android.webp';
import iosDownload from '../../images/download/ios.webp';
import i18next from '../../utils/i18n';
import { useEventTracker } from "../Context/EventTrackingContext";
import LanguageSelect from "../Select/LanguageSelect";

const pathKeys = {
    '':'1',
    'ootd':'3',
    'for-sellers':'4',
};

function MobileMenuDrawer({isOpen, setOpen}) {
    const navigate = useNavigate();
    const {sendEvent} = useEventTracker();
    const {user} = useAuth();
    const [selectedKey] = useState([pathKeys[window.location.pathname.split('/')[1]]]);

    const clickNavBar = (value) => {
        sendEvent({category:'navigation', action: value, label:'navbar'});
        navigate(`/${value}`);
    }
    const changeLanguage = (value) => {
        i18next.changeLanguage(value);
    }

    const menuItems = [
        {
            key: '0',
            icon: <HomeOutlined />,
            label: i18next.t('sidebar.home'),
            onClick: () => { clickNavBar('home'); }
        },
        {
            key: '1',
            icon: <RiseOutlined />,
            label: i18next.t('sidebar.trending'),
            onClick: () => { clickNavBar('trending'); }
        },
        {
            key: '2',
            icon: <ShoppingOutlined />,
            label: i18next.t('sidebar.products'),
            onClick: () => { clickNavBar('products'); }
        },
        {
            key: '3',
            icon: <TeamOutlined />,
            label: i18next.t('dashboard.general.creators'),
            onClick: () => { clickNavBar('posts'); }
        },
        {
            key:'4',
            icon:<UserOutlined/>,
            label: i18next.t('dashboard.general.login_register'),
            onClick: () => { clickNavBar('login'); }
        },
    ];

    const misc = [
        {
            key: '3',
            icon: <CalendarOutlined />,
            label: '#OOTD',
            onClick: () => { clickNavBar('ootd'); }
        },
    ]
    return(
        <Drawer title="" placement="right"  onClose={() => setOpen(false)} open={isOpen} width={'100%'} headerStyle={{display:'none'}} >
            <div className="mobile-menu-container">
                <div style={{height:'10vh'}}>
                    <div className="align-div">
                        <LanguageSelect defaultValue={i18next.language} onSelect={changeLanguage} onlyImage={true} />
                    </div>
                    <div className="align-div float-right">
                        <MenuUnfoldOutlined style={{fontSize:'21px',justifyContent:'center'}} onClick={() => setOpen(false)} />
                    </div>
                </div>
                <div className="mobile-menu-content">
                    {
                        menuItems.map((value,index) => 
                            <div key={index} className={`mobile-menu-item ${selectedKey === value['key'] ? 'mobile-menu-item-selected':''}`} onClick={value['onClick']}>
                                <div className="align-div mobile-menu-icon">
                                    {value['icon']}
                                </div>
                                <div className="align-div mobile-menu-text">
                                    {value['label']}
                                </div>
                            </div>
                        )
                    }
                    <div style={{marginTop:'5vh',textAlign:'center'}}>
                        <div>
                            <h3>Get WanderCart.</h3>
                        </div>
                        <div>
                            <a href='https://apps.apple.com/us/app/wandercart/id6450434248' target='_blank' className='align-div'>
                                <img src={iosDownload} className='navbar-download-links' style={{height:'3rem'}} />
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=com.daiki.wandercart.shopapp' target='_blank' className='align-div'>
                                <img src={androidDownload} className='navbar-download-links' style={{height:'3rem'}} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    )
}

export default MobileMenuDrawer;