import { AutoComplete, Button, Form, Input, Select, Space, Table } from "antd";
import { useEffect, useState } from "react";

const defaultColumns = [
    {
        title: 'Option title',
        dataIndex: 'title',
        width: '30%',
    },
    {
        title: 'Variation',
        dataIndex: 'variation',
        width:'70%'
    }
];

const optionItems = [{value:'Size'},{value:'Color'},{value:'Materials'},{value:'Pattern'}];
const optionValues = {
    'Color':["Red","Blue","Green","Black","White","Yellow","Pink"],
    'Size':["XS","S","M","L","XL","XXL"],
    'Materials':["Acrylic","Canvas","Cashmere","Chiffon","Cotton","Denim","Fleece","Jersey","Leather","Linen","Nylon","Polyester","Rayon","Satin","Silk","Spandex","Tulle","Twill","Velvet","Wool"],
    'Pattern':["Abstract","Animal Print","Batik","Camouflage","Camo","Chevron","Checkered","Color Block","Embroidered","Floral","Geometric","Gradient","Herringbone","Houndstooth","Ikat","Jacquard","Marble","Ombre","Paisley","Patchwork","Plaid","Polka Dot","Printed","Solid","Striped","Tartan","Textured","Tie-Dye","Tribal","Tropical"],
}

function ProductOptionsTable({dataValues, setDataValues,closeDrawer=false}){
    const [variantOptions,setVariantOptions] = useState([]);

    const onChange = (value,type,index) => {
        if(type === 'name'){
            if(Object.keys(optionValues).includes(value)){
                let cleanValue = optionValues[value].map((value) => {return {value:value,label:value}} )
                setVariantOptions(cleanValue);
            }
            else{
                setVariantOptions([]);
            }
        }

        let newData = [...dataValues];
        newData[index] = {...newData[index],[type]:value};
        setDataValues(newData);
    }
    const addRow = () => {
        setDataValues([...dataValues,{name:null,options:[]}]);
    }

    return(
        <div>
            <div style={{width:'100%',border:'1px solid #E5E7EB',borderRadius:'10px',overflow:'hidden',marginBottom:'10px'}}>
                <table style={{textAlign:'left',padding:'5px',color:'#4B5563',width:'100%'}}>
                    <tbody>
                        <tr style={{backgroundColor:'#F9FAFB',borderBottom:'1px solid #E5E7EB'}}>
                            <th style={{width:'30%',padding:'10px',fontWeight:'500',borderRight:'1px solid #E5E7EB'}}>Option title</th>
                            <th style={{width:'70%',padding:'10px',fontWeight:'500',borderRight:'1px solid #E5E7EB'}}>Variations</th>
                        </tr>
                        
                        {
                            dataValues.map((value,index) => 
                                <tr key={`option-row-${index}`} style={{backgroundColor:'white',borderBottom:'1px solid #E5E7EB'}}>
                                    <td style={{width:'30%',borderRight:'1px solid #E5E7EB',padding:'5px'}}>
                                        <AutoComplete
                                            options={optionItems}
                                            placeholder="Size, Material"
                                            style={{width:'100%'}}
                                            bordered={false} 
                                            value={value['name']}
                                            filterOption={(inputValue, option) =>
                                                option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                                            }
                                            onChange={(newValue) => onChange(newValue,'name',index)} />
                                    </td>
                                    <td style={{width:'70%',padding:'5px'}}>
                                        <Select 
                                            bordered={false} 
                                            placeholder="Large, Cotton"
                                            mode="tags" 
                                            value={value['options']}
                                            options={variantOptions}
                                            style={{width:'100%'}}
                                            onChange={(newValue) => onChange(newValue,'options',index)} 
                                            />
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
            <div style={{textAlign:'right'}}>
                <Button type="default" className="rounded-button hover-scale-sm" style={{borderColor:'#ef3078',color:'#ef3078'}} onClick={addRow} >
                    Add an Option
                </Button>
            </div>
        </div>
    )
}

export default ProductOptionsTable;
