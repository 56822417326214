import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, Space } from "antd";
import SectionHeader from '../../Header/SectionHeader';
import ProductVariantsForm from '../../Form/Shop/ProductVariantsForm';

function ProductVariantsDrawer({formValues, setFormValues, isProductVariantsDrawerVisible, setIsProductVariantsDrawerVisible, setIsProductOptionsDrawerVisible, initialValue, addItem=false}){
    const [form] = Form.useForm();

    const closeDrawer = () => {
        setIsProductVariantsDrawerVisible(false);
        form.resetFields();
    }

    const onSave = () => {
        const productValues = form.getFieldsValue();
        if(Object.keys(initialValue).includes('index')){
            let newVariantValues = [...formValues?.variants];
            newVariantValues[initialValue?.index] = productValues;
            setFormValues({...formValues,variants:newVariantValues});
        }
        else{
            let newVariantValues = [productValues,...formValues?.variants].map((value,index) => {return {...value,index:index}});
            setFormValues({...formValues,variants:newVariantValues});
        }
    }

    useEffect(() => {
        let updateValue = {...initialValue};
        updateValue['currency'] = initialValue?.currency || formValues?.currency || '';
        form.setFieldsValue(updateValue);
    },[initialValue]);
    
    return(
        <Drawer             
            open={isProductVariantsDrawerVisible} 
            bodyStyle={{ overflowY:'auto',maxHeight:'95vh'}}
            footer={null} 
            onClose={closeDrawer}
            width={window.innerWidth > 1000 ? '550px' :'100%'}
            centered={true}
            destroyOnClose={true}
            extra={
                initialValue
                ?
                    <Space size={0}>
                        <Button type={'text'} className='rounded-button' onClick={closeDrawer} style={{color:'rgba(0,0,0,0.6)'}} >Cancel</Button>
                        <Button type={'primary'} className='rounded-button' onClick={onSave} style={{color:'white',backgroundColor:'#ef3078',borderColor:'#ef3078'}} >Save</Button>
                    </Space>
                : <Button type={'primary'} className='rounded-button' onClick={onSave} style={{color:'white',backgroundColor:'#ef3078',borderColor:'#ef3078'}} >Create</Button>
            }>
            <div id='post-modal'></div>
            <div style={{paddingBottom:'120px'}}>
                <div style={{paddingLeft:'15px',paddingBottom:'10px'}}>
                    <SectionHeader name='Create Variants' description='' />
                </div>
                <div>
                    <ProductVariantsForm form={form} formValues={formValues} setFormValues={setFormValues} setIsProductOptionsDrawerVisible={setIsProductOptionsDrawerVisible} addItem={addItem} onClose={closeDrawer} />
                </div>
            </div>
        </Drawer>
    )
}
export default ProductVariantsDrawer;