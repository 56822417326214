import LoginForm from "../../components/Form/LoginForm";
import androidDownload from '../../images/download/android.webp';
import iosDownload from '../../images/download/ios.webp';
import { HeartOutlined, LeftOutlined } from "@ant-design/icons";
import { BackgroundImages } from "../../resources/BackgroundImages";
import { useEffect, useState } from "react";
import { Button } from "antd";
import { useAuth } from "../../utils/useAuth";
import { useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";

function LoginPage(){
    const {user} = useAuth();
    const navigate = useNavigate();
    
    const [backgroundImages,setBackgroundImages] = useState([]);

    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;
        while (currentIndex != 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        } 
        return array;
    }
    const setupArrays = async () => {
        let imageValues = [];
        const shuffle1 = await shuffle([...BackgroundImages]);
        imageValues = [...imageValues,shuffle1];
        const shuffle2 = await shuffle([...BackgroundImages]);
        imageValues = [...imageValues,shuffle2];
        if(window.innerWidth > 1000){
            const shuffle3 = await shuffle([...BackgroundImages]);
            imageValues = [...imageValues,shuffle3];
            const shuffle4 = await shuffle([...BackgroundImages]);
            imageValues = [...imageValues,shuffle4];
            const shuffle5 = await shuffle([...BackgroundImages]);
            imageValues = [...imageValues,shuffle5];
        }
        setBackgroundImages(imageValues);
    }
    const goBack = () => {
        console.log('goBack');
        navigate(-1);
    }
    useEffect(() => {
        if(user?.user_id){
            navigate('/profile');
        }
        setupArrays();
    },[]);

    return(
        <div className='full-page-center'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>WanderCart - Login to your Account!</title>
                <meta name="description" content="Login to your WanderCart Account. Promote and sell your favorite products on WanderCart. Manage all your Products on the WanderCart Dashboard Product Manger." />
                <link rel="canonical" href="https://www.wanderc.art/" />
            </Helmet>
            <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,overflowY:'hidden',opacity:0.1}}>
                <div style={{height:'100%'}}>
                    {
                        backgroundImages.map((value,index) => 
                            <div key={index} className='align-div' style={{width: window.innerWidth > 1000 ? '20%' :'50%', marginTop: index % 2 === 0 ? '0px':'-200px'}}>
                                {
                                    value.slice(5).map((postValue,postIndex) => 
                                        <div key={postIndex} className='post-image-container' style={{padding:5}}>
                                            <div className='post-image' style={{backgroundImage:`url(${postValue.image})`}} >
                                            </div>
                                            <div className="post-like-container">
                                                <Button icon={<HeartOutlined size={12}/>} shape="circle" ></Button>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="center-full">
                <div>
                    <div className="register-page-form-container">
                        <LoginForm />
                    </div>
                    <div style={{marginTop:'4vh'}}>
                        <div>
                            <h3>Get WanderCart.</h3>
                        </div>
                        <div>
                            <a href='https://apps.apple.com/us/app/wandercart/id6450434248' target='_blank' className='align-div'>
                                <img src={iosDownload} className='navbar-download-links' style={{height:'3rem'}} />
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=com.daiki.wandercart.shopapp' target='_blank' className='align-div'>
                                <img src={androidDownload} className='navbar-download-links' style={{height:'3rem'}} />
                            </a>
                        </div>
                    </div>
                </div>
                <div style={{position:'absolute',top:'45px',left:'20px'}}>
                    <Button type="circle" icon={<LeftOutlined size={32} color={'rgba(0,0,0,0.8)'} style={{strokeWidth:'5'}} />} onClick={goBack} style={{border:'1px solid rgba(0,0,0,0.2)',backgroundColor:'white'}} size='large' />
                </div>
            </div>
        </div>
    )
}
export default LoginPage;