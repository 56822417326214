import { PictureOutlined, PlusOutlined, ShoppingOutlined } from "@ant-design/icons";
import { useRef } from "react";
import ProductServices from "../../utils/ProductServices";
import { useAuth } from "../../utils/useAuth";
import { useEventTracker } from "../Context/EventTrackingContext";


function ProductImageUploadSquare({imageList, setImageList, user, setUploading, onClick=false, iconOnly=false, hasChanges=false}) {
    const inputFile = useRef(null);
    const {refresh} = useAuth();
    const {sendEvent} = useEventTracker();

    const uploadClick = () => {
        if(onClick){
            onClick();
        }
        else {
            inputFile.current.click();
        }
        sendEvent({category:'image', action:'upload_click',label:'product'});
    };
    const fileUpload = async (event) => {
        setUploading(true);
        var file = event.target.files[0];    
        var reader = new FileReader();
        reader.readAsDataURL(file);

        let productImageUploadResponse = await ProductServices.postProductImage({image:file, name: `${user.username}-${file.name}`, user:user})
        if(productImageUploadResponse.status !== 'success'){
            const refreshUser = await refresh();
            productImageUploadResponse = await ProductServices.postProductImage({image:file, name: `${user.username}-${file.name}`, user:refreshUser})
        }
        console.log(productImageUploadResponse);
        console.log(productImageUploadResponse.data);
        console.log([...imageList, productImageUploadResponse.data]);

        setImageList([...imageList, productImageUploadResponse.data]);
        setUploading(false);
        if(hasChanges){
            hasChanges();
        }
        sendEvent({category:'image', action:'upload_completed',label:'product'});
    };
    
    return(
        <div className="center-full upload-button-square">
            <div onClick={uploadClick}>
                {
                    iconOnly
                    ? <PlusOutlined />
                    : <PictureOutlined style={{fontSize:'21px',color:'rgba(0,0,0,0.6)'}} />
                }
                {
                    !iconOnly &&
                        <div style={{ marginTop:8,color:'rgba(0,0,0,0.8)' }}>Add Photo</div>
                }
            </div>
            <input type='file' id='file' ref={inputFile} onChange={(value) => fileUpload(value)} style={{display: 'none'}}/>
        </div>

    )
}

export default ProductImageUploadSquare;