import React  from 'react';
import { Button, Input, Modal, Tooltip, message } from "antd";
import { CopyOutlined } from '@ant-design/icons';
import i18next from '../../utils/i18n';

function ShareUserModal({isShareUserModalVisible, setIsShareUserModalVisible, userDetails=false}){

    const clickShare = () => {
        navigator.clipboard.writeText(`${window.location.origin}/${userDetails?.username}`);
        message.success(i18next.t('dashboard.alerts.copy_clipboard'));
    }
    return(
        <Modal             
            open={isShareUserModalVisible} 
            footer={null} 
            onCancel={() => setIsShareUserModalVisible(false)}
            style={{width:'100%',borderRadius:'20px',overflow:'hidden'}}
            className='share-modal'
            closable={false}
            centered
            destroyOnClose
            >
            <div style={{padding:'10px',paddingBottom:'20px'}}>
                <div style={{marginBottom:'20px'}}>
                    <div className="align-div">
                        <img src={userDetails?.avatar_img || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReh8NuEJj15o7u5x7dW99KPdSO_rH0LKihMw&usqp=CAU'} className="avatar-sidebar" style={{width:'45px',height:'45px'}} />
                    </div>
                    <div className="align-div" style={{paddingTop:'5px',paddingLeft:'5px'}}>
                        <div>
                            <h2 style={{fontSize:'17px',marginBottom:0}}>{userDetails?.username}</h2>
                        </div>
                        <div>
                            <div className="align-div user-stats-text">
                                {userDetails?.followers > 1 ? i18next.t('dashboard.general.followersWithOptions',{val:userDetails?.followers}) : i18next.t('dashboard.general.followers',{count:userDetails?.followers})}
                            </div>
                            <div className="align-div user-stats-text">
                                {userDetails?.likes > 1 ? i18next.t('dashboard.general.likesWithOptions',{val:userDetails?.likes}) : i18next.t('dashboard.general.likes',{count:userDetails?.likes})}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{padding:'5px'}}>
                        <h2 style={{marginBottom:1}}>{i18next.t('dashboard.share.share_profile')}</h2>
                    </div>
                    <div>
                        <Input value={`${window.location.origin}/${userDetails?.username}`} className='share-modal-input' size='large' suffix={<Tooltip title={i18next.t('dashboard.share.copy_to_clipboard')}><CopyOutlined style={{color:'rgba(0,0,0,0.6)',cursor:'pointer'}} onClick={clickShare}/></Tooltip>} />
                    </div>
                    <div style={{paddingLeft:'10px'}}>
                        <a onClick={clickShare} style={{fontSize:'12px'}}>{i18next.t('dashboard.share.copy_my_link')}</a>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default ShareUserModal;