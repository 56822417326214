import axios from "axios";

const SHOP_URL = `${process.env.REACT_APP_SERVER_URL}/collab/${process.env.REACT_APP_API_KEY}`;

class OfferServices {
    createEditOffer({values, user}){
        const configuration = {
            credentials: 'include',
            method: "post",
            url: SHOP_URL + "/offer",
            data: values,
            headers: {"Authorization" : `Bearer ${user.access}`},
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }
    getOffer({offer_id}) {
        const configuration = {
            method: "get",
            url: SHOP_URL + `/offer/${offer_id}`
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    };
    getOffersList({page=0, ordering='-updated_at',user_id=false,service_id=false,sender_id=false,sender_brand_id=false,receiver_id=false,receiver_brand_id=false,filters=false}){

        const limit = 12;
        const offset = limit * page || 0;

        var configAPIUrl = SHOP_URL
        if (offset > 0){configAPIUrl = SHOP_URL + `/offers?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True`}
        else { configAPIUrl = SHOP_URL + `/offers?limit=${limit}&ordering=${ordering}&is_active=True` };

        if(user_id){
            configAPIUrl = configAPIUrl + `&user_id=${user_id}`;
        }
        if(service_id){
            configAPIUrl = configAPIUrl + `&service_id=${service_id}`;
        }
        if(sender_id){
            configAPIUrl = configAPIUrl + `&sender_id=${sender_id}`;
        }
        if(sender_brand_id){
            configAPIUrl = configAPIUrl + `&sender_brand_id=${sender_brand_id}`;
        }
        if(receiver_id){
            configAPIUrl = configAPIUrl + `&receiver_id=${receiver_id}`;
        }
        if(receiver_brand_id){
            configAPIUrl = configAPIUrl + `&receiver_brand_id=${receiver_brand_id}`;
        }
        if(filters?.search){
            configAPIUrl = configAPIUrl + `&search=${filters?.search.join(',')}`;
        }
        if(filters){
            Object.keys(filters).forEach((value) => {
                if(value !== 'search' && filters[value].length > 0){
                    configAPIUrl = configAPIUrl + `&${value}=${filters[value].join(',')}`;
                }
            });
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    } 
}

export default new OfferServices();