import axios from "axios";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}`;

class CollectionServices {

    getCollections({page=0, ordering='display_order', filter=false, displayValues=false, search=false, user_id=false, username=false}){
        var configAPIUrl = API_URL + `/collection/list`;

        const limit = 12;
        const offset = limit * page || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/collection/list?limit=${limit}&offset=${offset}&ordering=${ordering}`}
        else { configAPIUrl = API_URL + `/collection/list?limit=${limit}&ordering=${ordering}`};

        if(search){
            configAPIUrl = configAPIUrl + `&search=${'&'.join(search.split(' '))}`
        }
        if(user_id){
            configAPIUrl = configAPIUrl + `&user_id=${user_id}`
        }
        if(username){
            configAPIUrl = configAPIUrl + `&user_id__username=${username}`
        }
        if(displayValues){
            configAPIUrl = configAPIUrl + `&display_order__gte=1`
        }
        if(filter){
            configAPIUrl = configAPIUrl + `&${filter}`
        }

        const configuration = {
            method: "get",
            url: configAPIUrl
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            return error.response;
        });
    }

    getCollectionUserRanking({page=0, collection_id, ordering='-likes'}){
        var configAPIUrl = API_URL + `/collection/ranking`;

        const limit = 12;
        const offset = limit * page || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/collection/ranking?limit=${limit}&offset=${offset}&ordering=${ordering}&collection__id=${collection_id}`}
        else { configAPIUrl = API_URL + `/collection/ranking?limit=${limit}&ordering=${ordering}&collection__id=${collection_id}`};

        const configuration = {
            method: "get",
            url: configAPIUrl
        };

        return axios(configuration)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            return error.response;
        });
    }
    
    async getCollectionLikeList({user_id=false, page=0, ordering='-updated_at'}){
        const limit = 12;
        const offset = limit * page || 0;
        if(user_id){
            var configAPIUrl = API_URL;
            if (offset > 0){configAPIUrl = API_URL + `/collection/likes?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True&user_id=${user_id}`}
            else { configAPIUrl = API_URL + `/collection/likes?limit=${limit}&ordering=${ordering}&is_active=True&user_id=${user_id}` };

            var configuration = {
                credentials: 'include',
                method: "get",
                url: configAPIUrl,
            };

            // Send POST Query with Login Info
            return axios(configuration)
            .then(response => {
                return response.data
            })
            .catch((error) => {
                error = new Error();
            });
        }
        else{
            return false
        }
    }
}

export default new CollectionServices();