import { useEffect, useState } from "react";

function PricePreview({price, currency}){
    const [priceFormatted,setPriceFormatted] = useState(false)

    const shortenPrice = {
        'Rp':{
            'tril':'T',
            'bil':'M',
            'mil':'jt',
            'thou':'K'
        },
        'else':{
            'tril':'T',
            'bil':'B',
            'mil':'M',
            'thou':'K'
        }
    }

    useEffect(() => {
        if(price >= 1000000000000){
            setPriceFormatted(`${price / 1000000000000}${currency === 'Rp' ? shortenPrice['Rp']['tril'] : shortenPrice['else']['tril']}`)
        }
        else if(price >= 1000000000){
            setPriceFormatted(`${price / 1000000000}${currency === 'Rp' ? shortenPrice['Rp']['bil'] : shortenPrice['else']['bil']}`)
        }
        else if(price >= 1000000){
            setPriceFormatted(`${price / 1000000}${currency === 'Rp' ? shortenPrice['Rp']['mil'] : shortenPrice['else']['mil']}`)
        }
        else if(price >= 1000){
            setPriceFormatted(`${price / 1000}${currency === 'Rp' ? shortenPrice['Rp']['thou'] : shortenPrice['else']['thou']}`)
        }
        else{
            setPriceFormatted(`${price || 0}`)
        }
    },[price]);

    return(
        <div>
            {currency === 'Rp' ? 'Rp ':currency}{priceFormatted}
        </div>
    )
}

export default PricePreview;