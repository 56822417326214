import { Button, Space } from "antd";
import { useEffect } from "react";
import Lottie from "lottie-react";
import checkingLottie from "../../../images/animation/checking.json";
import checkSuccessLottie from "../../../images/animation/check-success.json";
import checkFailLottie from "../../../images/animation/check-fail.json";
import { useNavigate } from "react-router-dom";

function OnboardingForm6({formValues,submitStep}){
    const navigate = useNavigate();
    
    const showSteps = () => {
        var fields = document.querySelectorAll("#inputFields");
        if(fields.length > 0){
            setTimeout(() => {
                if(formValues.username.length > 0){
                    fields[0].style.display = 'block';
                }
                if(formValues.email.length > 0){
                    fields[1].style.display = 'block';
                }
            },[1000]);
        }
    };

    const checkingDiv = (
        <div style={{width:'100%',marginBottom:'30px',textAlign:'center'}}>
            <h2 style={{fontWeight:600,marginBottom:'20px',color:'rgba(0,0,0,0.8)'}}>
                <strong>Checking User Details...</strong>
            </h2>
            <div style={{maxWidth:'250px',margin:'auto'}}>
                <Lottie animationData={checkingLottie} loop={true} />
            </div>
        </div>
    );

    const createUserDiv = (
        <div style={{width:'100%',marginBottom:'30px',textAlign:'center'}}>
            <h2 style={{fontWeight:600,marginBottom:'20px',color:'rgba(0,0,0,0.8)'}}>
                <strong>Creating User Account...</strong>
            </h2>
            <div style={{maxWidth:'250px',margin:'auto'}}>
                <Lottie animationData={checkingLottie} loop={true} />
            </div>
        </div>
    );

    const createBrandDiv = (
        <div style={{width:'100%',marginBottom:'30px',textAlign:'center'}}>
            <h2 style={{fontWeight:600,marginBottom:'20px',color:'rgba(0,0,0,0.8)'}}>
                <strong>Creating Your Brand...</strong>
            </h2>
            <div style={{maxWidth:'250px',margin:'auto'}}>
                <Lottie animationData={checkingLottie} loop={true} />
            </div>
        </div>
    );

    const successDiv = (
        <div style={{width:'100%',textAlign:'center'}}>
            <div style={{maxWidth:'200px',width:'40%',margin:'auto'}}>
                <Lottie animationData={checkSuccessLottie} loop={false} />
            </div>
            <h2 style={{fontWeight:600,marginBottom:0,color:'rgba(0,0,0,0.8)'}}>
                Congratulations!<br/>
            </h2>
            <div style={{maxWidth:'350px',margin:'auto',paddingTop:'15px',paddingBottom:'30px'}}>
                <h3 style={{marginBottom:0}}>
                    Your Onboarding was Completed Successfully!
                    Our Team is verifying your details
                    and will reach out to you shortly!
                </h3>
            </div>
            <div style={{paddingBottom:'15vh'}}>
                <h3 style={{fontWeight:600}}>
                    Getting Started:
                </h3>
                {
                    formValues?.type === 'brand'
                    ?
                        <Space direction="vertical">
                            <Button type='outline' size='large' className='rounded-button' onClick={() => navigate('/profile?tab=Settings')} style={{color:'#ef3078',borderColor:'#ef3078',width:'100%'}}>Fill in Brand Guide</Button>
                            <Button type='outline' size='large' className='rounded-button' onClick={() => navigate('/profile?tab=Collabs')} style={{color:'#ef3078',borderColor:'#ef3078',width:'100%'}}>Create a Collaboration</Button>
                            <Button type='outline' size='large' className='rounded-button' onClick={() => navigate('/profile?tab=Product')} style={{color:'#ef3078',borderColor:'#ef3078',width:'100%'}}>Add to Product Catalog</Button>
                        </Space>
                    :
                        <Space direction="vertical">
                            <Button type='outline' size='large' className='rounded-button' onClick={() => navigate('/work?tab=Collabs')} style={{color:'#ef3078',borderColor:'#ef3078',width:'100%'}}>Apply to Collaborations</Button>
                            <Button type='outline' size='large' className='rounded-button' onClick={() => navigate('/shop?tab=Fashion')} style={{color:'#ef3078',borderColor:'#ef3078',width:'100%'}}>Shop Products</Button>
                        </Space>
                }
            </div>
        </div>
    );

    const errorDiv = (
        <div style={{width:'100%',marginBottom:'30px',textAlign:'center'}}>
            <h2 style={{fontWeight:600,marginBottom:'20px',color:'rgba(0,0,0,0.8)'}}>
                <strong>Sorry! You are not eligible at this time!</strong>
            </h2>
            <div style={{maxWidth:'180px',margin:'auto'}}>
                <Lottie animationData={checkFailLottie} loop={false} />
            </div>
        </div>
    );

    useEffect(() => {
        showSteps();
    },[formValues.username,formValues.email]);
    
    return(
        <div style={{width:'100%',textAlign:'center',paddingBottom:'3vh'}}>
            {
                {
                    'checking':checkingDiv,
                    'creating_user':createUserDiv,
                    'creating_brand':createBrandDiv,
                    'success':successDiv,
                    'error':errorDiv
                }[submitStep]
            }
        </div>
    )
}

export default OnboardingForm6;