import { CloseCircleOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";
import i18next from '../../utils/i18n';

function ProductImageRemovePopconfirm({onConfirm, text=false}) {
    
    return(
        <div style={{position:'absolute',top:'5px',right:'5px'}}>
            <Popconfirm
                title={text ? text : i18next.t('dashboard.general.delete_image')}
                onConfirm={onConfirm}
                okText={i18next.t('dashboard.general.yes')}
                cancelText={i18next.t('dashboard.general.no')}
                >
                <CloseCircleOutlined style={{color:'red'}}  />
            </Popconfirm>
        </div>
    )
}

export default ProductImageRemovePopconfirm;