import React, { useState } from 'react';
import { Button, Modal } from "antd";
import iconImg from '../../images/logo/icon-gradient.jpeg';
import androidDownload from '../../images/download/android.webp';
import iosDownload from '../../images/download/ios.webp';
import downloadQrImg from '../../images/homepage/download_qr.webp';

function DownloadModal({isDownloadModalVisible, setIsDownloadModalVisible, message=false}){
    const [showQr,setShowQr] = useState(false);
    return(
        <Modal             
            open={isDownloadModalVisible} 
            footer={null}
            onCancel={() => setIsDownloadModalVisible(false)}
            destroyOnClose={true}
             >
            <div className="modal-div" style={{paddingBottom:'35px'}}>
                <div>
                    <h1 style={{lineHeight:1.2}}>
                        {message ? message : 'Download the App for Full Experience!'}
                    </h1>
                </div>
                <div style={{textAlign:'center'}}>
                    <div style={{paddingTop:'10px', paddingBottom:'5px'}}>
                        <h2>
                            Available for iOS and Android!
                        </h2>
                    </div>
                    <div>
                        <div className='align-div'>
                            <img src={iconImg} className='icon-button' style={{width: '45px', height:'45px'}} />
                        </div>
                        <a href='https://apps.apple.com/us/app/wandercart/id6450434248' target='_blank' className='align-div'>
                            <img src={iosDownload} className='navbar-download-links' />
                        </a>
                        <a href='https://play.google.com/store/apps/details?id=com.daiki.wandercart.shopapp' target='_blank' className='align-div'>
                            <img src={androidDownload} className='navbar-download-links' />
                        </a>
                    </div>
                    <Button type='link' style={{width:'100%'}} onClick={() => setShowQr(true)}>Use QR Code</Button>
                    {
                        showQr &&
                            <div style={{marginTop:'20px',marginBottom:'10px'}}>
                                <div className='align-div' style={{paddingLeft:'2px',paddingRight:'5px'}}>
                                    <img src={downloadQrImg} style={{width: '100px', height:'100px'}} />
                                </div>
                                <Button type='link' style={{width:'100%'}} onClick={() => setShowQr(false)}>Hide QR Code</Button>
                            </div>
                    }
                </div>
            </div>
        </Modal>
    )
}
export default DownloadModal;