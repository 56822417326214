import { Button, Form, Space, message } from "antd";
import { useEffect, useState } from "react";
import OnboardingForm0 from "./Onboarding/OnboardingForm0";
import OnboardingForm1 from "./Onboarding/OnboardingForm1";
import OnboardingForm2 from "./Onboarding/OnboardingForm2";
import OnboardingForm3 from "./Onboarding/OnboardingForm3";
import OnboardingForm4 from "./Onboarding/OnboardingForm4";
import OnboardingForm5 from "./Onboarding/OnboardingForm5";
import OnboardingForm6 from "./Onboarding/OnboardingForm6";
import { useAuth } from "../../utils/useAuth";
import UserServices from "../../utils/UserServices";
import { useEventTracker } from "../Context/EventTrackingContext";
import OnboardingFormLogin from "./Onboarding/OnboardingFormLogin";
import OnboardingForm8 from "./Onboarding/OnboardingForm8";
import BrandServices from "../../utils/BrandServices";
import { useNavigate } from "react-router-dom";
import OnboardingFormLandingSimple from "./Onboarding/OnboardingFormLandingSimple";

const defaultGoals = {
    'influencer':[
        {
            text:<div><strong>Earn</strong> $ and Promote New Products</div>,
            value:'Earn Money Promoting Products',
            status:true
        },
        {
            text:<div><strong>Access</strong> Exclusive Products</div>,
            value:'Access Exclusive Products',
            status:true
        },
        {
            text:<div><strong>Partner</strong> with Brands</div>,
            value:'Partner with Brands',
            status:true
        },
        {
            text:<div><strong>Save</strong> $ on Products</div>,
            value:'Save Money on Products',
            status:true
        },
        {
            text:'Other',
            value:'Other',
            status:false
        },
    ],
    'brand':[
        {
            text:<div><strong>Sell</strong> More Products</div>,
            value:'Sell More Products',
            status:true
        },
        {
            text:<div><strong>Grow</strong> Social Media Following</div>,
            value:'Grow Social Media Following',
            status:true
        },
        {
            text:<div><strong>Branding</strong> and Brand Awareness</div>,
            value:'Branding and Brand Awareness',
            status:true
        },
        {
            text:<div><strong>Save</strong> $ on Influencer Marketing</div>,
            value:'Save Money on Influencer Marketing',
            status:true
        },
        {
            text:'Other',
            value:'Other',
            status:false
        },
    ]
};

function OnboardingForm({defaultUser='influencer',onComplete=false, startPage=0}){
    const {setUser,refresh} = useAuth();
    const {sendEvent} = useEventTracker();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [page,setPage] = useState(startPage);
    const [formValues,setFormValues] = useState({type:defaultUser,country:'',region:'',categories:[],socials:[{type:'Instagram',username:'',follower_range:''}],goals:defaultGoals[defaultUser],username:'',email:'',password:''});
    const [submitStep,setSubmitStep] = useState('checking');

    const buttonStatus = formValues?.type === 'brand'
    ?
        {
            0:false,
            1:formValues.type !== '',
            2:formValues.name !== '',
            3:formValues.categories.length > 0,
            4:formValues.country !== '',
            5:formValues.socials.map(value => value.username.length).reduce(function(pv, cv) { return pv + cv; }, 0) > 0,
            6:true
        }
    :
        {
            0:false,
            1:formValues.type !== '',
            2:formValues.categories.length > 0,
            3:formValues.country !== '',
            4:formValues.socials.map(value => value.username.length).reduce(function(pv, cv) { return pv + cv; }, 0) > 0,
            5:true,
            6:true
        };

    const onFinish = async (user) => {
        setPage(page+1);
        const categories = await [...formValues.categories].map((value) => {return {name:value}});
        const goals = await [...formValues.goals].map((value) => {delete value.text; return value});

        const finalValues = {
            type:formValues.type,
            country:formValues?.country,
            region:formValues?.region,
            category:categories,
            socials_onboard:formValues.socials,
            goal:goals
        }
        
        let userUpdateResponse = await UserServices.editUserDetails({user:user,values:finalValues});
        if(userUpdateResponse.status === 401){
            const newUser = await refresh();
            userUpdateResponse = await UserServices.editUserDetails({user:newUser,values:finalValues});
        }
        if(userUpdateResponse.status === 'success'){
            Object.keys(finalValues).forEach((key) => {
                user[key] = finalValues[key];
            });
            setUser(user);
            sendEvent({category:'register', action:'submit_onboarding'});

            if(formValues?.type !== 'brand' && !onComplete){
                navigate('/profile?tab=Profile');
            }
            else {
                onComplete();
            }
        }
        else{
            setSubmitStep('error');
        }

        if(formValues?.type === 'brand'){
            setSubmitStep('creating_brand');

            const brandValues = {
                name: formValues?.name,
                country:[{name:formValues?.country}],
                category: categories,
                socials_onboard:formValues.socials,
            }

            let brandResponse = await BrandServices.createEditBrand({user:user, values:brandValues});
            if(brandResponse.status === 401){
                const newUser = await refresh();
                brandResponse = await BrandServices.createEditBrand({user:newUser, values:brandValues});
            }
            if(brandResponse.status === 'success'){
                message.success('Brand Created Successfully!');
                sendEvent({category:'register', action:'submit_onboarding_create_brand'});

                if(!onComplete){
                    navigate('/profile?tab=Profile');
                }
                else {
                    onComplete();
                }
            }
            else{
                setSubmitStep('error');
            }
        }

        if(onComplete){
            onComplete(user);
        }
    };

    useEffect(() => {
        if(formValues?.type){
            setFormValues({...formValues,goals:defaultGoals[formValues?.type]})
        }
    },[formValues?.type])

    return(
            page < 6
            ?
            <Form
                form={form}
                name="onboarding"
                layout="vertical"
                onFinish={null}
                scrollToFirstError
                style={{height:'100%'}}
                >
                <div style={{minHeight:'40vh',height:'100%',paddingTop:'10vh',paddingBottom:'10vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <div style={{width:'100%'}}>
                        {
                            formValues?.type === 'brand'
                            ?
                                {
                                    0:<OnboardingFormLandingSimple clickStart={() => setPage(1)} onComplete={onComplete} />,
                                    1:<OnboardingForm1 formValues={formValues} setFormValues={setFormValues} defaultUser={defaultUser} />,
                                    2:<div className="onboarding-page-form-md"><OnboardingForm8 formValues={formValues} setFormValues={setFormValues} /></div>,
                                    3:<OnboardingForm3 formValues={formValues} setFormValues={setFormValues} />,
                                    4:<div className="onboarding-page-form-md"><OnboardingForm2 formValues={formValues} setFormValues={setFormValues} /></div>,
                                    5:<OnboardingForm4 formValues={formValues} setFormValues={setFormValues} />,
                                    6:<div className="onboarding-page-form-md"><OnboardingForm8 formValues={formValues} setFormValues={setFormValues} /></div>,
                                }[page]
                            :
                                {
                                    0:<OnboardingFormLandingSimple clickStart={() => setPage(1)} onComplete={onComplete} />,
                                    1:<OnboardingForm1 formValues={formValues} setFormValues={setFormValues} defaultUser={defaultUser} />,
                                    2:<OnboardingForm3 formValues={formValues} setFormValues={setFormValues} />,
                                    3:<div className="onboarding-page-form-md"><OnboardingForm2 formValues={formValues} setFormValues={setFormValues} /></div>,
                                    4:<OnboardingForm4 formValues={formValues} setFormValues={setFormValues} />,
                                    5:<div className="onboarding-page-form-md"><OnboardingForm8 formValues={formValues} setFormValues={setFormValues} /></div>,
                                }[page]
                        }
                    </div>
                    {
                        page > 0 && page < 6 &&
                            <div style={{position:'absolute',bottom:'20px',right:'20px',zIndex:100}}>
                                {
                                    page === 1
                                    ?
                                        <Button.Group>
                                            <Space>
                                                <div className="align-div" style={{verticalAlign:'middle',padding:'5px',color:'rgba(0,0,0,0.6)'}}>
                                                    {page}/5
                                                </div>
                                                <Button type='primary' size='large' className='rounded-button' onClick={() => setPage(page+1)} style={{color:'white',backgroundColor:'#ef3078',borderColor:'#ef3078'}} >Next</Button>
                                            </Space>
                                        </Button.Group>
                                    :
                                        <Button.Group>
                                            <Space>
                                                <div className="align-div" style={{verticalAlign:'middle',padding:'5px',color:'rgba(0,0,0,0.6)'}}>
                                                    {page}/5
                                                </div>
                                                <Button type='primary' size='large' className='rounded-button' onClick={() => setPage(page-1)} style={{color:'#ef3078',backgroundColor:'white',borderColor:'#ef3078'}} >Back</Button>
                                                {
                                                    page === 5
                                                    ? <Button type='primary' size='large' className='rounded-button' onClick={() => setPage(page+1)} loading={loading} style={{color:'white',backgroundColor:'#ef3078',borderColor:'#ef3078'}} >Next</Button>
                                                    : <Button type='primary' size='large' className='rounded-button' disabled={!buttonStatus[page]} onClick={() => setPage(page+1)} style={{color:'white',backgroundColor:buttonStatus[page]?'#ef3078':'rgba(0,0,0,0.2)',borderColor:buttonStatus[page] ? '#ef3078':'rgba(0,0,0,0.1)'}} >Next</Button>
                                                }
                                            </Space>
                                        </Button.Group>
                                }
                            </div>
                        
                    }
                </div>
            </Form>
            :
                <div style={{minHeight:'40vh',height:'100%',paddingTop:'5vh',paddingBottom:'5vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    {
                        {
                            6:<OnboardingFormLogin formValues={formValues} onFinish={onFinish} goBack={() => setPage(page-1)} />,
                            7:<OnboardingForm6 formValues={formValues} submitStep={submitStep} />,
                        }[page]
                    }
                </div>
    )
}

export default OnboardingForm;