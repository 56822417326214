import { HeartOutlined, HomeOutlined, ShareAltOutlined, ShoppingOutlined, UserOutlined } from "@ant-design/icons";
import { useEventTracker } from "../Context/EventTrackingContext";

function MobileCreatorNavBar({user,buttonClick, selected}){
    const {sendEvent} = useEventTracker();

    const clickNavBar = (value) => {
        sendEvent({category:'navigation', action: value, label:'mobile_navbar'});
        buttonClick(`${value}`);
    }

    return(
        <div className='mobile-navbar-container no-desktop'>
            <div className='mobile-navbar'>
                <div className="align-div mobile-navbar-icon-container">
                    <div onClick={() => clickNavBar('posts')} style={{cursor:'pointer',borderColor: selected === 'posts' ? '#0891b2' : 'rgba(0,0,0,0.8)'}}>
                        <img src={user?.avatar_img || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReh8NuEJj15o7u5x7dW99KPdSO_rH0LKihMw&usqp=CAU'} className="mobile-navbar-avatar" />
                    </div>
                </div>
                <div className="align-div mobile-navbar-icon-container">
                    <ShoppingOutlined style={{fontSize:'29px',color: selected === 'products' ? '#0891b2' : 'rgba(0,0,0,0.8)'}} onClick={() => clickNavBar('products')} />
                </div>
                <div className="align-div mobile-navbar-icon-container">
                    <HeartOutlined style={{fontSize:'28px',color: selected === 'saved' ? '#0891b2' : 'rgba(0,0,0,0.8)'}}  onClick={() => clickNavBar('saved')} />
                </div>
                <div className="align-div mobile-navbar-icon-container" style={{paddingLeft:'14px'}}>
                    <ShareAltOutlined style={{fontSize:'26px'}}  onClick={() => clickNavBar('share')} />
                </div>
            </div>
        </div>
    )
}
export default MobileCreatorNavBar;