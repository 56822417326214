import { EditOutlined, LoadingOutlined, PictureOutlined, PlusOutlined } from "@ant-design/icons";
import { useRef, useState } from "react";
import { useAuth } from "../../utils/useAuth";
import i18next from '../../utils/i18n';
import noImage from '../../images/no_image.webp';
import { Button, message } from "antd";
import { useEventTracker } from "../Context/EventTrackingContext";
import UserServices from "../../utils/UserServices";

function BrandAvatarUpload({brandLogo, setBrandLogo, user}) {
    const inputFile = useRef(null);
    const {refresh} = useAuth();
    const {sendEvent} = useEventTracker();
    const [uploading, setUploading] = useState(false);

    const uploadClick = () => {
        inputFile.current.click();
        sendEvent({category:'image', action:'upload_brand_logo',label:'post'});
    };
    const fileUpload = async (event) => {
        setUploading(true);
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        
        let avatarImageUploadResponse = await UserServices.uploadAvatarImage({image:file, name: `${user.username}-avatar-${file.name}`, user:user})
        if(avatarImageUploadResponse.status !== 'success'){
            const newUser = await refresh();
            avatarImageUploadResponse = await UserServices.uploadAvatarImage({image:file, name: `${user.username}-avatar-${file.name}`, user:newUser})
        }
        if(avatarImageUploadResponse.status === 'success'){
            setBrandLogo(avatarImageUploadResponse.data);
            message.success(i18next.t('dashboard.alerts.update_avatar_success'));
        }
        else{
            message.info(i18next.t('dashboard.alerts.upload_error'));
        }
        setUploading(false);
    };

    return(
        <div onClick={uploadClick} style={{position:'relative',backgroundColor:'white'}}>
            <div style={{position:'absolute',bottom:'-7px',right:'-7px'}}>
                <Button className="hover-scale-sm" icon={uploading ? <LoadingOutlined /> : <EditOutlined />} shape="circle" size={'small'} style={{backgroundColor:'white',color:'#ef3078',borderColor:'#ef3078'}} />
            </div>
            <img src={brandLogo?.image || noImage} style={{width:'100%',aspectRatio:1/1,border:'1px solid rgba(0,0,0,0.2)',borderRadius:'10px',cursor:'pointer'}} />
            <input type='file' id='file' ref={inputFile} onChange={(event) => fileUpload(event)} style={{display: 'none'}}/>
        </div>

    )
}

export default BrandAvatarUpload;