import {  MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Checkbox, Collapse, Form, Input, InputNumber, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import { useAuth } from "../../../utils/useAuth";
import PricingTierForm from "./PricingTierForm";

const {Panel} = Collapse;

const currencies = [
    {
        label:'USD ($)',
        value:'$',
    },
    {
        label:'IDR (Rp)',
        value:'Rp',
    },
    {
        label:'THB (฿)',
        value:'฿',
    },
    {
        label:'MYR (RM)',
        value:'RM',
    },
    {
        label:'SGD (S$)',
        value:'S$',
    },
    {
        label:'PHP (₱)',
        value:'₱',
    },
    {
        label:'JPY (¥)',
        value:'¥',
    },
    {
        label:'INR (₹)',
        value:'₹',
    },
]

const followerCounts = [
    {
        label:'~1K',
        value:'~1k'
    },
    {
        label:'1K - 10K',
        value:'1Kto10K'
    },
    {
        label:'10K - 50K',
        value:'10Kto50K'
    },
    {
        label:'50K - 100K',
        value:'50Kto100K'
    },
    {
        label:'100K - 500K',
        value:'100Kto500K'
    },
    {
        label:'500K - 1M',
        value:'500Kto1M'
    },
    {
        label:'1M - 10M',
        value:'1Mto10M'
    },
    {
        label:'10M - 100M',
        value:'1Mto10M'
    },
    {
        label:'100M+',
        value:'100M'
    }
];


function ProductVariantsForm({form, formValues,setFormValues,setIsProductOptionsDrawerVisible}){
    const [productVariant,setProductVariant] = useState({});

    function OptionForm({optionValue,onChange}) {
        return(
            <div style={{marginBottom:'20px'}}>
                <div style={{padding:'2px',flex:1}}>
                    <div style={{fontWeight:'400',fontSize:'14px',marginBottom:'2px',color:'rgba(0,0,0,0.6)'}}>{optionValue.name}</div>
                </div>
                <Form.Item
                    name={optionValue?.name.toLowerCase()}
                    style={{marginBottom:'5px'}}
                    rules={[
                        {
                            required: true,
                            message: `${optionValue.name} is Required.`,
                        },
                    ]}
                    >
                    <Select
                        showSearch
                        size='large'
                        style={{ width:'100%',border:'1px solid rgba(0,0,0,0.2)',borderRadius:'10px'}}
                        bordered={false}
                        autoFocus={true}
                        placeholder={`Select ${optionValue.name} Type`}
                        onChange={(value) => setProductVariant({...productVariant,[optionValue.name]:value})}
                        >
                        {
                            optionValue?.options.map((value,index) => 
                                    <Select.Option key={`${optionValue}-type-${index}`} value={value} className="home-onboarding-select-option" >
                                        <div style={{fontWeight:'normal'}}>{value}</div>
                                    </Select.Option>
                                )
                        }
                    </Select>
                </Form.Item>
            </div>
        )
    }

    useEffect(() => {
        console.log(formValues);
        
    },[formValues])

    return(
        <Form
            name="product-variant-form"
            form={form}
            layout="vertical">
            <Collapse
                defaultActiveKey={'0'}
                accordion={true}
                bordered={false} 
                expandIconPosition={'end'} 
                expandIcon={({ isActive }) => isActive ? <MinusOutlined />:<PlusOutlined />}
                style={{fontSize:'18px',fontWeight:'500',backgroundColor:'white',padding:0}}>  
                <Panel header='General' key={'0'} >
                    <div style={{paddingBottom:'5vh'}}>
                        <div style={{marginBottom:'15px'}}>
                            <div style={{marginBottom:'15px'}}>
                                <div style={{padding:'2px',flex:1}}>
                                    <div style={{fontWeight:'400',fontSize:'14px',marginBottom:'2px',color:'rgba(0,0,0,0.6)'}}>Custom title</div>
                                </div>
                                <Form.Item
                                    name="name"
                                    style={{marginBottom:'5px'}}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Variant Name is Required.',
                                        },
                                    ]}
                                    >
                                    <Input style={{borderRadius:'10px'}} placeholder="Black Version" size='large' onChange={(value) => setProductVariant({...productVariant,name:value.target.value}) } />
                                </Form.Item>
                            </div>
                            <div style={{marginBottom:'20px'}}>
                                <div style={{padding:'2px',flex:1}}>
                                    <div style={{fontWeight:'400',fontSize:'14px',marginBottom:'2px',color:'rgba(0,0,0,0.6)'}}>Material</div>
                                </div>
                                <Form.Item
                                    name="material"
                                    style={{marginBottom:'5px'}}
                                    >
                                    <Input style={{borderRadius:'10px'}} placeholder="80% Cotton" size='large' onChange={(value) => setProductVariant({...productVariant,material:value.target.value}) } />
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{marginBottom:'15px'}}>
                            <div style={{marginBottom:'15px'}}>
                                <div style={{fontSize:'18px',fontWeight:'500',marginBottom:'15px'}}>
                                    Product Options
                                </div>
                            </div>
                            <div>
                                {
                                    formValues?.product_options && formValues?.product_options.length > 0 &&
                                        formValues.product_options.map((value) => 
                                            <OptionForm optionValue={value} onChange={(newValue) => setProductVariant({...productVariant,[value]:newValue}) }  />
                                        )
                                }

                            </div>
                            <div>
                                {
                                    formValues?.product_options && formValues?.product_options.length > 0
                                    ?<a style={{color:'#ef3078',fontSize:'12px'}} onClick={() => setIsProductOptionsDrawerVisible(true)}>Edit Options</a>
                                    :<a style={{color:'#ef3078',fontSize:'12px'}} onClick={() => setIsProductOptionsDrawerVisible(true)}>Add Options</a>   
                                }
                            </div>
                        </div>
                    </div>
                </Panel>
                <Panel header='Pricing' key={'1'} >
                    <div style={{paddingTop:'5vh',paddingBottom:'5vh'}}>
                        <div>
                            <div style={{fontSize:'16px',fontWeight:'500',marginBottom:'15px'}}>
                                Product Price
                            </div>
                            <div style={{display:'flex',marginBottom:'20px'}}>
                                <div style={{width:'30%',paddingRight:'4px'}}>
                                    <div style={{padding:'2px',flex:1}}>
                                        <div style={{fontWeight:'400',fontSize:'14px',marginBottom:'2px',color:'rgba(0,0,0,0.6)'}}>Currency</div>
                                    </div>
                                    <Form.Item
                                        name="currency"
                                        style={{marginBottom:'5px',width:'100%'}}
                                        >
                                        <Select
                                            size='large'
                                            style={{ width:'100%',border:'1px solid rgba(0,0,0,0.2)',borderRadius:'10px',height:'40px'}}
                                            bordered={false}
                                            placeholder="Select Currency"
                                            onChange={(value) => setProductVariant({...productVariant,currency:value})}
                                            >
                                            {
                                                currencies.map((value,index) => 
                                                        <Select.Option key={`currency-${index}`} value={value.value} className="home-onboarding-select-option" >
                                                            <div style={{fontWeight:'normal'}}>{value.label}</div>
                                                        </Select.Option>
                                                    )
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div style={{width:'70%',paddingLeft:'2px'}}>
                                    <div style={{padding:'2px',flex:1}}>
                                        <div style={{fontWeight:'400',fontSize:'14px',marginBottom:'2px',color:'rgba(0,0,0,0.6)'}}>Product Price</div>
                                    </div>
                                    <Form.Item
                                        name="price"
                                        style={{marginBottom:'5px'}}
                                        >
                                        <InputNumber 
                                            style={{borderRadius:'10px',width:'100%',overflow:'hidden'}} 
                                            placeholder="100,000" 
                                            size='large' 
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            onChange={(value) => setProductVariant({...productVariant,price:value}) } />
                                    </Form.Item>
                                </div>
                            </div>
                            <div style={{display:'flex',marginBottom:'20px'}}>
                                <div style={{width:'30%',paddingRight:'4px'}}>
                                    <div style={{padding:'2px',flex:1}}>
                                        <div style={{fontWeight:'400',fontSize:'14px',marginBottom:'2px',color:'rgba(0,0,0,0.6)'}}>Discount %</div>
                                    </div>
                                    <Form.Item
                                        name="discount_percent"
                                        style={{marginBottom:'5px'}}
                                        >
                                            <InputNumber 
                                                style={{borderRadius:'10px',width:'100%',overflow:'hidden'}} 
                                                placeholder="20"
                                                size='large' 
                                                formatter={(value) => `${value}%`}
                                                parser={(value) => value.replace('%', '')}
                                                onChange={(value) => setProductVariant({...productVariant,discount_percent:value}) }  />
                                    </Form.Item>
                                </div>
                                <div style={{width:'70%',paddingLeft:'2px'}}>
                                    <div style={{padding:'2px',flex:1}}>
                                        <div style={{fontWeight:'400',fontSize:'14px',marginBottom:'2px',color:'rgba(0,0,0,0.6)'}}>Discount Price</div>
                                    </div>
                                    <Form.Item
                                        name="discount_price"
                                        style={{marginBottom:'5px'}}
                                        >
                                            <InputNumber 
                                                style={{borderRadius:'10px',width:'100%',overflow:'hidden'}} 
                                                placeholder="80,000" 
                                                size='large' 
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                onChange={(value) => setFormValues({...formValues,discount_price:value}) } />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div style={{fontSize:'16px',fontWeight:'500',marginBottom:'5px'}}>
                                    Pricing Tiers
                                </div>
                                <div style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>Product tiers allow you to offer exclusive deals based on following size.</div>
                            </div>
                            <div>
                                <PricingTierForm formValues={formValues} setFormValues={setFormValues}/>
                            </div>
                        </div>
                    </div>
                </Panel>
                <Panel header='Attributes' key={'2'} >
                    <div style={{paddingTop:'5vh',paddingBottom:'5vh'}}>
                        <div style={{fontSize:'16px',fontWeight:'500',marginBottom:'15px'}}>
                            Dimensions
                        </div>
                        <div style={{display:'flex',marginBottom:'20px'}}>
                            <div style={{flexBasis:'50%',paddingRight:'4px'}}>
                                <div style={{padding:'2px',flex:1}}>
                                    <div style={{fontWeight:'400',fontSize:'14px',marginBottom:'2px',color:'rgba(0,0,0,0.6)'}}>Length</div>
                                </div>
                                <Form.Item
                                    name="dimension-length"
                                    style={{marginBottom:'5px'}}
                                    >
                                        <Input style={{borderRadius:'10px'}} placeholder="Length - ie. 28cm" size='large' onChange={(value) => setFormValues({...formValues,name:value.target.value}) } />
                                </Form.Item>
                            </div>
                            <div style={{flexBasis:'50%',paddingLeft:'2px'}}>
                                <div style={{padding:'2px',flex:1}}>
                                    <div style={{fontWeight:'400',fontSize:'14px',marginBottom:'2px',color:'rgba(0,0,0,0.6)'}}>Width</div>
                                </div>
                                <Form.Item
                                    name="dimension-width"
                                    style={{marginBottom:'5px'}}
                                    >
                                        <Input style={{borderRadius:'10px'}} placeholder="Width - ie. 19cm" size='large' onChange={(value) => setFormValues({...formValues,name:value.target.value}) } />
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{display:'flex',marginBottom:'20px'}}>
                            <div style={{flexBasis:'50%',paddingRight:'4px'}}>
                                <div style={{padding:'2px',flex:1}}>
                                    <div style={{fontWeight:'400',fontSize:'14px',marginBottom:'2px',color:'rgba(0,0,0,0.6)'}}>Height</div>
                                </div>
                                <Form.Item
                                    name="dimension-height"
                                    style={{marginBottom:'5px'}}
                                    >
                                        <Input style={{borderRadius:'10px'}} placeholder="Height - ie. 80cm" size='large' onChange={(value) => setFormValues({...formValues,name:value.target.value}) } />
                                </Form.Item>
                            </div>
                            <div style={{flexBasis:'50%',paddingLeft:'2px'}}>
                                <div style={{padding:'2px',flex:1}}>
                                    <div style={{fontWeight:'400',fontSize:'14px',marginBottom:'2px',color:'rgba(0,0,0,0.6)'}}>Weight</div>
                                </div>
                                <Form.Item
                                    name="dimension-weight"
                                    style={{marginBottom:'5px'}}
                                    >
                                        <Input style={{borderRadius:'10px'}} placeholder="Weight - ie. 14kg, 3g" size='large' onChange={(value) => setFormValues({...formValues,name:value.target.value}) } />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    
                </Panel>
            </Collapse>
        </Form>
    )
}

export default ProductVariantsForm;