import { Space } from "antd";
import { useEffect, useState } from "react";
import EditableTable from "./EditableTable";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import DeletePopconfirm from "../Popconfirm/DeletePopconfirm";

function ProductVariantTable({formValues,setFormValues,onEdit}){
    const [dataValues, setDataValues] = useState(formValues?.variants);
    const [tableColumns,setTableColumns] = useState([]);

    let defaultColumns = [
        {
            title: 'price',
            dataIndex: 'price',
            editable:true,
            render: (_, record) => (
                <div >
                    {record?.currency}{record?.price}
                </div>
            ),
            sorter: (a, b) => a.price - b.price,
        },
        {
            title: 'action',
            dataIndex: 'action',
            fixed: 'right',
            width: '80px',
            render: (_, record) =>
                <Space>
                    <a className="hover-scale-sm" onClick={() => onEdit(record)} >
                        <EditOutlined />
                    </a>
                    <DeletePopconfirm type="Variant" onDelete={() => onDelete(record)} iconOnly={true} />
                </Space>
        },
    ];

    const onDelete = (value) => {
        var newVariantList = [...formValues?.variants].filter(objValue => value.index !== objValue.index);
        setFormValues({...formValues,variants:newVariantList});  
    }

    const processData = (values) => {
        let newColumns = formValues?.product_options.map(value => {return {title:value.name.toLowerCase(),dataIndex:value.name.toLowerCase(),editable:true,sorter: (a, b) => a[value.name.toLowerCase()].localeCompare(b[value.name.toLowerCase()])}});
        setTableColumns([{title:'name',dataIndex:'name',width:'25%',editable:true,sorter:(a, b) => a.name.localeCompare(b.name)},...newColumns,...defaultColumns]);
        setDataValues(values);
    }

    const handleSave = (value) => {
        let newVariantList = [...formValues?.variants];
        newVariantList[value?.index] = value;
        setFormValues({...formValues,variants:newVariantList});        
    }

    useEffect(() => {
        if(formValues?.variants){
            processData(formValues?.variants);
        }        
    },[formValues?.variants]);

    return(
        <div style={{width:'100%'}}>
            <EditableTable data={dataValues} defaultColumns={tableColumns} handleSave={handleSave} />
        </div>
    )
}

export default ProductVariantTable;
