import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, Space, message } from "antd";
import { useAuth } from '../../../utils/useAuth';
import CreateCollabForm from '../../Form/Shop/CreateCollabForm';
import ServiceServices from '../../../utils/ServiceServices';
import logo from '../../../images/logo/icon-no-bg.webp';
import RegisterModal from '../../Modal/RegisterModal';

function CreateCollabDrawer({isCreateCollabDrawerVisible, setIsCreateCollabDrawerVisible, initialValues=false, addItem=false, onDelete=false}){
    const {user,refresh} = useAuth();
    const [form] = Form.useForm();
    const [formValues,setFormValues] = useState({});
    const [isLoading,setIsLoading] = useState(false);
    const [isLoadingDraft,setIsLoadingDraft] = useState(false);
    const [isRegisterModalVisible,setIsRegisterModalVisible] = useState(false);

    const closeDrawer = () => {
        setIsCreateCollabDrawerVisible(false);
        setIsLoading(false);
        setIsLoadingDraft(false);
        setFormValues({});
        form.resetFields();
    }

    const onPublish = async ({isDraft}) => {
        if(user){
            if(isDraft){ setIsLoadingDraft(true)}
            else{ setIsLoading(true)};
    
            let fieldValues = form.getFieldsValue();
    
            let collabValues = {
                ...formValues,
                is_draft:isDraft
            }
            
            if(formValues?.category){
                let categoriesTemp = [...formValues.category];
                const categories = categoriesTemp.map((value) => {return {name:value}});
                collabValues['category'] = categories;
            }
            if(formValues?.socials){
                let socialTemp = [...formValues.socials];
                collabValues['socials'] = socialTemp.map((value) => {return {type:value,username:user.username}});
            }
            if(formValues?.images){
                let imagesTemp = [...formValues.images];
                collabValues['images'] = imagesTemp.map((value) => value.id);
            }
            if(formValues?.budget){
                collabValues['budget'] = [formValues.budget];
            }
            if(fieldValues?.questions){
                collabValues['questions'] = fieldValues?.questions.map(value => {return {value: value}})
            }
            if(collabValues?.questions){
                collabValues['questions'] = collabValues?.questions.map(value => {return {value: value}})
            }
            if(fieldValues?.brand_id){
                collabValues['brand_id'] = fieldValues?.brand_id;
            }
            if(formValues?.brand_id?.id){
                collabValues['brand_id'] = formValues?.brand_id?.id;
            }
    
            let request_config = {values:collabValues,user:user};
            if(formValues?.id){
                request_config['service_id'] = formValues?.id;
            }

            let createCollabResponse = await ServiceServices.createEditService(request_config);
            if(createCollabResponse.status !== 'success'){
                const newUser = await refresh();
                request_config['user'] = newUser;
                createCollabResponse = await ServiceServices.createEditService(request_config);
            }
            if(createCollabResponse.status === 'success'){
                message.success('Collab was Successfully Created!');
                if(!formValues?.id){
                    addItem(createCollabResponse.data);
                }
                closeDrawer();
            }
    
            if(isDraft){ setIsLoadingDraft(false) }
            else{ setIsLoading(false)};
        }
        else{
            setIsRegisterModalVisible(true);
        }
    }

    useEffect(() => {
        let formattedValues = {...initialValues};
        formattedValues['socials'] = formattedValues?.socials ? formattedValues?.socials.map(value => value.type) : [];
        formattedValues['category'] = formattedValues?.category ? formattedValues?.category.map(value => value.name) : [];
        formattedValues['questions'] = formattedValues?.questions ? formattedValues?.questions.map(value => value.value) : [];
        if(formattedValues?.budget){ formattedValues['budget'] = formattedValues?.budget[0]};
        if(formattedValues?.description_more && formattedValues?.description_more.length > 0){
            formattedValues?.description_more.forEach((value) => {
                formattedValues[`description_more_${value.order}`] = value?.value
            });
        }
        setFormValues({...formattedValues});
        form.setFieldsValue(formattedValues);
    },[initialValues]);

    return(
        <Drawer             
            open={isCreateCollabDrawerVisible} 
            bodyStyle={{ overflowY:'auto',maxHeight:'95vh'}}
            footer={null} 
            onClose={closeDrawer}
            width={'100%'}
            centered={true}
            destroyOnClose={true}
            extra={
                <Space size={0}>
                    <Button type={'link'} className='rounded-button' loading={isLoadingDraft} style={{color:'#ef3078'}} onClick={() => onPublish({isDraft:true})}>Save as Draft</Button>
                    <Button type={'primary'} className='rounded-button' loading={isLoading} style={{color:'white',backgroundColor:'#ef3078',borderColor:'#ef3078'}} onClick={() => onPublish({isDraft:false})}>
                        {formValues?.id ? 'Save' : 'Create'}
                    </Button>
                </Space>
            }>
            <div id='post-modal'></div>
            <div className="content-wide-container" style={{paddingBottom:'20vh',maxWidth:'800px',textAlign:'left'}}>
                <div style={{padding:window.innerWidth > 1000 ? '15px' : 0,paddingTop:window.innerWidth > 1000 ? '30px' : '10px',paddingBottom:'15px'}}>
                    <Space style={{display:'flex',alignItems:'center',marginBottom:'5px'}}>
                        <div>
                            <img src={logo} alt="datarebel-logo" style={{width:'28px',aspectRatio:1/1}} />
                        </div>
                        <div>
                            <h1 style={{marginBottom:0,lineHeight:1}}>Create a Collab</h1>
                        </div>
                    </Space>
                    <div>
                        <h3 style={{marginBottom:0,color:'rgba(0,0,0,0.6)',fontWeight:'400'}}>
                            Try posting your collaboration ideas to get feedback and application from influencers.
                        </h3>
                    </div>
                </div>
                <CreateCollabForm form={form} formValues={formValues} setFormValues={setFormValues} successDelete={(value) => {closeDrawer();onDelete(value);}} />
            </div>
            <div className='no-desktop' style={{position:'fixed',bottom:0,left:0,right:0,padding:'10px',backgroundColor:'white'}}>
                <Button type={'primary'} className='rounded-button' size="large" loading={isLoading} style={{color:'white',backgroundColor:'#ef3078',borderColor:'#ef3078',width:'100%'}} onClick={() => onPublish({isDraft:false})}>
                    {formValues?.id ? 'Save Collaboration' : 'Create Collaboration'}
                </Button>
            </div>
            <RegisterModal isRegisterModalVisible={isRegisterModalVisible} setIsRegisterModalVisible={setIsRegisterModalVisible} />
        </Drawer>
    )
}
export default CreateCollabDrawer;