import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../utils/useAuth";
import { AlertOutlined, SettingOutlined, ShoppingOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { useEventTracker } from "../Context/EventTrackingContext";
import { Badge, Space } from "antd";

function DiscoveryMobileNavBar(){
    const { user } = useAuth();
    const navigate = useNavigate();
    const {sendEvent} = useEventTracker();
    const [searchParams] = useSearchParams();
    const tab = searchParams.get('tab');

    const clickNavBar = (value) => {
        sendEvent({category:'navigation', action: value, label:'mobile_navbar'});
        navigate(`/${value}`);
    }

    return(
        <div className='no-desktop' style={{position:'fixed',bottom:0,left:0,right:0,borderTop:'1px solid rgba(0,0,0,0.1)'}}>
            <div style={{background:'white',display:'flex',alignItems:'center',width:'100%',padding:'10px 5px 5px 5px'}}>
                <div style={{width:'33%'}}>
                    <Space direction="vertical" size={0}>
                        <TeamOutlined style={{fontSize:'24px',color:(window.location.pathname === '/work') ? '#ef3078' : 'rgba(0,0,0,0.6)'}}  onClick={() => clickNavBar('work?tab=Collabs')} />
                        <div style={{color:(window.location.pathname === '/work') ? '#ef3078' : 'rgba(0,0,0,0.8)',fontSize:'12px'}}>
                            Collabs
                        </div>
                    </Space>
                </div>
                {
                    false &&
                        <div style={{width:'20%'}}>
                            <Space direction="vertical" size={0}>
                                <ShoppingOutlined style={{fontSize:'24px',color:window.location.pathname === '/shop' ? '#ef3078' : 'rgba(0,0,0,0.6)'}}  onClick={() => clickNavBar('shop?tab=Products')} />
                                <div style={{color:window.location.pathname === '/shop' ? '#ef3078' : 'rgba(0,0,0,0.8)',fontSize:'12px'}}>
                                    Products
                                </div>
                            </Space>
                        </div>
                }
                <div style={{width:'33%'}}>
                    <Space direction="vertical" size={0}>
                        <Badge size="small" count={0} showZero>
                            <AlertOutlined style={{fontSize:'24px',color:window.location.pathname === '/active' ? '#ef3078' : 'rgba(0,0,0,0.6)'}}  onClick={() => clickNavBar('active?tab=Active')} />
                        </Badge>
                        <div style={{color:window.location.pathname === '/active' ? '#ef3078' : 'rgba(0,0,0,0.8)',fontSize:'12px'}}>
                            Activity
                        </div>
                    </Space>
                </div>
                <div style={{width:'33%'}}>
                    {
                        user
                        ?
                            <Space direction="vertical" size={0}>
                                <div onClick={() => clickNavBar('profile?tab=Profile')} style={{cursor:'pointer'}}>
                                    <img src={user?.avatar_img || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReh8NuEJj15o7u5x7dW99KPdSO_rH0LKihMw&usqp=CAU'} className="mobile-navbar-avatar" style={{borderColor:(window.location.pathname === '/profile' && tab === 'Profile') ? '#ef3078' : 'rgba(0,0,0,0.6)'}} />
                                </div>
                                <div style={{color:(window.location.pathname === '/profile' && tab === 'Profile') ? '#ef3078' : 'rgba(0,0,0,0.8)',fontSize:'12px'}}>
                                    Profile
                                </div>
                            </Space>
                        :
                            <Space direction="vertical" size={0}>
                                <UserOutlined style={{fontSize:'24px',color:'rgba(0,0,0,0.6)'}}  onClick={() => clickNavBar('login')} />
                                <div style={{color:'rgba(0,0,0,0.8)',fontSize:'12px'}}>
                                    Login
                                </div>
                            </Space>
                    }
                </div>
            </div>
        </div>
    )
}
export default DiscoveryMobileNavBar;