import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {translations} from '../resources/i18n/Internationalization';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        lng: "en", 
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: translations['en']
            },
            id: {
                translation: translations['id']
            }
        }
    });

i18n.services.formatter.add('numbers_shorten', (value, lng, options) => {
    if(value >= 1000000000){
        return `${value/1000000000}B`
    }
    else if(value >= 1000000){
        return `${value/1000000}M`
    }
    else if(value >= 10000){
        return `${value/1000}K`
    }
    else{
        return `${value.toLocaleString()}`
    }
});

export default i18n;