import { CloseCircleOutlined, DownOutlined } from "@ant-design/icons";
import { Dropdown, Input } from "antd";
import { BsTiktok,BsInstagram,BsTwitter,BsLinkedin,BsFacebook, BsYoutube, BsTwitch } from "react-icons/bs";
import lemon8Icon from '../../../images/homepage/for-influencers/lemon8-icon.png'
import FollowerGroupSelect from "../../Select/FollowerGroupSelect";

const socials = {
    'Instagram':{
        'icon': <BsInstagram fontSize={32} style={{verticalAlign:'middle'}} color='#cd486b'  />,
        placeholder: "What's Your IG?",
        color:'#cd486b'
    },
    'Tiktok':{
        'icon': <BsTiktok fontSize={32} style={{verticalAlign:'middle'}} color='black'  />,
        placeholder: "Post on TikTok?",
        color:'black'
    },
    'Facebook':{
        'icon': <BsFacebook fontSize={32} style={{verticalAlign:'middle'}} color='#1877F2'  />,
        placeholder: "Add Me on Facebook?",
        color:'#1877F2'
    },
    'Lemon8':{
        'icon': <img src={lemon8Icon} style={{width:'32px',aspectRatio:1/1}} />,
        placeholder: "Are You on Lemon8?",
        color:'#FAE933'
    },
    'Twitter':{
        'icon': <BsTwitter fontSize={32} style={{verticalAlign:'middle'}} color='#1DA1F2'  />,
        placeholder: "Do you have Twitter?",
        color:'#1DA1F2'
    },
    'YouTube':{
        icon: <BsYoutube fontSize={32} style={{verticalAlign:'middle'}} color='red'  />,
        placeholder: "What's Your Youtube Channel?",
        color:'red'
    },
    'Twitch':{
        'icon': <BsTwitch fontSize={32} style={{verticalAlign:'middle'}} color='#6441a5'  />,
        placeholder: "Live on Twitch?",
        color:'#6441a5'
    },
    'LinkedIn':{
        'icon': <BsLinkedin fontSize={32} style={{verticalAlign:'middle'}} color='#0072b1'  />,
        placeholder: "Let's Connect on LinkedIn?",
        color:'#0072b1'
    }
}

function SocialFormInput({onChange,onRemove,inputValue}) {
    
    const items = [
        {
            value:'Instagram',
            label: <BsInstagram fontSize={32} style={{verticalAlign:'middle'}} color='#cd486b' onClick={() => selectSocial('Instagram')} />,
            color:'#cd486b'
        },
        {
            value:'Tiktok',
            label: <BsTiktok fontSize={32} style={{verticalAlign:'middle'}} color='black' onClick={() => selectSocial('Tiktok')} />,
            color:'black'
        },
        {
            value:'Facebook',
            label: <BsFacebook fontSize={32} style={{verticalAlign:'middle'}} color='#1877F2' onClick={() => selectSocial('Facebook')} />,
            color:'#1877F2'
        },
        {
            value:'Lemon8',
            label: <img src={lemon8Icon} style={{width:'32px',aspectRatio:1/1}} onClick={() => selectSocial('Lemon8')} />,
            color:'#FAE933'
        },
        {
            value:'Twitter',
            label: <BsTwitter fontSize={32} style={{verticalAlign:'middle'}} color='#1DA1F2' onClick={() => selectSocial('Twitter')} />,
            color:'#1DA1F2'
        },
        {
            value:'YouTube',
            label: <BsYoutube fontSize={32} style={{verticalAlign:'middle'}} color='red' onClick={() => selectSocial('YouTube')} />,
            color:'red'
        },
        {
            value:'Twitch',
            label: <BsTwitch fontSize={32} style={{verticalAlign:'middle'}} color='#6441a5' onClick={() => selectSocial('Twitch')} />,
            color:'#6441a5'
        },
        {
            value:'LinkedIn',
            label: <BsLinkedin fontSize={32} style={{verticalAlign:'middle'}} color='#0072b1' onClick={() => selectSocial('LinkedIn')} />,
            color:'#0072b1'
        },
    ]
    const selectSocial = (value) => {
        changeSocials({...inputValue,type:value});
    }
    const changeSocials = (value) => {
        onChange(value);
    }

    return(
        <div className="social-media-field-container">
            <div className="social-media-select-container">
                <Dropdown menu={{ items }} trigger={'click'} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <a onClick={e => e.preventDefault()}>
                        <div className="align-div" style={{verticalAlign:'middle',paddingRight:'5px'}}>
                            {socials[inputValue?.type].icon}
                        </div>
                        <div className="align-div" style={{verticalAlign:'middle',color:"rgba(0,0,0,0.6)"}}>
                            <DownOutlined />
                        </div>                                    
                    </a>
                </Dropdown>
            </div>
            <div className="social-media-field-subcontainer-container">
                <div className="social-media-field-subcontainer" style={{flexBasis:'70%'}}>
                    <div style={{position:'absolute',top:'-15px',left:0,textAlign:'left',paddingLeft:'5px',fontSize:'12px',color:'rgba(0,0,0,0.6)'}}>
                        Your Username
                    </div>
                    <Input className="social-media-field-input" style={{borderColor:socials[inputValue?.type]?.color ? socials[inputValue?.type]?.color:'rgba(0,0,0,0.2)'}} placeholder={socials[inputValue?.type]?.placeholder || 'Your Name'} value={inputValue?.username} onChange={value => {changeSocials({...inputValue,username:value.target.value})}} bordered={false} size="large" autoFocus={true} />
                </div>
                <div className="social-media-field-subcontainer no-mobile" style={{flexBasis:'30%'}}>
                    <div style={{position:'absolute',top:'-15px',left:0,textAlign:'left',paddingLeft:'5px',fontSize:'12px',color:'rgba(0,0,0,0.6)'}}>
                        # of Followers
                    </div>
                    <FollowerGroupSelect borderColor={socials[inputValue?.type]?.color ? socials[inputValue?.type]?.color:'rgba(0,0,0,0.2)'} onChange={value => {changeSocials({...inputValue,follower_range:value})}} value={inputValue?.followers} />
                </div>
            </div>
            <div onClick={onRemove}>
                <CloseCircleOutlined className="social-media-delete" />
            </div>
        </div>
    )
}

export default SocialFormInput;