import axios from "axios";
import {message} from 'antd';

const AUTH_URL = process.env.REACT_APP_SERVER_URL + '/auth/';


class AuthService {
    login({username, password, noMessage=false}) {
        const configuration = {
            credentials: 'include',
            method: "post",
            url: AUTH_URL + "login/",
            data: {
                username: username,
                password: password
            },
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then(response => {
            if (response.data.access) {
                var user_data = response.data;

                var refreshExp = new Date();
                refreshExp.setDate(refreshExp.getDate() + 14);
                user_data['refreshExp'] = refreshExp;
                
                var accessExp = new Date();
                accessExp.setHours(accessExp.getHours() + 1);
                user_data['accessExp'] = accessExp;
            }
            if(!noMessage){
                message.success('Successful Login!');
            }
            return user_data
        })
        .catch((error) => {
            if (error.response.status === 401){
                message.error('Either Username and Password were Incorrect. Please try again!');
                return 401
            }
            else {
                message.error('Issue with Login. Please try again!');
                return 201
            }
            error = new Error();
        });
    }

    register({username, email, password}){
        const configuration = {
            credentials: 'include',
            method: "post",
            url: AUTH_URL + "register/",
            data: {
                username: username,
                email: email,
                password: password
            },
        };

        return axios(configuration)
        .then(response => {
            return response
        })
        .catch((error) => {
            message.error("Something went wrong! Please try again!");
            return error.response;
            error = new Error();
        });
    }

    logout() {
        localStorage.removeItem("user");
        window.location.href = '/login';
        message.success('Successful Log Out!');
    };

    refreshToken(user) {
        const configuration = {
            credentials: 'include',
            method: "post",
            url: AUTH_URL + "login/refresh/",
            data: { refresh: user.refresh }
        };

        return axios(configuration)
        .then(response => {
            if (response.data.access) {
                var user_data = response.data;
                var refreshExp = new Date();
                refreshExp.setDate(refreshExp.getDate() + 14);
                user_data['refreshExp'] = refreshExp;

                var accessExp = new Date();
                accessExp.setHours(accessExp.getHours() + 1);
                user_data['accessExp'] = accessExp;
                return user_data;
            }
        })
        .catch((error) => {
            return error.response
        });
    }

    checkRegister(data) {
        const configuration = {
            credentials: 'include',
            method: "post",
            url: AUTH_URL + "register/check/",
            data: { data }
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            error = new Error();
        });
    }

    checkLogin() {
        if (JSON.parse(localStorage.getItem('user')) !== null) {
        const configuration = {
            credentials: 'include',
            method: "post",
            url: AUTH_URL + "login/check/",
            headers: {"Authorization" : `Bearer ${JSON.parse(localStorage.getItem('user')).access}`}
        };
    
        return axios(configuration)
        .then(response => {
            console.log('User is Logged in.');
        })
        .catch((error) => {
            if (JSON.parse(localStorage.getItem('user'))) {
            localStorage.removeItem("user");
            };
            console.log('User needs login.');
            error = new Error();
        });
        }
        else {
            console.log('Access Token not Found.')
        }
    }

    getTokens() {
        var user_data = JSON.parse(localStorage.getItem('user'));
        var date = new Date().toISOString();

        // Check if there is login data
        if(user_data){
        //Check Expiration of AccessToken
        if(date > user_data.exp) {
            this.logout()
            return false
        }
        else {
            return user_data;
        }
        }
        else {
        return false
        }
    }
    updateUser({user,data}) {
        const configuration = {
            credentials: 'include',
            method: "patch",
            url: AUTH_URL + `update_profile/${user.user_id}/`,
            headers: {"Authorization" : `Bearer ${user.access}`},
            data:data
        };

        return axios(configuration)
        .then(response => {
            return response
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    };

    changePassword({user,data}) {
        const configuration = {
            credentials: 'include',
            method: "patch",
            url: AUTH_URL + `change_password/${user.user_id}/`,
            headers: {"Authorization" : `Bearer ${user.access}`},
            data:data
        };
        return axios(configuration)
        .then(response => {
            return response
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    };
    deleteUser({user}) {
        const configuration = {
            credentials: 'include',
            method: "delete",
            url: AUTH_URL + `delete/`,
            headers: {"Authorization" : `Bearer ${user.access}`}
        };
        return axios(configuration)
        .then(response => {
            return response
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    };
}

export default new AuthService();